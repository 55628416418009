import { Col, Row } from "antd";

import { FormInput } from "components/form/Input";
import { Toggle } from "components/Toggle";
import DepotSelect from "pages/depot-management/components/DepotSelect";
import { FilterDataType } from "pages/discharge-list-management/queries/types";

export const ContainerDetail = ({
  containerSizeType,
  listDischargeId,
}: FilterDataType) => (
  <Row gutter={[16, 0]}>
    <Col span={12}>
      <FormInput
        disabled
        name={`numberOfContainer-${containerSizeType}`}
        label="Number Of Containers"
        customFormItemClassName="!mb-1"
      />
    </Col>
    <Col span={12} className="relative">
      <DepotSelect
        name={`depotName-${containerSizeType}`}
        label="Depot Name"
        placeholder="Choose Depot Name"
        customFormItemClassName="!mb-1"
        filterDepots={(depot) => depot.status}
      />
      {listDischargeId.length <= 1 ? (
        <div className="absolute -top-2 right-0">
          <Toggle
            name={`isOfHide-${containerSizeType}`}
            customFormItemClassName="[&_.ant-form-item-row]:!flex-row [&_.ant-form-item-row]:!justify-end [&_.ant-form-item-row]:!flex-row [&_.ant-form-item-label]:!p-0 [&_.ant-form-item-label>label:after]:hidden [&_.ant-form-item-label>label]:pr-2 [&_.ant-form-item-label>label]:pt-[0.125rem] [&_.ant-form-item-label>label]:text-sm [&_.ant-form-item-label>label]:text-blue-sapphire [&_.ant-form-item-label>label]:font-roboto [&_.ant-form-item-label>label]:font-normal [&_.ant-form-item-label>label]:leading-8 [&_.ant-form-item-control]:!w-auto [&_.ant-form-item-control]:!grow-0 [&_.ant-form-item-control]:!mr-2 !mb-0"
            label="Off-Hire"
          />
        </div>
      ) : null}
    </Col>
  </Row>
);
