import { Tag, Typography } from "antd";

import { EDO_STATUS } from "constants/constants";
import { useMemo } from "react";

interface IStatusLabel {
  labelStatus: number;
  customClassName?: string;
  isResponsive?: boolean;
}

export const StatusLabel = ({
  labelStatus,
  customClassName = "",
  isResponsive = true,
}: IStatusLabel) => {
  const renderLabelColor = useMemo(() => {
    switch (labelStatus) {
      case EDO_STATUS.New.value:
        return {
          label: EDO_STATUS.New.label,
          color: `bg-blue-midnight-express`,
        };
      case EDO_STATUS.WaitForLog.value:
        return {
          label: EDO_STATUS.WaitForLog.label,
          color: `bg-violet-indigo`,
        };
      case EDO_STATUS.ReadyForRelease.value:
        return {
          label: EDO_STATUS.ReadyForRelease.label,
          color: `bg-green-pastel-green`,
        };
      case EDO_STATUS.Unrelease.value:
        return { label: EDO_STATUS.Unrelease.label, color: `bg-red-torch-red` };
      case EDO_STATUS.Shortship.value:
      case EDO_STATUS.Delete.value:
        return {
          label:
            labelStatus === EDO_STATUS.Delete.value
              ? EDO_STATUS.Delete.label
              : EDO_STATUS.Shortship.label,
          color: `bg-blue-rock-blue`,
        };
      case EDO_STATUS.Release.value:
        return {
          label: EDO_STATUS.Release.label,
          color: `bg-green-islamic-green`,
        };
      case EDO_STATUS.ReleaseUpdate.value:
        return {
          label: EDO_STATUS.ReleaseUpdate.label,
          color: `bg-green-tropical-rain-forest`,
        };
      default:
        return { label: "ReleaseUpdate", color: `bg-[yellow]` };
    }
  }, [labelStatus]);
  return (
    <Tag
      className={`${
        renderLabelColor.color
      } px-[6px] py-[4px] w-[fit-content] border-none truncate ${
        isResponsive ? "statusLabelSL:w-[70px]" : ""
      } `}
    >
      <Typography.Text
        className={`text-white font-roboto text-xs leading-4 font-normal ${customClassName}`}
      >
        {renderLabelColor.label}
      </Typography.Text>
    </Tag>
  );
};
