import EmptyPageIcon from "assets/icons/EmptyPageIcon.svg";
import { Typography } from "antd";

export const NoMatchPage = () => (
  <div className="h-full min-h-[60vh] flex flex-col items-center justify-start pt-16">
    <img className="mb-6" src={EmptyPageIcon} alt="table-empty" />
    <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-6">
      404 - Page Not Found
    </Typography.Text>
  </div>
);
