import { FormItemProps, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { FormSelect } from "components/form/Select";
import { MasterDataService, useMasterData } from "hooks/use-master-data";
import { stubTrue } from "lodash";
import { memo, useMemo } from "react";
import { Depot } from "../interfaces";

type DepotSelectProps = FormItemProps &
  SelectProps & {
    isLogHistory?: boolean;
    filterDepots?: (depot: Depot) => boolean;
    customFormItemClassName?: string;
    customSelectClassName?: string;
  };

function DepotSelect(props: DepotSelectProps) {
  const { isLogHistory, filterDepots = stubTrue } = props;
  const { isPending, masterData } = useMasterData([MasterDataService.DEPOTS]);

  const depots = masterData[MasterDataService.DEPOTS];

  const options: DefaultOptionType[] = useMemo(
    () =>
      (depots ?? []).filter(filterDepots).map((depot) => ({
        label: String(depot.depotName),
        value: isLogHistory
          ? JSON.stringify({
              label: depot.depotName,
              value: depot.depotId,
            })
          : depot.depotId,
      })),
    [depots, isLogHistory, filterDepots],
  );

  return (
    <FormSelect
      options={options}
      filterOption={(input, option) => {
        if (!option?.label) return false;

        return String(option.label).toLowerCase().includes(input.toLowerCase());
      }}
      loading={isPending}
      virtual
      showSearch
      {...props}
    />
  );
}

export default memo(DepotSelect);
