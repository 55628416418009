import {
  BLSearchingData,
  ConsigneeSearchingData,
  ContainerSizeType,
  EdoDetail,
  FiltersEdo,
  PreReadFileType,
  ReturnDateType,
  TreeDataType,
  UploadFileType,
} from "./interfaces";
import {
  ExportPDFByBillEmailPDF,
  ExportPDFParam,
  GetEdoListParams,
  SearchingListParams,
  UpdateBLWithCounterPayload,
  UpdateBLWithLogisticPayload,
  UpdatePayload,
  UpdateSingleEdo,
  blService,
  edoServices,
} from "./services";
import {
  IBasePaginationResponse,
  IBaseResponse,
  UserRole,
} from "interfaces/common-model";
import { UseQueryOptions, useMutation, useQuery } from "react-query";

import { CancelTokenSource } from "axios";
import { ExcelColumnSortType } from ".";
import { MutableRefObject } from "react";
import { REACT_QUERY_RETRY_TIMES } from "constants/constants";
import { fetchApi } from "libs/fetchAPI";
import { useCountry } from "./components/SelectCountry";

export type GetEdoListOptions = GetEdoListParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<TreeDataType>>,
    unknown
  >;

export type GetEdoDetailOptions = UseQueryOptions<IBaseResponse<EdoDetail>> & {
  doId: string;
};

export type GetBLSearchingOptions = SearchingListParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<BLSearchingData>>,
    unknown
  >;

export type GetConsigneeSearchingOptions = SearchingListParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<ConsigneeSearchingData>>,
    unknown
  >;
export type GetContainerSizeTypeOptions = UseQueryOptions<
  IBaseResponse<ContainerSizeType>
> & {
  payload: SelectALlPayload;
  id: string[];
  timestamp: string | null;
};

export type GetGetBillOfLadingListOptions = UseQueryOptions<
  IBaseResponse<any>
> & {
  payload: SelectALlPayload;
  id: string[];
  timestamp: string | null;
};

export type SelectALlPayload = {
  isSelectAll: boolean;
  filterRequestModel: FiltersEdo & { roleEnum: UserRole };
  listExceptEdoId: string[];
};

export const edoQueryKeys = {
  GET_EDO_LIST: "GET_EDO_LIST",
  GET_CONTAINER_SIZES: "GET_CONTAINER_SIZES",
  GET_EDO_BL_LIST_SEARCHING: "GET_EDO_BL_LIST_SEARCHING",
  GET_EDO_CONSIGNEE_SEARCHING: "GET_EDO_CONSIGNEE_SEARCHING",
  GET_EDO_POD_SEARCHING: "GET_EDO_POD_SEARCHING",
  GET_EDO_FPOD_SEARCHING: "GET_EDO_FPOD_SEARCHING",
  GET_EDO_EMPTY_DEPOT_SEARCHING: "GET_EDO_EMPTY_DEPOT_SEARCHING",
  GET_EDO_DETAIL: "GET_EDO_DETAIL",
  GET_CONTAINER_SIZE_TYPE_LIST: "GET_CONTAINER_SIZE_TYPE_LIST",
  GET_BILL_OF_LADING_LIST: "GET_BILL_OF_LADING_LIST",
  GET_PICKUP_DATE_RETURN_DATE_BY_ARRIVAL_DATE:
    "GET_PICKUP_DATE_RETURN_DATE_BY_ARRIVAL_DATE",
};

export const useGetEdoList = (options: GetEdoListOptions) => {
  const countryName = useCountry();

  return useQuery({
    queryKey: [edoQueryKeys.GET_EDO_LIST, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<TreeDataType>>>(
        edoServices.getEdoList({ ...options, countryName })
      ),
    ...options,
    retry: REACT_QUERY_RETRY_TIMES,
  });
};

export const useGetContainerSizes = () => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [edoQueryKeys.GET_CONTAINER_SIZES],
    queryFn: () =>
      fetchApi<IBaseResponse<string[]>>(
        edoServices.getContainerSize(countryName)
      ),
  });
};

export const useConsigneeList = (options?: GetConsigneeSearchingOptions) => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [edoQueryKeys.GET_EDO_CONSIGNEE_SEARCHING, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<ConsigneeSearchingData>>>(
        edoServices.getConsigneeList({ ...options, countryName })
      ),
    ...options,
  });
};

export const useGetPODList = (options?: GetBLSearchingOptions) => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [edoQueryKeys.GET_EDO_POD_SEARCHING, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<BLSearchingData>>>(
        edoServices.getPODList({ ...options, countryName })
      ),
    ...options,
  });
};

export const useGetFPODList = (options?: GetBLSearchingOptions) => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [edoQueryKeys.GET_EDO_FPOD_SEARCHING, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<BLSearchingData>>>(
        edoServices.getFPODList({ ...options, countryName })
      ),
    ...options,
  });
};

export const useGetEmptyDepotList = (options?: GetBLSearchingOptions) => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [edoQueryKeys.GET_EDO_EMPTY_DEPOT_SEARCHING, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<BLSearchingData>>>(
        edoServices.getEmptyDepotList({ ...options, countryName })
      ),
    ...options,
  });
};

export const useCompanySearch = (options?: GetBLSearchingOptions) => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [edoQueryKeys.GET_EDO_EMPTY_DEPOT_SEARCHING, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<BLSearchingData>>>(
        edoServices.getCompanySearch({ ...options, countryName })
      ),
    ...options,
  });
};
export const useGetEdoDetail = (options: GetEdoDetailOptions) =>
  useQuery({
    queryKey: [edoQueryKeys.GET_EDO_DETAIL, options],
    queryFn: () =>
      fetchApi<IBaseResponse<EdoDetail>>(
        edoServices.getEdoDetail(options.doId)
      ),
    ...options,
  });

export const useGetContainerSizeTypeList = (
  options: GetContainerSizeTypeOptions
) =>
  useQuery({
    queryKey: [
      edoQueryKeys.GET_CONTAINER_SIZE_TYPE_LIST,
      options.id,
      options.timestamp,
    ],
    queryFn: () =>
      fetchApi<IBaseResponse<ContainerSizeType>>(
        blService.posToGetContainerSizeTypeList(options)
      ),
    ...options,
  });

export const useUpdateStatus = () =>
  useMutation((payload: UpdatePayload) =>
    fetchApi(edoServices.updateStatus({ ...payload }))
  );

export const useCreateOrUpdareEdo = () => {
  const countryName = useCountry();
  return useMutation(
    (payload: {
      append: FormData;
      type: string;
      cancelToken: MutableRefObject<CancelTokenSource | undefined>;
    }) => fetchApi(edoServices.createOrUpdateEdo({ ...payload, countryName }))
  );
};

export const useCheckUpdateStatusToLogistic = () =>
  useMutation((payload: UpdatePayload) =>
    fetchApi(edoServices.checkAbleToSendEmailToLogistic(payload))
  );

export const useCreateOrUpdateEdo = () => {
  const countryName = useCountry();
  return useMutation((payload: UploadFileType) =>
    fetchApi(edoServices.createOrUpdateEdo({ ...payload, countryName }))
  );
};
export const usePreReadFileBeforeAction = () =>
  useMutation((payload: PreReadFileType) =>
    fetchApi<IBaseResponse<{ containerNumber: number }>>(
      edoServices.preReadFileBeforeAction(payload)
    )
  );

export const useCreateOrUpdateEdoByLaraApi = () => {
  const countryName = useCountry();
  return useMutation(
    (payload: {
      listBill: string[];
      isAdd: boolean;
      cancelToken: MutableRefObject<CancelTokenSource | undefined>;
    }) =>
      fetchApi(
        edoServices.createOrUpdateEdoByLaraApi({ ...payload, countryName })
      )
  );
};

export const useUpdateSingleEdo = () =>
  useMutation((payload: UpdateSingleEdo) =>
    fetchApi(edoServices.editSingleEdo(payload))
  );

export const useExportExcel = () => {
  const countryName = useCountry();
  return useMutation(
    (payload: FiltersEdo & ExcelColumnSortType & { roleEnum: UserRole }) =>
      fetchApi(edoServices.exportExcelEdo({ ...payload, countryName }))
  );
};

export const useExportPDF = () =>
  useMutation((payload: { id: string; timezone: string }) =>
    fetchApi<BlobPart>(edoServices.exportPDF(payload))
  );

export const useExportPDFByBill = () =>
  useMutation((payload: ExportPDFParam) =>
    fetchApi<BlobPart>(edoServices.exportPDFByBill(payload))
  );

export const useExportPDFByBillEmailPDF = () =>
  useMutation((payload: ExportPDFByBillEmailPDF) =>
    fetchApi<BlobPart>(edoServices.exportPDFByBillEmailPDF(payload))
  );

export const useCheckUpdateBLWithLogistic = () =>
  useMutation((payload: UpdateBLWithLogisticPayload) =>
    fetchApi(blService.checkUpdateBLWithLogistic(payload))
  );

export const useUpdateBLWithLogistic = () =>
  useMutation((payload: UpdateBLWithLogisticPayload) =>
    fetchApi(blService.updateBLWithLogistic(payload))
  );

// Edit BL for counter role
export const useGetBillOfLadingList = (
  options: GetGetBillOfLadingListOptions
) =>
  useQuery({
    queryKey: [
      edoQueryKeys.GET_BILL_OF_LADING_LIST,
      options.id,
      options.timestamp,
    ],
    queryFn: () =>
      fetchApi<IBaseResponse<any>>(blService.posToGetBillOfLadingList(options)),
    ...options,
  });

export const useCheckUpdateBLWithCounter = () =>
  useMutation((payload: UpdateBLWithCounterPayload) =>
    fetchApi(blService.checkUpdateBLWithCounter(payload))
  );

export const useUpdateBLWithCounter = () =>
  useMutation((payload: UpdateBLWithCounterPayload) =>
    fetchApi(blService.updateBLWithCounter(payload))
  );

export const useGetPartyAndConsigneeByBlId = () =>
  useMutation((blID: string) =>
    fetchApi<
      IBaseResponse<{
        selectedConsignee: string;
        receivingPartyInfo: {
          name: string;
          code: string;
        };
      }>
    >(blService.getReceivingPartyAndConsigneeByBLId(blID))
  );

export const useGetPickupdateRerurnDateByArrivalDate = () =>
  useMutation((options: ReturnDateType) =>
    fetchApi<
      IBaseResponse<{
        pickUpDate: string;
        returnDate: string;
        returnDays: number;
      }>
    >(edoServices.getPickupdateRerurnDateByArrivalDate(options))
  );
