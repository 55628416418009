import { useMutation } from "react-query";

import { fetchApi } from "libs/fetchAPI";
import { BKAVServices } from "../services";
import { IBaseResponse } from "interfaces/common-model";
import { ValidationPreRead } from "./types";
import { UploadFileTypeDischarge } from "pages/eod-management/interfaces";

export const useExportExcelDischarge = () =>
  useMutation(() => fetchApi(BKAVServices.exportBKAVTemplate()));

export const usePreReadBKAVFile = () =>
  useMutation((payload: { file: FormData }) =>
    fetchApi<IBaseResponse<ValidationPreRead>>(
      BKAVServices.preReadFileBKAVBeforeAction(payload)
    )
  );

  export const useImportBKAV = () =>
    useMutation((payload: UploadFileTypeDischarge) => //can reuse type
      fetchApi<BlobPart | IBaseResponse<any>>(BKAVServices.uploadBKAV(payload))
    );