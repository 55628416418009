import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;

export const AppLoading = () => (
  <div className="h-screen w-full top-0 left-0 fixed flex items-center justify-center">
    <Spin indicator={antIcon} />
  </div>
);
