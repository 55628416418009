import { featureAccess, fieldAccess } from "./constants";
import { useMemo, useState } from "react";

import { CustomTabs } from "components/tab/CustomTabs";
import { TabItem } from "./components/TabItem";
import { TabLabel } from "pages/accounts-management/components/TabLabel";
import { Typography } from "antd";

const fieldAccessActiveKeys = fieldAccess.map((item) => item.groupKey);
const featureAccessActiveKeys = featureAccess.map((item) => item.groupKey);

export const tabs = [
  {
    key: "feature_access",
    label: "Feature Access",
    children: (
      <TabItem
        data={featureAccess}
        collapseActiveKeys={featureAccessActiveKeys}
      />
    ),
  },
  {
    key: "field_access",
    label: "Field Access",
    children: (
      <TabItem data={fieldAccess} collapseActiveKeys={fieldAccessActiveKeys} />
    ),
  },
];

export const UserRole = () => {
  const [activeKeyTab, setActiveKeyTab] = useState("feature_access");

  const handleTabChange = (activeKey: string) => {
    setActiveKeyTab(activeKey);
  };

  const userRoleTabs = useMemo(
    () =>
      tabs.map(({ key, label, children }) => ({
        key,
        label: (
          <TabLabel key={key} label={label} isActive={key === activeKeyTab} />
        ),
        children,
      })),
    [activeKeyTab]
  );

  return (
    <>
      <Typography.Text className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block mb-6">
        User Role
      </Typography.Text>
      <div className="border-x border-t border-blue-solitude border-solid mt-5">
        <CustomTabs
          activeKey={activeKeyTab}
          onChange={handleTabChange}
          items={userRoleTabs}
        />
      </div>
    </>
  );
};
