import {
  itemsMenuCreateUpdateEdo,
  renderItemActionBaseOnRole,
} from "../updateStatusItemSetup";
import { useCallback, useMemo } from "react";

import { CustomButtom } from "components/button/CustomButton";
import { CustomDropdownButton } from "components/button/CustomDropdownButton";
import DowloadBtnIcon from "assets/icons/DowloadBtnIcon.svg";
import { EDO_STATUS } from "constants/constants";
import { ItemType } from "antd/es/menu/hooks/useItems";
import TruckIcon from "assets/icons/TruckIcon.svg";
import { UserRole } from "interfaces/common-model";
import { createOrUdateEdo } from "../constants";
import dayjs from "dayjs";
import { useEdoContext } from "contexts/edo-context";

export interface IRenderActionByRole {
  isCheckedCheckbox: boolean;
  isLoadingDownloadExcel: boolean;
  isDisable: boolean;
  role: UserRole;
  handleClickDownloadOrcancel: (isChecked: boolean) => void;
  callBackDataDropButton: (values: ItemType) => void;
  callBackClickButton: () => void;
  callBackDataDropButtonCreateOrUpdateEdo: (values: ItemType) => void;
  callBackClickButtonCreateOrUpdateEdo: () => void;
  callBackTriggerReadyForRelease: () => void;
  selectedBLs: string[];
}

export const RenderButtonActionByRole = ({
  isCheckedCheckbox,
  isLoadingDownloadExcel,
  isDisable,
  role,
  handleClickDownloadOrcancel,
  callBackDataDropButton,
  callBackClickButton,
  callBackDataDropButtonCreateOrUpdateEdo,
  callBackClickButtonCreateOrUpdateEdo,
  callBackTriggerReadyForRelease,
  selectedBLs,
}: IRenderActionByRole) => {
  const ImportCS = useMemo(() => [UserRole.ImportCS], []);
  const logisticCounterDepot = useMemo(
    () => [UserRole.Counter, UserRole.Logistic, UserRole.Depot],
    []
  );
  const { setEdo, isLoadingBulkUpdate } = useEdoContext();

  const callBackClickButtonUpdateBls = useCallback(() => {
    role === UserRole.Logistic
      ? setEdo((prev) => ({
          ...prev,
          editBlWithLogisticRole: {
            isOpen: true,
            type: selectedBLs.length > 1 ? "Multiple" : "Single",
            bl: null,
            canEdit: true,
            timestamp: dayjs().toISOString(),
          },
        }))
      : setEdo((prev) => ({
          ...prev,
          editBlWithCounterRole: {
            isOpen: true,
            type: selectedBLs.length > 1 ? "Multiple" : "Single",
            bl: null,
            canEdit: true,
            timestamp: dayjs().toISOString(),
          },
        }));
  }, [selectedBLs, role, setEdo]);

  const memoItemAction = useMemo(() => {
    return renderItemActionBaseOnRole(role).menuItem;
  }, [role]);

  return (
    <>
      {logisticCounterDepot.includes(role) &&
        (isCheckedCheckbox ? (
          <>
            <CustomButtom
              title="Cancel"
              customSize="small"
              customColor="secondary"
              variant="text"
              onClick={() => handleClickDownloadOrcancel(isCheckedCheckbox)}
              customClassName="w-[100px] bg-transparent"
              customTextClassName="!text-red-base"
              disabled={isLoadingBulkUpdate}
            />
            <CustomButtom
              title="Bulk Update"
              customSize="small"
              customColor="primary"
              variant="outline"
              onClick={callBackClickButtonUpdateBls}
              customClassName="w-[112px] mx-[8px]"
              customTextClassName="!text-red-base"
              disabled={isLoadingBulkUpdate}
            />
            {role === UserRole.Logistic ? (
              <CustomButtom
                title="Ready For Release"
                customSize="large"
                customColor="secondary"
                onClick={callBackTriggerReadyForRelease}
                customClassName="h-[40px] w-[183px] flex items-center justify-center"
                customTextClassName="text-sm"
                icon={<img src={TruckIcon} alt="dowloadIcon" />}
                disabled={isLoadingBulkUpdate}
              />
            ) : (
              <CustomDropdownButton
                callBackDataClickDropDown={callBackDataDropButton}
                callBackClickButton={callBackClickButton}
                items={memoItemAction}
                title={EDO_STATUS.Release.actionLabel}
                customMenuClassName={"!w-[135px]"}
                disabled={isLoadingBulkUpdate}
              />
            )}
          </>
        ) : (
          <CustomButtom
            title="Download"
            customSize="small"
            disabled={isDisable || isLoadingBulkUpdate}
            customColor="secondary"
            icon={<img src={DowloadBtnIcon} alt="dowloadIcon" />}
            customClassName="w-38 flex items-center justify-center"
            onClick={() => {
              handleClickDownloadOrcancel(isCheckedCheckbox);
            }}
            loading={isLoadingDownloadExcel}
          />
        ))}
      {ImportCS.includes(role) && (
        <>
          <CustomButtom
            title={isCheckedCheckbox ? "Cancel" : "Download"}
            customSize="small"
            customColor={isCheckedCheckbox ? "secondary" : "primary"}
            variant={isCheckedCheckbox ? "text" : "outline"}
            onClick={() => handleClickDownloadOrcancel(isCheckedCheckbox)}
            customClassName="w-[100px] mr-[8px] flex items-center justify-center bg-transparent"
            customTextClassName="!text-red-base"
            loading={isLoadingDownloadExcel}
            disabled={isDisable || isLoadingBulkUpdate}
          />
          {isCheckedCheckbox ? (
            <CustomDropdownButton
              callBackDataClickDropDown={callBackDataDropButton}
              callBackClickButton={callBackClickButton}
              items={memoItemAction}
              title={EDO_STATUS.WaitForLog.actionLabel}
              customMenuClassName="!w-[190px] "
              customClassName={`${
                isLoadingBulkUpdate && `[&_.ant-btn-compact-item]:bg-[#EFEFF1]`
              }`} //disable color
              disabled={isLoadingBulkUpdate}
            />
          ) : (
            <CustomDropdownButton
              callBackDataClickDropDown={
                callBackDataDropButtonCreateOrUpdateEdo
              }
              callBackClickButton={callBackClickButtonCreateOrUpdateEdo}
              items={itemsMenuCreateUpdateEdo}
              title={createOrUdateEdo.Add.label}
              customMenuClassName={"!w-[173px]"}
              customClassName={`${
                isLoadingBulkUpdate && `[&_.ant-btn-compact-item]:bg-[#EFEFF1]`
              }`} //disable color
              disabled={isLoadingBulkUpdate}
            />
          )}
        </>
      )}
    </>
  );
};
