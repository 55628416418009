import { Typography } from "antd";

type TabItemProps = {
  label: string;
  isActive: boolean;
};

export const TabLabel = ({ label, isActive }: TabItemProps) => (
  <Typography.Text
    className={`text-sm font-roboto leading-5 text-blue-sapphire ${
      isActive ? "font-bold" : "font-normal"
    }`}
  >
    {label}
  </Typography.Text>
);
