import { Modal, Skeleton, Tag, Typography } from "antd";

import BackCheveronIcon from "assets/icons/BackCheveronIcon.svg";
import RedEmailIcon from "assets/icons/RedEmailIcon.svg";
import { TagsSkeleton } from "pages/email-management/TagsSkeleton";
import UploadFileExcelIcon from "assets/icons/UploadFileExcelIcon.svg";
import { authConfig } from "constants/configs";
import { get } from "lodash";
import { useEdoContext } from "contexts/edo-context";
import { useExportPDFByBillEmailPDF } from "pages/eod-management/queries";
import { useGetEmailDetail } from "pages/email-management/queries";
import { getTimezoneOffset } from "libs/helper";

export default function TabItemEmailDetail() {
  const {
    isOpenHistoryTransaction,
    isOpenHistoryTransactionEmailDetail,
    setEdo,
  } = useEdoContext();

  const emailId = get(
    isOpenHistoryTransactionEmailDetail,
    "emailDataOfEdoRow.emailDetailId",
    ""
  );

  const { data: emailData, isFetching } = useGetEmailDetail(
    {
      EmailDetail: emailId,
      BucketName: authConfig.s3Bucket || "",
      currentTimezone: getTimezoneOffset(),
    },
    isOpenHistoryTransactionEmailDetail.isOpen
  );

  const emails = get(emailData, "data.emails", [""]);
  const files = get(emailData, "data.files", [
    { fileName: "", size: "", id: "" },
  ]);
  const subject = get(emailData, "data.subject", "");
  const body = get(emailData, "data.body", "");

  const handleCancel = () => {
    setEdo((prev) => ({
      ...prev,
      isOpenHistoryTransaction: {
        ...isOpenHistoryTransaction,
        isOpen: false,
      },
      isOpenHistoryTransactionEmailDetail: {
        ...isOpenHistoryTransactionEmailDetail,
        isOpen: false,
      },
    }));
  };
  const handleBack = () => {
    setEdo((prev) => ({
      ...prev,
      isOpenHistoryTransaction: {
        ...isOpenHistoryTransaction,
        isOpen: true,
      },
      isOpenHistoryTransactionEmailDetail: {
        ...isOpenHistoryTransactionEmailDetail,
        isOpen: false,
      },
    }));
  };
  const { mutate: exportPDF } = useExportPDFByBillEmailPDF(); //any change on this should also apply to ModalResendEmail

  const handleExportCSV = () => {
    const emailId = get(emailData, "data.id", "");
    const payload = {
      emailDetail: emailId,
      bucketName: authConfig.s3Bucket || "",
      currentTimezone: getTimezoneOffset(),
    };
    exportPDF(payload, {
      onSuccess: (data) => {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
    });
  };

  return (
    <Modal
      closable={false}
      className="!w-[1158px] [&_.ant-modal-content]:p-0 [&_.ant-modal-content]:pt-6"
      open={isOpenHistoryTransactionEmailDetail.isOpen}
      onCancel={handleCancel}
      footer={false}
      centered
    >
      <>
        <div className="px-6 border border-solid border-t-0 border-l-0 border-r-0 border-b-blue-solitude pb-6">
          <div className="flex">
            <img
              onClick={handleBack}
              className="cursor-pointer self-start pt-1 pr-2"
              src={BackCheveronIcon}
              alt="backIcon"
            />
            <div className="flex flex-col">
              <Typography.Text className="text-blue-sapphire font-antonio text-xl font-normal not-italic leading-6 tracking-[-0.1px]">
                Email Details
              </Typography.Text>
              <Typography.Text className="font-roboto mt-2 mb-6 text-blue-rock-blue text-sm font-normal not-italic leading-5">
                View Transaction’s History
              </Typography.Text>
            </div>
          </div>
          <div className="max-h-[700px] overflow-y-auto">
            <div className="flex mb-2">
              <img className="mr-1" src={RedEmailIcon} alt="emailIcon" />
              <Typography.Text className=" text-blue-sapphire font-roboto text-sm font-bold not-italic leading-4">
                Send To Emails:
              </Typography.Text>
            </div>
            {isFetching ? (
              <TagsSkeleton />
            ) : (
              emails.map((tag) => {
                return (
                  <Tag
                    key={tag}
                    closable={false}
                    className={`m-1 px-2 py-1  text-blue-sapphire bg-white font-roboto text-xs leading-[14px] font-normal `}
                  >
                    {tag}
                  </Tag>
                );
              })
            )}

            <div className="mt-4">
              <div className="flex flex-col">
                <Typography.Text className="mb-[6px] text-blue-sapphire font-roboto text-sm font-normal not-italic leading-4">
                  Subject
                </Typography.Text>
                {isFetching ? (
                  <div className="[&_.ant-skeleton]:w-full [&_.ant-skeleton-input]:!w-full [&_.ant-skeleton-input]:!h-12">
                    <Skeleton.Input className="w-full" size="large" active />
                  </div>
                ) : (
                  <div className="p-3 bg-blue-solitude-second border border-solid border-grey-slate-grey">
                    {subject}
                  </div>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <Typography.Text className="mb-[6px] text-blue-sapphire font-roboto text-sm font-normal not-italic leading-4">
                  Body
                </Typography.Text>
                {isFetching ? (
                  <div className="[&_.ant-skeleton]:w-full [&_.ant-skeleton-input]:!w-full [&_.ant-skeleton-input]:!h-12">
                    <Skeleton.Input className="w-full" size="large" active />
                  </div>
                ) : (
                  <div className="p-3 bg-blue-solitude-second border border-solid border-grey-slate-grey">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: body,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-6">
              {files?.length > 0 && (
                <>
                  <Typography.Text className="mb-[6px] text-blue-sapphire font-roboto text-sm font-normal not-italic leading-4">
                    Attached File
                  </Typography.Text>
                  <div className="flex items-center">
                    <div className="mt-3 flex flex-col">
                      {isFetching ? (
                        <>
                          <Skeleton.Input className="" size="small" active />
                          <Skeleton.Input
                            className="pt-1"
                            size="small"
                            active
                          />
                        </>
                      ) : (
                        <>
                          {files.map((file, index) => {
                            return (
                              <div key={index} className="flex cursor-pointer">
                                <img
                                  className="mr-2"
                                  src={UploadFileExcelIcon}
                                  alt="FileIcon"
                                />
                                <div
                                  onClick={handleExportCSV}
                                  className="flex flex-col underLineHover"
                                >
                                  <Typography.Text
                                    key={index}
                                    className={`text-blue-midnight-express font-roboto text-sm font-normal not-italic leading-5 ${
                                      index !== 0 ? "pt-3" : ""
                                    }`}
                                  >
                                    {file.fileName}
                                  </Typography.Text>
                                  <Typography.Text className="text-blue-waikawa-grey font-roboto text-xs font-light not-italic leading-[14px]">
                                    {file.size}
                                  </Typography.Text>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
