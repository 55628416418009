import { Checkbox, Collapse, Drawer, Form, Image, Skeleton } from "antd";
import { every, isEmpty, map, range } from "lodash";
import {
  filterEdoDangerousGoods,
  filterEdoOwnerContainer,
  filterEdoPartLoad,
  filterEdoStatus,
} from "../constants";
import { useCallback, useMemo } from "react";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import { CheckBoxGroup } from "components/CheckboxGroup";
import type { CollapseProps } from "antd";
import { CustomButtom } from "components/button/CustomButton";
import { DateRangePicker } from "components/DateRangePicker";
import type { Dayjs } from "dayjs";
import { IFilter } from "../interfaces";
import XCloseIcon from "assets/icons/XCloseIcon.svg";
import { convertDayjsDataToUtc } from "../helpers";
import useDidMountEffect from "hooks/use-did-mount-effect";
import { useGetContainerSizes } from "../queries";
import { useCountry } from "./SelectCountry";
import { useUserRole } from "./SelectRole";

type CustomerFilters = {
  fromBLETADate: string;
  toBLETADate: string;
  fromUpdateDate: string;
  toUpdateDate: string;
  fromPickupDate: string;
  toPickupDate: string;
  fromReturnDate: string;
  toReturnDate: string;
  listStatus: number[];
  listContainerSize: string[];
  isDG: boolean[];
  listSOCIndicator: boolean | null[] | [];
  isPL: boolean[];
};

type DrawerFilterForm = {
  BLETADate: Dayjs[];
  updateDate: Dayjs[];
  toUpdateDate: Dayjs[];
  pickupDate: Dayjs[];
  returnDate: Dayjs[];
  listStatus: number[];
  listContainerSize: string[];
  isDG: boolean[];
  listSOCIndicator: [boolean | null] | [];
  isPL: boolean[];
};

const INITIAL_FORM_VALUES: CustomerFilters = {
  fromBLETADate: "",
  toBLETADate: "",
  fromUpdateDate: "",
  toUpdateDate: "",
  fromPickupDate: "",
  toPickupDate: "",
  fromReturnDate: "",
  toReturnDate: "",
  listStatus: [],
  listContainerSize: [],
  isDG: [],
  listSOCIndicator: [],
  isPL: [],
};

const DrawerFilter = ({
  open,
  setOpen,
  callBackApply,
  isTriggerResetCallBack,
}: IFilter) => {
  const [form] = Form.useForm<DrawerFilterForm>();
  const role = useUserRole();

  const {
    data: containerSizes,
    isFetching: isLoadingContainerSizes,
    refetch,
  } = useGetContainerSizes();

  const filterEdoSizeApiConvert = useMemo(() => {
    return map(containerSizes?.data, (value) => ({
      value,
      label: value,
    }));
  }, [isLoadingContainerSizes]);

  useDidMountEffect(() => {
    refetch();
  }, [role]);

  const onClose = () => {
    setOpen(false);
  };

  const items: CollapseProps["items"] = useMemo(() => {
    return [
      {
        key: "1",
        label: "BL ETA",
        children: (
          <Form.Item name="BLETADate" className="mb-0">
            <DateRangePicker customClassName="!w-full" />
          </Form.Item>
        ),
      },
      {
        key: "2",
        label: "eDO Status",
        children: (
          <Form.Item name="listStatus" className="mb-0">
            <CheckBoxGroup checkBoxList={filterEdoStatus} />
          </Form.Item>
        ),
      },
      {
        key: "3",
        label: "Container size/type",
        children: (
          <Form.Item name="listContainerSize" className="mb-0">
            {isLoadingContainerSizes ? (
              <div className="flex flex-col gap-5">
                {Array.from({ length: 4 }, (_, index) => index).map(
                  (
                    index //just loading phase of 4 items
                  ) => (
                    <Checkbox key={index} disabled>
                      <Skeleton.Input size={"small"} active />
                    </Checkbox>
                  )
                )}
              </div>
            ) : (
              <CheckBoxGroup checkBoxList={filterEdoSizeApiConvert} />
            )}
          </Form.Item>
        ),
      },
      {
        key: "4",
        label: "Status Update Date",
        children: (
          <Form.Item name="updateDate" className="mb-0">
            <DateRangePicker customClassName="!w-full" />
          </Form.Item>
        ),
      },
      {
        key: "5",
        label: "Pick Up Date",
        children: (
          <Form.Item name="pickupDate" className="mb-0">
            <DateRangePicker customClassName="!w-full" />
          </Form.Item>
        ),
      },
      {
        key: "6",
        label: "Return Date",
        children: (
          <Form.Item name="returnDate" className="mb-0">
            <DateRangePicker customClassName="!w-full" />
          </Form.Item>
        ),
      },
      {
        key: "7",
        label: "Dangerous Goods",
        children: (
          <Form.Item name="isDG" className="mb-0">
            <CheckBoxGroup checkBoxList={filterEdoDangerousGoods} />
          </Form.Item>
        ),
      },
      {
        key: "8",
        label: "Shipper - Owned Container",
        children: (
          <Form.Item name="listSOCIndicator" className="mb-0">
            <CheckBoxGroup checkBoxList={filterEdoOwnerContainer} />
          </Form.Item>
        ),
      },
      {
        key: "9",
        label: "Part-load",
        children: (
          <Form.Item name="isPL" className="mb-0">
            <CheckBoxGroup checkBoxList={filterEdoPartLoad} />
          </Form.Item>
        ),
      },
    ];
  }, [filterEdoSizeApiConvert, isLoadingContainerSizes]);

  useDidMountEffect(() => {
    form.resetFields();
  }, [isTriggerResetCallBack]);

  const onReset = useCallback(() => {
    form.resetFields();
  }, []);
  const countryName = useCountry();
  const handleFilters = (values: DrawerFilterForm) => {
    const convertToPayloadType = {
      fromBLETADate: convertDayjsDataToUtc(values.BLETADate, 0),
      toBLETADate: convertDayjsDataToUtc(values.BLETADate, 1),
      fromUpdateDate: convertDayjsDataToUtc(values.updateDate, 0),
      toUpdateDate: convertDayjsDataToUtc(values.updateDate, 1),
      fromPickupDate: convertDayjsDataToUtc(values.pickupDate, 0),
      toPickupDate: convertDayjsDataToUtc(values.pickupDate, 1),
      fromReturnDate: convertDayjsDataToUtc(values.returnDate, 0),
      toReturnDate: convertDayjsDataToUtc(values.returnDate, 1),
      listStatus: values.listStatus,
      listContainerSize: values.listContainerSize,
      isDG: values.isDG.length === 1 ? values.isDG[0] : null,
      listSOCIndicator: values.listSOCIndicator,
      isPL: values.isPL.length === 1 ? values.isPL[0] : null,
      countryName,
    };
    callBackApply(convertToPayloadType, every(values, isEmpty));
  };

  return (
    <>
      <Drawer
        rootClassName="custom-drawer-edo-import"
        title="Filter"
        placement="left"
        width={336}
        onClose={onClose}
        open={open}
        maskClosable={false}
        closeIcon={<img src={XCloseIcon} alt="XIcon" />}
      >
        <>
          <Form
            initialValues={INITIAL_FORM_VALUES}
            form={form}
            onFinish={handleFilters}
            className=""
          >
            <Collapse
              defaultActiveKey={map(range(1, items.length + 1), String)}
              expandIconPosition={"end"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Image
                    preview={false}
                    alt="collapse-edo-filter"
                    src={ArrowUp}
                  />
                ) : (
                  <Image
                    preview={false}
                    alt="collapse-edo-filter"
                    src={ArrowDown}
                  />
                )
              }
              items={items}
            />
            <div className="custom-footer sticky bottom-0 flex justify-between px-6 py-4">
              <CustomButtom
                title={"Reset All"}
                variant="outline"
                customSize="medium"
                customColor="secondary"
                customClassName="w-[95px]"
                onClick={onReset}
              />
              <CustomButtom
                title={"Apply"}
                loading={isLoadingContainerSizes}
                customSize="medium"
                customColor="secondary"
                customClassName="w-[95px] flex justify-center"
                htmlType="submit"
              />
            </div>
          </Form>
        </>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
