import { Depot, DepotFormValues } from "../interfaces";
import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";

import { CustomerDepotHistoryUpdate } from "pages/eod-management/components/ModalTransactionHistory/interfaces";
import { UseQueryOptions } from "react-query";

export const depotQueryKeys = {
  GET_DEPOT_LIST: "GET_DEPOT_LIST",
  GET_DEPOT_FILTERS: "GET_DEPOT_FILTERS",
  GET_DEPOT_HISTORY: "GET_DEPOT_HISTORY",
};

export type GetHistoryHistoryParams = {
  PageNum?: number;
  PageSize?: number;
  isTrigger?: boolean;
  id: string;
};

export type GetDepotListOptions = DepotFormValues &
  UseQueryOptions<IBaseResponse<IBasePaginationResponse<Depot>>, unknown>;

  export type GetDepotHistory = GetHistoryHistoryParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<CustomerDepotHistoryUpdate>>,
    unknown
  >;