import { ConfigProvider, Table } from "antd";
import {
  ExpandedChildDataType,
  IChildrenTreeData,
  ISubTable,
} from "../interfaces";
import { cloneDeep, isEmpty, remove } from "lodash";
import { useEffect, useState } from "react";

import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { TableRowSelection } from "antd/es/table/interface";
import { UserRole } from "interfaces/common-model";
import { useSubTableColumn } from "../hooks/use-sub-table-column";
import { useUserRole } from "./SelectRole";

export const SubTable = ({
  record,
  childRowSelection,
  setChildRowSelection,
  parentRowSelection,
  setParentRowSelection,
  setUncheckedItemAfterCheckedAll,
}: ISubTable) => {
  const columns = useSubTableColumn();
  const role = useUserRole();
  const [isDisplay, setIsDisplay] = useState<boolean>(false);

  const childRowSelectionRender: TableRowSelection<ExpandedChildDataType> = {
    getCheckboxProps: (record: ExpandedChildDataType) => ({
      disabled: role === UserRole.Depot ? true : !record.canEdit, // Column configuration not to be checked
    }),
    selectedRowKeys: cloneDeep(childRowSelection).find(
      (item: IChildrenTreeData) => item.parent === record.blId
    )?.children,
    hideSelectAll: true,
    // TODO: optimize
    onChange: (selectedRowKeys) => {
      // handle Parent
      if (!isEmpty(selectedRowKeys)) {
        setParentRowSelection(
          parentRowSelection.includes(record.blId)
            ? [...parentRowSelection]
            : [...parentRowSelection, record.blId]
        );
      } else {
        setParentRowSelection(
          parentRowSelection.filter((item) => item !== record.blId)
        );
      }
      //end handle Parent

      // handle children
      //TODO: create function delete
      const childhandlerCloner = cloneDeep(childRowSelection);
      const findItem = childhandlerCloner.find(
        (item) => item.parent === record.blId
      );
      if (findItem && isEmpty(selectedRowKeys)) {
        const findItemIndex = childhandlerCloner.findIndex(
          (item) => item.parent === record.blId
        );
        childhandlerCloner.splice(findItemIndex, 1);
        // setAll children as clone of removed found child
        setChildRowSelection(childhandlerCloner);
      } else if (findItem) {
        // override data of child array by shallow copy
        findItem!.children = selectedRowKeys as string[];
        setChildRowSelection(childhandlerCloner);
      } else {
        // set normal
        setChildRowSelection([
          ...childRowSelection,
          {
            parent: record.blId,
            children: selectedRowKeys,
            totalChild: [...record.deliveryOrders],
          },
        ]);
      }
    },
    onSelect: (record: ExpandedChildDataType, selected: boolean) => {
      if (!selected) {
        setUncheckedItemAfterCheckedAll((prev: string[]) => [
          ...prev,
          record.id,
        ]);
      } else {
        setUncheckedItemAfterCheckedAll((prev: string[]) => {
          const cloneCurrentData = cloneDeep(prev);
          remove(cloneCurrentData, (item) => item === record.id);
          return [...cloneCurrentData];
        });
      }
    },
    //end handle children
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      setIsDisplay(true);
    }, 500); //display edo have more than 3 items after 500ms
    return () => clearTimeout(interval);
  }, []);

  return record.deliveryOrders.length < 3 || isDisplay ? (
    <div
      className={`custom-child-table-edo-import ${
        role === UserRole.Depot ? "custom-child-table-edo-import-for-depot" : ""
      } bg-white`}
    >
      <ConfigProvider renderEmpty={EmptyTable}>
        <Table
          columns={columns}
          dataSource={record.deliveryOrders}
          pagination={false}
          rowSelection={{
            ...childRowSelectionRender,
          }}
          rowKey="id"
        />
      </ConfigProvider>
    </div>
  ) : (
    <div className="custom-child-table-edo-import bg-white [&_.ant-table-tbody]:!h-[400px] [&_.ant-spin-dot-spin]:!left-[51.2%]">
      <ConfigProvider renderEmpty={EmptyTable}>
        <Table columns={columns} loading />
      </ConfigProvider>
    </div>
  );
};
