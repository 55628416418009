import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";
import { UseQueryOptions, useMutation, useQuery } from "react-query";
import {
  Account,
  AccountRolesAndCountries,
  ICompany,
  UpdateAccountPayload,
} from "./interfaces";
import {
  CountryListParams,
  GetAccountListParams,
  accountServices,
} from "./services";

import { fetchApi } from "libs/fetchAPI";
import { CustomerDepotHistoryUpdate } from "pages/eod-management/components/ModalTransactionHistory/interfaces";

export type GetAccountListOptions = GetAccountListParams &
  UseQueryOptions<IBaseResponse<IBasePaginationResponse<Account>>, unknown>;

export type GetAccountFiltersResponse = {
  companies: ICompany[];
  countries: string[];
  roles: string[];
};

const accountQueryKeys = {
  GET_ACCOUNT_FILTERS: "GET_ACCOUNT_FILTERS",
  GET_ACCOUNT_LIST: "GET_ACCOUNT_LIST",
  GET_ACCOUNT_ROLES_AND_COUNTRIES: "GET_ACCOUNT_ROLES_AND_COUNTRIES",
  GET_USER_HISTORY: "GET_USER_HISTORY",
};

export const useGetAccountFilters = (
  isInternal?: boolean,
  countryName?: string
) =>
  useQuery({
    queryKey: [accountQueryKeys.GET_ACCOUNT_FILTERS, isInternal, countryName],
    queryFn: () =>
      fetchApi<IBaseResponse<GetAccountFiltersResponse>>(
        accountServices.getAccountFilters(isInternal, countryName)
      ),
  });

export const useGetAccountList = (options?: GetAccountListOptions) =>
  useQuery({
    queryKey: [accountQueryKeys.GET_ACCOUNT_LIST, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<Account>>>(
        accountServices.getAccountList(options)
      ),
    ...options,
  });

export const useUpdateAccount = () =>
  useMutation((payload: UpdateAccountPayload) =>
    fetchApi(accountServices.updateAccount(payload))
  );

export const useCreateAccount = () =>
  useMutation((payload: UpdateAccountPayload) =>
    fetchApi<IBaseResponse<Account>>(accountServices.createAccount(payload))
  );

export const useGetAccountsRolesAndCountries = (payload: {
  ccgid: string;
  timeStamp: string;
}) => {
  return useQuery({
    queryKey: [accountQueryKeys.GET_ACCOUNT_ROLES_AND_COUNTRIES, payload.ccgid],
    queryFn: () =>
      fetchApi<IBaseResponse<AccountRolesAndCountries[]>>(
        accountServices.getAccountRolesAndCountries(payload.ccgid)
      ),
    enabled: !!payload.ccgid,
  });
};

export const useAssignRole = () =>
  useMutation((payload: { data: AccountRolesAndCountries[]; ccgid: string }) =>
    fetchApi(accountServices.assignRole(payload))
  );

export const useGetCountryList = (payload?: CountryListParams) => {
  return useQuery({
    queryKey: [accountQueryKeys.GET_ACCOUNT_ROLES_AND_COUNTRIES],
    queryFn: () =>
      fetchApi<IBaseResponse<string[]>>(accountServices.getCountryLis(payload)),
  });
};

export type GetUserHistoryParams = {
  PageNum?: number;
  PageSize?: number;
  CountryName: string;
  isTrigger: boolean;
  id: string;
};

export type GetUserHistory = GetUserHistoryParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<CustomerDepotHistoryUpdate>>,
    unknown
  >;

export const useUserHistory = (options: GetUserHistory) =>
  useQuery({
    queryKey: [accountQueryKeys.GET_USER_HISTORY, options],
    queryFn: () =>
      fetchApi<
        IBaseResponse<
          IBasePaginationResponse<
            {
              actualVoyageNo: string;
              containerNo: string;
            } & CustomerDepotHistoryUpdate
          >
        >
      >(accountServices.getUserHistory(options)),
    enabled: options.isTrigger,
    ...options,
  });
