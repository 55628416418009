import { ConfigProvider, Form, Image, Pagination, Typography } from "antd";
import { DEFAULT_PERPAGE, EMAIL_DATE_FORMAT } from "constants/constants";
import { EmailItem, useGetEmailList, useResendEmail } from "./queries";
import Table, { ColumnsType } from "antd/es/table";
import { concat, trim } from "lodash";
import { useCallback, useMemo, useState } from "react";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import { ButtonVariant } from "components/button/types";
import CrossIcon from "assets/icons/CrossIcon.svg";
import { CustomButtom } from "components/button/CustomButton";
import CustomMutipleTagsDisplay from "components/CustomMutipleTagsDisplay";
import { CustomSortIcon } from "components/CustomSortIcon";
import { DateRangePicker } from "components/DateRangePicker";
import { Dayjs } from "dayjs";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { FormInput } from "components/form/Input";
import { ModalConfirmation } from "components/ModalConfirmation";
import ModalResendEmail from "./ModalResedEmail";
import ResendConfirmationIcon from "assets/icons/ResendConfirmationIcon.svg";
import ResendIcon from "assets/icons/ResendIcon.svg";
import SearchIcon from "assets/icons/SearchIcon.svg";
import SendMailIcon from "assets/icons/SendMailIcon.svg";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { authConfig } from "constants/configs";
import dayjs from "dayjs";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { useNotification } from "hooks/use-notification";
import { getTimezoneOffset } from "libs/helper";

type Filters = {
  Keyword: string;
  Dates: null | (Dayjs | null)[];
};

type FiltersPayload = {
  Keyword: string;
  FromDate?: string;
  ToDate?: string;
};

const INITIAL_FILTERS: FiltersPayload = {
  Keyword: "",
  FromDate: "",
  ToDate: "",
};

export default function EmailManagement() {
  const [PageNum, setPageNum] = useState(1);
  const [searchParams, setSearchParams] = useState(INITIAL_FILTERS);
  const currentCountry = useCountry();

  const {
    data: emailList,
    isFetching: isLoadingEmailList,
    refetch,
  } = useGetEmailList({
    ...searchParams,
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE),
    countryName: currentCountry,
  });

  const columns: ColumnsType<EmailItem> = useMemo(
    () => [
      {
        title: <TableHeaderTitle title="BL No./ Sent Date" />,
        dataIndex: "blDetail",
        key: "blDetail",
        sortIcon: CustomSortIcon,
        sorter: (a, b) =>
          a.blDetail.billOfLadingNumber?.localeCompare(
            b.blDetail.billOfLadingNumber
          ),
        render: (_: any, record: EmailItem) => (
          <div className="flex flex-col w-full">
            <Typography.Text className="truncate font-roboto text-sm text-blue-midnight-express leading-5 font-normal">
              {record.blDetail.billOfLadingNumber}
            </Typography.Text>
            <Typography.Text className="truncate font-roboto text-xs text-blue-waikawa-grey leading-5 font-normal">
              {record.blDetail.sentDate
                ? dayjs(record.blDetail.sentDate).format(EMAIL_DATE_FORMAT)
                : ""}
            </Typography.Text>
          </div>
        ),
        width: "130px",
      },
      {
        title: <TableHeaderTitle title="Container No." />,
        dataIndex: "containerNumber",
        key: "containerNumber",
        render: (_: any, record: EmailItem) => (
          <div className="flex items-center justify-start">
            <CustomMutipleTagsDisplay
              tags={record.containerNumbers}
              numberOfShowedTags={1}
            />
          </div>
        ),
        ellipsis: true,
        width: "250px",
      },
      {
        title: <TableHeaderTitle title="Sent To" />,
        dataIndex: "email",
        key: "email",
        render: (_: any, record: EmailItem) => (
          <div className="flex items-center justify-start">
            <CustomMutipleTagsDisplay
              tags={concat(record.emails, record.additionEmails)}
              numberOfShowedTags={1}
            />
          </div>
        ),
        ellipsis: true,
        width: "250px",
      },
      {
        title: <TableHeaderTitle title="Subject" />,
        dataIndex: "subject",
        key: "subject",
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.subject?.localeCompare(b.subject),
        render: (_: any, record: EmailItem) => (
          <div className="w-full flex items-center justify-start">
            <Typography.Text className="text-violet-revolver font-roboto text-sm font-normal not-italic leading-5">
              {record.subject}
            </Typography.Text>
          </div>
        ),
      },
      {
        title: <TableHeaderTitle title="" />,
        dataIndex: "",
        key: "",
        render: (_: any, record: EmailItem) => (
          <div className="flex items-center justify-center">
            <img
              className="cursor-pointer"
              onClick={() => HanelOpenSendEmailModal(record)}
              src={SendMailIcon}
              alt="sendMailIcon"
            />
          </div>
        ),
        width: "60px",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [openModalSendEmail, setOpenModalEmail] = useState<boolean>(false);
  const [openModalResendMutiple, setOpenModalResendMutiple] =
    useState<boolean>(false);
  const [currentEmailChoosing, setCurrentChoosing] = useState<EmailItem>();
  const HanelOpenSendEmailModal = useCallback((record: EmailItem) => {
    setOpenModalEmail(true);
    setCurrentChoosing(record);
  }, []);
  const { handleOpenSuccessNotification } = useNotification();

  const { mutate: resendEmail, isLoading: isLoadingResendEmail } =
    useResendEmail();

  const [form] = Form.useForm<any>();
  const keyword = Form.useWatch("Keyword", form);
  const handleResetSearch = () => {
    form.setFieldValue("Keyword", "");
  };

  const handleOnChangePagination = (page: number) => {
    setPageNum(page);
  };

  const handleFormSearch = (values: Filters) => {
    let FromDate = "";
    let ToDate = "";

    if (values?.Dates) {
      FromDate = `${dayjs.utc(values.Dates[0]).format()}`;
      ToDate = `${dayjs.utc(values.Dates[1]).format()}`;
    }

    setPageNum(1);
    setSearchParams({
      ...searchParams,
      FromDate,
      ToDate,
      Keyword: trim(values.Keyword),
    });
  };

  const handleCallBackResend = (listEmail: string[], emailId: string) => {
    const payload = {
      emails: [emailId],
      additionalEmail: listEmail,
      bucketName: authConfig.s3Bucket || "",
      isMultipleResend: false,
      countryName: currentCountry,
      currentTimezone: getTimezoneOffset(),
    };
    resendEmail(payload, {
      onSuccess: () => {
        setOpenModalEmail(false);
        refetch();
        handleOpenSuccessNotification({
          message: "Emails have been sent!",
        });
      },
    });
  };

  const [checkedRow, setCheckedRow] = useState<string[]>([]);
  const rowSelection = {
    selectedRowKeys: checkedRow,
    onChange: (selectedRowKeys: React.Key[], selectedRows: EmailItem[]) => {
      setCheckedRow(selectedRowKeys as string[]);
    },
  };

  const handleOpenModal = useCallback(() => {
    setOpenModalResendMutiple(true);
  }, []);

  const handleResendMutipleEmail = useCallback(() => {
    const payload = {
      emails: checkedRow,
      additionalEmail: [], //Mutiple no need addtional emails
      bucketName: authConfig.s3Bucket || "",
      isMultipleResend: true,
      countryName: currentCountry,
      currentTimezone: getTimezoneOffset(),
    };
    resendEmail(payload, {
      onSuccess: () => {
        refetch();
        setOpenModalResendMutiple(false);
        handleOpenSuccessNotification({
          message: "Emails have been sent!",
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRow]);

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-6 flex justify-between items-center h-10">
          <h1 className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block">
            Email
          </h1>
          {checkedRow.length !== 0 && (
            <div className="flex ">
              <CustomButtom
                title="Cancel"
                variant={ButtonVariant.outline}
                customSize={"medium"}
                customColor={"secondary"}
                customClassName="h-[40px]"
                onClick={() => setCheckedRow([])}
              />
              <CustomButtom
                title="Resend"
                customSize="large"
                customColor="secondary"
                onClick={handleOpenModal}
                customClassName="ml-2 h-[40px] flex items-center justify-center"
                customTextClassName="text-sm"
                icon={<img src={ResendIcon} alt="dowloadIcon" />}
              />
            </div>
          )}
        </div>
      </div>
      <div className="border border-solid border-blue-solitude">
        <div className="flex justify-end items-center bg-white px-[24px] border-solid py-[16px] border-blue-solitude">
          <Form
            initialValues={INITIAL_FILTERS}
            form={form}
            className="flex"
            onFinish={handleFormSearch}
          >
            <div className="flex-wrap flex mx-[12px]">
              <Form.Item name="Dates" className="mb-4">
                <DateRangePicker />
              </Form.Item>
              <FormInput
                name="Keyword"
                customFormItemClassName="ml-3 flex-1 !mb-4 w-[40.125rem] sL:w-[14rem] mr-3"
                placeholder="Search by BL No., Container No., Sent To, Email Subject"
                prefix={
                  <Image
                    preview={false}
                    src={SearchIcon}
                    alt="input-search-icon"
                  />
                }
                suffix={
                  keyword ? (
                    <img
                      className="cursor-pointer"
                      src={CrossIcon}
                      alt="CrossIcon"
                      onClick={handleResetSearch}
                    />
                  ) : null
                }
              />
            </div>
            <Form.Item className="mb-0">
              <CustomButtom
                title="Apply"
                customSize="small"
                customColor="primary"
                htmlType="submit"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="custom-table-email-management">
          <ConfigProvider renderEmpty={EmptyTable}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={emailList?.data.data || []}
              pagination={false}
              loading={isLoadingEmailList}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
            />
          </ConfigProvider>
          {emailList && emailList?.data.data.length >= 1 && (
            <div className="p-[24px] flex justify-between items-center border border-blue-hawkes-blue">
              <div>
                <span className="font-roboto text-[14px] not-italic font-normal leading-4 text-blue-waikawa-grey">
                  Results:&nbsp;
                </span>
                <span className="font-roboto text-blue-midnight-express text-[14px] not-italic font-normal left-5">
                  {emailList?.data.totalRecords}
                </span>
              </div>
              <div className="custom-pagination-customer-management">
                <Pagination
                  pageSize={emailList?.data?.pageSize}
                  current={PageNum}
                  onChange={handleOnChangePagination}
                  total={emailList?.data?.totalRecords}
                  nextIcon={<img alt="pagination-next" src={AngleRight} />}
                  prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
                  showSizeChanger={false}
                />
              </div>
            </div>
          )}
        </div>
        {currentEmailChoosing && openModalSendEmail && (
          <ModalResendEmail
            currentEmai={currentEmailChoosing || null}
            isOpen={openModalSendEmail}
            setOpen={setOpenModalEmail}
            handleResend={handleCallBackResend}
            loadingSubmit={isLoadingResendEmail}
          />
        )}
        <ModalConfirmation
          open={openModalResendMutiple}
          okText="Confirm"
          customImage={<img alt="logout-img" src={ResendConfirmationIcon} />}
          onCancel={() => setOpenModalResendMutiple(false)}
          onOk={handleResendMutipleEmail}
          cancelText="Cancel"
          title="Resend Multiple Emails"
          description="Do you wish to resend these selected emails?"
          subDescription=""
          isLoadingBtn={isLoadingResendEmail}
          customClassName={"flex justify-center"}
        />
      </div>
    </>
  );
}
