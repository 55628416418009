import { useMemo, useState } from "react";

import { CustomButtom } from "components/button/CustomButton";
import { CustomTabs } from "components/tab/CustomTabs";
import PlusIcon from "assets/icons/PlusIcon.svg";
import { TabItem } from "./components/TabItem";
import { TabLabel } from "./components/TabLabel";
import { Typography } from "antd";
import { UserRole } from "interfaces/common-model";
import { useUserRole } from "pages/eod-management/components/SelectRole";

export const AccountManagement = () => {
  const [activeKeyTab, setActiveKeyTab] = useState("internal");
  const [isOpenModalAddNewAccount, setOpenModalAddNewAccount] =
    useState<boolean>(false);
  const role = useUserRole(true);
  const tabs = useMemo(() => {
    return [
      {
        key: "internal",
        label: "Internal",
        children: (
          <TabItem
            isInternal
            isOpenModalAddNewAccount={isOpenModalAddNewAccount}
            setOpenModalAddNewAccount={setOpenModalAddNewAccount}
          />
        ),
      },
      {
        key: "external",
        label: "External",
        children: (
          <TabItem
            isOpenModalAddNewAccount={isOpenModalAddNewAccount}
            setOpenModalAddNewAccount={setOpenModalAddNewAccount}
          />
        ),
      },
    ];
  }, [isOpenModalAddNewAccount, setOpenModalAddNewAccount]);

  const handleTabChange = (activeKey: string) => {
    setActiveKeyTab(activeKey);
  };

  const accountTabs = useMemo(
    () =>
      tabs.map(({ key, label, children }) => ({
        key,
        label: (
          <TabLabel key={key} label={label} isActive={key === activeKeyTab} />
        ),
        children,
      })),
    [activeKeyTab, isOpenModalAddNewAccount]
  );

  return (
    <>
      <div className="flex justify-between w-full">
        <Typography.Text className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block mb-6">
          Account Management
        </Typography.Text>
        {role === UserRole.ITOfficer && (
          <CustomButtom
            title="Add New"
            customSize="small"
            customColor="secondary"
            icon={<img src={PlusIcon} alt="add new account" />}
            customClassName="w-38 flex items-center justify-center"
            onClick={() => {
              setOpenModalAddNewAccount(true);
            }}
          />
        )}
      </div>
      <div className="border border-blue-solitude border-solid mt-5">
        <CustomTabs
          activeKey={activeKeyTab}
          onChange={handleTabChange}
          items={accountTabs}
        />
      </div>
    </>
  );
};
