import { Depot } from "../interfaces";
import { GetDepotListParams } from "./types";
import { GetHistoryHistoryParams } from "../queries/types";
import { IFetchApi } from "libs/fetchAPI";
import { omitEmptyParams } from "libs/helper";

export const depotServices = {
  getAllDepots: (params: { countryName: string }): IFetchApi => {
    const searchParams = new URLSearchParams(omitEmptyParams(params));
    return {
      url: "depots",
      method: "get",
      isAuth: true,
      params: searchParams,
    };
  },
  getDepotList: (params: GetDepotListParams): IFetchApi => {
    const searchParams = new URLSearchParams(omitEmptyParams(params));
    return {
      url: "depots/depot-search-filter-listing",
      method: "get",
      isAuth: true,
      params: searchParams,
    };
  },
  getDepotFilters: (): IFetchApi => ({
    url: "depots/filter",
    method: "get",
    isAuth: true,
  }),
  createNewDepot: (payload: Depot): IFetchApi => ({
    url: "depots/depot-create",
    method: "post",
    isAuth: true,
    payload,
  }),
  updateDepot: (payload: Depot): IFetchApi => ({
    url: "depots/depot-update",
    method: "put",
    isAuth: true,
    payload,
  }),
  geDepotHistory: (params: GetHistoryHistoryParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `depots/history?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
};
