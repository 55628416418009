import { Form, FormItemProps, Select, SelectProps, Typography } from "antd";

import ArrowDown from "assets/icons/ArrowDown.svg";
import OptionNotFound from "assets/images/OptionNotFound.svg";
import { formStyles } from "components/form/styles";

type CustomSelectProps = FormItemProps &
  SelectProps & {
    customClassName?: string;
    customFormItemClassName?: string;
    placeholderStyle?: string;
    popupClassName?: string;
  };

export const CustomSelect = ({
  mode,
  loading,
  listHeight,
  placeholder,
  initialValue,
  placeholderStyle,
  customClassName,
  customFormItemClassName,
  suffixIcon = <img alt="select-arrow-down-icon" src={ArrowDown} />,
  onClear,
  popupClassName = "",
  ...restProps
}: CustomSelectProps) => (
  <Form.Item
    className={`${formStyles.formItem.base} ${customFormItemClassName}`}
    initialValue={initialValue}
    {...restProps}
  >
    <Select
      {...restProps}
      mode={mode}
      listHeight={listHeight}
      loading={loading}
      onClear={onClear}
      size="large"
      popupClassName={`custom-account-select-dropdown !w-[18.75rem] ${popupClassName}`}
      className={`custom-account-select min-w-[150px] ${
        mode === "multiple"
          ? formStyles.select.multiple
          : formStyles.select.base
      } ${customClassName}`}
      suffixIcon={suffixIcon}
      placeholder={
        <Typography.Text className={placeholderStyle}>
          {placeholder}
        </Typography.Text>
      }
      notFoundContent={
        <div className="h-[200px] flex flex-col items-center justify-center py-6">
          <img src={OptionNotFound} alt="select-option-not-found" />
          <Typography.Text className="block mt-1 font-roboto font-normal leading-[14px] text-blue-waikawa-grey">
            No data matches
          </Typography.Text>
        </div>
      }
    />
  </Form.Item>
);
