import {
  ExpandedChildDataType,
  IChildrenTreeData,
  TreeDataType,
} from "pages/eod-management/interfaces";
import { compact, every, isArray, isEmpty, isNil, isObject } from "lodash";
import dayjs, { Dayjs } from "dayjs";

import { DefaultOptionType } from "antd/es/select";
import { EMAIL_REGEX, NUMBER_AND_CHARS_ONLY } from "constants/constants";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { Mutation } from "react-query";
import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";
import { pingSSOConfig } from "constants/configs";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/duration"));

export const isLogin = () => {
  return Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
};

/**
 * Get first letter of each word in a string (word)
 *
 * @param {string} fullName
 * @return {string} acronym
 */
export const getAcronym = (fullName: string) => {
  // fullName: 'John Harry'
  if (!fullName) {
    return "";
  }

  const splitWord = fullName.split(/\s/); // => ['John', 'Harry']
  const acronym = splitWord.reduce((response, word, idx, arr) => {
    if (idx === 0 || idx === arr.length - 1) {
      return (response += word.slice(0, 1));
    }

    return (response += "");
  }, "");

  return acronym;
};

export const dateSorter = (a: any, b: any) => {
  const dateA = dayjs(a);
  const dateB = dayjs(b);
  return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
};

export const omitEmptyParams = (params: any) =>
  Object.fromEntries(
    Object.entries(params as ArrayLike<string>).filter(([, v]) => {
      if (typeof v === "boolean") {
        // keep boolean value (it can be false value)
        return true;
      }

      return !!v;
    })
  );

export const onDisablePastDate =
  // disabled select days before today
  (current: Dayjs) => {
    const today = dayjs().endOf("day");
    return current.isBefore(today.subtract(1, "day")) || current.isSame(today);
  };

export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return "0 B";
  const sizes = ["B", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};

export const dayjsToUtc = (params: string) => dayjs.utc(params).format();

export const getFileTypeByName = (fileName: string) =>
  fileName.split(".")[fileName.split(".").length - 1];

export const getValueFromAsyncSelect = (
  fieldValue: DefaultOptionType | string | null,
  isConsigneeOrNameValue?: boolean
): string | null => {
  if (!fieldValue) {
    return null;
  }

  if (typeof fieldValue === "string") {
    return fieldValue;
  }

  const value = isConsigneeOrNameValue ? fieldValue.label : fieldValue.value;
  return value as string;
};

export const parseJsonGetValueOrLabel = (
  stringJson: string | null,
  isGetValue: boolean
) => {
  return stringJson
    ? isGetValue
      ? JSON.parse(stringJson).value
      : JSON.parse(stringJson).label
    : stringJson;
};

export const insertItemIntoArray = <T>(arr: T[], index: number, newItem: T) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

// https://github.com/ant-design/ant-design/issues/5007
export const emailValidator = (
  _rule: RuleObject,
  values: StoreValue,
  callback: (error?: string) => void
): Promise<void | any> | void => {
  if (!values) {
    callback();
  } else {
    if (typeof values === "string") {
      if (!values.match(EMAIL_REGEX)) {
        callback("Invalid emails");
      } else {
        callback();
      }
    }
    const invalidInputs = values.filter(
      (value: string) => !value.match(EMAIL_REGEX)
    );
    if (invalidInputs.length) {
      callback("Invalid emails");
    } else {
      callback();
    }
  }
};

export const ccgidRegex = (
  _rule: RuleObject,
  values: StoreValue,
  callback: (error?: string) => void
): Promise<void | any> | void => {
  if (!values) {
    callback();
  } else {
    if (typeof values === "string") {
      if (!values.match(NUMBER_AND_CHARS_ONLY)) {
        callback("Ccgid not allow special character");
      } else {
        callback();
      }
    }
  }
};

export const trimText = (
  text?: string,
  numberOfCharacters = 10,
  isTruncate = true
) => {
  if (!text) {
    return "";
  }

  if (text.length > numberOfCharacters) {
    return `${text.substring(0, numberOfCharacters)}${isTruncate ? "..." : ""}`;
  }

  return text;
};

export const removeRedundantConsigneeFlag = (
  inputString: string | null,
  flags: string[]
) => {
  if (!inputString) return null;
  let lastIndex = -1;
  let foundFlag = "";
  flags.forEach((element) => {
    if (inputString.lastIndexOf(element) !== -1) {
      foundFlag = element;
      lastIndex = inputString.lastIndexOf(element);
    }
  });
  return lastIndex !== -1
    ? inputString.slice(0, lastIndex) +
        inputString.slice(lastIndex + foundFlag.length)
    : inputString;
};

export const redirectSSOPageToLogin = () => {
  const dataConnect2: any = {
    response_type: pingSSOConfig.responseType,
    client_id: pingSSOConfig.clientId,
    redirect_uri: pingSSOConfig.redirectUrl,
    scope: `${pingSSOConfig.scope} ${pingSSOConfig.scopeRead} ${pingSSOConfig.scopeWrite}`,
    response_mode: pingSSOConfig.reponseMode,
    state: generateUUIDv4(),
    nonce: generateUUIDv4(),
  };
  const searchParams = new URLSearchParams({
    ...omitEmptyParams(dataConnect2),
  });
  window.location.assign(`${pingSSOConfig.webConnect}?${searchParams}`);
};

const generateUUIDv4 = () =>
  ([1e7, -1e3, -4e3, -8e3, -1e11] as unknown as string[])
    .join("")
    .replace(/[018]/g, (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

// Function to encode a message with a secret key
export const encodeMessage = (message: string, secretKey: string): string => {
  return [...message]
    .map((char, i) =>
      String.fromCharCode(
        char.charCodeAt(0) ^ secretKey.charCodeAt(i % secretKey.length)
      )
    )
    .join("");
};

// Function to decode a message with a secret key
export const decodeMessage = (
  encodedMessage: string,
  secretKey: string
): string =>
  [...encodedMessage]
    .map((char, i) =>
      String.fromCharCode(
        char.charCodeAt(0) ^ secretKey.charCodeAt(i % secretKey.length)
      )
    )
    .join("");

export const objectContainsKeys = (
  obj: Record<string, any>,
  keysToCheck: string[]
) => {
  return keysToCheck.every((key) => key in obj);
};

export const convertTextareaToString = (textareaId: string | null) => {
  if (!textareaId) {
    return null;
  }
  // Get the value from the textarea and replace line breaks with a single space
  let inputString = textareaId.replace(/\n/g, " ");
  // Remove duplicate spaces by replacing multiple spaces with a single space
  inputString = inputString.replace(/\s+/g, " ");
  return inputString.trim(); // Trim leading and trailing spaces
};

export const removeDuplicatesAndAddArray = (
  arr1: any[],
  arr2: any[]
): any[] => {
  const uniqueMap = new Map(); // Use a Map to store unique items
  const resultArray = [];

  // Iterate over the first array
  for (const element of arr1) {
    // If the element is not already in the map, add it to the map and result array
    if (!uniqueMap.has(element)) {
      uniqueMap.set(element, true);
      resultArray.push(element);
    }
  }

  // Iterate over the second array
  for (const element of arr2) {
    // If the element is not already in the map, add it to the map and result array
    if (!uniqueMap.has(element)) {
      uniqueMap.set(element, true);
      resultArray.push(element);
    }
  }

  return resultArray;
};

export const convertBaseDataToAllTableKeyString = (
  apIData: TreeDataType[],
  unCheckedItemAfterCheckedAll: string[]
): [string[], IChildrenTreeData[]] => {
  const childrenArray: (
    | {
        parent: string;
        children: string[];
        totalChild: ExpandedChildDataType[];
      }
    | undefined
  )[] = apIData.map((item) => {
    const children = item.deliveryOrders
      .filter(
        (item) =>
          !unCheckedItemAfterCheckedAll.includes(item.id) && item.canEdit
      )
      .map((deliveryOrder) => deliveryOrder.id);
    if (children.length > 0) {
      return {
        parent: item.blId,
        children,
        totalChild: [...item.deliveryOrders],
      };
    }
  });

  const blParentIdArray: string[] = compact(childrenArray).map((item) =>
    item.children.length > 0 ? item.parent : ""
  );

  return [compact(blParentIdArray), compact(childrenArray)];
};

export const extractChildren = (data: { children: string[] }[]): string[] => {
  const childrenArray: string[] = [];
  for (const item of data) {
    childrenArray.push(...item.children);
  }
  return childrenArray;
};

//get queryTracking's index by its status
export const findIndexByStatus = (
  status: string,
  mutations: Mutation<unknown, unknown, void, unknown>[]
) => {
  return mutations.findIndex(
    (mutation: any) =>
      mutation?.options?.variables?.queryTracking &&
      mutation.state.status === status
  );
};

export const allEmptyValues = (obj: Record<string, any>): boolean => {
  return every(obj, (value) => {
    if (isObject(value) && !isEmpty(value)) {
      return allEmptyValues(value as Record<string, any>);
    }
    return (
      isNil(value) ||
      value === "" ||
      value === false ||
      (isArray(value) && value.length === 0)
    );
  });
};

export const convertUTCToBaseDate = (
  date: string,
  endOfDate: boolean
): Dayjs | null => {
  if (!date) {
    return null;
  }
  return endOfDate
    ? dayjs.utc(date.toString()).endOf("day")
    : dayjs.utc(date.toString()).startOf("day");
};

export function cleanRoles(
  data: {
    country: string;
    roles: string[];
  }[]
) {
  return data.map((item) => {
    return {
      ...item,
      roles: item.roles.filter((role) => role !== ""),
    };
  });
}

export const getTimezoneOffset = () => {
  const offset = dayjs().utcOffset(); // Get the offset in minutes
  const sign = offset >= 0 ? "" : "-"; // Determine if the offset is positive or negative

  // Convert the offset to hours and minutes
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60);
  const minutes = absOffset % 60;

  // Format the offset as HH.MM
  return `${sign}${hours}.${minutes < 10 ? "0" : ""}${minutes}`;
};
