import { Dropdown, Image, MenuProps, Typography } from "antd";
import { edoActions, edoActionsDepot } from "../constants";

import { ExpandedChildDataType } from "../interfaces";
import ThreeDotExpandIcon from "assets/icons/ThreeDotExpandIcon.svg";
import { UserRole } from "interfaces/common-model";
import { useEdoContext } from "contexts/edo-context";
import { useExportPDF } from "../queries";
import { useMemo } from "react";
import { useUserRole } from "./SelectRole";
import { getTimezoneOffset } from "libs/helper";

type EdoMoreActionButtonProps = {
  record: ExpandedChildDataType;
};

export const EdoMoreActionButton = ({ record }: EdoMoreActionButtonProps) => {
  const { setEdo } = useEdoContext();
  const role = useUserRole();

  const { mutate: exportPDF } = useExportPDF();

  const items: MenuProps["items"] = useMemo(() => {
    const actions = role === UserRole.Depot ? edoActionsDepot : edoActions;
    return actions.map(({ key, label }) => ({
      key,
      label: (
        <Typography.Text className="font-roboto font-normal text-base leading-6 text-blue-sapphire">
          {label}
        </Typography.Text>
      ),
    }));
  }, [role]);

  const handleEdoAction = (event: any, record: ExpandedChildDataType) => {
    switch (event.key) {
      case "edit-edo":
        return setEdo((prev) => ({
          ...prev,
          timestamp: new Date().toISOString(),
          doId: record.id,
          isOpenDrawerEdoDetail: true,
        }));
      case "view-pdf-file":
        return exportPDF(
          { id: record.id, timezone: getTimezoneOffset() },
          {
            onSuccess: (data: any) => {
              const file = new Blob([data], { type: "application/pdf" });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            },
          }
        );
      case "view-history":
        return setEdo((prev) => ({
          ...prev,
          isOpenHistoryTransaction: {
            isOpen: true,
            edoRowData: record.id,
          },
        }));
      default:
        break;
    }
  };

  return (
    <div className="flex items-center justify-center item-center w-full">
      <Dropdown
        placement="bottomLeft"
        trigger={["click"]}
        menu={{
          items,
          onClick: (menuInfo) => handleEdoAction(menuInfo, record),
          className:
            "border border-solid border-blue-sapphire !rounded-none !p-0 shadow-none [&>li]:!rounded-none [&>li]:!px-3 [&>li]:!py-2 [&>li]:min-w-[8.25rem] [&>li:hover]:!bg-blue-solitude-light",
        }}
      >
        <Image
          preview={false}
          className="cursor-pointer !w-6 !h-6"
          src={ThreeDotExpandIcon}
          alt="edo-action-icon"
        />
      </Dropdown>
    </div>
  );
};
