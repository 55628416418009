export const formStyles = {
  formItem: {
    base: "[&_.ant-form-item-label]:!pb-[0.125rem] [&_.ant-form-item-label>label]:font-roboto [&_.ant-form-item-label>label]:font-normal [&_.ant-form-item-label>label]:text-sm [&_.ant-form-item-label>label]:leading-4 [&_.ant-form-item-label>label]:text-blue-sapphire select-none relative [&_.ant-form-item-required::before]:!hidden [&_input]:text-blue-sapphire",
  },
  datePicker: {
    base: "h-10 border border-solid border-blue-waikawa-grey rounded-none shadow-none [&:not(:focus):hover]:border-blue-royal-blue focus:border-blue-waikawa-grey focus:shadow-none [&_.ant-picker-input>input]:font-roboto [&_.ant-picker-input>input]:font-normal [&_.ant-picker-input>input]:text-sm [&_.ant-picker-input>input]:text-blue-sapphire [&_.ant-picker-input>input]:leading-4 [&_.ant-picker-input>input::placeholder]:text-sm [&_.ant-picker-input>input::placeholder]:leading-4 [&_.ant-picker-input>input::placeholder]:font-roboto [&_.ant-picker-input>input::placeholder]:font-normal [&_.ant-picker-input>input::placeholder]:text-blue-rock-blue [&.ant-picker-disabled]:!bg-blue-solitude-second [&.ant-picker-disabled]:!border-grey-slate-grey [&.ant-picker-disabled_.ant-picker-input>input]:!text-grey-slate-grey [&.ant-picker-status-error]:!border-red-torch",
    withDropdown:
      "w-full rounded-none h-10 !pl-[6.5625rem] shadow-none border-blue-waikawa-grey [&:not(:disabled):hover]:!border-blue-waikawa-grey [&_.ant-picker-input>input]:font-roboto [&_.ant-picker-input>input]:font-normal [&_.ant-picker-input>input]:text-sm [&_.ant-picker-input>input]:text-blue-sapphire [&_.ant-picker-input>input]:leading-4 [&_.ant-picker-input>input::placeholder]:text-sm [&_.ant-picker-input>input::placeholder]:leading-4 [&_.ant-picker-input>input::placeholder]:font-roboto [&_.ant-picker-input>input::placeholder]:font-normal [&_.ant-picker-input>input::placeholder]:text-blue-rock-blue [&.ant-picker-disabled]:!bg-blue-solitude-second [&.ant-picker-disabled]:!border-grey-slate-grey [&.ant-picker-disabled_.ant-picker-input>input]:!text-grey-slate-grey [&.ant-picker-status-error]:!border-red-torch",
  },
  select: {
    base: "!h-10 [&_.ant-select-selection-search-input]:!h-full [&_.ant-select-selection-search-input]:pr-4 [&_.ant-select-selection-search]:pr-4 [&_.ant-select-selector]:!border [&_.ant-select-selector]:border-solid [&_.ant-select-selector]:!border-blue-waikawa-grey [&_.ant-select-selector]:rounded-none [&_.ant-select-selector:not(:focus):hover]:border-blue-royal-blue [&.ant-select-disabled_.ant-select-selector]:!border-grey-slate-grey [&_.ant-select-selector]:!shadow-none [&_.ant-select-selection-item]:font-roboto [&_.ant-select-selection-item]:font-normal [&_.ant-select-selection-item]:text-sm [&_.ant-select-selection-item]:text-blue-sapphire [&_.ant-select-selection-search-input]:!text-blue-sapphire [&_.ant-select-selection-item]:leading-4 [&_.ant-select-selection-placeholder]:text-sm [&_.ant-select-selection-placeholder]:leading-4 [&_.ant-select-selection-placeholder]:font-roboto [&_.ant-select-selection-placeholder]:font-normal [&_.ant-select-selection-placeholder]:text-blue-rock-blue [&_.ant-select-selection-placeholder]:pl-[0.125rem] [&.ant-select-disabled]:!bg-blue-solitude-second [&.ant-select-disabled]:!border-grey-slate-grey [&.ant-select-disabled_.ant-select-selection-item]:!text-grey-slate-grey [&.ant-select-status-error_.ant-select-selector]:!border-red-torch",
    multiple:
      "!h-10 [&_.ant-select-selection-search-input]:!h-full [&_.ant-select-selector]:!border [&_.ant-select-selector]:!border-solid [&_.ant-select-selector]:!border-blue-waikawa-grey [&_.ant-select-selector]:!rounded-none [&_.ant-select-selector:not(:focus):hover]:border-blue-royal-blue [&.ant-select-disabled_.ant-select-selector]:!border-grey-slate-grey [&_.ant-select-selector]:!shadow-none [&_.ant-select-selection-item]:font-roboto [&_.ant-select-selection-item]:font-normal [&_.ant-select-selection-item]:text-sm [&_.ant-select-selection-item]:text-blue-sapphire [&_.ant-select-selection-search-input]:!text-blue-sapphire [&_.ant-select-selection-item]:leading-4 [&_.ant-select-selection-placeholder]:text-sm [&_.ant-select-selection-placeholder]:leading-4 [&_.ant-select-selection-placeholder]:font-roboto [&_.ant-select-selection-placeholder]:font-normal [&_.ant-select-selection-placeholder]:text-blue-rock-blue [&_.ant-select-selection-placeholder]:pl-[0.125rem] [&.ant-select-disabled]:!bg-blue-solitude-second [&.ant-select-disabled]:!border-grey-slate-grey [&.ant-select-disabled_.ant-select-selection-item]:!text-grey-slate-grey [&_.ant-select-selection-item]:!bg-white [&_.ant-select-selection-item]:!h-auto [&_.ant-select-selection-item]:!py-1 [&_.ant-select-selection-item]:!px-2 [&_.ant-select-selection-item]:!my-0 [&_.ant-select-selection-item]:!mr-2 [&_.ant-select-selection-item]:border [&_.ant-select-selection-item]:border-solid [&_.ant-select-selection-item]:!border-blue-solitude [&_.ant-select-selection-item]:!rounded-sm [&_.ant-select-selection-item-content]:font-roboto [&_.ant-select-selection-item-content]:font-normal [&_.ant-select-selection-item-content]:text-xs [&_.ant-select-selection-item-content]:text-blue-sapphire [&_.ant-select-selection-item-content]:!leading-[0.875rem [&.ant-select-status-error_.ant-select-selector]:!border-red-torch",
  },
  input: {
    base: "h-10 border border-solid border-blue-waikawa-grey rounded-none [&:not(:focus):hover]:border-blue-royal-blue focus:border-blue-waikawa-grey focus:shadow-none font-roboto font-normal text-sm text-blue-sapphire leading-4 [&_input:placeholder]:text-sm [&_input::placeholder]:leading-4 [&_input::placeholder]:font-roboto [&_input::placeholder]:font-normal [&_input::placeholder]:!text-blue-rock-blue disabled:!border-grey-slate-grey disabled:text-grey-slate-grey disabled:bg-blue-solitude-second [&.ant-input-status-error:not(.ant-input-disabled)]:!border-red-torch placeholder:!text-blue-rock-blue",
    number:
      "h-10 w-full pl-[6.5625rem] border shadow-none border-solid border-blue-waikawa-grey [&:not(:focus):hover]:!border-blue-waikawa-grey rounded-none focus:border-blue-waikawa-grey focus:shadow-none [&_.ant-input-number]:rounded-none [&_.ant-input-number-input-wrap>input]:!pl-[0.125rem] [&_.ant-input-number-input-wrap>input]:font-roboto [&_.ant-input-number-input-wrap>input]:font-normal [&_.ant-input-number-input-wrap>input]:text-sm [&_.ant-input-number-input-wrap>input]:!h-[2.375rem] [&_.ant-input-number-input-wrap>input]:text-blue-sapphire [&_.ant-input-number-input-wrap>input::placeholder]:!text-ellipsis [&_.ant-input-number-input-wrap]:pr-10 [&_.ant-input-number-input-wrap>input]:leading-4 [&_.ant-input-number-input-wrap>input::placeholder]:text-sm [&_.ant-input-number-input-wrap>input::placeholder]:leading-4 [&_.ant-input-number-input-wrap>input::placeholder]:font-roboto [&_.ant-input-number-input-wrap>input::placeholder]:font-normal [&_.ant-input-number-input-wrap>input::placeholder]:text-blue-rock-blue [&_..ant-input-number-disabled]:!border-grey-slate-grey [&_.ant-input-number-disabled_.ant-input-number-input-wrap>input]:!text-grey-slate-grey [&_.ant-input-number-disabled]:!bg-blue-solitude-second [&.ant-input-status-error:not(.ant-input-disabled)]:!border-red-torch",
    textArea:
      "h-10 border border-solid border-blue-waikawa-grey rounded-none [&:not(:focus):hover]:border-blue-royal-blue focus:border-blue-waikawa-grey focus:shadow-none font-roboto font-normal text-sm text-blue-sapphire leading-4 placeholder:text-sm placeholder:leading-6 placeholder:font-roboto placeholder:font-normal placeholder:text-blue-rock-blue disabled:!border-grey-slate-grey disabled:text-grey-slate-grey disabled:bg-blue-solitude-second !p-3 [&.ant-input-status-error]:!border-red-torch",
  },
  checkbox: {
    base: "[&_.ant-checkbox-inner]:!rounded-none [&_.ant-checkbox-inner]:!border-blue-solitude [&_.ant-checkbox-checked_.ant-checkbox-inner]:!bg-blue-sapphire [&_.ant-checkbox-checked_.ant-checkbox-inner]:!border-none [&_.ant-checkbox-disabled_.ant-checkbox-inner::after]:border-white [&_span:nth-child(2)]:!ps-0 [&_span:nth-child(2)]:!pe-0",
  },
};
