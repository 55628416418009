import DangerIcon from "assets/icons/DangerIcon.svg";
import { Typography } from "antd";

type ErrorInputProps = {
  message?: string;
};

export const ErrorInput = ({
  message = "This field must not be empty",
}: ErrorInputProps) => (
  <div className="flex justify-start text-center mt-1.5 mb-4">
    <img
      className="text-red-torch w-3.5 h-3.5"
      src={DangerIcon}
      alt="DangerIcon"
    />
    <Typography.Text className="font-roboto font-normal text-sm leading-4 ml-1 text-red-torch">
      {message}
    </Typography.Text>
  </div>
);
