export enum ButtonSize {
  large = "large",
  medium = "medium",
  small = "small",
  extraSmall = "extra-small",
}

export enum ButtonColor {
  primary = "primary",
  secondary = "secondary",
  danger = "danger",
  tonal = "tonal",
}

export enum ButtonVariant {
  filled = "filled",
  outline = "outline",
  text = "text",
}
