import { EdoStatus, UserRole } from "interfaces/common-model";

import { EDO_STATUS } from "constants/constants";

export type FieldStatus = {
  disabled: boolean;
  required: boolean;
};

export type MenuItemType = {
  key: number | null;
  label: string;
};

export type NextStatusData = {
  canChangeStatus: boolean;
  canEditInfo: boolean;
  dropdown: MenuItemType[] | [];
};

type HookProps = {
  role: UserRole;
  status: number;
};

export type EdoDetailFormConfig = {
  actualVoyageNo: FieldStatus;
  laraVoyageNo: FieldStatus;
  arrivalDate: FieldStatus;
  vesselName: FieldStatus;
  carrier: FieldStatus;
  isApplyAllBL: FieldStatus;
  blNo: FieldStatus;
  consigneeName: FieldStatus;
  receivingParty: FieldStatus;
  podName: FieldStatus;
  fPodName: FieldStatus;
  securityCode: FieldStatus;
  releaseNo: FieldStatus;
  pickupDate: FieldStatus;
  returnDate: FieldStatus;
  returnFullDate: FieldStatus;
  emptyReturnDepotName: FieldStatus;
  isOffHire: FieldStatus;
  updateReason: FieldStatus;
  nextStatus: NextStatusData;
};

const defaultConfig: EdoDetailFormConfig = {
  actualVoyageNo: {
    disabled: true,
    required: false,
  },
  laraVoyageNo: {
    disabled: true,
    required: false,
  },
  arrivalDate: {
    disabled: true,
    required: false,
  },
  vesselName: {
    disabled: true,
    required: false,
  },
  carrier: {
    disabled: true,
    required: false,
  },
  isApplyAllBL: {
    disabled: true,
    required: false,
  },
  blNo: {
    disabled: true,
    required: false,
  },
  consigneeName: {
    disabled: true,
    required: false,
  },
  receivingParty: {
    disabled: true,
    required: false,
  },
  podName: {
    disabled: true,
    required: false,
  },
  fPodName: {
    disabled: true,
    required: true,
  },
  securityCode: {
    disabled: true,
    required: false,
  },
  releaseNo: {
    disabled: true,
    required: false,
  },
  pickupDate: {
    disabled: true,
    required: false,
  },
  returnDate: {
    disabled: true,
    required: false,
  },
  returnFullDate: {
    disabled: true,
    required: false,
  },
  emptyReturnDepotName: {
    disabled: true,
    required: false,
  },
  isOffHire: {
    disabled: true,
    required: false,
  },
  updateReason: {
    disabled: true,
    required: false,
  },
  nextStatus: {
    canChangeStatus: false,
    canEditInfo: false,
    dropdown: [],
  },
};

const getImportCSConfig = (status: EdoStatus): EdoDetailFormConfig => {
  let config: EdoDetailFormConfig;
  switch (status) {
    case EdoStatus.New:
      config = {
        actualVoyageNo: {
          disabled: false,
          required: true,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: false,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: false,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: false,
          required: true,
        },
        fPodName: {
          disabled: false,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.WaitForLog.value,
              label: EDO_STATUS.WaitForLog.actionLabel,
            },
            {
              key: EDO_STATUS.ReadyForRelease.value,
              label: EDO_STATUS.ReadyForRelease.label,
            },
            {
              key: EDO_STATUS.Delete.value,
              label: EDO_STATUS.Delete.actionLabel,
            },
            {
              key: EDO_STATUS.Shortship.value,
              label: EDO_STATUS.Shortship.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.WaitForLog:
      config = {
        actualVoyageNo: {
          disabled: false,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: false,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: false,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: false,
          required: false,
        },
        fPodName: {
          disabled: false,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Delete.value,
              label: EDO_STATUS.Delete.actionLabel,
            },
            {
              key: EDO_STATUS.Shortship.value,
              label: EDO_STATUS.Shortship.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.ReadyForRelease:
      config = {
        actualVoyageNo: {
          disabled: false,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: false,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: false,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: false,
          required: false,
        },
        fPodName: {
          disabled: false,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Delete.value,
              label: EDO_STATUS.Delete.actionLabel,
            },
            {
              key: EDO_STATUS.Shortship.value,
              label: EDO_STATUS.Shortship.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.Unrelease:
      config = {
        actualVoyageNo: {
          disabled: false,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: false,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: false,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: false,
          required: false,
        },
        fPodName: {
          disabled: false,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Delete.value,
              label: EDO_STATUS.Delete.actionLabel,
            },
            {
              key: EDO_STATUS.Shortship.value,
              label: EDO_STATUS.Shortship.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.Shortship: //won't require any fields when restore
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: false,
          dropdown: [
            { key: EDO_STATUS.New.value, label: EDO_STATUS.New.actionLabel },
          ],
        },
      };
      break;
    case EdoStatus.Delete: //won't require any fields when restore
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: false,
          dropdown: [
            { key: EDO_STATUS.New.value, label: EDO_STATUS.New.actionLabel },
          ],
        },
      };
      break;
    case EdoStatus.Release:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: true,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: true,
        },
        consigneeName: {
          disabled: true,
          required: true,
        },
        receivingParty: {
          disabled: true,
          required: true,
        },
        podName: {
          disabled: true,
          required: true,
        },
        fPodName: {
          disabled: true,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: false,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.ReleaseUpdate:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: true,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: true,
        },
        consigneeName: {
          disabled: true,
          required: true,
        },
        receivingParty: {
          disabled: true,
          required: true,
        },
        podName: {
          disabled: true,
          required: true,
        },
        fPodName: {
          disabled: true,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: false,
          dropdown: [],
        },
      };
      break;

    default:
      config = defaultConfig;
      break;
  }

  return config;
};

const getLogisticConfig = (status: EdoStatus): EdoDetailFormConfig => {
  let config: EdoDetailFormConfig;
  switch (status) {
    case EdoStatus.New:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: false,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.WaitForLog:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: false,
          required: true,
        },
        isOffHire: {
          disabled: false,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.ReadyForRelease.value,
              label: EDO_STATUS.ReadyForRelease.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.ReadyForRelease:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: false,
          required: false,
        },
        isOffHire: {
          disabled: false,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.Unrelease:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: false,
          required: false,
        },
        isOffHire: {
          disabled: false,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.Shortship:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: false,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.Delete:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: false,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.Release:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: false,
          required: true,
        },
        isOffHire: {
          disabled: false,
          required: false,
        },
        updateReason: {
          disabled: false,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.ReleaseUpdate:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: false,
          required: true,
        },
        isOffHire: {
          disabled: false,
          required: false,
        },
        updateReason: {
          disabled: false,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;

    default:
      config = defaultConfig;
      break;
  }
  return config;
};

const getCounterConfig = (status: EdoStatus): EdoDetailFormConfig => {
  let config;
  switch (status) {
    case EdoStatus.New:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.WaitForLog:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;
    case EdoStatus.ReadyForRelease:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: false,
          required: true,
        },
        consigneeName: {
          disabled: false,
          required: true,
        },
        receivingParty: {
          disabled: false,
          required: true,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: true,
        },
        returnDate: {
          disabled: false,
          required: true,
        },
        returnFullDate: {
          disabled: false,
          required: true,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Release.value,
              label: EDO_STATUS.Release.actionLabel,
            },
            {
              key: EDO_STATUS.Delete.value,
              label: EDO_STATUS.Delete.actionLabel,
            },
            {
              key: EDO_STATUS.Shortship.value,
              label: EDO_STATUS.Shortship.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.Unrelease:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: false,
          required: true,
        },
        consigneeName: {
          disabled: false,
          required: true,
        },
        receivingParty: {
          disabled: false,
          required: true,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: true,
        },
        returnDate: {
          disabled: false,
          required: true,
        },
        returnFullDate: {
          disabled: false,
          required: true,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Release.value,
              label: EDO_STATUS.Release.actionLabel,
            },
            {
              key: EDO_STATUS.Delete.value,
              label: EDO_STATUS.Delete.actionLabel,
            },
            {
              key: EDO_STATUS.Shortship.value,
              label: EDO_STATUS.Shortship.label,
            },
          ],
        },
      };
      break;
    case EdoStatus.Shortship:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: true,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: true,
        },
        consigneeName: {
          disabled: true,
          required: true,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: true,
        },
        fPodName: {
          disabled: true,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: false,
          dropdown: [
            { key: EDO_STATUS.New.value, label: EDO_STATUS.New.actionLabel },
          ],
        },
      };
      break;
    case EdoStatus.Delete:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: true,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: true,
        },
        consigneeName: {
          disabled: true,
          required: true,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: true,
        },
        fPodName: {
          disabled: true,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: true,
          required: false,
        },
        returnDate: {
          disabled: true,
          required: false,
        },
        returnFullDate: {
          disabled: true,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: true,
          required: false,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: false,
          dropdown: [
            { key: EDO_STATUS.New.value, label: EDO_STATUS.New.actionLabel },
          ],
        },
      };
      break;
    case EdoStatus.Release:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: false,
        },
        consigneeName: {
          disabled: true,
          required: false,
        },
        receivingParty: {
          disabled: true,
          required: false,
        },
        podName: {
          disabled: true,
          required: false,
        },
        fPodName: {
          disabled: true,
          required: false,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: true,
        },
        returnDate: {
          disabled: false,
          required: true,
        },
        returnFullDate: {
          disabled: false,
          required: true,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: false,
          required: true,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Unrelease.value,
              label: EDO_STATUS.Unrelease.actionLabel,
            },
          ],
        },
      };
      break;
    case EdoStatus.ReleaseUpdate:
      config = {
        actualVoyageNo: {
          disabled: true,
          required: true,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: true,
          required: true,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: true,
          required: false,
        },
        isApplyAllBL: {
          disabled: true,
          required: false,
        },
        blNo: {
          disabled: true,
          required: true,
        },
        consigneeName: {
          disabled: true,
          required: true,
        },
        receivingParty: {
          disabled: true,
          required: true,
        },
        podName: {
          disabled: true,
          required: true,
        },
        fPodName: {
          disabled: true,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: true,
        },
        returnDate: {
          disabled: false,
          required: true,
        },
        returnFullDate: {
          disabled: false,
          required: true,
        },
        emptyReturnDepotName: {
          disabled: true,
          required: false,
        },
        isOffHire: {
          disabled: true,
          required: false,
        },
        updateReason: {
          disabled: false,
          required: true,
        },
        nextStatus: {
          canChangeStatus: true,
          canEditInfo: true,
          dropdown: [
            {
              key: EDO_STATUS.Unrelease.value,
              label: EDO_STATUS.Unrelease.actionLabel,
            },
          ],
        },
      };
      break;

    default:
      config = defaultConfig;
      break;
  }
  return config;
};

export const useConfigStatusMatrix = ({ role, status }: HookProps) => {
  let config;

  switch (role) {
    case UserRole.ImportCS:
      config = getImportCSConfig(status);
      break;
    case UserRole.Logistic:
      config = getLogisticConfig(status);
      break;
    case UserRole.Counter:
      config = getCounterConfig(status);
      break;
    case UserRole.Depot:
      // TODO: handle config for Depot
      config = defaultConfig;
      break;
    case UserRole.ITOfficer:
    case UserRole.Admin:
      config = {
        actualVoyageNo: {
          disabled: false,
          required: false,
        },
        laraVoyageNo: {
          disabled: true,
          required: false,
        },
        arrivalDate: {
          disabled: false,
          required: false,
        },
        vesselName: {
          disabled: true,
          required: false,
        },
        carrier: {
          disabled: false,
          required: false,
        },
        isApplyAllBL: {
          disabled: false,
          required: false,
        },
        blNo: {
          disabled: false,
          required: false,
        },
        consigneeName: {
          disabled: false,
          required: false,
        },
        receivingParty: {
          disabled: false,
          required: false,
        },
        podName: {
          disabled: false,
          required: false,
        },
        fPodName: {
          disabled: false,
          required: true,
        },
        securityCode: {
          disabled: true,
          required: false,
        },
        releaseNo: {
          disabled: true,
          required: false,
        },
        pickupDate: {
          disabled: false,
          required: false,
        },
        returnDate: {
          disabled: false,
          required: false,
        },
        returnFullDate: {
          disabled: false,
          required: false,
        },
        emptyReturnDepotName: {
          disabled: false,
          required: false,
        },
        isOffHire: {
          disabled: false,
          required: false,
        },
        updateReason: {
          disabled: false,
          required: false,
        },
        nextStatus: {
          canChangeStatus: false,
          canEditInfo: true,
          dropdown: [],
        },
      };
      break;
    default:
      config = defaultConfig;
      break;
  }

  return config;
};
