import {
  Form,
  FormItemProps,
  Image,
  Select,
  SelectProps,
  Typography,
} from "antd";

import ArrowDown from "assets/icons/ArrowDown.svg";
import OptionNotFound from "assets/images/OptionNotFound.svg";
import { formStyles } from "./styles";
import { useMemo } from "react";

type FormSelectProps = Omit<FormItemProps, "size"> &
  SelectProps & {
    customFormItemClassName?: string;
    customSelectClassName?: string;
  };

export const NotFoundContent = () => (
  <div className="h-[12.5rem] flex flex-col items-center justify-center py-6">
    <img src={OptionNotFound} alt="select-option-not-found" />
    <Typography.Text className="block mt-1 font-roboto font-normal leading-[0.875rem] text-blue-waikawa-grey">
      No data matches
    </Typography.Text>
  </div>
);

export const FormSelect = ({
  mode,
  label,
  disabled,
  showSearch,
  name,
  initialValue,
  rules,
  popupClassName = "custom-account-select-dropdown",
  customSelectClassName,
  customFormItemClassName = "!mb-4",
  notFoundContent = <NotFoundContent />,
  ...restProps
}: FormSelectProps) => {
  const selectStyle = useMemo(() => {
    switch (mode) {
      case "multiple":
        return formStyles.select.multiple;
      case "tags":
        return formStyles.select.multiple;
      default:
        return formStyles.select.base;
    }
  }, [mode]);

  return (
    <Form.Item
      name={name}
      rules={rules}
      initialValue={initialValue}
      className={`${formStyles.formItem.base} ${customFormItemClassName}`}
      label={label}
    >
      <Select
        mode={mode}
        disabled={disabled}
        showSearch={showSearch}
        className={`${selectStyle} ${customSelectClassName}`}
        notFoundContent={notFoundContent}
        popupClassName={popupClassName}
        suffixIcon={
          <Image preview={false} alt="select-arrow-down-icon" src={ArrowDown} />
        }
        {...restProps}
      />
    </Form.Item>
  );
};
