import { Space, Tag, Typography } from "antd";
import { cloneDeep, range } from "lodash";
import { useMemo, useState } from "react";

import { CustomTooltip } from "./CustomTooltip";
import { MAX_TRUNCATE } from "constants/constants";
import { TooltipsCopy } from "./TooltipsCopy";
import { removeItemsByIndices } from "pages/eod-management/updateStatusItemSetup";

type CustomMultipleTagsDisplayProps = {
  tags: string[];
  numberOfShowedTags: number;
};

const CustomMutipleTagsDisplay = ({
  tags,
  numberOfShowedTags,
}: CustomMultipleTagsDisplayProps) => {
  const toolTipDisplayTag = useMemo(() => {
    return removeItemsByIndices(cloneDeep(tags), range(numberOfShowedTags));
  }, [tags]);

  const renderDisplayTags = useMemo(() => {
    return tags.length > numberOfShowedTags
      ? removeItemsByIndices(
          cloneDeep(tags),
          range(cloneDeep(tags).length).slice(numberOfShowedTags)
        )
      : tags;
  }, [tags]);

  const renderTooltipDisplay = useMemo(() => {
    return toolTipDisplayTag.map((tag) => {
      const isLongTag = tag.length > MAX_TRUNCATE.TOTAL_30_CHARS; //number of max char before truncate
      const tagElem = (
        <span>
          {isLongTag ? `${tag.slice(0, MAX_TRUNCATE.TOTAL_30_CHARS)}...` : tag}
        </span>
      );
      return (
        <div key={tag} className="[&_.ant-typography:hover]:underline">
          <TooltipsCopy trigger={["click"]} copytext={tag} placement="right">
            <Typography.Text
              className={`cursor-pointer text-white font-roboto text-sm leading-5 font-normal`}
            >
              {tagElem}
            </Typography.Text>
          </TooltipsCopy>
        </div>
      );
    });
  }, [tags]);

  const [hoverStyle, setHoverStyle] = useState<{
    color: string;
    background: string;
  }>({
    color: "",
    background: "",
  });
  const handleEnter = (type: string) => {
    if (type === "leave") {
      setHoverStyle({
        color: "!text-blue-sapphire",
        background: "!bg-white",
      });
    } else {
      setHoverStyle({
        color: "!text-white",
        background: "!bg-blue-sapphire",
      });
    }
  };

  const renderHoverStyle = useMemo(() => {
    return `${hoverStyle.color} ${hoverStyle.background}`;
  }, [hoverStyle]);

  return (
    <Space size={[0, 8]} wrap>
      {renderDisplayTags.map((tag) => {
        return (
          <TooltipsCopy
            key={tag}
            trigger={["click"]}
            copytext={tag}
            placement="bottom"
          >
            <Tag
              key={tag}
              closable={false}
              className={`cursor-pointer px-2 py-1 text-blue-sapphire bg-white font-roboto text-xs leading-[14px] font-normal `}
            >
              {/* 23 chars as max to truncate with total render >1 else truncate with 30 chars max */}
              {tag.length > MAX_TRUNCATE.TOTAL_23_CHARS &&
              renderDisplayTags.length > 1
                ? `${tag.slice(0, MAX_TRUNCATE.TOTAL_23_CHARS)}...`
                : tag.length > MAX_TRUNCATE.TOTAL_30_CHARS
                ? `${tag.slice(0, MAX_TRUNCATE.TOTAL_30_CHARS)}...`
                : tag}
            </Tag>
          </TooltipsCopy>
        );
      })}
      {tags.length > numberOfShowedTags && (
        <CustomTooltip
          trigger={["hover"]}
          copytext={renderTooltipDisplay}
          isShowCopy={false}
          callBackOnMouseEnter={handleEnter}
          callBackonMouseLeave={handleEnter}
          className="w-500px"
          placement="bottom"
          customToolTip={`[&_.ant-tooltip-inner]:!p-0`}
          customInnerClass={"p-3"}
        >
          <Tag
            className={`cursor-pointer customeTags px-2 py-1 font-roboto text-xs leading-[14px] font-normal ${renderHoverStyle}`}
          >
            {`+${tags.length - numberOfShowedTags} others`}
          </Tag>
        </CustomTooltip>
      )}
    </Space>
  );
};

export default CustomMutipleTagsDisplay;
