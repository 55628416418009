import {
  Col,
  Form,
  Modal,
  ModalProps,
  Row,
  SelectProps,
  Typography,
} from "antd";
import { Depot, DepotDetailFormValues, DepotType } from "../interfaces";
import { useCreateDepot, useUpdateDepot } from "../queries";
import { useEffect, useState } from "react";

import { CustomButtom } from "components/button/CustomButton";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { FormCheckbox } from "components/form/Checkbox";
import { FormInput } from "components/form/Input";
import { FormSelect } from "components/form/Select";
import { emailValidator } from "libs/helper";
import get from "lodash/get";
import { trim } from "lodash";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { useNotification } from "hooks/use-notification";

type ModalDepotProps = ModalProps & {
  depot?: Depot | null;
  countryOptions: SelectProps["options"];
  typeOptions: SelectProps["options"];
  refetchOption?: () => void;
  onCancel?: () => void;
};

const INITIAL_FORM_VALUES: DepotDetailFormValues = {
  address: null,
  countryName: null,
  depotCode: null,
  depotId: null,
  depotName: null,
  emails: undefined,
  logisticEmails: undefined,
  isDeployed: false,
  status: false,
  phoneNumber: null,
  type: DepotType.Terminal,
};

export const ModalDepot = ({
  depot,
  countryOptions,
  typeOptions,
  refetchOption,
  onCancel,
  ...restProps
}: ModalDepotProps) => {
  const [form] = Form.useForm();
  const { handleOpenSuccessNotification } = useNotification();
  const [isCreate, setIsCreate] = useState(true);

  const { mutateAsync: createDepot, isLoading: isCreatingDepot } =
    useCreateDepot();
  const { mutateAsync: updateDepot, isLoading: isUpdatingDepot } =
    useUpdateDepot();

  const depotCode = Form.useWatch("depotCode", form);
  const depotCodeError = form.getFieldError("depotCode");
  const depotCodeErrorMessage =
    depotCodeError.length < 1 || !depotCode ? undefined : (
      <ErrorInput message={depotCodeError[0]} />
    );

  const handleCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const handleApiError = (error: any) => {
    const msgCode = get(error, "response.data.Message");

    if (msgCode === ERROR_MESSAGE_CODE.MSG_02) {
      form.setFields([
        {
          name: "depotCode",
          errors: ["Depot code existed."],
        },
      ]);
    }

    if (msgCode === ERROR_MESSAGE_CODE.MSG_03) {
      form.setFields([
        {
          name: "emails",
          errors: ["Invalid email format."],
        },
      ]);
    }
  };

  const currentCountry = useCountry();
  const handleSubmitForm = (values: Depot) => {
    if (isCreate) {
      // create new customer
      createDepot({
        ...values,
        depotCode: trim(values.depotCode),
        depotName: trim(values.depotName),
        countryName: currentCountry,
        address: trim(values.address),
        phoneNumber: trim(values.phoneNumber),
      })
        .then(() => {
          // show notification
          handleOpenSuccessNotification({
            message: "Add new depot successfully!",
          });
          // refetch customer list
          refetchOption && refetchOption();
          // close modal and reset form
          handleCancelModal();
        })
        .catch(handleApiError);
    } else {
      updateDepot({
        ...values,
        depotCode: trim(values.depotCode),
        depotName: trim(values.depotName),
        countryName: currentCountry,
        address: trim(values.address),
        depotId: depot?.depotId!,
        phoneNumber: trim(values.phoneNumber),
      })
        .then(() => {
          handleOpenSuccessNotification({
            message: "Depot information has been updated successfully!",
          });
          refetchOption && refetchOption();
          handleCancelModal();
        })
        .catch(handleApiError);
    }
  };

  useEffect(() => {
    setIsCreate(!depot);
  }, [depot]);

  useEffect(() => {
    if (depot) {
      form.setFieldsValue(depot);
    }
  }, [depot, form]);

  useEffect(() => {
    form.setFieldValue("countryName", currentCountry);
  }, [restProps.open]);

  return (
    <Modal
      closable={false}
      className="w-[512px] h-[560px]"
      footer={false}
      onCancel={handleCancelModal}
      {...restProps}
    >
      <>
        <Typography.Text className="!block text-blue-sapphire text-xl not-italic font-antonio font-bold tracking-[-0.1px]">
          {isCreate ? "Create New Depot" : "Edit Depot"}
        </Typography.Text>
        <Typography.Text className="!block text-blue-rock-blue font-roboto text-sm not-italic font-normal mt-2">
          Enter Depot’s Information
        </Typography.Text>
        <Form
          className="mt-5"
          form={form}
          layout="vertical"
          initialValues={INITIAL_FORM_VALUES}
          onFinish={handleSubmitForm}
        >
          <Row className="pb-6" gutter={[16, 0]}>
            <Col span={12}>
              <FormInput
                label="Depot Code"
                name="depotCode"
                help={depotCodeErrorMessage}
                placeholder="Enter Depot Code"
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                ]}
                onFocus={() =>
                  form.setFields([{ name: "depotCode", errors: [] }])
                }
                disabled={!isCreate}
              />
            </Col>
            <Col span={12}>
              <FormInput
                label="Depot Name"
                name="depotName"
                placeholder="Enter Depot Name"
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <FormSelect
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                ]}
                placeholder="Select Depot Type"
                options={typeOptions}
              />
            </Col>
            <Col span={12}>
              <FormSelect
                label="Country"
                name="countryName"
                placeholder="Select Country"
                disabled
                options={countryOptions}
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <FormInput
                label="Address"
                name="address"
                placeholder="Enter Address"
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <FormInput
                label="Phone Number"
                name="phoneNumber"
                placeholder="Enter Phone Number"
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <FormSelect
                showSearch
                mode="tags"
                label="Depot email"
                open={false}
                name="emails"
                rules={[
                  {
                    required: true,
                    message: <ErrorInput />,
                  },
                  {
                    validator: emailValidator,
                    message: <ErrorInput message="Incorrect email format(s)" />,
                  },
                ]}
                suffixIcon={null}
                placeholder="Enter Depot email"
                customSelectClassName="[&_.ant-select-selection-item]:!my-1 !h-auto [&_.ant-select-selector]:!py-1.5 [&_.ant-select-selector]:!px-2.5 [&_.ant-select-selection-search]:!ms-[0.1875rem]"
                onFocus={() => form.setFields([{ name: "emails", errors: [] }])}
              />
            </Col>
            <Col span={24}>
              <FormSelect
                showSearch
                mode="tags"
                label="Logistic email"
                open={false}
                name="logisticEmails"
                rules={[
                  {
                    validator: emailValidator,
                    message: <ErrorInput message="Incorrect email format(s)" />,
                  },
                ]}
                suffixIcon={null}
                placeholder="Enter Logistic email"
                customSelectClassName="[&_.ant-select-selection-item]:!my-1 !h-auto [&_.ant-select-selector]:!py-1.5 [&_.ant-select-selector]:!px-2.5 [&_.ant-select-selection-search]:!ms-[0.1875rem]"
                onFocus={() =>
                  form.setFields([{ name: "logisticEmails", errors: [] }])
                }
              />
            </Col>
            <Col span={24}>
              <FormCheckbox
                name="status"
                checkboxLabel="Depot is Active?"
                customFormItemClassName="mb-0"
                valuePropName="checked"
              />
            </Col>
            <Col span={24}>
              <FormCheckbox
                name="isDeployed"
                checkboxLabel="eDO is deployed?"
                customFormItemClassName="mb-0"
                valuePropName="checked"
              />
            </Col>
          </Row>
          <div className="grid grid-cols-12 gap-x-4">
            <CustomButtom
              title="Cancel"
              disabled={isCreatingDepot || isUpdatingDepot}
              variant="outline"
              customSize="medium"
              customColor="secondary"
              customClassName="col-span-6"
              onClick={handleCancelModal}
            />
            <CustomButtom
              title="Save"
              loading={isCreatingDepot || isUpdatingDepot}
              customSize="medium"
              customColor="secondary"
              customClassName="col-span-6"
              htmlType="submit"
            />
          </div>
        </Form>
      </>
    </Modal>
  );
};
