import { Form, Spin, Typography, Upload, UploadProps } from "antd";
import { formatBytes, getFileTypeByName } from "libs/helper";
import { useCallback, useMemo, useState } from "react";

import { EodAction } from "pages/eod-management/constants";
import FailUploadIcon from "assets/icons/FailUploadIcon.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import RemoveFileIcon from "assets/icons/RemoveFileIcon.svg";
import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";
import UploadFileEdiIcon from "assets/icons/UploadFileEdiIcon.svg";
import UploadFileIcon from "assets/icons/UploadFile.svg";
import UploadFileTxtIcon from "assets/icons/UploadFileTxtIcon.svg";
import useDidMountEffect from "hooks/use-did-mount-effect";
import { useEdoContext } from "contexts/edo-context";

export interface ICustomerModal {
  callBackResetFile: () => void;
  triggerReset: boolean;
  isCreationEdo: boolean;
  action: EodAction;
}
export const FileType = {
  txt: "txt",
  edi: "edi",
};

export const UploadFileEod = ({
  callBackResetFile,
  triggerReset,
}: ICustomerModal) => {
  const [fileError, setfileError] = useState<{
    isEmpty: boolean;
    isWrongType: boolean;
    isOverSize: boolean;
  }>({
    isEmpty: false,
    isWrongType: false,
    isOverSize: false,
  });
  const [fileData, setFileData] = useState<{
    fileType?: string;
    size: number;
    file: RcFile | undefined;
  }>();
  const [hiddenDrag, setHiddenDrag] = useState(false);
  const { uploadFile, setEdo } = useEdoContext();

  const resetStyleAndValidation = useCallback(() => {
    setHiddenDrag(false);
    setFileData({
      size: 0,
      file: undefined,
    });
    setfileError({
      isEmpty: false,
      isWrongType: false,
      isOverSize: false,
    });
  }, []);

  useDidMountEffect(() => {
    resetStyleAndValidation();
  }, [triggerReset]);

  const isFormError = useMemo(() => {
    return fileError.isOverSize || fileError.isWrongType || fileError.isEmpty;
  }, [fileError]);

  const props: UploadProps = {
    name: "File",
    onChange(info) {
      const { status } = info.file;
      if (status === "removed") {
        setFileData({ size: 0, file: undefined });
        setEdo((prev) => ({
          ...prev,
          uploadFile: { isReading: true, quantity: 0 },
        }));
      }
      setHiddenDrag(status !== "removed"); //on type UploadFileStatus antd
    },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <img src={RemoveFileIcon} alt="removeIcon" />,
      showPreviewIcon: true,
      previewIcon: <img src={RemoveFileIcon} alt="removeIcon" />,
    },
    beforeUpload() {
      return false;
    },
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const customValidator = (rule: RuleObject, value: StoreValue) => {
    if (!value || value.length === 0) {
      setfileError({
        ...fileError,
        isEmpty: true,
      });
      return Promise.reject(" ");
    } else {
      const isWrongType = ["edi", "txt"].includes(
        getFileTypeByName(value[0].name)
      );
      const isOverSize = value[0].size > 2 * 10 ** 7;
      setfileError({
        ...fileError,
        isEmpty: false,
        isWrongType: !isWrongType, //accept txt/edi file
        isOverSize: isOverSize, //20KB
      });
      if (isWrongType && !isOverSize) {
        setFileData({
          fileType: getFileTypeByName(value[0].name),
          size: value[0].size,
          file: value[0],
        });

        return Promise.resolve();
      } else {
        callBackResetFile();
        return Promise.reject(" ");
      }
    }
  };

  return (
    <div className="mt-6">
      <div className="flex w-full">
        {fileData?.file && (
          <img
            className="mr-2"
            src={
              fileData.fileType === FileType.txt
                ? UploadFileTxtIcon
                : UploadFileEdiIcon
            }
            alt="FileIcon"
          />
        )}
        <div className="flex flex-col w-full">
          <Form.Item
            name="File"
            className="mb-0 [&_.ant-form-item-explain-error]:hidden"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                validator: customValidator,
                message: undefined,
              },
            ]}
          >
            <Upload.Dragger
              className={` 
              [&_.ant-upload-drag]:border-dashed [&_.ant-upload-drag]:border-blue-solitude
            [&_.ant-upload-list-item-name]:!p-0 
            [&_.ant-upload-list-item-name]:!font-roboto 
            [&_.ant-upload-list-item-name]:font-sx 
            [&_.ant-upload-list-item-name]:font-normal 
            [&_.ant-upload-list-item-name]:!text-blue-midnight-express 
            [&_.ant-upload-list-item-actions>button]:!opacity-100 
            [&_.ant-upload-icon]:hidden 
            ${
              isFormError &&
              "[&_.ant-upload-list-item-container]:hidden [&_.ant-upload-drag]:border-solid [&_.ant-upload-drag]:border-red-torch"
            }
            ${
              hiddenDrag &&
              !fileError.isOverSize &&
              !fileError.isWrongType &&
              "[&_.ant-upload-drag]:hidden"
            }
          `}
              {...props}
            >
              <div
                className={`h-[178px] cursor-pointer rounded-lg gap-2 flex flex-col items-center justify-center`}
              >
                <>
                  <img
                    src={isFormError ? FailUploadIcon : UploadFileIcon}
                    alt=""
                  />
                  {isFormError && (
                    <div className="text-red-torch font-roboto text-base font-normal leading-[18px]">
                      {fileError.isEmpty ? (
                        <span> This field must not be empty</span>
                      ) : (
                        <>
                          <span>Failed to upload file. </span>
                          {fileError.isOverSize && (
                            <span> The maximum size is 20MB.</span>
                          )}
                          {fileError.isWrongType && (
                            <span> Wrong file format</span>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <span>
                    <span className="text-blue-royal-blue text-sm">
                      Click to upload
                    </span>
                    <span className="text-blue-waikawa-grey text-sm">
                      &nbsp;or drag and drop
                    </span>
                  </span>
                  {!isFormError && (
                    <span className="text-blue-waikawa-grey text-xs">
                      Supported Format .EDI, .TXT (Max &lt;20MB)
                    </span>
                  )}
                </>
              </div>
            </Upload.Dragger>
          </Form.Item>
          {fileData?.file && (
            <div className="flex flex-col gap-1">
              <Typography.Text className="font-roboto text-xs text-blue-waikawa-grey leading-[14px] font-normal">
                {formatBytes(fileData?.size)}
              </Typography.Text>
              <Typography.Text className="font-roboto text-xs text-blue-waikawa-grey leading-[14px] font-normal">
                Total containers:&nbsp;
                {uploadFile.isReading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 12 }} spin />
                    }
                  />
                ) : (
                  <Typography.Text
                    className={`font-roboto text-xs ${
                      uploadFile.quantity.toLocaleString("en-US") === "0"
                        ? `text-red-torch-red`
                        : `text-[#0173E2]`
                    } leading-[14px] font-normal`}
                  >
                    {uploadFile.quantity.toLocaleString("en-US")} eDO(s)
                  </Typography.Text>
                )}
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
