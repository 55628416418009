import { LoginType, SK } from "constants/constants";
import { decodeMessage, objectContainsKeys } from "libs/helper";
import { load, logout } from "libs/storage";

import { LOCAL_STORAGE_KEYS } from "constants/endpoints";

const pingSSOUserInfoKeys = ["email", "sub", "upn"];
export type pingSSOType = {
  email: string;
  sub: string;
  upn: string;
};
export const getPingSSOInfo = () => {
 
  const pingUserInfo = load(LOCAL_STORAGE_KEYS.PING_INFO, true) as pingSSOType;
  if (
    !load(LOCAL_STORAGE_KEYS.PING_INFO, true) ||
    !load(LOCAL_STORAGE_KEYS.LOG_TYPE) ||
    decodeMessage(load(LOCAL_STORAGE_KEYS.LOG_TYPE) as LoginType, SK) !==
      `${LoginType.PingSSO}${pingUserInfo.sub}`
  ) {
    return {
      email: null,
      sub: null,
      upn: null,
    };
  }
  if (pingUserInfo && objectContainsKeys(pingUserInfo, pingSSOUserInfoKeys)) {
    return pingUserInfo;
  } else {
    logout();
  }
};
