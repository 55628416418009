import {
  Collapse,
  CollapseProps,
  Divider,
  Drawer,
  Form,
  Image,
  Typography,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCheckDischargeBulkUpdate,
  useDeleteUpdateBLWithLogistic,
  useDischargeBulkUpdate,
  useGetDischargesDetail,
} from "pages/discharge-list-management/queries";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import { ContainerDetail } from "./ContainerDetail";
import { CustomButtom } from "components/button/CustomButton";
import DrawerCloseIcon from "assets/icons/XCloseIcon.svg";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { FiltersDischarge } from "pages/discharge-list-management";
import { ModalConfirmation } from "components/ModalConfirmation";
import { SelectALlDischargePayload } from "pages/discharge-list-management/queries/types";
import { ViewLoading } from "components/ViewLoading";
import { getValueFromAsyncSelect } from "libs/helper";
import { useCountry } from "pages/eod-management/components/SelectCountry";

type UpdateBLModalProps = {
  isOpen: boolean;
  refetchDischargeList: () => void;
  lisDischargeId: string[];
  isSelectAll: boolean;
  listExceptDischargeId: string[];
  filterDischargeData: FiltersDischarge;
  timestamp: string;
  onClose: () => void;
};

export const DrawerBulkUpdateDischarge = ({
  isOpen,
  lisDischargeId = [],
  refetchDischargeList,
  isSelectAll,
  listExceptDischargeId,
  filterDischargeData,
  timestamp,
  onClose,
}: UpdateBLModalProps) => {
  const [form] = Form.useForm();
  const countryName = useCountry();
  const [isOpenModalForceUpdate, setOpenModalConfirmForceUpdate] =
    useState<boolean>(false);
  const [currentPayload, setCurrentPayload] =
    useState<SelectALlDischargePayload | null>(null);
  const { data: containerSizeType, isLoading: isFetchingContainerSizeType } =
    useGetDischargesDetail({
      payload: {
        listExceptDischargeId,
        isSelectAll,
        listItemBulkUpdateModel: [], // empty
        listDischargeId: lisDischargeId,
        filterRequestModel: { ...filterDischargeData, countryName },
      },
      timestamp,
      enabled:
        isOpen &&
        lisDischargeId.length > 0 &&
        lisDischargeId.every((item) => !!item),
    });

  const { mutate: bulkUpdate, isLoading: isBulkUpdating } =
    useDischargeBulkUpdate();

  const { mutate: checkBulkUpdate, isLoading: isChecking } =
    useCheckDischargeBulkUpdate();

  const { mutate: deleteAll, isLoading: isDeleting } =
    useDeleteUpdateBLWithLogistic();

  const collapseContainerItems: CollapseProps["items"] = useMemo(() => {
    if (!containerSizeType || !containerSizeType.data.length) {
      return [];
    }

    return containerSizeType.data.map((item) => ({
      label: `${item.containerSizeType} Container`,
      key: item.containerSizeType,
      children: <ContainerDetail {...item} />,
    }));
  }, [containerSizeType]);

  const collapseActiveKeys =
    collapseContainerItems.length > 0
      ? collapseContainerItems.map((item) => item.key as number)
      : [];

  const handleSubmitForm = (values: Record<string, string>) => {
    let updateData = [];
    for (const container of containerSizeType?.data!) {
      const isOffHide = values[`isOfHide-${container.containerSizeType}`];
      const depotName = values[`depotName-${container.containerSizeType}`];

      const updatedContainer = {
        containerSizeType: container.containerSizeType,
        listDischargeId: [...container.listDischargeId],
        depotId: getValueFromAsyncSelect(depotName) || null,
        isOffHire: Boolean(isOffHide),
      };
      updateData.push(updatedContainer);
    }

    const payload: SelectALlDischargePayload = {
      filterRequestModel: { ...filterDischargeData, countryName },
      listDischargeId: lisDischargeId,
      isSelectAll: isSelectAll,
      listExceptDischargeId: listExceptDischargeId,
      listItemBulkUpdateModel: [...updateData],
    };
    if (isOpenModalForceUpdate) {
      bulkUpdate(payload, {
        onSuccess: () => {
          setOpenModalConfirmForceUpdate(false);
          onClose();
          refetchDischargeList();
        },
      });
    } else
      checkBulkUpdate(payload, {
        onSuccess: () => {
          bulkUpdate(payload, {
            onSuccess: () => {
              setOpenModalConfirmForceUpdate(false);
              onClose();
              refetchDischargeList();
            },
          });
        },
        onError: (err: any) => {
          if (err.response.data.Message === ERROR_MESSAGE_CODE.MSG_03) {
            setOpenModalConfirmForceUpdate(true);
            setCurrentPayload(payload);
          }
        },
      });
    return;
  };

  const handleContinueProcess = useCallback(() => {
    currentPayload &&
      bulkUpdate(currentPayload, {
        onSuccess: () => {
          setOpenModalConfirmForceUpdate(false);
          onClose();
          refetchDischargeList();
        },
      });
  }, [currentPayload]);

  const handleCloseDrawerEditBLLogistic = () => {
    form.resetFields();
    onClose();
  };

  const handleDelete = () => {
    const payload: SelectALlDischargePayload = {
      filterRequestModel: { ...filterDischargeData, countryName },
      listDischargeId: lisDischargeId,
      isSelectAll: isSelectAll,
      listExceptDischargeId: listExceptDischargeId,
      listItemBulkUpdateModel: [],
    };
    deleteAll(payload, {
      onSuccess: () => {
        refetchDischargeList();
        onClose();
      },
    });
  };
  useEffect(() => {
    if (containerSizeType && collapseContainerItems.length) {
      let initialFormValues = {};
      containerSizeType?.data.forEach((container) => {
        const depotName = container.depotId
          ? {
              label: container.depotName,
              value: container.depotId,
            }
          : null;
        initialFormValues = {
          ...initialFormValues,
          [`numberOfContainer-${container.containerSizeType}`]:
            container.listDischargeId.length,
          [`depotName-${container.containerSizeType}`]: depotName,
          [`isOfHide-${container.containerSizeType}`]: Boolean(
            container.isOffHire
          ),
        };
      });
      form.setFieldsValue(initialFormValues);
    }
  }, [collapseContainerItems.length, containerSizeType, form]);

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      contentWrapperStyle={{ width: 664 }}
      open={isOpen}
      placement="right"
      className="rounded-tl-lg rounded-bl-lg"
      onClose={handleCloseDrawerEditBLLogistic}
      bodyStyle={{ padding: 0 }}
    >
      {isFetchingContainerSizeType ? (
        <ViewLoading />
      ) : (
        <div className="p-6">
          <div className="flex items-center justify-between">
            <Typography.Text className="!block font-antonio font-bold text-[1.625rem] text-blue-sapphire leading-8 -tracking-[0.01625rem]">
              Bulk Update Discharges
            </Typography.Text>
            <Image
              preview={false}
              className="cursor-pointer"
              alt="custom-drawer-close-icon"
              src={DrawerCloseIcon}
              onClick={handleCloseDrawerEditBLLogistic}
            />
          </div>
          <Divider className="mt-[1.875rem] bg-blue-solitude" />
          <Form
            className="pb-16"
            form={form}
            layout="vertical"
            onFinish={handleSubmitForm}
          >
            <Collapse
              items={collapseContainerItems}
              bordered={false}
              expandIconPosition="end"
              defaultActiveKey={collapseActiveKeys}
              className="mt-6 [&_.ant-collapse-item]:border-none [&_.ant-collapse-header]:border-solid [&_.ant-collapse-header]:!border-blue-solitude [&_.ant-collapse-header]:!p-4 [&_.ant-collapse-header]:bg-blue-solitude-light [&_.ant-collapse-content]:!bg-white [&_.ant-collapse-content]:pt-4 [&_.ant-collapse-content-box]:!p-0 [&_.ant-collapse-header-text]:text-base [&_.ant-collapse-header-text]:font-antonio [&_.ant-collapse-header-text]:font-bold [&_.ant-collapse-header-text]:leading-5 [&_.ant-collapse-header-text]:tracking-[-0.04px] [&_.ant-collapse-header-text]:!text-red-base [&_.ant-collapse-header-text]:select-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowUp}
                  />
                ) : (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowDown}
                  />
                )
              }
            />
            <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
              <div className="flex items-center justify-between">
                <div className="flex gap-4">
                  <CustomButtom
                    customClassName="min-w-[9.75rem]"
                    customColor="secondary"
                    customSize="medium"
                    htmlType="submit"
                    title="Save"
                    disabled={isBulkUpdating || isDeleting || isChecking}
                  />
                  <CustomButtom
                    variant="outline"
                    customClassName="min-w-[9.75rem]"
                    customColor="secondary"
                    customSize="medium"
                    title="Delete"
                    disabled={isBulkUpdating || isDeleting || isChecking}
                    onClick={handleDelete}
                  />
                </div>
                <CustomButtom
                  title="Cancel"
                  variant="text"
                  customSize="medium"
                  customColor="secondary"
                  customClassName="w-32"
                  onClick={handleCloseDrawerEditBLLogistic}
                  disabled={isBulkUpdating || isDeleting || isChecking}
                />
              </div>
            </div>
          </Form>
          <ModalConfirmation
            open={isOpenModalForceUpdate}
            title={"Multiple values update"}
            okText="Confirm"
            description="Selected items have different values of Depot name. "
            subDescription="Do you want to override new Depot name to all?"
            onCancel={() => setOpenModalConfirmForceUpdate(false)}
            onOk={handleContinueProcess}
            cancelText={"Cancel"}
            width={"410px"}
          />
        </div>
      )}
    </Drawer>
  );
};
