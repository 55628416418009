import { Checkbox, CheckboxProps, Form, FormItemProps, Typography } from "antd";

import { formStyles } from "./styles";

type FormCheckboxProps = FormItemProps &
  CheckboxProps & {
    checkboxLabel?: string;
    customFormItemClassName?: string;
    customCheckboxClassName?: string;
  };

export const FormCheckbox = ({
  name,
  checkboxLabel,
  valuePropName,
  customFormItemClassName,
  customCheckboxClassName,
  ...restProps
}: FormCheckboxProps) => (
  <Form.Item
    name={name}
    className={customFormItemClassName}
    valuePropName={valuePropName}
  >
    <Checkbox
      className={`${formStyles.checkbox.base} ${customCheckboxClassName}`}
      {...restProps}
    >
      {checkboxLabel && (
        <Typography.Text
          className={`text-blue-sapphire font-roboto text-sm leading-4 font-normal ml-2`}
        >
          {checkboxLabel}
        </Typography.Text>
      )}
    </Checkbox>
  </Form.Item>
);
