import {
  FromType,
  INIT_UPLOAD_DATA,
} from "pages/discharge-list-management/components/ModalUploadDischarge";
import { useExportExcelDischarge, useImportBKAV } from "./queries";
import { useRef, useState } from "react";

import { ButtonVariant } from "components/button/types";
import { CancelTokenSource } from "axios";
import { CustomButtom } from "components/button/CustomButton";
import DownloadIcon from "assets/icons/DownloadIcon.svg";
import FailUploadIcon from "assets/icons/FailUploadIcon.svg";
import { Form } from "antd";
import { ModalConfirmation } from "components/ModalConfirmation";
import ModalProcess from "./components/ModalProcess";
import { Upload } from "components/Upload";
import UploadIcon from "assets/icons/UploadIcon.svg";
import XLSXIcon from "assets/icons/XLSXIcon.svg";
import { useNotification } from "hooks/use-notification";

export default function BKAVManagement() {
  const [form] = Form.useForm();
  const [triggerResetUploadStyle, setTriggerResetUploadStyle] =
    useState<boolean>(false);
  const file = Form.useWatch("File", form);
  const { handleOpenFailNotification } = useNotification();
  const [openModalProcess, setOpenModalProcess] = useState(false);
  const [openModalConfirmWhenCancel, setOpenModalConfirmWhenCancel] =
    useState(false);
  const [showButtonX, setShowButtonX] = useState<boolean>(false);

  const cancelTokenSource = useRef<CancelTokenSource | undefined>();
  const { mutate: exportBKAVTemplate, isLoading: isLoadingExportBKAVTemplate } =
    useExportExcelDischarge();

  const [errorResponse, setErrorResponse] = useState<Blob | null>(null);

  const [fileAfterRead, setFileAfterRead] = useState<{
    fileName: string;
  }>({
    fileName: "",
  });

  const {
    mutate: importBKAV,
    isLoading: isLoadingImportBKAV,
    isSuccess: isSuccessImportBKAV,
  } = useImportBKAV();

  const handleReset = () => {
    form.setFieldsValue({ File: INIT_UPLOAD_DATA.File });
  };

  const handleResetAndClearData = () => {
    form.setFieldsValue({ File: INIT_UPLOAD_DATA.File });
    setTriggerResetUploadStyle(!triggerResetUploadStyle);
  };

  const onFinish = (values: FromType) => {
    var formData = new FormData();
    if (values.File) {
      formData.append("file", values.File[0].originFileObj);
    }
    if (values.File) {
      setFileAfterRead({
        fileName: values.File[0].originFileObj.name,
      });
    }
    setOpenModalProcess(true);
    const payload = {
      file: formData,
      cancelToken: cancelTokenSource,
    };
    setErrorResponse(null);
    setShowButtonX(false);
    importBKAV(payload, {
      onSuccess: (data: any) => {
        if (data.type.split("/")[1] === "xlsx") {
          setErrorResponse(data);
        }
        setShowButtonX(true);
      },
    });
  };

  const exportTemplate = () => {
    exportBKAVTemplate(undefined, {
      onSuccess: (data: any) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `eDo_BKAV_template.xlsx`);
        document.body.appendChild(link);
        link.click();
      },
    });
  };

  return (
    <>
      <Form initialValues={INIT_UPLOAD_DATA} form={form} onFinish={onFinish}>
        <div className="flex flex-col">
          <div className="mb-6 flex justify-between items-center h-10">
            <h1 className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block">
              BKAV Upload
            </h1>
            {file && file.length !== 0 ? (
              <div className="flex ">
                <CustomButtom
                  title="Cancel"
                  variant={ButtonVariant.outline}
                  customSize={"medium"}
                  customColor={"secondary"}
                  customClassName="h-[40px]"
                  onClick={handleResetAndClearData}
                />
                <CustomButtom
                  title={`Upload File`}
                  customSize="large"
                  customColor="secondary"
                  customClassName="ml-2 h-[40px] flex items-center justify-center"
                  customTextClassName="text-sm"
                  htmlType="submit"
                  loading={isLoadingImportBKAV}
                  icon={<img src={UploadIcon} alt="Upload Icon" />}
                />
              </div>
            ) : (
              <CustomButtom
                title="Download Template File"
                customSize="large"
                customColor="secondary"
                customClassName="ml-2 h-[40px] flex items-center justify-center"
                icon={<img src={DownloadIcon} alt="dowloadIcon" />}
                onClick={exportTemplate}
                loading={isLoadingExportBKAVTemplate}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col justify-end items-center x border-solid py-[16px">
          <Upload
            triggerReset={triggerResetUploadStyle}
            callBackResetFile={handleReset}
            fileImages={[{ icon: XLSXIcon, type: "xlsx" }]}
            fileTypes={["xlsx", "xls"]}
            supportFormatText={".xlsx"}
            overSizeValue={2} //20MB
            customClassName={"w-full [&_.ant-upload-btn]:!bg-[#F9F9FB]"}
          />
        </div>
        <ModalConfirmation
          customImage={
            <img
              className="w-[120px] h-[120px]"
              alt="logout-img"
              src={FailUploadIcon}
            />
          }
          open={openModalConfirmWhenCancel}
          okText={"Confirm"}
          onCancel={() => setOpenModalConfirmWhenCancel(false)}
          onOk={() => {
            setOpenModalConfirmWhenCancel(false);
            if (showButtonX) {
              handleOpenFailNotification({
                message: "Cannot cancel the request at the moment!",
              });
              return;
            }
            setOpenModalProcess(false);
            cancelTokenSource.current?.cancel("Process has been cancelled");
          }}
          cancelText={"Cancel"}
          title={"Cancel Importing Files"}
          description={"Your upload is not complete."}
          subDescription={"Do you want to cancel the upload?"}
          customClassName={"flex justify-center"}
        />
        {openModalProcess && (
          <ModalProcess
            showButtonX={showButtonX}
            isLoading={isLoadingImportBKAV}
            isSuccess={isSuccessImportBKAV}
            callBackClose={() => {
              setOpenModalProcess(false);
            }}
            callBackCancel={() => {
              setOpenModalConfirmWhenCancel(true);
            }}
            fileAfterRead={fileAfterRead}
            importError={errorResponse}
          />
        )}
      </Form>
    </>
  );
}
