export const authConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: process.env.REACT_APP_AUTHORITY,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  azureScope: process.env.REACT_APP_SCOPE,
  s3Bucket: process.env.REACT_APP_S3_BUCKET,
  edoVersion: process.env.REACT_APP_VERSION,
};

export const pingSSOConfig = {
  webProfile: process.env.REACT_APP_PING_SSO_WEB_CONNECT_PROFILE,
  webConnect: process.env.REACT_APP_PING_SSO_WEB_CONNECT,
  clientId: process.env.REACT_APP_PING_SSO_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_PING_SSO_REDIRECT_URI,
  responseType: process.env.REACT_APP_PING_SSO_RESPONSE_TYPE,
  scope: process.env.REACT_APP_PING_SSO_SCOPE,
  scopeRead: process.env.REACT_APP_PING_SSO_SCOPE_READ,
  scopeWrite: process.env.REACT_APP_PING_SSO_SCOPE_WRITE,
  reponseMode: process.env.REACT_APP_PING_SSO_REPONSE_MODE,
};

export const gaConfig = {
  gtmCode: process.env.REACT_APP_GA_GTM_CODE,
};
