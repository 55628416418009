import { Checkbox, Col, Row, Typography } from "antd";

import { useMemo } from "react";

type CustomDateRangePicker = {
  customClassName?: string;
  checkBoxList: { value: any; label: string }[];
};

export const CheckBoxGroup = ({
  customClassName = "",
  checkBoxList,
  ...restProps
}: CustomDateRangePicker) => {

  const renderCol = useMemo(() => {
    return checkBoxList.map((item) => (
      <Col key={item.value} span={24}>
        <Checkbox value={item.value}>
          <Typography.Text className={`text-blue-midnight-express font-roboto text-sm leading-5 font-normal`}>
            {item.label}
          </Typography.Text>
        </Checkbox>
      </Col>
    ));
  }, [checkBoxList]);

  return (
    <div className="custom-table-edo-import">
      <Checkbox.Group
        className={`${customClassName}`}
        {...restProps}
        style={{ width: "100%" }}
      >
        <Row gutter={[0, 16]}>{renderCol}</Row>
      </Checkbox.Group>
    </div>
  );
};
