import { FormItemProps, InputProps, Typography } from "antd";

import { AsyncSelect } from "./AsyncSelect";
import { FormInput } from "components/form/Input";
import RedPlusIcon from "assets/icons/RedPlusIcon.svg";
import { Rule } from "antd/es/form";
import { Toggle } from "components/Toggle";
import { useConsigneeList } from "pages/eod-management/queries";

type ConsigneeInputProps = FormItemProps &
  InputProps & {
    isConsigneeTemporary: boolean;
    controlRules?: Rule[];
    controlName?: string;
    blNo?: string | null;
    subName?: string;
    subRules?: Rule[];
    callBackAddNewConsignee?: () => void;
    isShopCustomizeConsignee: boolean;
  };

export const ConsigneeInput = ({
  label,
  blNo,
  name,
  subName,
  rules,
  disabled,
  controlName,
  controlRules,
  subRules,
  isConsigneeTemporary,
  callBackAddNewConsignee,
  isShopCustomizeConsignee,
  ...restProps
}: ConsigneeInputProps) => {
  return (
    <div className="relative">
      {isConsigneeTemporary ? (
        <FormInput
          label={label}
          name={subName}
          rules={subRules}
          disabled={disabled}
          placeholder="Enter Consignee"
          {...restProps}
        />
      ) : (
        <AsyncSelect
          virtual
          showSearch={false}
          isConsigneeSelect
          disabled={disabled}
          popupClassName="custom-select-popup-group"
          name={name}
          label="Consignee"
          filterOption={false}
          params={{ IsTemporary: isConsigneeTemporary, Blno: blNo }}
          placeholder="Enter Consignee"
          optionKey={{ label: "value", value: "key" }}
          fetchOptions={useConsigneeList}
          rules={rules}
          triggerFetchApiManually
          isTemporary={isConsigneeTemporary}
          dropdownRender={(menu) => (
            <>
              {isShopCustomizeConsignee && (
                <div
                  className="cursor-pointer flex items-center justify-start px-4 py-2"
                  onClick={callBackAddNewConsignee}
                >
                  <img src={RedPlusIcon} alt="plusIcon" />
                  <Typography.Text className="text-red-base font-roboto text-sm font-normal not-italic leading-5 ml-2">
                    Customize
                  </Typography.Text>
                </div>
              )}
              {menu}
            </>
          )}
        />
      )}

      <div className="absolute -top-2 right-0">
        <Toggle
          name={controlName}
          label="Temporary"
          disabled={disabled}
          customFormItemClassName="[&_.ant-form-item-row]:!flex-row [&_.ant-form-item-row]:!justify-end [&_.ant-form-item-row]:!flex-row [&_.ant-form-item-label]:!p-0 [&_.ant-form-item-label>label]:pt-[0.125rem] [&_.ant-form-item-label>label]:text-sm [&_.ant-form-item-label>label]:text-blue-sapphire [&_.ant-form-item-label>label]:font-roboto [&_.ant-form-item-label>label]:font-normal [&_.ant-form-item-label>label]:leading-8 [&_.ant-form-item-control]:!w-auto [&_.ant-form-item-control]:!grow-0 [&_.ant-form-item-control]:!mr-0 !mb-0"
          rules={controlRules}
        />
      </div>
    </div>
  );
};
