/* eslint-disable react-hooks/exhaustive-deps */

import {
  Collapse,
  CollapseProps,
  Divider,
  Drawer,
  Form,
  Image,
  Typography,
} from "antd";
import { isArray, isEmpty, isObject } from "lodash";
import {
  BLInfoOfBLDetail,
  DoInfo,
  EditBLWithCounterForm,
  VoyageInfoOfBLDetail,
} from "pages/eod-management/interfaces";
import {
  useCheckUpdateBLWithCounter,
  useGetBillOfLadingList,
  useGetPartyAndConsigneeByBlId,
  useUpdateBLWithCounter,
} from "pages/eod-management/queries";
import { useEffect, useMemo, useState } from "react";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import DrawerCloseIcon from "assets/icons/XCloseIcon.svg";
import SaveChangesImage from "assets/images/SaveChanges.svg";
import { CustomButtom } from "components/button/CustomButton";
import { ModalConfirmation } from "components/ModalConfirmation";
import { ViewLoading } from "components/ViewLoading";
import { authConfig } from "constants/configs";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { useEdoContext } from "contexts/edo-context";
import dayjs from "dayjs";
import { useNotification } from "hooks/use-notification";
import { getValueFromAsyncSelect } from "libs/helper";
import get from "lodash/get";
import { ModalAddNewConsignee } from "pages/customer-management/components/ModalAddNewConsignee";
import { UpdateBLDataWithCounter } from "pages/eod-management/services";
import { useCountry } from "../SelectCountry";
import { useUserRole } from "../SelectRole";
import { BLInformation } from "./BLInformation";
import { DeliveryInformation } from "./DeliveryInformation";
import { VoyageInformation } from "./VoyageInformation";

enum EdoDetailCollapseKeys {
  Voyage = "VOYAGE_INFO",
  BL = "BL_INFO",
  Delivery = "DELIVERY_INFO",
}

const INITIAL_EDO_DETAIL_FORM: EditBLWithCounterForm = {
  isConsigneeTemporary: true, //prevent call api when first visit with temp = false
  consigneeTemporary: "",
  actualVoyageNo: [],
  laraVoyageNo: [],
  arrivalDate: [],
  blNo: null,
  consigneeName: null,
  receivingParty: null,
  securityCode: null,
  releaseNo: null,
  pickupDate: dayjs(),
  isReturnByDays: true,
  returnDate: null,
  returnFullDate: dayjs(),
  updateReason: null,
};

type DrawerEditBLCounterProps = {
  refetchEdoList: () => void;
  selectedBLs: string[];
};

export const DrawerEditBLCounter = ({
  selectedBLs = [],
  refetchEdoList,
}: DrawerEditBLCounterProps) => {
  const [form] = Form.useForm<EditBLWithCounterForm>();
  const role = useUserRole();

  const {
    editBlWithCounterRole,
    setEdo,
    customConsignee,
    isOpenModalAddCustomConsingee,
    isSelectAll,
    listExceptEdoId,
    edoFilter,
    isLoadingBulkUpdate,
  } = useEdoContext();
  const { handleOpenSuccessNotification } = useNotification();

  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);
  const [modalConfirmationConfig, setModalConfirmationConfig] = useState({
    title: "",
    description: "",
    subDescription: "",
  });
  const [saveOnly, setSaveOnly] = useState<boolean>(true);
  const countryName = useCountry();
  const { data, isLoading: isFetchingBLDetail } = useGetBillOfLadingList({
    payload: {
      filterRequestModel: { ...edoFilter, roleEnum: role, countryName },
      isSelectAll,
      listExceptEdoId,
    },
    id: selectedBLs,
    timestamp: editBlWithCounterRole.timestamp,
    enabled:
      editBlWithCounterRole.isOpen &&
      selectedBLs.length > 0 &&
      selectedBLs.every((item) => !!item),
  });

  const { mutateAsync: checkUpdateBLWithCounter, isLoading: isCheckingBLData } =
    useCheckUpdateBLWithCounter();

  const { mutateAsync: updateBLWithCounter, isLoading: isUpdatingBLCounter } =
    useUpdateBLWithCounter();

  useEffect(() => {
    setEdo((prve) => ({ ...prve, isLoadingBulkUpdate: isUpdatingBLCounter }));
  }, [isUpdatingBLCounter]);

  const pickupDate = Form.useWatch("pickupDate", form);
  const isMultipleSelectBLs = editBlWithCounterRole.type === "Multiple";

  const blInfo: BLInfoOfBLDetail = get(data, "data.blInfo", {
    consigneeCode: null,
    consigneeName: null,
    receivingPartyCode: null,
    receivingPartyName: null,
    listBLData: [],
    listBill: [],
    isTemporary: true, //prevent call api when first visit with temp = false
  });

  const ableToSubmit: boolean = get(data, "data.isAllValid", true);
  const isOneOfReleaseOrReleaseUpdate: boolean = get(
    data,
    "data.isAnyReleased",
    false
  );
  const isAllReadyForRelease: boolean = get(
    data,
    "data.isAllReadyForRelease",
    false
  );
  const isAllReleased: boolean = get(data, "data.isAllReleased", false);
  const isAllReleaseUpdated: boolean = get(
    data,
    "data.isAllReleaseUpdated",
    false
  );
  const isOneOfDeleteOrShortship: boolean = get(
    data,
    "data.isAnyDeleteOrShortship",
    false
  );

  const isAllUnreleased: boolean = get(data, "data.isAllUnreleased", false);
  const voyageInfo: VoyageInfoOfBLDetail = get(data, "data.voyageInfo", {
    listActualVoyageNo: [],
    listArrivalDate: [],
    listArrivalDateString: [],
    listLARAVoyageNo: [],
  });

  const doInfo: DoInfo = get(data, "data.doInfo", {
    emptyReturnDepotCode: null,
    emptyReturnDepotName: null,
    isOffHire: null,
    pickupDate: null,
    releaseNo: null,
    returnDate: null,
    returnFullDate: null,
    returnNumber: null,
    securityCode: null,
    updateReason: null,
  });

  const isReturnByDays = Form.useWatch("isReturnByDays", form);
  const isConsigneeTemporary = Form.useWatch("isConsigneeTemporary", form);

  const handleCloseDrawerEditBLCounter = () => {
    form.resetFields();
    setEdo((prev) => ({
      ...prev,
      editBlWithCounterRole: {
        isOpen: false,
        type: null,
        bl: null,
        canEdit: null,
        timestamp: null,
      },
      customConsignee: null,
    }));
  };

  const handleAddnewConsignee = () => {
    setEdo((prev) => ({
      ...prev,
      isOpenModalAddCustomConsingee: true,
    }));
  };

  useEffect(() => {
    if (customConsignee) {
      form.setFieldsValue({
        consigneeName: customConsignee,
      });
      setEdo((prev) => ({
        ...prev,
        isOpenModalAddCustomConsingee: false,
      }));
    }
  }, [customConsignee]);

  const serializeFormValues = () => {
    const formValues = form.getFieldsValue();

    let updateData: UpdateBLDataWithCounter = {
      receivingPartyCode: getValueFromAsyncSelect(formValues.receivingParty),
      pickupDate: formValues.pickupDate
        ? dayjs.utc(formValues.pickupDate.toString()).toISOString()
        : null,
      returnDate:
        formValues.returnDate !== null ? Number(formValues.returnDate) : 0,
      returnFullDate: formValues.returnFullDate
        ? dayjs
            .utc(formValues.returnFullDate.toString())
            .startOf("day")
            .toISOString()
        : null,
      returnNumber: Boolean(formValues.isReturnByDays),
      updateReason: formValues.updateReason || null,
    };

    if (!isMultipleSelectBLs && blNo) {
      updateData = {
        ...updateData,
        billNumber: isArray(blNo)
          ? (blNo[0].value as string)
          : (blNo as string),
        isTemporary: formValues.isConsigneeTemporary,
        consigneeCode: formValues.isConsigneeTemporary
          ? formValues.consigneeTemporary
          : getValueFromAsyncSelect(formValues.consigneeName, true),
      };
    }

    return {
      listEdoId: selectedBLs,
      updateData,
    };
  };

  const listBLNoOptions = useMemo(
    () =>
      blInfo.listBLData.length
        ? blInfo.listBLData.map((item) => ({
            label: item.name,
            value: item.code,
            disabled: true,
          }))
        : [],
    [blInfo.listBLData]
  );

  const getMaxArrivalDate = useMemo(() => {
    const today = dayjs().endOf("day");

    if (!voyageInfo.listArrivalDate.length) {
      return today;
    }

    const maxArrivalDate = Math.max(
      ...voyageInfo.listArrivalDate.map((item) => dayjs(item).valueOf())
    );

    return dayjs(maxArrivalDate).toISOString();
  }, [voyageInfo.listArrivalDate]);
  const [consigneeMappingBl, setConsigneeMappingBl] = useState<
    {
      blId: string | null;
      tempSelectedConsignee: string | null;
    }[]
  >([]);
  const consigneeName = Form.useWatch("consigneeName", form);
  const blNo = Form.useWatch("blNo", form);
  const { mutateAsync: getPartyAndConsigneeByBlId } =
    useGetPartyAndConsigneeByBlId();

  const updateBlMappingConsignee = (keyToUpdate: string, newValue: string) => {
    const updatedData = consigneeMappingBl.map((item) => {
      if (item.blId === keyToUpdate) {
        return { ...item, tempSelectedConsignee: newValue };
      }
      return item;
    });
    const elementToUpdateIndex = updatedData.findIndex(
      (item) => item.blId === keyToUpdate
    );

    if (elementToUpdateIndex === -1) {
      updatedData.push({ blId: keyToUpdate, tempSelectedConsignee: newValue });
    }

    setConsigneeMappingBl(updatedData);
  };

  useEffect(() => {
    if (!isObject(blNo) && !isEmpty(blNo)) {
      getPartyAndConsigneeByBlId(`${blNo}`).then((data) => {
        const elementToUpdateIndex = consigneeMappingBl.findIndex(
          (item) => item.blId === blNo
        );

        const formValues: EditBLWithCounterForm = {
          ...form.getFieldsValue(),
          consigneeName:
            elementToUpdateIndex !== -1 &&
            consigneeMappingBl[elementToUpdateIndex].tempSelectedConsignee
              ? consigneeMappingBl[elementToUpdateIndex].tempSelectedConsignee
              : data.data.selectedConsignee,
          receivingParty: data.data.receivingPartyInfo
            ? {
                label: data.data.receivingPartyInfo.name,
                value: data.data.receivingPartyInfo.code,
              }
            : null,
        };

        if (elementToUpdateIndex === -1) {
          blNo && updateBlMappingConsignee(blNo, consigneeName as string);
        }
        form.setFieldsValue(formValues);
      });
    }
  }, [blNo]);

  useEffect(() => {
    if (!isObject(blNo) && !isEmpty(blNo) && blNo && consigneeName) {
      updateBlMappingConsignee(blNo, consigneeName as string);
    }
  }, [consigneeName]);

  const blListOfCurrentContainerOptions = useMemo(() => {
    return blInfo.listBill.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [blInfo]);

  const collapseItems: CollapseProps["items"] = useMemo(
    () => [
      {
        key: EdoDetailCollapseKeys.Voyage,
        label: "BL Information",
        children: (
          <BLInformation
            blNo={
              !isObject(blNo) && !isEmpty(blNo)
                ? blNo
                : blInfo && !isEmpty(blInfo.listBLData)
                ? blInfo.listBLData[0].code
                : null
            }
            isConsigneeTemporary={isConsigneeTemporary}
            isMultiple={isMultipleSelectBLs}
            blListOptions={blListOfCurrentContainerOptions}
            isHasAnyReleaseStatus={isOneOfReleaseOrReleaseUpdate}
            callBackAddNewConsignee={handleAddnewConsignee}
          />
        ),
      },
      {
        key: EdoDetailCollapseKeys.BL,
        label: "Voyage Information",
        children: <VoyageInformation />,
      },
      {
        key: EdoDetailCollapseKeys.Delivery,
        label: "Delivery Information",
        children: (
          <DeliveryInformation
            arrivalDate={getMaxArrivalDate}
            isReturnByDays={!!isReturnByDays}
            isMultiple={isMultipleSelectBLs}
            pickupDate={pickupDate}
            setFieldValue={form.setFieldValue}
            isRequireUpdateReason={isAllReleased || isAllReleaseUpdated}
            saveOnly={saveOnly}
          />
        ),
      },
    ],
    [
      form.setFieldValue,
      getMaxArrivalDate,
      isConsigneeTemporary,
      isMultipleSelectBLs,
      isReturnByDays,
      pickupDate,
      blNo,
      isFetchingBLDetail,
      saveOnly,
    ]
  );

  const handleCloseModalConfirmation = () => {
    setIsOpenModalConfirmation(false);
    setModalConfirmationConfig({
      title: "",
      description: "",
      subDescription: "",
    });
  };

  const handleUpdateBL = ({
    payload,
  }: {
    payload: {
      listEdoId: string[];
      updateData: UpdateBLDataWithCounter;
    };
  }) => {
    if (isSelectAll) {
      setIsOpenModalConfirmation(false);
    }
    updateBLWithCounter({
      ...payload,
      bucketName: authConfig.s3Bucket || "",
      filterRequestModel: { ...edoFilter, roleEnum: role, countryName },
      isSelectAll,
      listExceptEdoId,
      saveOnly,
      queryTracking: true,
    })
      .then(() => {
        handleOpenSuccessNotification({
          message: "BLs List has been updated!",
        });
        handleCloseModalConfirmation();
        handleCloseDrawerEditBLCounter();
        refetchEdoList();
      })
      .catch(() => {
        handleOpenSuccessNotification({
          type: "error",
          message: "Something went wrong! Please check that again.",
        });
        handleCloseModalConfirmation();
      });
  };

  const handleCheckBLDateBeforeUpdating = () => {
    const payload = { ...serializeFormValues() };

    if (modalConfirmationConfig.title === "Dates are selected") {
      // the next time when user agree to override data for all eDOs
      // continue the "update BLs" processing
      return handleUpdateBL({ payload });
    }
    return checkUpdateBLWithCounter({
      ...payload,
      bucketName: authConfig.s3Bucket || "",
      filterRequestModel: { ...edoFilter, roleEnum: role, countryName },
      isSelectAll,
      listExceptEdoId,
      saveOnly,
    })
      .then(() => {
        // continue the "update BLs" processing
        return handleUpdateBL({ payload });
      })
      .catch((error) => {
        const msgCode = get(error, "response.data.Message");
        if (msgCode === ERROR_MESSAGE_CODE.MSG_05) {
          setModalConfirmationConfig({
            title: "Dates are selected",
            description:
              "There is at least 1 eDO which has different free-time from the others.",
            subDescription: "Do you want to continue?",
          });
        }
      });
  };

  const handleSubmitForm = () => {
    setSaveOnly(false);
    // all fields are valid, show the modal confirmation "Save changes"
    setModalConfirmationConfig({
      title: "Save changes",
      description: "Are you sure you want to save this changes?",
      subDescription: "",
    });

    setIsOpenModalConfirmation(true);
  };

  const preHandleSubmitForm = () => {
    setSaveOnly(false);
  };

  useEffect(() => {
    const formValues: EditBLWithCounterForm = {
      actualVoyageNo: Array.from(new Set(voyageInfo.listActualVoyageNo)),
      blNo: listBLNoOptions,
      consigneeName: blInfo.consigneeCode
        ? { label: blInfo.consigneeName, value: blInfo.consigneeCode }
        : null,
      receivingParty: blInfo.receivingPartyCode
        ? { label: blInfo.receivingPartyName, value: blInfo.receivingPartyCode }
        : null,
      laraVoyageNo: voyageInfo.listLARAVoyageNo,
      arrivalDate: Array.from(new Set(voyageInfo.listArrivalDateString)),
      securityCode: doInfo.securityCode,
      releaseNo: doInfo.releaseNo,
      pickupDate: doInfo.pickupDate ? dayjs(doInfo.pickupDate) : null,
      isReturnByDays:
        doInfo.returnNumber !== null ? Boolean(doInfo.returnNumber) : true, // default by days (SRS edit edo detail)
      returnDate: doInfo.returnDate ? Number(doInfo.returnDate) : null,
      returnFullDate: doInfo.returnFullDate
        ? dayjs(doInfo.returnFullDate)
        : null,
      updateReason: doInfo.updateReason,
      isConsigneeTemporary: blInfo.isTemporary,
      consigneeTemporary: blInfo.consigneeCode,
    };
    form.setFieldsValue(formValues);
  }, [
    blInfo.consigneeCode,
    blInfo.consigneeName,
    blInfo.listBLData,
    blInfo.receivingPartyCode,
    blInfo.receivingPartyName,
    doInfo.pickupDate,
    doInfo.releaseNo,
    doInfo.returnDate,
    doInfo.returnFullDate,
    doInfo.returnNumber,
    doInfo.securityCode,
    doInfo.updateReason,
    form,
    listBLNoOptions,
    voyageInfo.listActualVoyageNo,
    voyageInfo.listArrivalDateString,
    voyageInfo.listLARAVoyageNo,
    blInfo.isTemporary,
  ]);

  const handleOnClickSaveOnly = () => {
    setSaveOnly(true);
    setModalConfirmationConfig({
      title: "Save changes",
      description: "Are you sure you want to save this changes?",
      subDescription: "",
    });

    setIsOpenModalConfirmation(true);
  };
  const isEnabledSaveOnly =
    Number(isAllUnreleased) +
      Number(isAllReadyForRelease) +
      Number(isAllReleased) ===
    0; //only one parameter is true then it not be disabled

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      contentWrapperStyle={{ width: 712 }}
      open={editBlWithCounterRole?.isOpen}
      placement="right"
      className="rounded-tl-lg rounded-bl-lg"
      onClose={handleCloseDrawerEditBLCounter}
      bodyStyle={{ padding: 0 }}
    >
      {isFetchingBLDetail ? (
        <ViewLoading />
      ) : (
        <div className="p-6">
          <div className="flex items-center justify-between">
            <Typography.Text className="!block font-antonio font-bold text-[1.625rem] text-blue-sapphire leading-8 -tracking-[0.01625rem]">
              Edit BL
            </Typography.Text>
            <Image
              preview={false}
              className="cursor-pointer"
              alt="custom-drawer-close-icon"
              src={DrawerCloseIcon}
              onClick={handleCloseDrawerEditBLCounter}
            />
          </div>
          <Divider className="mt-[1.875rem] bg-blue-solitude" />
          <Form
            form={form}
            className={"pb-16"}
            initialValues={INITIAL_EDO_DETAIL_FORM}
            layout="vertical"
            onFinish={handleSubmitForm}
            scrollToFirstError
          >
            <Collapse
              items={collapseItems}
              bordered={false}
              expandIconPosition="end"
              defaultActiveKey={[
                EdoDetailCollapseKeys.BL,
                EdoDetailCollapseKeys.Delivery,
                EdoDetailCollapseKeys.Voyage,
              ]}
              className="[&_.ant-collapse-item]:border-none [&_.ant-collapse-header]:border-b [&_.ant-collapse-header]:border-solid [&_.ant-collapse-header]:!border-blue-solitude [&_.ant-collapse-header]:!p-4 [&_.ant-collapse-header]:bg-blue-solitude-light [&_.ant-collapse-content]:!bg-white [&_.ant-collapse-content]:pt-4 [&_.ant-collapse-content-box]:!p-0 [&_.ant-collapse-header-text]:text-base [&_.ant-collapse-header-text]:font-antonio [&_.ant-collapse-header-text]:font-bold [&_.ant-collapse-header-text]:leading-5 [&_.ant-collapse-header-text]:tracking-[-0.04px] [&_.ant-collapse-header-text]:!text-red-base [&_.ant-collapse-header-text]:select-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowUp}
                  />
                ) : (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowDown}
                  />
                )
              }
            />
            <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
              <div className="flex items-center justify-between">
                <div className="flex gap-4">
                  {isAllReleaseUpdated ? (
                    <CustomButtom
                      customClassName="min-w-[9.75rem]"
                      customColor="secondary"
                      customSize="medium"
                      onClick={preHandleSubmitForm}
                      htmlType="submit"
                      title="Save and Update"
                    />
                  ) : (
                    <>
                      {!isEnabledSaveOnly && (
                        <CustomButtom
                          customClassName="min-w-[9.75rem]"
                          customColor="secondary"
                          customSize="medium"
                          onClick={preHandleSubmitForm}
                          htmlType="submit"
                          title="Save and Change status"
                          disabled={
                            !ableToSubmit ||
                            isEnabledSaveOnly ||
                            isLoadingBulkUpdate
                          }
                        />
                      )}
                      {!isAllReleased && (
                        <CustomButtom
                          customClassName="w-[143px]"
                          customColor="secondary"
                          customSize="medium"
                          variant="outline"
                          title="Save"
                          disabled={
                            isOneOfDeleteOrShortship || isLoadingBulkUpdate
                          }
                          onClick={handleOnClickSaveOnly}
                        />
                      )}
                    </>
                  )}
                </div>
                <CustomButtom
                  title="Cancel"
                  variant="text"
                  customSize="medium"
                  customColor="secondary"
                  customClassName="w-32"
                  onClick={handleCloseDrawerEditBLCounter}
                />
              </div>
            </div>
          </Form>
          <ModalConfirmation
            isDisableButton={isCheckingBLData || isUpdatingBLCounter}
            open={isOpenModalConfirmation}
            onOk={handleCheckBLDateBeforeUpdating}
            onCancel={handleCloseModalConfirmation}
            customImage={
              <Image
                preview={false}
                alt="modal-confirm-change-edo-status-icon"
                src={SaveChangesImage}
              />
            }
            okText="Confirm"
            cancelText="Cancel"
            title={modalConfirmationConfig.title}
            description={modalConfirmationConfig.description}
            subDescription={modalConfirmationConfig.subDescription}
            isLoadingBtn={isUpdatingBLCounter}
          />
          {isOpenModalAddCustomConsingee && (
            <ModalAddNewConsignee
              customConsignee={customConsignee}
              open={isOpenModalAddCustomConsingee}
            />
          )}
        </div>
      )}
    </Drawer>
  );
};
