import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
const PREFIX = "EDO-";

export function save(key: string, value: any, prefix = PREFIX): string | null {
  try {
    window.localStorage.setItem(`${prefix}${key}`, value);
    return value;
  } catch (e) {
    window.console.error("Error in storage.save", e);
    return null;
  }
}

export function saveJSON(
  key: string,
  value: any,
  prefix = PREFIX
): string | null {
  try {
    localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
    return value;
  } catch (e) {
    window.console.error("Error in storage.save", e);
    return null;
  }
}

export function unSave(key: string, prefix = PREFIX): void {
  localStorage.removeItem(`${prefix}${key}`);
}

export function load<T>(key: string, parse = false, prefix = PREFIX): T | null {
  try {
    const value = window.localStorage.getItem(`${prefix}${key}`);
    return value && parse ? JSON.parse(value) : value;
  } catch (e) {
    window.console.error("Error in storage.load", e);
    return null;
  }
}

export function clear(key: string | string[], prefix = PREFIX): void {
  try {
    if (Array.isArray(key)) {
      key.forEach((k) => {
        window.localStorage.removeItem(`${prefix}${k}`);
      });
    } else {
      window.localStorage.removeItem(`${prefix}${key}`);
    }
    return;
  } catch (e) {
    window.console.error("Error in storage.clear", e);
  }
}

export const logout = () => {
  clear([
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
    LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
    LOCAL_STORAGE_KEYS.EXPIRED_TIME,
    LOCAL_STORAGE_KEYS.TOKEN_TYPE,
    LOCAL_STORAGE_KEYS.LOG_OUT,
    LOCAL_STORAGE_KEYS.USER_ROLE,
    LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN,
    LOCAL_STORAGE_KEYS.VERSION_LOGGER,
    LOCAL_STORAGE_KEYS.PING_INFO,
    LOCAL_STORAGE_KEYS.LOG_TYPE,
    LOCAL_STORAGE_KEYS.COUNTRY,
  ]);
  window.location.href = "/";
  localStorage.clear();
  window.localStorage.clear();
  sessionStorage.clear();
  return;
};

export const removeData = () => {
  return clear([]);
};
