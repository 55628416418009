import { Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import { FormCheckbox } from "components/form/Checkbox";
import { IFieldAccessChild } from "../interfaces";
import { userRoleStyles } from "../styles";

const subTableColumns: ColumnsType<IFieldAccessChild> = [
  {
    title: "",
    width: 162,
    render: (_value: any, record: IFieldAccessChild, _index: number) => (
      <Typography.Text className="font-roboto font-normal text-sm text-blue-midnight-express leading-4">
        {record.fieldName}
      </Typography.Text>
    ),
  },
  {
    title: "Import CS",
    width: "16%",
    render: (_value: any, record: IFieldAccessChild, _index: number) => (
      <div className="flex items-center justify-center">
        <FormCheckbox
          disabled
          checked={record.importCS}
          customFormItemClassName="mb-0"
        />
      </div>
    ),
  },
  {
    title: "Logistic",
    width: "16%",
    render: (_value: any, record: IFieldAccessChild, _index: number) => (
      <div className="flex items-center justify-center">
        <FormCheckbox
          disabled
          checked={record.logistic}
          customFormItemClassName="mb-0"
        />
      </div>
    ),
  },
  {
    title: "Counter",
    width: "16%",
    render: (_value: any, record: IFieldAccessChild, _index: number) => (
      <div className="flex items-center justify-center">
        <FormCheckbox
          disabled
          checked={record.counter}
          checkboxLabel=""
          customFormItemClassName="mb-0"
        />
      </div>
    ),
  },
  {
    title: "Depot",
    width: "16%",
    render: (_value: any, record: IFieldAccessChild, _index: number) => (
      <div className="flex items-center justify-center">
        <FormCheckbox
          disabled
          checked={record.depot}
          customFormItemClassName="mb-0"
        />
      </div>
    ),
  },
  {
    title: "IT officer",
    width: "16%",
    render: (_value: any, record: IFieldAccessChild, _index: number) => (
      <div className="flex items-center justify-center">
        <FormCheckbox
          disabled
          checked={record.itOfficer}
          customFormItemClassName="mb-0"
        />
      </div>
    ),
  },
];

type SubTableProps = {
  data: IFieldAccessChild[];
};

export const SubTable = ({ data }: SubTableProps) => (
  <Table
    showHeader={false}
    pagination={false}
    columns={subTableColumns}
    className={userRoleStyles.table.sub}
    dataSource={data}
  />
);
