import "dayjs/plugin/duration";
import "dayjs/plugin/utc";

import {
  Badge,
  ConfigProvider,
  Dropdown,
  Image,
  MenuProps,
  Table,
  Typography,
} from "antd";
import {
  ColumnCustomerSortExport,
  CustomerFormFilters,
  CustomerStatus,
  ICustomer,
  SortTableColumn,
} from "./interfaces";
import type {
  ColumnsType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import {
  DEFAULT_PERPAGE,
  EXCEL_NAME_FILE_FORMAT,
  SYSTEM_DATE_FORMAT,
} from "constants/constants";
import { Form, Pagination } from "antd";
import {
  INITIAL_FILTERS,
  INITIAL_FORM_VALUES,
  customerStatusOptions,
} from "./constants";
import { useCallback, useMemo, useState } from "react";
import { useExportExcelCustomer, useGetCustomerList } from "./queries";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import CrossIcon from "assets/icons/CrossIcon.svg";
import { CustomButtom } from "components/button/CustomButton";
import CustomMutipleTagsDisplay from "components/CustomMutipleTagsDisplay";
import { CustomSelect } from "pages/accounts-management/components/CustomSelect";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { DateRangePicker } from "components/DateRangePicker";
import type { Dayjs } from "dayjs";
import DowloadBtnIcon from "assets/icons/DowloadBtnIcon.svg";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { FormInput } from "components/form/Input";
import { ModalCustomer } from "./components/ModalCustomer";
import { ModalCustomerHistory } from "./components/ModalCustomerHistory";
import PlusIcon from "assets/icons/PlusIcon.svg";
import { RangePickerProps } from "antd/es/date-picker";
import SearchIcon from "assets/icons/SearchIcon.svg";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import ThreeDotExpandIcon from "assets/icons/ThreeDotExpandIcon.svg";
import { UserRole } from "interfaces/common-model";
import dayjs from "dayjs";
import get from "lodash/get";
import { tableStyles } from "components/table/styles";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { useNotification } from "hooks/use-notification";
import { useUserRole } from "pages/eod-management/components/SelectRole";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/duration"));

export default function CustomerManagement() {
  const [form] = Form.useForm<CustomerFormFilters>();
  const { handleOpenSuccessNotification } = useNotification();

  const [PageNum, setPageNum] = useState(1);
  const [currentSortColumn, setCurrentSortColumn] =
    useState<SortTableColumn | null>(null);
  const [searchParams, setSearchParams] = useState(INITIAL_FILTERS);

  const [isOpenModalCustomer, setIsOpenModalCustomer] = useState(false);
  const [tempCustomer, setTempCustomer] = useState<ICustomer | null>(null);
  const [isOpenCustomerHistory, setIsOpenCustomerHistory] =
    useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");

  // fetch customer list
  const {
    data: customerResponse,
    isFetching: isFetchingCustomerList,
    refetch,
  } = useGetCustomerList({
    ...searchParams,
    PageNum,
    keepPreviousData: true,
  });

  // export excel customer
  const { mutateAsync: exportExcelCustomer, isLoading: isExportingExcel } =
    useExportExcelCustomer();

  const role = useUserRole(true);

  const pagination = get(customerResponse, "data", {
    data: [],
    pageNum: 1,
    pageSize: Number(DEFAULT_PERPAGE),
    totalPages: 1,
    totalRecords: 0,
  });
  const tableDataSource = get(pagination, "data", []);
  const keyword = Form.useWatch("Keyword", form);

  const items: MenuProps["items"] = useMemo(() => {
    const actions = [
      UserRole.ITOfficer,
      UserRole.Admin,
      UserRole.Counter,
    ].includes(role)
      ? [
          { key: "edit", label: "Edit" },
          { key: "update_history", label: "History" },
        ]
      : [{ key: "update_history", label: "History" }];
    return actions.map(({ key, label }) => ({
      key,
      label: (
        <Typography.Text className="font-roboto font-normal text-base leading-6 text-blue-sapphire">
          {label}
        </Typography.Text>
      ),
    }));
  }, [role]);

  const tableColumns = useMemo(() => {
    let tableCols: ColumnsType<ICustomer> = [
      {
        title: <TableHeaderTitle title="VAT ID" />,
        dataIndex: "vatId",
        width: 160,
        key: ColumnCustomerSortExport.VatId,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => Number(a.vatId) - Number(b.vatId),
        render: (_value: any, record: ICustomer, _index: number) => (
          <Typography.Text className="!block text-left font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.vatId}
          </Typography.Text>
        ),
      },
      {
        title: (
          <TableHeaderTitle
            customClassName="whitespace-break-spaces"
            title="Customer Name/ Status/ Phone Number/ Date Created"
          />
        ),
        key: ColumnCustomerSortExport.CustomerInfo,
        ellipsis: true,
        width: "20%",
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.customerName?.localeCompare(b.customerName),
        render: (_value: any, record: ICustomer, _index: number) => {
          const formatCreatedDate = dayjs(record.createdDate).format(
            SYSTEM_DATE_FORMAT
          );

          return (
            <div className="flex items-center">
              <CustomTooltip
                isShowCopy={false}
                copytext={
                  record.status
                    ? CustomerStatus.Active
                    : CustomerStatus.Inactive
                }
              >
                <Badge
                  color={record.status ? "#00865D" : "#D9DCE8"}
                  className="[&_span]:!w-2 [&_span]:!h-2 cursor-pointer"
                />
              </CustomTooltip>
              <div className="w-full flex-1 pl-3">
                <CustomTooltip isShowCopy copytext={record?.customerName}>
                  <Typography.Text className="!block font-roboto font-normal text-sm text-blue-midnight-express leading-5 truncate">
                    {record.customerName}
                  </Typography.Text>
                </CustomTooltip>

                <Typography.Text className="!block font-roboto font-normal text-xs text-blue-waikawa-grey leading-[0.875rem] mt-1 truncate">
                  <CustomTooltip
                    isShowCopy={true}
                    copytext={record.phoneNumber}
                  >
                    <span className="text-blue-royal-blue">
                      {record.phoneNumber}&nbsp;
                    </span>
                  </CustomTooltip>
                  | {formatCreatedDate}
                </Typography.Text>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <TableHeaderTitle
            title="eDOs Released"
            customClassName="whitespace-break-spaces"
          />
        ),
        key: ColumnCustomerSortExport.EdosReleaseNumber,
        dataIndex: "edosReleaseNumber",
        width: 120,
        ellipsis: true,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.edosReleaseNumber - b.edosReleaseNumber,
        render: (_value: any, record: ICustomer, _index: number) => (
          <Typography.Text className="!block p-4 text-center font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.edosReleaseNumber}
          </Typography.Text>
        ),
      },
      {
        title: <TableHeaderTitle title="Address" />,
        key: ColumnCustomerSortExport.Address,
        ellipsis: true,
        dataIndex: "address",
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.address?.localeCompare(b.address),
        render: (_value: any, record: ICustomer, _index: number) => (
          <CustomTooltip isShowCopy={true} copytext={record.address}>
            {record.address}
          </CustomTooltip>
        ),
      },
      {
        title: <TableHeaderTitle title="Emails" />,
        width: "30%",
        key: ColumnCustomerSortExport.Email,
        render: (_value: any, record: ICustomer, _index: number) => (
          <div className="flex items-center justify-between h-full overflow-hidden">
            <CustomMutipleTagsDisplay
              tags={record.listEmail}
              numberOfShowedTags={1}
            />
          </div>
        ),
      },
      {
        title: "",
        width: 60,
        render: (_value: any, record: ICustomer, _index: number) => (
          <div className="flex items-center justify-center w-full">
            <Dropdown
              placement="bottomLeft"
              trigger={["click"]}
              menu={{
                items,
                onClick: (menuInfo) => handleEdoAction(menuInfo, record),
                className:
                  "border border-solid border-blue-sapphire !rounded-none !p-0 shadow-none [&>li]:!rounded-none [&>li]:!px-3 [&>li]:!py-2 [&>li]:min-w-[8.25rem] [&>li:hover]:!bg-blue-solitude-light",
              }}
            >
              <Image
                preview={false}
                className="cursor-pointer !w-6 !h-6"
                src={ThreeDotExpandIcon}
                alt="edo-action-icon"
              />
            </Dropdown>
          </div>
        ),
      },
    ];
    return tableCols;
  }, [role]);

  const handleEdoAction = (event: any, record: ICustomer) => {
    switch (event.key) {
      case "edit":
        handleEditRow(record);
        break;
      case "update_history":
        setCustomerId(record.id);
        setIsOpenCustomerHistory(true);
        break;
    }
  };

  const handleCloseModalCustomer = () => {
    setTempCustomer(null);
    setIsOpenModalCustomer(false);
  };

  const handleEditRow = (record: ICustomer) => {
    setTempCustomer(record);
    setIsOpenModalCustomer(true);
  };

  const currentCountry = useCountry();
  const handleFilters = (values: CustomerFormFilters) => {
    let FromDate = "";
    let ToDate = "";

    if (values?.Dates) {
      FromDate = `${dayjs.utc(values.Dates[0]).format()}`;
      ToDate = `${dayjs.utc(values.Dates[1]).format()}`;
    }

    setPageNum(1); // reset page number
    setSearchParams({
      PageSize: Number(DEFAULT_PERPAGE),
      FromDate,
      ToDate,
      Status: values.Status
        ? values.Status === CustomerStatus.Active
        : undefined,
      Keyword: values.Keyword,
      countryName: currentCountry,
    });
  };

  const handleOnChangePagination = (page: number) => {
    setPageNum(page);
  };

  const handleClearKeyword = () => {
    form.setFieldValue("Keyword", "");
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ICustomer> | SorterResult<ICustomer>[]
  ) => {
    if (!Array.isArray(sorter)) {
      setCurrentSortColumn({
        columnKey: sorter.order ? `${sorter.columnKey}` : null,
        isDescending: sorter.order === "descend",
      });
    }
  };

  const handleExportExcel = () => {
    const params = {
      ...searchParams,
      countryName: currentCountry,
      IsDescending: currentSortColumn?.isDescending,
      ColumnSortIndex: currentSortColumn?.columnKey,
    };

    exportExcelCustomer({
      params,
      retry: false,
    })
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Customer_List_${dayjs(Date.now()).format(
            EXCEL_NAME_FILE_FORMAT
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        handleOpenSuccessNotification({
          message: "Oops! Something went wrong!",
        });
      });
  };

  const disableFutureDate: RangePickerProps["disabledDate"] = useCallback(
    (current: Dayjs) => {
      // Can not select days before today and today
      return current && current > dayjs().endOf("day");
    },
    []
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="h-10 mb-6 flex justify-between items-center">
          <h1 className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block">
            Customer Management
          </h1>
          {[UserRole.ITOfficer, UserRole.Admin, UserRole.Counter].includes(
            role
          ) && (
            <div className="flex items-center">
              <CustomButtom
                title="Download"
                customSize="small"
                customClassName="mr-2"
                variant="outline"
                disabled={isExportingExcel}
                customColor="secondary"
                onClick={handleExportExcel}
              />
              <CustomButtom
                title="Add New"
                customSize="small"
                customColor="secondary"
                icon={<img src={PlusIcon} alt="add new account" />}
                customClassName="w-38 flex items-center justify-center"
                onClick={() => {
                  setIsOpenModalCustomer(true);
                }}
              />
            </div>
          )}
          {[UserRole.ImportCS].includes(role) && (
            <div className="flex items-center">
              <CustomButtom
                title="Download"
                customSize="small"
                customColor="secondary"
                icon={<img src={DowloadBtnIcon} alt="dowloadIcon" />}
                customClassName="w-38 flex items-center justify-center"
                onClick={handleExportExcel}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end items-center bg-white px-6 border pt-4 border-solid border-blue-solitude">
        <Form
          initialValues={INITIAL_FORM_VALUES}
          form={form}
          className="flex items-center flex-wrap"
          onFinish={handleFilters}
        >
          <Form.Item name="Dates" className="mb-4">
            <DateRangePicker disabledDate={disableFutureDate} />
          </Form.Item>
          <CustomSelect
            name="Status"
            placeholder="All Status"
            customFormItemClassName="mb-4 mr-3"
            customClassName="!w-[7.9375rem] ml-3"
            options={customerStatusOptions}
            placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
          />
          <FormInput
            name="Keyword"
            customFormItemClassName="flex-1 !mb-4 w-[33.125rem] sL:w-[14rem] mr-3"
            placeholder="Search by VAT ID, Customer Name, Phone Number, Email, Address"
            prefix={
              <Image preview={false} src={SearchIcon} alt="input-search-icon" />
            }
            suffix={
              keyword ? (
                <img
                  className="cursor-pointer"
                  src={CrossIcon}
                  alt="CrossIcon"
                  onClick={handleClearKeyword}
                />
              ) : null
            }
          />
          <Form.Item className="mb-4">
            <CustomButtom
              title="Apply"
              customSize="small"
              customColor="primary"
              htmlType="submit"
            />
          </Form.Item>
        </Form>
      </div>
      <ConfigProvider renderEmpty={EmptyTable}>
        <Table
          rowKey="id"
          columns={tableColumns}
          dataSource={tableDataSource || []}
          pagination={false}
          loading={isFetchingCustomerList}
          className={tableStyles.dashed}
          onChange={handleTableChange}
        />
      </ConfigProvider>
      {tableDataSource.length >= 1 && (
        <div className="p-6 flex justify-between items-center border-x border-b border-solid border-blue-solitude">
          <div>
            <Typography.Text className="font-roboto text-sm not-italic font-normal leading-4 text-blue-waikawa-grey">
              Results:&nbsp;
            </Typography.Text>
            <Typography.Text className="text-blue-midnight-express text-sm not-italic font-roboto font-normal left-5">
              {pagination.totalRecords}
            </Typography.Text>
          </div>
          <div className="custom-pagination-customer-management">
            <Pagination
              defaultCurrent={1}
              pageSize={pagination.pageSize}
              current={pagination.pageNum}
              onChange={handleOnChangePagination}
              total={pagination.totalRecords}
              nextIcon={
                <Image preview={false} alt="pagination-next" src={AngleRight} />
              }
              prevIcon={
                <Image
                  preview={false}
                  alt="pagination-previous"
                  src={AngleLeft}
                />
              }
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
      <ModalCustomer
        customer={tempCustomer}
        refetchOption={refetch}
        open={isOpenModalCustomer}
        onCancel={handleCloseModalCustomer}
      />
      <ModalCustomerHistory
        isOpen={isOpenCustomerHistory}
        onClose={() => setIsOpenCustomerHistory(false)}
        recordId={customerId}
      />
    </>
  );
}
