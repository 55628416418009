import { Col, Row, Spin } from "antd";

import { Dayjs } from "dayjs";
import { EdoDetailFormConfig } from "pages/eod-management/hooks/use-config-status-matrix";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { FormDatePicker } from "components/form/DatePicker";
import { FormInput } from "components/form/Input";
import { Toggle } from "components/Toggle";

type BLInfoProps = {
  config: EdoDetailFormConfig;
  isFieldRequiredForChangeStatus: boolean;
  onChangArivalDate?: (date: Dayjs | null) => void;
  loadingRecalculateDate?: boolean;
};

export const VoyageInfo = ({
  config,
  isFieldRequiredForChangeStatus,
  onChangArivalDate,
  loadingRecalculateDate = false,
}: BLInfoProps) => {
  return (
    <Row className="pb-2" gutter={[16, 0]}>
      <Col span={12}>
        <FormInput
          disabled={config?.actualVoyageNo.disabled}
          name="actualVoyageNo"
          label="Actual Voyage No."
          placeholder="Enter Actual Voyage No."
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.actualVoyageNo.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <FormInput
          disabled={config?.laraVoyageNo.disabled}
          name="laraVoyageNo"
          label="LARA Voyage No."
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.laraVoyageNo.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        {loadingRecalculateDate ? (
          <div className="flex gap-2 absolute right-3">
            <Spin /> <p>...Recalculating</p>
          </div>
        ) : (
          ""
        )}
        <FormDatePicker
          disabled={config?.arrivalDate.disabled}
          showToday={false}
          allowClear={false}
          name="arrivalDate"
          label={`Arrival Date`}
          placeholder="Select arrival date"
          customDatePickerClassName="w-full"
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.arrivalDate.required
                : false,
              message: <ErrorInput />,
            },
          ]}
          callBackOnChange={onChangArivalDate}
        />
      </Col>
      <Col span={12}>
        <FormInput
          disabled={config?.vesselName.disabled}
          name="vesselName"
          label="Vessel Name"
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.vesselName.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <FormInput
          disabled={config?.carrier.disabled}
          name="carrier"
          label="Carrier"
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.carrier.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Toggle
          disabled={config?.isApplyAllBL.disabled}
          name="isApplyAllBL"
          customFormItemClassName="[&_.ant-form-item-row]:!flex-row-reverse [&_.ant-form-item-row]:!justify-end [&_.ant-form-item-row]:!flex-row [&_.ant-form-item-label]:!p-0 [&_.ant-form-item-label>label]:pt-[0.125rem] [&_.ant-form-item-label>label]:text-sm [&_.ant-form-item-label>label]:text-blue-sapphire [&_.ant-form-item-label>label]:font-roboto [&_.ant-form-item-label>label]:font-normal [&_.ant-form-item-label>label]:leading-8 [&_.ant-form-item-control]:!w-auto [&_.ant-form-item-control]:!grow-0 [&_.ant-form-item-control]:!mr-2"
          label="Apply to all BLs under this LARA Voyage No."
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.isApplyAllBL.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};
