import { IFetchApi } from "libs/fetchAPI";
import { ResendEmail } from "./queries";
import { omitEmptyParams } from "libs/helper";

export type GetEmailListParams = {
  Keyword?: string;
  PageNum?: number;
  PageSize?: number;
  countryName: string;
};

export type CustomerPayload = {
  email: string;
  customerName: string;
  vatId: string;
  address: string;
  phoneNumber: string;
};

export type EmailDetail = {
  EmailDetail: string;
  BucketName: string;
  currentTimezone: string;
};

export const emailServices = {
  getEmailList: (params?: GetEmailListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `email/email-search?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getEmailDetail: (params: EmailDetail): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `email/detail-email?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  resendEmail: (payload: ResendEmail): IFetchApi => {
    return {
      url: `email/resend-email`,
      method: "post",
      isAuth: true,
      payload,
    };
  },
};
