import { EdoStatus, UserRole } from "interfaces/common-model";

import ChangeToLogisticIcon from "assets/icons/changeToLogisticIcon.svg";
import { EDO_STATUS } from "constants/constants";
import { EdoDetailModalConfig } from "./interfaces";
import FailUpdateStatusIcon from "assets/icons/failUpdateStatusIcon.svg";
import { MenuProps } from "antd";
import ReStoreIcon from "assets/icons/reStoreIcon.svg";
import ShortShipIcon from "assets/icons/shortShipIcon.svg";
import TrashBinIcon from "assets/icons/TrashBinIcon.svg";
import UnReleaseIcon from "assets/icons/UnReleaseIcon.svg";
import { createOrUdateEdo } from "./constants";

export type DropButtonDrop = {
  key: string;
  label: JSX.Element;
};

export const removeItemsByIndices = (arr: any[], indices: number[]) => {
  // Sort indices in descending order to avoid index shifting when removing elements
  indices.sort((a, b) => b - a);
  for (let i of indices) {
    arr.splice(i, 1);
  }
  return arr;
};

const convertToActionByRole = (
  baseItem: DropButtonDrop[],
  removeArrayKey: number[]
) => {
  const removeArra = removeItemsByIndices(baseItem, removeArrayKey);
  return {
    menuItem: removeArra.slice(1),
    firstItem: removeArra[0],
  };
};

export const renderItemActionBaseOnRole = (role: UserRole) => {
  const baseItemAdmin: DropButtonDrop[] = [
    //TODO: remove first item onList
    {
      key: `${EDO_STATUS.WaitForLog.value}`,
      label: <div>{EDO_STATUS.WaitForLog.actionLabel}</div>,
    },
    {
      key: `${EDO_STATUS.ReadyForRelease.value}`,
      label: <div>{EDO_STATUS.ReadyForRelease.label}</div>,
    },
    {
      key: `${EDO_STATUS.Release.value}`,
      label: <div>{EDO_STATUS.Release.actionLabel}</div>,
    },
    {
      key: `${EDO_STATUS.Unrelease.value}`,
      label: <div>{EDO_STATUS.Unrelease.actionLabel}</div>,
    },
    {
      key: `${EDO_STATUS.Shortship.value}`,
      label: <div>{EDO_STATUS.Shortship.label}</div>,
    },
    {
      key: `${EDO_STATUS.Delete.value}`,
      label: <div>{EDO_STATUS.Delete.actionLabel}</div>,
    },
    {
      key: `${EDO_STATUS.New.value}`,
      label: <div>{EDO_STATUS.New.actionLabel}</div>,
    },
  ];
  const cloneBase = [...baseItemAdmin];
  switch (role) {
    case UserRole.ImportCS:
      return convertToActionByRole(cloneBase, [1, 2, 3]);
    case UserRole.Counter:
      return convertToActionByRole(cloneBase, [0, 1]);
    default: //admin as UserRole.ImportCS
      return convertToActionByRole(cloneBase, [1, 2, 3]);
  }
};

export const itemsMenuCreateUpdateEdo: MenuProps["items"] = [
  //TODO: remove first item onList
  {
    key: `${createOrUdateEdo.Update.value}`,
    label: <div>{createOrUdateEdo.Update.label}</div>,
  },
];

export const renderModalStyle = (statusType: number) => {
  switch (statusType) {
    case EdoStatus.WaitForLog: // wait for log -> send to logistic
      return {
        title: "Send to Logistic eDOs",
        description: "Are you sure you want to Send to logistic selected eDOs?",
        subDescription: "",
        icon: ChangeToLogisticIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.ReadyForRelease: // ReadyForRelease
      return {
        title: "Ready for release eDOs",
        description:
          "Are you sure you want to Ready for release selected eDOs?",
        subDescription: "",
        icon: ChangeToLogisticIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.Release: // Release
      return {
        title: "Release eDOs",
        description: "Are you sure you want to Release selected eDOs?",
        subDescription: "",
        icon: ChangeToLogisticIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.Shortship: // Shortship
      return {
        title: "Shortship eDOs",
        description: "Are you sure you want to Shortship selected eDOs?",
        subDescription: "",
        icon: ShortShipIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.Delete: // Delete
      return {
        title: "Delete eDOs",
        description: "Are you sure you want to Delete selected eDOs?",
        subDescription: "",
        icon: TrashBinIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.Unrelease: // Unrelease
      return {
        title: "Unrelease eDOs",
        description: "Are you sure you want to Unrelease selected eDOs?",
        subDescription: "",
        icon: UnReleaseIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.New: // New -> Restore
      return {
        title: "Restore eDOs",
        description: "Are you sure you want to Restore selected eDOs?",
        subDescription: "",
        icon: ReStoreIcon,
        confirmText: "Confirm",
      };
  }
};

export const renderModalStyleWhenFail = (statusType: number) => {
  switch (statusType) {
    case EdoStatus.WaitForLog:
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Send to logistic.",
        subDescription: "Please check again",
        icon: FailUpdateStatusIcon,
        confirmText: "Back",
      };
    case EdoStatus.ReadyForRelease:
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Ready for release.",
        subDescription: "Please check again",
        icon: FailUpdateStatusIcon,
        confirmText: "Back",
      };
    case EdoStatus.Release: //Release
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Release.",
        subDescription: "Please check again",
        icon: ChangeToLogisticIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.Shortship:
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Shortship. ",
        subDescription: "Please check again",
        icon: FailUpdateStatusIcon,
        confirmText: "Back",
      };
    case EdoStatus.Delete:
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Delete.",
        subDescription: "Please check again",
        icon: FailUpdateStatusIcon,
        confirmText: "Back",
      };
    case EdoStatus.Unrelease: //Unrelease //this action no longer appear on design
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Unrelease.",
        subDescription: "",
        icon: TrashBinIcon,
        confirmText: "Confirm",
      };
    case EdoStatus.New:
      return {
        title: "Cannot Perform Action",
        description: "1 or more eDOs are not valid to Restore.",
        subDescription: "Please check again",
        icon: FailUpdateStatusIcon,
        confirmText: "Back",
      };
  }
};

/**
 * Get edo detail modal config styles
 *
 * @param {EdoStatus} edoStatus
 * @return {EdoDetailModalConfig}
 */
export const getEdoDetailModalConfig = (
  edoStatus: EdoStatus, isSOC?: boolean
): EdoDetailModalConfig => {
  switch (edoStatus) {
    case EdoStatus.New: // Expect: never happen
      return {
        title: "Update Status",
        description: "You have filled all required information.",
        subDescription:
          "Do you wish to save and change to previous eDO status?",
        icon: ReStoreIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };

    case EdoStatus.WaitForLog:
      if(isSOC) {
        return {
          title: "Update status",
          description: "You have filled all the required information. Do you wish to save and change eDO status to Ready for release?",
          subDescription: "",
          icon: ChangeToLogisticIcon,
          cancelText: "Cancel",
          okText: "Confirm",
        };
      } else
      return {
        title: "Update status",
        description: "You have filled all the required information.",
        subDescription: "Do you wish to save and pass action to Logistic team?",
        icon: ChangeToLogisticIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };

    case EdoStatus.Delete:
      return {
        title: "Update status",
        description: "Are you sure you want to Delete this eDO?",
        subDescription: "",
        icon: TrashBinIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };

    case EdoStatus.Shortship:
      return {
        title: "Update status",
        description: "Are you sure you want to Shortship this eDO?",
        subDescription: "",
        icon: ShortShipIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };

    case EdoStatus.ReadyForRelease:
      return {
        title: "Update status",
        description: "You have filled all the required information.",
        subDescription: "Are you sure you want to Ready for release this eDO?",
        icon: ChangeToLogisticIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };

    case EdoStatus.Release:
      return {
        title: "Update Status",
        description: "You have filled all required information.",
        subDescription: "Are you sure you want to Release this eDO?",
        icon: ChangeToLogisticIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };

    case EdoStatus.Unrelease:
      return {
        title: "Update status",
        description: "Are you sure you want to Unrelease this eDO?",
        subDescription: "",
        icon: UnReleaseIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };
    default: // From Shortship/Deleted to previous status
      return {
        title: "Update Status",
        description: "You have filled all required information.",
        subDescription:
          "Do you wish to save and change to previous eDO status?",
        icon: ChangeToLogisticIcon,
        cancelText: "Cancel",
        okText: "Confirm",
      };
  }
};
