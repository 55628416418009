import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";

type RenderIconInfo = {
  isSelected?: boolean;
  isOpen?: boolean;
  isSubMenu?: boolean;
  disabled?: boolean;
};

export const ExpandIcon = ({ isOpen }: RenderIconInfo) => (
  <div>
    {isOpen ? (
      <img alt="sidebar-submenu-arrow-up-icon" src={ArrowUp} />
    ) : (
      <img alt="sidebar-submenu-arrow-down-icon" src={ArrowDown} />
    )}
  </div>
);
