import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const getParamsFromHash = (hash: string): Record<string, string> => {
  const params: Record<string, string> = {};
  const hashParams = hash.slice(1).split('&');

  for (const param of hashParams) {
    const [key, value] = param.split('=');
    if (key && value) {
      params[key] = decodeURIComponent(value);
    }
  }

  return params;
};

export const useGetQueryParam = () => {
  const { search, hash } = useLocation();
  return useMemo(() => getParamsFromHash(hash), [search, hash]);
};
