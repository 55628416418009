import {
  Depot,
  DepotFilters,
  DepotFormDeployed,
  DepotFormStatus,
  DepotType,
} from "../interfaces";
import { GetDepotHistory, GetDepotListOptions, depotQueryKeys } from "./types";
import { GetDepotListParams, depotServices } from "../services";
import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";
import { useMutation, useQuery } from "react-query";

import { CustomerDepotHistoryUpdate } from "pages/eod-management/components/ModalTransactionHistory/interfaces";
import { fetchApi } from "libs/fetchAPI";
import pick from "lodash/pick";
import { useCountry } from "pages/eod-management/components/SelectCountry";

export const useGetDepotList = (options?: GetDepotListOptions) => {
  const formData = pick(options, [
    "Status",
    "CountryName",
    "IsDeployed",
    "Type",
    "KeyWord",
    "PageNum",
    "PageSize",
  ]);
  const currentCountry = useCountry();
  const serializeParams: GetDepotListParams = {
    Status: formData.Status
      ? formData.Status === DepotFormStatus.Active
      : undefined,
    IsDeployed: formData.IsDeployed
      ? formData.IsDeployed === DepotFormDeployed.Yes
      : undefined,
    Type: formData.Type ? (formData.Type as DepotType) : undefined,
    KeyWord: formData.KeyWord,
    PageNum: formData.PageNum,
    PageSize: formData.PageSize,
    countryName: currentCountry,
  };

  return useQuery({
    queryKey: [depotQueryKeys.GET_DEPOT_LIST, serializeParams],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<Depot>>>(
        depotServices.getDepotList(serializeParams)
      ),
    ...options,
  });
};

export const useGetDepotFilters = () =>
  useQuery({
    queryKey: [depotQueryKeys.GET_DEPOT_FILTERS],
    queryFn: () =>
      fetchApi<IBaseResponse<DepotFilters>>(depotServices.getDepotFilters()),
  });

export const useCreateDepot = () =>
  useMutation((payload: Depot) =>
    fetchApi(depotServices.createNewDepot(payload))
  );

export const useUpdateDepot = () =>
  useMutation((payload: Depot) => fetchApi(depotServices.updateDepot(payload)));

export const useDepotHistory = (options: GetDepotHistory) =>
  useQuery({
    queryKey: [depotQueryKeys.GET_DEPOT_HISTORY, options],
    queryFn: () =>
      fetchApi<
        IBaseResponse<
          IBasePaginationResponse<
            {
              actualVoyageNo: string;
              containerNo: string;
            } & CustomerDepotHistoryUpdate
          >
        >
      >(depotServices.geDepotHistory(options)),
    enabled: options.isTrigger,
    ...options,
  });
