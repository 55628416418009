import {
  Checkbox,
  Collapse,
  Drawer,
  Form,
  Image,
  Skeleton,
  Typography,
} from "antd";
import { forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { map, range } from "lodash";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import { CheckBoxGroup } from "components/CheckboxGroup";
import type { CollapseProps } from "antd";
import { CustomButtom } from "components/button/CustomButton";
import { DateRangePicker } from "components/DateRangePicker";
import type { Dayjs } from "dayjs";
import { FiltersDischarge } from "..";
import XCloseIcon from "assets/icons/XCloseIcon.svg";
import { allEmptyValues } from "libs/helper";
import { convertDayjsDataToUtc } from "pages/eod-management/helpers";
import { useGetFilterData } from "../queries";
import { useCountry } from "pages/eod-management/components/SelectCountry";

export type DischargeFilters = {
  fromCreatedDate: string;
  toCreatedDate: string;
  fromModifiedDate: string;
  toModifiedDate: string;
  listContainerSize: string[];
  isDepotNameEmpty: boolean;
};

type DrawerFilterForm = {
  createdDate: Dayjs[];
  modifiedDate: Dayjs[];
  listContainerSize: string[];
  isDepotNameEmpty: boolean;
};

const INITIAL_FORM_VALUES: DischargeFilters = {
  fromCreatedDate: "",
  toCreatedDate: "",
  fromModifiedDate: "",
  toModifiedDate: "",
  listContainerSize: [],
  isDepotNameEmpty: true,
};

interface IDischargeFilter {
  open: boolean; //default antd
  callBackApply: (value: FiltersDischarge, isEmptyAll: boolean) => void;
  setOpen: (isOpen: boolean) => void;
}

const DrawerFilterDischargeList = forwardRef(
  ({ open, setOpen, callBackApply }: IDischargeFilter, ref) => {
    const [form] = Form.useForm<DrawerFilterForm>();
    const countryName = useCountry();

    const {
      data: dischargeFilterData,
      isFetching: isLoadingDischargeFilterData,
    } = useGetFilterData(); //temporary get container size from edo

    const filterEdoSizeApiConvert = useMemo(() => {
      return map(dischargeFilterData?.data.containerSizeTypes, (value) => ({
        value,
        label: value,
      }));
    }, [isLoadingDischargeFilterData]);

    const onClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      resetForward() {
        form.resetFields();
      },
    }));

    const items: CollapseProps["items"] = useMemo(() => {
      return [
        {
          key: "1",
          label: "Container size/type",
          children: (
            <Form.Item name="listContainerSize" className="mb-0">
              {isLoadingDischargeFilterData ? (
                <div className="flex flex-col gap-5">
                  {Array.from({ length: 4 }, (_, index) => index).map(
                    (
                      index //just loading phase of 4 items
                    ) => (
                      <Checkbox key={index} disabled>
                        <Skeleton.Input size={"small"} active />
                      </Checkbox>
                    )
                  )}
                </div>
              ) : (
                <CheckBoxGroup checkBoxList={filterEdoSizeApiConvert} />
              )}
            </Form.Item>
          ),
        },
        {
          key: "2",
          label: "Depot Name",
          children: (
            <div className="custom-table-edo-import">
              <Form.Item
                name="isDepotNameEmpty"
                valuePropName="checked"
                className="mb-0"
              >
                <Checkbox>
                  <Typography.Text
                    className={`text-blue-midnight-express font-roboto text-sm leading-5 font-normal`}
                  >
                    Blank
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            </div>
          ),
        },
        {
          key: "3",
          label: "Updated Date",
          children: (
            <Form.Item name="createdDate" className="mb-0">
              <DateRangePicker customClassName="!w-full" />
            </Form.Item>
          ),
        },
        {
          key: "4",
          label: "Created Date",
          children: (
            <Form.Item name="modifiedDate" className="mb-0">
              <DateRangePicker customClassName="!w-full" />
            </Form.Item>
          ),
        },
      ];
    }, [filterEdoSizeApiConvert, isLoadingDischargeFilterData]);

    const onReset = useCallback(() => {
      form.resetFields();
      form.setFieldsValue({
        isDepotNameEmpty: false,
      });
    }, []);

    const handleFilters = (values: DrawerFilterForm) => {
      const convertToPayloadType = {
        fromCreatedDate: convertDayjsDataToUtc(values.createdDate, 0),
        toCreatedDate: convertDayjsDataToUtc(values.createdDate, 1),

        fromModifiedDate: convertDayjsDataToUtc(values.modifiedDate, 0),
        toModifiedDate: convertDayjsDataToUtc(values.modifiedDate, 1),

        listContainerSize: values.listContainerSize,
        isDepotNameEmpty: values.isDepotNameEmpty,

        countryName,
      };
      callBackApply(convertToPayloadType, allEmptyValues(values));
    };

    return (
      <>
        <Drawer
          rootClassName="custom-drawer-edo-import"
          title="Filter"
          placement="left"
          width={336}
          onClose={onClose}
          open={open}
          maskClosable={false}
          closeIcon={<img src={XCloseIcon} alt="XIcon" />}
        >
          <>
            <Form
              initialValues={INITIAL_FORM_VALUES}
              form={form}
              onFinish={handleFilters}
              className=""
            >
              <Collapse
                defaultActiveKey={map(range(1, items.length + 1), String)}
                expandIconPosition={"end"}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <Image
                      preview={false}
                      alt="collapse-edo-filter"
                      src={ArrowUp}
                    />
                  ) : (
                    <Image
                      preview={false}
                      alt="collapse-edo-filter"
                      src={ArrowDown}
                    />
                  )
                }
                items={items}
              />
              <div className="custom-footer sticky bottom-0 flex justify-between px-6 py-4">
                <CustomButtom
                  title="Reset All"
                  variant="outline"
                  customSize="medium"
                  customColor="secondary"
                  customClassName="w-[95px]"
                  onClick={onReset}
                />
                <CustomButtom
                  title={"Apply"}
                  loading={isLoadingDischargeFilterData}
                  customSize="medium"
                  customColor="secondary"
                  customClassName="w-[95px] flex justify-center"
                  htmlType="submit"
                />
              </div>
            </Form>
          </>
        </Drawer>
      </>
    );
  }
);

export default DrawerFilterDischargeList;
