import { Tabs, TabsProps } from "antd";

import { tabsStyles } from "./styles";

type CustomTabsProps = TabsProps & {
  customClassName?: string;
};

export const CustomTabs = ({
  customClassName,
  ...restProps
}: CustomTabsProps) => {
  return (
    <Tabs {...restProps} className={`${tabsStyles.base} ${customClassName}`} />
  );
};
