import { Tooltip, TooltipProps, Typography } from "antd";

import useDidMountEffect from "hooks/use-did-mount-effect";
import { useState } from "react";

type ITooltip = TooltipProps & {
  copytext: string;
  children: React.ReactNode | string;
};

export const TooltipsCopy = ({
  copytext,
  children,
  ...props
}: ITooltip) => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const onOpenChange = (open: boolean) => {
    setIsOpenTooltip(open);
    navigator.clipboard.writeText(copytext);
  };

  useDidMountEffect(() => {
    const timer = setTimeout(() => {
      setIsOpenTooltip(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [isOpenTooltip]);

  return (
    <Tooltip
      open={isOpenTooltip}
      {...props}
      onOpenChange={onOpenChange}
      className="cursor-default"
      rootClassName="[&_.ant-tooltip-inner]:px-3 [&_.ant-tooltip-inner]:py-2"
      title={
        <div className="flex items-center">
          <Typography.Text className="font-roboto font-normal text-sm !text-white leading-5">
           Copied
          </Typography.Text>
        </div>
      }
      arrow={false}
    >
      {children}
    </Tooltip>
  );
};
