import { Form, FormItemProps, Input, InputProps, Skeleton } from "antd";

import { formStyles } from "./styles";

type FormInputProps = Omit<InputProps, "size"> &
  FormItemProps & {
    customFormItemClassName?: string;
    isLoading?: boolean;
  };

export const FormInput = ({
  label,
  rules,
  placeholder,
  customFormItemClassName = "!mb-4",
  isLoading = false,
  ...restProps
}: FormInputProps) => (
  <Form.Item
    rules={rules}
    label={label}
    className={`${formStyles.formItem.base} ${customFormItemClassName}`}
    {...restProps}
  >
    {isLoading ? (
      <Skeleton.Input
        className="!w-full [&_.ant-skeleton-input]:!w-full"
        size="default"
        active
      />
    ) : (
      <Input
        placeholder={placeholder}
        className={formStyles.input.base}
        {...restProps}
      />
    )}
  </Form.Item>
);
