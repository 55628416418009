import { Input, Space, Tag, theme } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { EMAIL_REGEX } from "constants/constants";
import GrayPlusIcon from "assets/icons/GrayPlusIcon.svg";
import type { InputRef } from "antd";
import XCloseTagICon from "assets/icons/XCloseTagICon.svg";
import { trim } from "lodash";

type FullTagEmail = {
  callBackData: (tagsValue: string[], errorTagsValue: string[]) => void;
  initData: string[];
};

const CustomDisplayFullTagEmail = ({
  callBackData,
  initData,
}: FullTagEmail) => {
  const { token } = theme.useToken();
  const [tags, setTags] = useState<string[]>(initData);
  const [errorTags, setErrorTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    if (errorTags.find((errorTag) => errorTag === removedTag)) {
      setErrorTags(errorTags.filter((tag) => tag !== removedTag));
    }
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !EMAIL_REGEX.test(`${inputValue}`)) {
      setErrorTags([...errorTags, trim(inputValue)]);
    }
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, trim(inputValue)]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const tagInputStyle: React.CSSProperties = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: "top",
  };

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  useEffect(() => {
    callBackData(tags, errorTags);
  }, [tags, errorTags]);
  return (
    <Space
      size={[0, 8]}
      wrap
      className="[&_.ant-tag-error]:!bg-red-pale-pink [&_.ant-tag-error]:!border-red-torch-red"
    >
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              size="small"
              style={tagInputStyle}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }
        return (
          <Tag
            key={tag}
            closable
            style={{ userSelect: "none" }}
            onClose={() => handleClose(tag)}
            color={!EMAIL_REGEX.test(`${tag}`) ? "error" : ""}
            closeIcon={<img src={XCloseTagICon} alt="closeIcon" />}
            className="px-2 py-1 flex justify-center items-center text-blue-sapphire font-roboto text-xs leading-3 font-normal not-italic"
          >
            {tag}
          </Tag>
        );
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          className="px-2 py-1 flex justify-center items-center text-blue-sapphire font-roboto text-xs leading-3 font-normal not-italic !w-[150px]"
        />
      ) : (
        <Tag
          className="px-2 py-1 flex justify-center items-center text-blue-waikawa-grey font-roboto text-xs leading-3 font-normal not-italic"
          style={tagPlusStyle}
          icon={
            <img className="mr-[3px]" src={GrayPlusIcon} alt="addNewIcon" />
          }
          onClick={showInput}
        >
          Add Email
        </Tag>
      )}
    </Space>
  );
};

export default CustomDisplayFullTagEmail;
