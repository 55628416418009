import { FormItemProps, SelectProps, Spin, Typography } from "antd";
import { useMemo, useState } from "react";

import { ConsigneeFlags } from "constants/constants";
import { FormSelect } from "components/form/Select";
import OptionNotFound from "assets/images/OptionNotFound.svg";
import { get } from "lodash";
import keys from "lodash/keys";
import { useDebounceValue } from "hooks/use-debounce-value";
import { useEdoContext } from "contexts/edo-context";

type AsyncOptionKey = {
  label: string;
  value: string;
};

type AsyncSelectProps = FormItemProps &
  SelectProps & {
    optionKey: AsyncOptionKey;
    isConsigneeSelect?: boolean;
    allowCreateNewOne?: boolean;
    customSelectClassName?: string;
    customFormItemClassName?: string;
    params?: Record<string, any>;
    fetchOptions: (options: any) => any;
    triggerFetchApiManually?: boolean;
    isTemporary?: boolean;
    hideAddNewEmpty?: boolean;
    isLogHistory?: boolean;
    isCustomApiResponse?: "depotCode" | "moreDefine";
  };

export const AsyncSelect = ({
  fetchOptions,
  optionKey,
  params,
  isConsigneeSelect = false,
  allowCreateNewOne = false,
  triggerFetchApiManually = false,
  isTemporary = true,
  hideAddNewEmpty = false,
  isLogHistory = false,
  isCustomApiResponse,
  ...restProps
}: AsyncSelectProps) => {
  const { setEdo } = useEdoContext();
  const [searchValue, setSearchValue] = useState("");

  const debounceSearchText = useDebounceValue(searchValue);
  const { data, isFetching } = fetchOptions({
    ...params,
    Keyword: debounceSearchText,
    enabled: triggerFetchApiManually
      ? isTemporary
        ? !isTemporary && triggerFetchApiManually
        : triggerFetchApiManually
      : !!debounceSearchText,
  });

  const results: any[] = get(data, "data.data", []);
  const options = useMemo(() => {
    if (!results) {
      return [];
    }

    if (isCustomApiResponse) {
      switch (isCustomApiResponse) {
        case "depotCode": {
          return results.map((item) => {
            const label = item["depotCode"];
            return {
              label,
              value: label,
            };
          });
        }
      }
    }

    if (isConsigneeSelect) {
      let consigneeOptions: SelectProps["options"] = [];
      const consigneeData = results[0]; // the consignee result always has maximum 1 element

      const filterKeys = keys(consigneeData).filter(
        (key) => !!consigneeData[key].value
      );

      filterKeys.forEach((key, index) => {
        let groupOption;
        switch (key) {
          case "consignee":
            groupOption = {
              label: "Consignee",
              options: [
                {
                  label: consigneeData[key].value,
                  value: `${consigneeData[key].value}${ConsigneeFlags.CONSIGNEE}`,
                },
              ],
            };
            break;
          case "notify1s":
            groupOption = {
              label: "Notify party 1",
              options: [
                {
                  label: consigneeData[key].value,
                  value: `${consigneeData[key].value}${ConsigneeFlags.NOTIFY1S}`,
                },
              ],
            };
            break;
          case "notify2s":
            groupOption = {
              label: "Notify party 2",
              options: [
                {
                  label: consigneeData[key].value,
                  value: `${consigneeData[key].value}${ConsigneeFlags.NOTIFY2S}`,
                },
              ],
            };
            break;
          default:
            groupOption = {
              label: "",
              options: [],
            };
            break;
        }

        consigneeOptions!.push(groupOption);
      });

      return consigneeOptions;
    }

    return results.map((item) => {
      const defaultLabel = item[optionKey.label];
      const label = allowCreateNewOne
        ? `${item["vatId"]} - ${defaultLabel}`
        : defaultLabel;

      return {
        label,
        value: isLogHistory
          ? JSON.stringify({
              label: item[optionKey.label],
              value: item[optionKey.value],
            })
          : item[optionKey.value],
      };
    });
  }, [
    allowCreateNewOne,
    isConsigneeSelect,
    isLogHistory,
    optionKey.label,
    optionKey.value,
    results,
  ]);

  const handleSearchReceivingParty = (value: string) => {
    setSearchValue(value);
  };

  const handleOpenModalCreateCustomer = () => {
    setEdo((prev) => ({
      ...prev,
      isOpenModalCreateCustomer: true,
    }));
  };

  return (
    <FormSelect
      searchValue={searchValue}
      onSearch={handleSearchReceivingParty}
      options={options}
      filterOption={false}
      notFoundContent={
        isFetching ? (
          <div className="h-[200px] flex flex-col items-center justify-center py-6">
            <Spin />
          </div>
        ) : !!debounceSearchText ? (
          <div className="h-[12.5rem] flex flex-col items-center justify-center py-6">
            <img src={OptionNotFound} alt="select-option-not-found" />
            <Typography.Text className="block mt-1 font-roboto font-normal leading-[0.875rem] text-blue-waikawa-grey">
              No data matches
            </Typography.Text>
            {hideAddNewEmpty && (
              <Typography.Text className="block mt-1 font-roboto font-normal leading-[0.875rem] text-blue-waikawa-grey">
                Do you want to&nbsp;
                <Typography.Text
                  onClick={handleOpenModalCreateCustomer}
                  className="text-blue-royal-blue cursor-pointer hover:underline"
                >
                  create a new one
                </Typography.Text>
                ?
              </Typography.Text>
            )}
          </div>
        ) : null
      }
      {...restProps}
    />
  );
};
