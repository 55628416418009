import { load, save } from "libs/storage";
import { useCallback, useEffect, useState } from "react";

import { CustomSelect } from "pages/accounts-management/components/CustomSelect";
import { DefaultOptionType } from "antd/es/select";
import { Form } from "antd";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { queryClient } from "App";
import { useNavigate } from "react-router-dom";

export const useCountry = () => {
  const [country, setCountry] = useState<string>(
    load(LOCAL_STORAGE_KEYS.COUNTRY) || ""
  );

  const handleStorageChange = useCallback(() => {
    const tempStoredUserRole = load<string>(LOCAL_STORAGE_KEYS.COUNTRY);

    if (tempStoredUserRole) {
      setCountry(tempStoredUserRole);
    }
  }, [queryClient]);

  useEffect(() => {
    handleStorageChange();
  }, []);
  useEffect(() => {
    window.addEventListener("storageCountry", handleStorageChange);
    return () => {
      window.removeEventListener("storageCountry", handleStorageChange);
    };
  }, [handleStorageChange]);

  return country;
};

type CountrySelection = {
  listCountryOps: DefaultOptionType[];
};

export const SelectCountry = ({ listCountryOps }: CountrySelection) => {
  const [form] = Form.useForm();
  const country = Form.useWatch("country", form);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname !== "/edo") {
      navigate("/edo");
    }
    save(
      LOCAL_STORAGE_KEYS.COUNTRY,
      country ? country : listCountryOps[0].value
    );
    window.dispatchEvent(new Event("storageCountry"));
  }, [country]);

  return (
    <Form
      initialValues={{
        country: listCountryOps[0].value,
      }}
      form={form}
      layout="horizontal"
    >
      <div className="[&_.ant-select-selector]:!w-[110px] [&_.custom-account-select.ant-select-single>.ant-select-selector]:!border-0 [&_.ant-select-arrow]:mr-10">
        <CustomSelect
          name="country"
          placeholder="Role"
          options={listCountryOps}
          className="mb-0 mr-10 [&_.ant-form-item-label>label]:!h-10 [&_.ant-form-item-label>label]:text-blue-sapphire"
          placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
          popupClassName="!w-[6.875rem]"
        />
      </div>
    </Form>
  );
};
