import {
  ConfigProvider,
  Form,
  Image,
  Pagination,
  Table,
  Typography,
} from "antd";
import { Depot, DepotFormValues } from "./interfaces";
import { useGetDepotFilters, useGetDepotList } from "./queries";
import { useMemo, useState } from "react";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import CrossIcon from "assets/icons/CrossIcon.svg";
import { CustomButtom } from "components/button/CustomButton";
import { CustomSelect } from "pages/accounts-management/components/CustomSelect";
import { DEFAULT_PERPAGE } from "constants/constants";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { FormInput } from "components/form/Input";
import { ModalDepot } from "./components/ModalDepot";
import { ModalDepotHistory } from "./components/ModalDepotHistory";
import PlusIcon from "assets/icons/PlusIcon.svg";
import SearchIcon from "assets/icons/SearchIcon.svg";
import get from "lodash/get";
import { tableStyles } from "components/table/styles";
import { useTableColumns } from "./useTableColumns";

const INITIAL_FILTERS = {
  Status: "",
  CountryName: "",
  IsDeployed: "",
  Type: "",
  KeyWord: "",
  PageNum: 1,
  PageSize: Number(DEFAULT_PERPAGE),
};

export const DepotManagement = () => {
  const [form] = Form.useForm<DepotFormValues>();
  const [PageNum, setPageNum] = useState(1);
  const [isOpenModalDepot, setIsOpenModalDepot] = useState(false);
  const [isOpenDepotHistory, setIsOpenDepotHistory] = useState(false);
  const [depotSelectedId, setDepotSelectedId] = useState<string>("");
  const [temporaryDepot, setTemporaryDepot] = useState<Depot | null>(null);
  const [searchParams, setSearchParams] = useState(INITIAL_FILTERS);

  const {
    data: depotListResponse,
    isFetching: isFetchingDepotList,
    refetch,
  } = useGetDepotList({
    ...searchParams,
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE),
    keepPreviousData: true,
  });
  const { data: depotFilters, isLoading: isFetchingDepotFilters } =
    useGetDepotFilters();

  const pagination = get(depotListResponse, "data");
  const tableDataSource = get(pagination, "data", []);

  const statusOptions = useMemo(() => {
    const statusList = get(depotFilters, "data.status", []);
    const defaultOption = { label: "All Status", value: "" };
    if (!statusList.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...statusList.map((status) => {
        const statusOption = status ? "Active" : "Inactive";
        return { label: statusOption, value: statusOption };
      }),
    ];
  }, [depotFilters]);

  const countryOptions = useMemo(() => {
    const countries = get(depotFilters, "data.countries", []);
    const defaultOption = { label: "All Countries", value: "" };
    if (!countries.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...countries.map((country) => ({ label: country, value: country })),
    ];
  }, [depotFilters]);

  const isDeployedOptions = useMemo(() => {
    const isDeployedList = get(depotFilters, "data.isDeployeds", []);
    const defaultOption = { label: "All Deployed", value: "" };
    if (!isDeployedList.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...isDeployedList.map((item) => {
        const statusOption = item ? "Yes" : "No";
        return { label: statusOption, value: statusOption };
      }),
    ];
  }, [depotFilters]);

  const typeOptions = useMemo(() => {
    const types = get(depotFilters, "data.types", []);
    const defaultOption = { label: "All Types", value: "" };
    if (!types.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...types.map((type) => ({ label: type, value: type })),
    ];
  }, [depotFilters]);

  const keyword = Form.useWatch("KeyWord", form);

  const handleEdoAction = (event: any, record: Depot) => {
    switch (event.key) {
      case "edit":
        setTemporaryDepot(record);
        setIsOpenModalDepot(true);
        break;
      case "update_history":
        setDepotSelectedId(record.depotId);
        setIsOpenDepotHistory(true);
        break;
    }
  };

  const depotTableColumns = useTableColumns({ handleEdoAction });

  const handleCloseModalDepot = () => {
    setTemporaryDepot(null);
    setIsOpenModalDepot(false);
    setIsOpenModalDepot(false);
  };

  const handleApplyFilters = (values: DepotFormValues) => {
    // https://stackoverflow.com/questions/71077346/how-to-let-query-are-performed-at-the-component-onmount-and-triggered-by-user-ev/71093384#71093384
    // manually refetch and apply all filters (including search keyword)
    setPageNum(1); // reset page number
    setSearchParams(values);
  };

  const handleClearKeyword = () => {
    form.setFieldValue("KeyWord", "");
  };

  return (
    <>
      <div className="flex items-center justify-between mt-1.5 mb-7.5">
        <Typography.Text className="font-antonio font-bold text-xl text-blue-sapphire leading-6 tracking-[-0.1px] !block">
          Terminal/Depot List
        </Typography.Text>
        <CustomButtom
          title="Add New"
          customSize="small"
          customColor="secondary"
          icon={<Image preview={false} src={PlusIcon} alt="add new depot" />}
          customClassName="w-38 flex items-center justify-center"
          onClick={() => {
            setIsOpenModalDepot(true);
          }}
        />
      </div>
      <div className="bg-white">
        <Form form={form} onFinish={handleApplyFilters}>
          <div className="flex items-center flex-wrap pt-4 pb-0 px-6 border border-solid border-x-blue-solitude border-t-blue-solitude border-b-0">
            <CustomSelect
              name="Status"
              initialValue=""
              placeholder="Select status"
              disabled={isFetchingDepotFilters}
              customFormItemClassName="mb-4"
              options={statusOptions}
              customClassName="!w-[7.9375rem] mr-3"
              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
            />
            <CustomSelect
              name="IsDeployed"
              initialValue=""
              placeholder="Select deployed"
              disabled={isFetchingDepotFilters}
              customFormItemClassName="mb-4"
              options={isDeployedOptions}
              customClassName="!w-[7.9375rem] mr-3"
              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
            />
            <CustomSelect
              name="Type"
              initialValue=""
              placeholder="Select type"
              disabled={isFetchingDepotFilters}
              customFormItemClassName="mb-4"
              options={typeOptions}
              customClassName="!w-[7.9375rem] mr-3"
              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
            />
            <FormInput
              name="KeyWord"
              disabled={isFetchingDepotFilters}
              customFormItemClassName="mr-3 flex-1 !mb-4"
              placeholder="Search by Depot code, Depot name, Country, Address, Depot Email, Logistic email"
              prefix={
                <Image
                  preview={false}
                  src={SearchIcon}
                  alt="input-search-icon"
                />
              }
              suffix={
                keyword ? (
                  <img
                    className="cursor-pointer"
                    src={CrossIcon}
                    alt="CrossIcon"
                    onClick={handleClearKeyword}
                  />
                ) : null
              }
            />
            <Form.Item className="mb-4">
              <CustomButtom
                title="Apply"
                disabled={isFetchingDepotFilters}
                customSize="small"
                customColor="primary"
                htmlType="submit"
              />
            </Form.Item>
          </div>
        </Form>
        <ConfigProvider renderEmpty={EmptyTable}>
          <Table
            rowKey="depotId"
            loading={isFetchingDepotList}
            pagination={false}
            columns={depotTableColumns}
            dataSource={tableDataSource}
            className={tableStyles.dashed}
          />
        </ConfigProvider>
        {tableDataSource.length >= 1 && (
          <div className="p-6 flex justify-between items-center border-x border-b border-solid border-blue-solitude">
            <div>
              <Typography.Text className="font-roboto text-sm not-italic font-normal leading-4 text-blue-waikawa-grey">
                Results:&nbsp;
              </Typography.Text>
              <Typography.Text className="text-blue-midnight-express text-sm not-italic font-roboto font-normal left-5">
                {pagination?.totalRecords}
              </Typography.Text>
            </div>
            <div className="custom-pagination-customer-management">
              <Pagination
                nextIcon={
                  <Image
                    preview={false}
                    alt="pagination-next"
                    src={AngleRight}
                  />
                }
                prevIcon={
                  <Image
                    preview={false}
                    alt="pagination-previous"
                    src={AngleLeft}
                  />
                }
                pageSize={pagination?.pageSize}
                current={PageNum}
                onChange={setPageNum}
                total={pagination?.totalRecords}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
        <ModalDepot
          depot={temporaryDepot}
          open={isOpenModalDepot}
          countryOptions={countryOptions.slice(1)} // remove default option
          typeOptions={typeOptions.slice(1)} // remove default option
          refetchOption={refetch}
          onCancel={handleCloseModalDepot}
        />
        <ModalDepotHistory
          isOpen={isOpenDepotHistory}
          onClose={() => setIsOpenDepotHistory(false)}
          recordId={depotSelectedId}
        />
      </div>
    </>
  );
};
