import { Collapse, Typography } from "antd";

import ArrowDownIcon from "assets/icons/ArrowDownIcon.svg";
import ArrowUpIcon from "assets/icons/ArrowUpIcon.svg";
import DangerIcon from "assets/icons/DangerIcon.svg";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import SuccessIcon from "assets/icons/SuccessIcon.svg";
import { ValidationPreRead } from "../queries/types";
import XCloseIcon from "assets/icons/XCloseIcon.svg";
import XLSXIcon from "assets/icons/XLSXIcon.svg";

export interface IModalUploadIMportEdo {
  callBackClose: () => void;
  callBackCancel: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  showButtonX: boolean;
  fileAfterRead: ValidationPreRead;
  importDischargeError: boolean;
  duplicatedDischargeError: boolean;
  existedDischargeError: boolean;
}

const ModalProcessingUploadDischarge = ({
  callBackClose,
  callBackCancel,
  isLoading,
  isSuccess,
  showButtonX,
  fileAfterRead,
  importDischargeError,
  duplicatedDischargeError,
  existedDischargeError
}: IModalUploadIMportEdo) => {

  return (
    <div className="absolute bottom-0 right-10 w-[307px]">
      <Collapse
        defaultActiveKey={["1"]}
        className="bg-white rounded-none rounded-t-lg shadow-popUpShadow [&_.ant-collapse-content]:border-t-0 [&_.ant-collapse-content-box]:!p-0"
        items={[
          {
            key: "1",
            label: (
              <div>
                <Typography.Text className="font-antonio font-bold text-base text-blue-sapphire leading-5 tracking-[-0.04px]">
                  Importing Files
                </Typography.Text>
              </div>
            ),
            children: (
              <div className="flex flex-col">
                {isLoading && (
                  <div className="flex justify-between bg-blue-solitude-light items-center py-2 px-4">
                    <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-4">
                      Starting Upload Files....
                    </Typography.Text>
                    <Typography.Text
                      onClick={callBackCancel}
                      className="cursor-pointer font-roboto font-normal text-sm text-blue-royal-blue leading-6"
                    >
                      Cancel
                    </Typography.Text>
                  </div>
                )}
                <div className="flex p-4 justify-between items-center">
                  <div className="flex items-center w-4/5">
                    <img src={XLSXIcon} alt="fileType" />
                    <Typography.Text className="truncate font-roboto font-normal text-base text-blue-waikawa-grey leading-[18px]">
                      {fileAfterRead.fileName}
                    </Typography.Text>
                  </div>
                  {isLoading ? (
                    <Loading3QuartersOutlined
                      className="text-blue-sapphire h-4"
                      spin
                    />
                  ) : isSuccess ? (
                    <img src={SuccessIcon} alt="successIcon" />
                  ) : (
                    <div className="flex">
                      <Typography.Text className="mr-2 font-roboto font-normal text-xs text-blue-rock-blue leading-[14px]">
                        Failed
                      </Typography.Text>
                      <img src={DangerIcon} alt="" />
                    </div>
                  )}
                </div>
                {!duplicatedDischargeError && !existedDischargeError && importDischargeError && (
                  <Typography.Text className="text-red-torch font-roboto font-normal text-xs leading-4 ml-4 mb-6">
                    Upload Failed
                  </Typography.Text>
                )}
                {duplicatedDischargeError && (
                  <Typography.Text className="text-red-torch font-roboto font-normal text-xs leading-4 ml-4 mb-6">
                    System found a duplicated item. Please try again.
                  </Typography.Text>
                )}
                {existedDischargeError && (
                  <Typography.Text className="text-red-torch font-roboto font-normal text-xs leading-4 ml-4 mb-6">
                    System found a duplicated item. Please try again.
                  </Typography.Text>
                )}
              </div>
            ),
          },
        ]}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) =>
          isActive ? (
            <div>
              <img src={ArrowDownIcon} alt="open" />
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : null}
            </div>
          ) : (
            <div>
              <img src={ArrowUpIcon} alt="open" />
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : null}
            </div>
          )
        }
      />
    </div>
  );
};

export default ModalProcessingUploadDischarge;
