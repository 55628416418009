import { Dayjs } from "dayjs";

export interface ICustomer {
  address: string;
  createdDate: string;
  customerName: string;
  edosReleaseNumber: number;
  email: string;
  id: string;
  listEmail: string[];
  modifiedDate: string;
  phoneNumber: string;
  status: boolean;
  vatId: string;
  countryName: string;
}

export interface CustomerFilters {
  PageNum?: number;
  FromDate?: string;
  ToDate?: string;
  Keyword?: string;
  PageSize?: number;
  Status?: boolean;
  countryName: string;
}

export interface CustomerFormFilters {
  Keyword?: string;
  Status: CustomerStatus | string;
  Dates: null | (Dayjs | null)[];
}

export enum CustomerStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum ColumnCustomerSortExport {
  VatId = 1,
  CustomerInfo = 2,
  EdosReleaseNumber = 3,
  Address = 4,
  Email = 5,
}

export interface SortTableColumn {
  columnKey: string | null;
  isDescending: boolean;
}
