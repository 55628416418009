import { Link } from "react-router-dom";
import { Menu } from "antd";
import { SidebarIcon } from "./SidebarIcon";
import { SidebarIconName } from "layouts/types";

type SidebarMenuItemProps = {
  label: string;
  isSelected?: boolean;
  eventKey?: string;
  icon?: SidebarIconName;
};

export const SidebarItem = ({
  label,
  icon,
  isSelected,
  eventKey = "",
}: SidebarMenuItemProps) => {
  return (
    <Menu.Item
      eventKey={eventKey}
      className={`!w-full !rounded-none !mt-0 !ml-0 mb-2 !pr-[19.5px] sidebar-custom-item flex items-center [&_.ant-menu-title-content]:h-full [&_.ant-menu-title-content]:flex [&_.ant-menu-title-content]:items-center ${
        isSelected
          ? "border-r-[3px] border-solid border-red-base"
          : "border-r-0 border-transparent"
      }`}
    >
      <div className={`flex items-center`}>
        {icon && (
          <SidebarIcon
            name={icon}
            fill={isSelected ? "#E20101" : "#0A2071"}
            customClassName="mr-2.5"
          />
        )}
        <Link
          className={`!text-base font-normal !leading-6 !font-resolve tracking-[0.08px] ${
            isSelected ? "!text-red-base" : "!text-blue-sapphire"
          }`}
          to={eventKey}
        >
          {label}
        </Link>
      </div>
    </Menu.Item>
  );
};
