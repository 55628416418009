import { load, save } from "libs/storage";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CustomSelect } from "pages/accounts-management/components/CustomSelect";
import { Form } from "antd";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { UserRole } from "interfaces/common-model";
import { customerQueryKeys } from "layouts/queries";
import { get } from "lodash";
import { queryClient } from "App";
import { useCountry } from "./SelectCountry";
import { useNavigate } from "react-router-dom";

export const AllRoleOptions = [
  { label: "Import CS", value: UserRole.ImportCS },
  { label: "Logistic", value: UserRole.Logistic },
  { label: "Counter", value: UserRole.Counter },
];

export const useUserRole = (isGetBaseRole?: boolean) => {
  const [userRole, setRole] = useState<UserRole>(
    Number(load<UserRole>(LOCAL_STORAGE_KEYS.USER_ROLE))
  );
  const currentCountry = useCountry();

  const queryListRoleData = get(
    queryClient.getQueryData(customerQueryKeys.GET_USER_ROLE),
    "data.roles"
  );

  const baseRole = useMemo(() => {
    return currentCountry && queryListRoleData
      ? (queryListRoleData as any).find(
          (item: any) => item.countryName === currentCountry
        )?.roleEnum
      : null;
  }, [queryListRoleData, currentCountry]);

  const handleStorageChange = useCallback(() => {
    const tempStoredUserRole = load<UserRole>(
      LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN
    );
    if (tempStoredUserRole) {
      setRole(tempStoredUserRole);
    }
  }, []);

  useEffect(() => {
    handleStorageChange();
  }, []);
  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [handleStorageChange]);

  return isGetBaseRole ? baseRole : Number(userRole);
};

export type RoleListType = {
  roleEnum: number;
  roleName: string;
  countryName: string;
};
export const SelectUserRole = () => {
  const [form] = Form.useForm();
  const role = Form.useWatch("userRole", form);
  const currentCountry = useCountry();

  const queryRoleList: RoleListType[] | any = get(
    queryClient.getQueryData(customerQueryKeys.GET_USER_ROLE),
    "data.roles"
  );

  const tempStoredUserRole = load<UserRole>(
    LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN
  );

  const RoleOptions = useMemo(() => {
    const listOps = queryRoleList
      ? queryRoleList
          .filter(
            (item: RoleListType) =>
              ![UserRole.Admin, UserRole.ITOfficer].includes(item.roleEnum) &&
              item.countryName === currentCountry
          )
          .map((role: RoleListType) => ({
            label: role.roleName,
            value: role.roleEnum,
          }))
      : [];

    if (listOps[0]) {
      form.setFieldValue("userRole", listOps[0].value);
    }
    if (listOps.length === 0) {
      form.setFieldValue("userRole", AllRoleOptions[0].value);
    }
    return listOps.length > 0 ? listOps : AllRoleOptions;
  }, [queryRoleList, currentCountry]);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname !== "/edo") {
      navigate("/edo");
    }
    save(
      LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN,
      role ? role : UserRole.ImportCS
    );
    window.dispatchEvent(new Event("storage"));
  }, [role, RoleOptions]);

  useEffect(() => {
    if (
      tempStoredUserRole &&
      [UserRole.Counter, UserRole.Depot, UserRole.ImportCS].includes(
        tempStoredUserRole
      )
    ) {
      form.setFieldValue("userRole", Number(tempStoredUserRole));
    } else if ([UserRole.Admin, UserRole.ITOfficer].includes(role)) {
      form.setFieldValue("userRole", UserRole.ImportCS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} layout="horizontal">
      <div className="[&_.ant-select-selector]:!w-[110px] [&_.custom-account-select.ant-select-single>.ant-select-selector]:!border-0 [&_.ant-select-arrow]:mr-10">
        <CustomSelect
          name="userRole"
          placeholder="Role"
          options={RoleOptions}
          className="mb-0 mr-10 [&_.ant-form-item-label>label]:!h-10 [&_.ant-form-item-label>label]:text-blue-sapphire"
          placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
          popupClassName="!w-[6.875rem]"
        />
      </div>
    </Form>
  );
};
