import { CustomerFilters, ICustomer } from "./interfaces";
import {
  CustomerPayload,
  ExportExcelCustomerParams,
  GetCustomerHistoryParams,
  UpdateCustomerPayload,
  customerServices,
} from "./services";
import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";

import { CustomerDepotHistoryUpdate } from "pages/eod-management/components/ModalTransactionHistory/interfaces";
import { fetchApi } from "libs/fetchAPI";
import pick from "lodash/pick";
import { useCountry } from "pages/eod-management/components/SelectCountry";

export type GetCustomerListOptions = CustomerFilters &
  UseQueryOptions<IBaseResponse<IBasePaginationResponse<ICustomer>>, unknown>;

export type ExportExcelCustomerOptions = UseMutationOptions<
  BlobPart,
  unknown
> & {
  params: ExportExcelCustomerParams;
};

export type GetCustomerHistory = GetCustomerHistoryParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<CustomerDepotHistoryUpdate>>,
    unknown
  >;

export const customerQueryKeys = {
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  GET_CUSTOMER_HISTORY: "GET_CUSTOMER_HISTORY",
};

export const useGetCustomerList = (options?: GetCustomerListOptions) => {
  const params = pick(options, [
    "Keyword",
    "PageNum",
    "PageSize",
    "FromDate",
    "ToDate",
    "Status",
    "SearchForNameOnly",
  ]);
  const currentCountry = useCountry();
  return useQuery({
    queryKey: [customerQueryKeys.GET_CUSTOMER_LIST, params],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<ICustomer>>>(
        customerServices.getCustomerList({
          ...params,
          countryName: currentCountry,
        })
      ),
    ...options,
  });
};

export const useCreateCustomer = () => {
  return useMutation((payload: CustomerPayload) =>
    fetchApi<IBaseResponse<ICustomer>>(customerServices.createCustomer(payload))
  );
};

export const useUpdateCustomer = () =>
  useMutation((payload: UpdateCustomerPayload) =>
    fetchApi<IBaseResponse<ICustomer>>(customerServices.updateCustomer(payload))
  );

export const useExportExcelCustomer = () =>
  useMutation((options: ExportExcelCustomerOptions) =>
    fetchApi<BlobPart>(customerServices.exportExcelCustomer(options.params))
  );

export const useCustomerHistory = (options: GetCustomerHistory) =>
  useQuery({
    queryKey: [customerQueryKeys.GET_CUSTOMER_HISTORY, options],
    queryFn: () =>
      fetchApi<
        IBaseResponse<
          IBasePaginationResponse<
            {
              actualVoyageNo: string;
              containerNo: string;
            } & CustomerDepotHistoryUpdate
          >
        >
      >(customerServices.getCustomerHistory(options)),
    enabled: options.isTrigger,
    ...options,
  });
