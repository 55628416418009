import { HttpStatusCode } from "axios";

export interface IPagination {
  PageNum: string;
  PageSize: string;
  Keyword?: string;
  FromDate?: string;
  ToDate?: string;
}

export enum UserRole {
  ImportCS = 0,
  Logistic = 1,
  Counter = 2,
  Depot = 3,
  Admin = 4,
  ITOfficer = 5,
}

export enum EdoStatus {
  New = 0,
  WaitForLog = 1,
  ReadyForRelease = 2,
  Unrelease = 3,
  Shortship = 4,
  Delete = 5,
  Release = 6,
  ReleaseUpdate = 7,
}

export enum IBaseResponseMessage {
  Success = "Success",
  Failed = "Failed",
}

export interface IBaseResponse<IData> {
  data: IData;
  isSuccess: boolean;
  message: IBaseResponseMessage;
  statusCode: HttpStatusCode;
}

export interface IBasePaginationResponse<IData> {
  data: IData[];
  pageNum: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  totalChildrenRecords?: number;
}

export type PanelMode =
  | "time"
  | "date"
  | "week"
  | "month"
  | "quarter"
  | "year"
  | "decade";
