import { LoginType, SK } from "constants/constants";
import { load, logout } from "libs/storage";

import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { decodeMessage } from "libs/helper";
import { getPingSSOInfo } from "./use-get-ping-sso-user-info";

export const isPingSSO = () => {
  const localCheck = load(LOCAL_STORAGE_KEYS.LOG_TYPE)
    ? decodeMessage(load(LOCAL_STORAGE_KEYS.LOG_TYPE) as LoginType, SK)
    : undefined;

  const pingProfile = getPingSSOInfo();

  if (
    [`${LoginType.PingSSO}${pingProfile?.sub}`, `${LoginType.Azure}`].includes(
      `${localCheck}`
    )
  ) {
    return localCheck === `${LoginType.PingSSO}${pingProfile?.sub}`;
  } else {
    if (window.location.pathname !== "/") {
      logout();
    }
  }
};
