import { Col, Form, Modal, Row, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CustomTabs } from "components/tab/CustomTabs";
import { FormInput } from "components/form/Input";
import { TabItemRelease } from "./components/TabItemRelease";
import { TabItemUpdate } from "./components/TabItemUpdate";
import { TabLabel } from "pages/accounts-management/components/TabLabel";
import { useEdoContext } from "contexts/edo-context";

type HistoryHeaderForm = {
  ActualVoyageNo: string;
  ContainerNo: string;
};

export const ModalTransactionHistory = () => {
  const [activeKeyTab, setActiveKeyTab] = useState("update");
  const { isOpenHistoryTransaction, setEdo } = useEdoContext();

  useEffect(() => {
    setActiveKeyTab("update");
  }, [isOpenHistoryTransaction.isOpen]);

  const handleCallBackOutSide = useCallback(
    (actualVoyageNo: string, containerNo: string) => {
      form.setFieldValue("ActualVoyageNo", actualVoyageNo);
      form.setFieldValue("ContainerNo", containerNo);
    },
    []
  );

  const tabs = useMemo(() => {
    return [
      {
        key: "update",
        label: "Update history",
        children: <TabItemUpdate callBackOutSide={handleCallBackOutSide} />,
      },
      {
        key: "release",
        label: "Release history",
        children: <TabItemRelease />,
      },
    ];
  }, []);

  const handleTabChange = (activeKey: string) => {
    setActiveKeyTab(activeKey);
  };

  const accountTabs = useMemo(
    () =>
      tabs.map(({ key, label, children }) => ({
        key,
        label: (
          <TabLabel key={key} label={label} isActive={key === activeKeyTab} />
        ),
        children,
      })),
    [activeKeyTab]
  );

  const handelCloseModal = () => {
    setEdo((prev) => ({
      ...prev,
      isOpenHistoryTransaction: {
        ...isOpenHistoryTransaction,
        isOpen: false,
      },
    }));
  };
  const [form] = Form.useForm<HistoryHeaderForm>();

  return (
    <>
      <Modal
        closable={false}
        className="!w-[1110px]"
        open={isOpenHistoryTransaction.isOpen}
        onOk={() => {}}
        onCancel={handelCloseModal}
        footer={false}
        centered
      >
        <div className="mb-6">
          <Typography.Text className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block mb-2">
            History
          </Typography.Text>
          <Typography.Text className="text-sm leading-5 font-normal font-Roboto text-blue-rock-blue">
            View Transaction’s History
          </Typography.Text>
        </div>
        <Form form={form} layout="vertical" className="flex w-full">
          <Row className="w-full" gutter={[16, 0]}>
            <Col span={12}>
              <FormInput
                label="Actual Voyage No."
                name="ActualVoyageNo"
                disabled
              />
            </Col>
            <Col span={12}>
              <FormInput label="Container No." name="ContainerNo" disabled />
            </Col>
          </Row>
        </Form>
        <div className="border border-blue-solitude border-solid mt-5">
          <CustomTabs
            activeKey={activeKeyTab}
            onChange={handleTabChange}
            className="custom-account-tab"
            items={accountTabs}
          />
        </div>
      </Modal>
    </>
  );
};
