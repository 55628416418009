import { Form, FormItemProps, Switch, SwitchProps } from "antd";

type ToggleProps = FormItemProps &
  SwitchProps & {
    customFormItemClassName?: string;
    customToggleClassName?: string;
  };

export const Toggle = ({
  label,
  customFormItemClassName = "!mb-4",
  customToggleClassName,
  ...restProps
}: ToggleProps) => (
  <Form.Item
    className={customFormItemClassName}
    label={label}
    valuePropName="checked"
    {...restProps}
  >
    <Switch
      className={`h-[1.125rem] leading-[1.125rem] min-w-[1.875rem] [&_.ant-switch-handle]:w-[0.875rem] [&_.ant-switch-handle]:h-[0.875rem] [&.ant-switch-checked_.ant-switch-handle]:!start-[calc(100%-16px)] bg-blue-rock-blue [&_.ant-switch-checked)]:bg-blue-royal-blue [&_.ant-switch-checked):!hover]:bg-blue-royal-blue [&:not(_.ant-switch-checked):hover]:!bg-blue-rock-blue ${customToggleClassName}`}
      {...restProps}
    />
  </Form.Item>
);
