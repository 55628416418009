import { EDO_STATUS } from "constants/constants";
import { FiltersEdo } from "./interfaces";

export const filterEdoStatus = [
  { value: EDO_STATUS.New.value, label: EDO_STATUS.New.label },
  {
    value: EDO_STATUS.WaitForLog.value,
    label: EDO_STATUS.WaitForLog.label,
  },
  {
    value: EDO_STATUS.ReadyForRelease.value,
    label: EDO_STATUS.ReadyForRelease.label,
  },
  {
    value: EDO_STATUS.Unrelease.value,
    label: EDO_STATUS.Unrelease.label,
  },
  {
    value: EDO_STATUS.Shortship.value,
    label: EDO_STATUS.Shortship.label,
  },
  { value: EDO_STATUS.Delete.value, label: EDO_STATUS.Delete.label },
  {
    value: EDO_STATUS.Release.value,
    label: EDO_STATUS.Release.label,
  },
  {
    value: EDO_STATUS.ReleaseUpdate.value,
    label: EDO_STATUS.ReleaseUpdate.label,
  },
];

export const filterEdoDangerousGoods = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const filterEdoOwnerContainer = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
  { value: null, label: "N/A" },
];

export const filterEdoPartLoad = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const edoActions = [
  { key: "edit-edo", label: "Edit eDO" },
  { key: "view-pdf-file", label: "View PDF file" },
  { key: "view-history", label: "View History" },
];

export const edoActionsDepot = [
  { key: "view-pdf-file", label: "View PDF file" },
  { key: "view-history", label: "View History" },
];

export const createOrUdateEdo = {
  Add: { value: 1, label: "Add New eDO" },
  Update: { value: 2, label: "Update eDO" },
};

export enum EodAction {
  uploadFile = "uploadFile",
  enterBlNumb = "enterBlNumb",
}

export const INITIAL_FILTERS: FiltersEdo = {
  fromBLETADate: null,
  toBLETADate: null,
  fromUpdateDate: null,
  toUpdateDate: null,
  fromPickupDate: null,
  toPickupDate: null,
  fromReturnDate: null,
  toReturnDate: null,
  listStatus: [],
  listContainerSize: [],
  isDG: null,
  listSOCIndicator: [],
  isPL: null,
  keyword: "",
  countryName: "",
};
