import { Collapse, Typography } from "antd";

import ArrowDownIcon from "assets/icons/ArrowDownIcon.svg";
import ArrowUpIcon from "assets/icons/ArrowUpIcon.svg";
import DangerIcon from "assets/icons/DangerIcon.svg";
import DownloadRedIcon from "assets/icons/DownloadRedIcon.svg";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import SuccessIcon from "assets/icons/SuccessIcon.svg";
import XCloseIcon from "assets/icons/XCloseIcon.svg";
import XLSXIcon from "assets/icons/XLSXIcon.svg";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export interface IModalUploadIMportEdo {
  callBackClose: () => void;
  callBackCancel: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  showButtonX: boolean;
  fileAfterRead: { fileName: string };
  importError: Blob | null;
}

const ModalProcess = ({
  callBackClose,
  callBackCancel,
  isLoading,
  isSuccess,
  showButtonX,
  fileAfterRead,
  importError,
}: IModalUploadIMportEdo) => {
  const navigate = useNavigate();
  const handleDownloadErrorFile = () => {
    if (importError) {
      const url = window.URL.createObjectURL(new Blob([importError]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Data_import_err_${dayjs(Date.now()).format(SYSTEM_DATE_FORMAT)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  };
  return (
    <div className="absolute bottom-0 right-10 w-[307px]">
      <Collapse
        defaultActiveKey={["1"]}
        className="bg-white rounded-none rounded-t-lg shadow-popUpShadow [&_.ant-collapse-content]:border-t-0 [&_.ant-collapse-content-box]:!p-0"
        items={[
          {
            key: "1",
            label: (
              <div>
                <Typography.Text className="font-antonio font-bold text-base text-blue-sapphire leading-5 tracking-[-0.04px]">
                  Importing Files
                </Typography.Text>
              </div>
            ),
            children: (
              <div className="flex flex-col">
                {isLoading && (
                  <div className="flex justify-between bg-blue-solitude-light items-center py-2 px-4">
                    <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-4">
                      Starting Upload Files....
                    </Typography.Text>
                    <Typography.Text
                      onClick={callBackCancel}
                      className="cursor-pointer font-roboto font-normal text-sm text-blue-royal-blue leading-6"
                    >
                      Cancel
                    </Typography.Text>
                  </div>
                )}
                <div className="flex p-4 justify-between items-center">
                  <div className="flex items-center w-4/5">
                    <img src={XLSXIcon} alt="fileType" />
                    <Typography.Text className="truncate font-roboto font-normal text-base text-blue-waikawa-grey leading-[18px]">
                      {fileAfterRead.fileName}
                    </Typography.Text>
                  </div>
                  {isLoading ? (
                    <Loading3QuartersOutlined
                      className="text-blue-sapphire h-4"
                      spin
                    />
                  ) : isSuccess && !importError ? (
                    <img src={SuccessIcon} alt="successIcon" />
                  ) : (
                    <div className="flex">
                      <Typography.Text className="mr-2 font-roboto font-normal text-xs text-blue-rock-blue leading-[14px]">
                        Failed
                      </Typography.Text>
                      <img src={DangerIcon} alt="" />
                    </div>
                  )}
                </div>
                {importError && (
                  <div
                    onClick={handleDownloadErrorFile}
                    className="flex justify-start ml-4 mb-6 items-center"
                  >
                    <img src={DownloadRedIcon} alt="" />
                    <Typography.Text className="cursor-pointer ml-2 text-red-torch font-roboto font-normal text-sm leading-4">
                      Download Error File
                    </Typography.Text>
                  </div>
                )}
                {isSuccess && !importError && (
                  <div>
                    <div
                      onClick={() => navigate("/edo")}
                      className="flex justify-start ml-4 mb-6 items-center"
                    >
                      <Typography.Text className="cursor-pointer ml-2 text-blue-royal-blue font-roboto font-normal text-sm leading-4">
                        Go to EDO Management
                      </Typography.Text>
                    </div>
                  </div>
                )}
              </div>
            ),
          },
        ]}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) =>
          isActive ? (
            <div>
              <img src={ArrowDownIcon} alt="open" />
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : null}
            </div>
          ) : (
            <div>
              <img src={ArrowUpIcon} alt="open" />
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : null}
            </div>
          )
        }
      />
    </div>
  );
};

export default ModalProcess;
