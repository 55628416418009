import { Skeleton } from "antd";

export const TagsSkeleton = () => {
  return (
    <>
      {Array.from({ length: 4 }, (_, index) => index).map(
        (
          index //just loading phase, of 4 items
        ) => (
          <Skeleton.Input className="mr-1" key={index} size={"small"} active />
        )
      )}
    </>
  );
};
