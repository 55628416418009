import {
  GetReleaseListParams,
  GetUpdateListParams,
  historyServices,
} from "./services";
import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";
import { UseQueryOptions, useMutation, useQuery } from "react-query";

import { HistoryUpdate } from "./interfaces";
import { fetchApi } from "libs/fetchAPI";

export type GetUpdateListOptions = GetUpdateListParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<HistoryUpdate>>,
    unknown
  >;

export type GetReleaseListOptions = GetReleaseListParams &
  UseQueryOptions<
    IBaseResponse<IBasePaginationResponse<HistoryUpdate>>,
    unknown
  >;

const accountQueryKeys = {
  GET_UPDATE_HISTORY: "GET_UPDATE_HISTORY",
  GET_RELEASE_HISTORY: "GET_RELEASE_HISTORY",
};

export const useGetUpdateList = (options?: GetUpdateListOptions) =>
  useQuery({
    queryKey: [accountQueryKeys.GET_UPDATE_HISTORY, options],
    queryFn: () =>
      fetchApi<
        IBaseResponse<
          IBasePaginationResponse<
            { actualVoyageNo: string; containerNo: string } & HistoryUpdate
          >
        >
      >(historyServices.getUpdateList(options)),
    enabled: options?.isTrigger,
    ...options,
  });

export const useGetReleaseList = (options?: GetUpdateListOptions) =>
  useQuery({
    queryKey: [accountQueryKeys.GET_RELEASE_HISTORY, options],
    queryFn: () =>
      fetchApi<
        IBaseResponse<
          IBasePaginationResponse<
            { actualVoyageNo: string; containerNo: string } & HistoryUpdate
          >
        >
      >(historyServices.getReleaseList(options)),
    enabled: options?.isTrigger,
    ...options,
  });

export type GetTempEdiFile = {
  fileName: string;
  bucketName: string;
};

export const useGetEdiFile = () =>
  useMutation((payload: GetTempEdiFile) =>
    fetchApi<BlobPart>(historyServices.getEdiFile(payload))
  );
