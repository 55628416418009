import { ColumnCustomerSortExport, CustomerFilters } from "./interfaces";

import { IFetchApi } from "libs/fetchAPI";
import { omitEmptyParams } from "libs/helper";

export type ExportExcelCustomerParams = CustomerFilters & {
  ColumnSortIndex?: ColumnCustomerSortExport | null | string;
  IsDescending?: boolean;
  countryName: string;
};

export type CustomerPayload = {
  vatId: string;
  customerName: string;
  phoneNumber: string;
  address: string;
  email?: string;
  listEmail: string[];
  countryName: string;
};

export type UpdateCustomerPayload = CustomerPayload & { id: string };

export type GetCustomerHistoryParams = {
  PageNum?: number;
  PageSize?: number;
  isTrigger?: boolean;
  id: string;
};

export const customerServices = {
  getCustomerList: (params?: CustomerFilters): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });

    return {
      url: `customers`,
      method: "get",
      isAuth: true,
      params: searchParams,
    };
  },
  createCustomer: (payload: CustomerPayload): IFetchApi => ({
    url: `customers`,
    method: "post",
    isAuth: true,
    payload: payload,
  }),
  updateCustomer: (payload: UpdateCustomerPayload): IFetchApi => ({
    url: `customers/${payload.id}`,
    method: "put",
    isAuth: true,
    payload: payload,
  }),
  exportExcelCustomer: (params: ExportExcelCustomerParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: "customers/export-excel",
      method: "get",
      isAuth: true,
      isDownload: true,
      params: searchParams,
    };
  },
  getCustomerHistory: (params: GetCustomerHistoryParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `customers/history?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
};
