import { IBaseResponse } from "interfaces/common-model";
import { MasterDataType } from "interfaces/master-data";
import { fetchApi, IFetchApi } from "libs/fetchAPI";
import { Depot } from "pages/depot-management/interfaces";
import { depotServices } from "pages/depot-management/services";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { useQueries, UseQueryOptions, UseQueryResult } from "react-query";

type MasterDataServiceDataTuple = [
  service: MasterDataService,
  responseData: unknown,
];
interface MasterDataGetter {
  getFetchApiOptions: (params: { countryName: string }) => IFetchApi;
  transformResult: (responseData: IBaseResponse<any>) => unknown;
}

export enum MasterDataService {
  DEPOTS = "depots",
}

interface UseMasterDataResult {
  masterData: MasterDataType;
  isPending: boolean;
}

const MASTER_DATA_GETTERS: ReadonlyMap<MasterDataService, MasterDataGetter> =
  new Map([
    [
      MasterDataService.DEPOTS,
      {
        getFetchApiOptions: depotServices.getAllDepots,
        transformResult: (responseData: IBaseResponse<Depot>) =>
          responseData.data,
      },
    ],
  ]);

const QUERY_KEY_PREFIX = Symbol("MASTER_DATA_QUERY_KEY_PREFIX");

export function useMasterData(
  services: readonly MasterDataService[],
): UseMasterDataResult {
  const countryName = useCountry();

  const results: readonly UseQueryResult<MasterDataServiceDataTuple>[] =
    useQueries(
      services.map<UseQueryOptions<MasterDataServiceDataTuple>>((service) => ({
        queryKey: [QUERY_KEY_PREFIX, service, countryName],
        queryFn: async () => {
          const masterDataGetter = MASTER_DATA_GETTERS.get(service);

          if (!masterDataGetter) {
            return [service, undefined];
          }

          const { getFetchApiOptions, transformResult } = masterDataGetter;

          return fetchApi<IBaseResponse<unknown>>(
            getFetchApiOptions({ countryName }),
          ).then<MasterDataServiceDataTuple>((responseData) => [
            service,
            transformResult(responseData),
          ]);
        },
        enabled: !!countryName,
        staleTime: Infinity,
      })),
    );

  const isPending = results.some((result) => result.isLoading);

  const masterData: MasterDataType = Object.fromEntries(
    results
      .filter(({ isSuccess }) => isSuccess)
      .map(({ data }) => data as MasterDataServiceDataTuple),
  );

  return { masterData, isPending };
}
