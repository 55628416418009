import { Badge, Dropdown, Image, Typography } from "antd";
import { Depot, DepotFormStatus } from "./interfaces";

import { CMG_CGM_DEPOT } from "constants/constants";
import { ColumnsType } from "antd/es/table";
import CustomMutipleTagsDisplay from "components/CustomMutipleTagsDisplay";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import ThreeDotExpandIcon from "assets/icons/ThreeDotExpandIcon.svg";
import { UserRole } from "interfaces/common-model";
import { trimText } from "libs/helper";
import { useMemo } from "react";
import { useUserRole } from "pages/eod-management/components/SelectRole";

type UseTableColumnsParams = {
  handleEdoAction: (event: any, record: Depot) => void; //yes it is any event
};

export const useTableColumns = ({ handleEdoAction }: UseTableColumnsParams) => {
  const role = useUserRole(true);

  const itemsCustom = (record: Depot) => {
    const actions = !(
      ![UserRole.Admin, UserRole.ITOfficer].includes(role) &&
      record.depotCode === CMG_CGM_DEPOT
    )
      ? [
          { key: "edit", label: "Edit" },
          { key: "update_history", label: "History" },
        ]
      : [{ key: "update_history", label: "History" }];
    return actions.map(({ key, label }) => ({
      key,
      label: (
        <Typography.Text className="font-roboto font-normal text-base leading-6 text-blue-sapphire">
          {label}
        </Typography.Text>
      ),
    }));
  };

  const columns: ColumnsType<Depot> = useMemo(
    () => [
      {
        title: (
          <TableHeaderTitle
            title={
              <>
                <Typography.Text className="!block font-roboto text-sm text-blue-sapphire leading-4 font-normal">
                  Depot Name/ Status/
                </Typography.Text>
                <Typography.Text className="mt-1.5 !block font-roboto text-sm text-blue-sapphire leading-4 font-normal">
                  Depot Code/ Type
                </Typography.Text>
              </>
            }
          />
        ),
        dataIndex: "depotId",
        ellipsis: true,
        width: 200,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.depotName?.localeCompare(b.depotName),
        render: (_value: any, record: Depot, _index: number) => {
          return (
            <div className="flex items-center">
              <CustomTooltip
                isShowCopy={false}
                copytext={
                  record.status
                    ? DepotFormStatus.Active
                    : DepotFormStatus.Inactive
                }
              >
                <Badge
                  color={record.status ? "#00865D" : "#D9DCE8"}
                  className="[&_span]:!w-2 [&_span]:!h-2"
                />
              </CustomTooltip>
              <div className="w-full flex-1 pl-3">
              <CustomTooltip
                isShowCopy
                copytext={record.depotName}
              >
                <Typography.Text className="!block font-roboto font-normal text-sm text-blue-midnight-express leading-5 truncate">
                  {record.depotName}
                </Typography.Text>
              </CustomTooltip>
                <Typography.Text className="!block font-roboto font-normal text-xs text-blue-waikawa-grey leading-[0.875rem] mt-1 truncate">
                  {record.depotCode && record.depotCode.length > 10 ? (
                    <CustomTooltip
                      isShowCopy
                      copytext={record.depotCode}
                    >
                      {trimText(record.depotCode)} |&nbsp;
                    </CustomTooltip>
                  ) : (
                    <>{trimText(record.depotCode)} |&nbsp;</>
                  )}
                  {trimText(record.type)}
                </Typography.Text>
              </div>
            </div>
          );
        },
      },
      {
        title: <TableHeaderTitle title="Phone Number" />,
        width: 150,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => Number(a.isDeployed) - Number(b.isDeployed),
        render: (_value: any, record: Depot, _index: number) => (
          <CustomTooltip isShowCopy copytext={record.phoneNumber}>
            <Typography.Text className="truncate !block text-center font-roboto font-normal text-sm text-violet-revolver leading-4">
              {record.phoneNumber}
            </Typography.Text>
          </CustomTooltip>
        ),
      },
      {
        title: <TableHeaderTitle title="Deployed" />,
        width: 118,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => Number(a.isDeployed) - Number(b.isDeployed),
        render: (_value: any, record: Depot, _index: number) => (
          <Typography.Text className="!block p-4 text-center font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.isDeployed ? "Yes" : "No"}
          </Typography.Text>
        ),
      },
      {
        title: (
          <TableHeaderTitle
            title={
              <>
                <Typography.Text className="!block font-roboto text-sm text-blue-sapphire leading-4 font-normal">
                  Address/
                </Typography.Text>
                <Typography.Text className="mt-1.5 !block font-roboto text-sm text-blue-sapphire leading-4 font-normal">
                  Country
                </Typography.Text>
              </>
            }
          />
        ),
        ellipsis: true,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.address?.localeCompare(b.address),
        render: (_value: any, record: Depot, _index: number) => {
          return (
            <>
              <CustomTooltip
                placement="top"
                isShowCopy
                copytext={record.address}
              >
                <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-5 mb-1 max-w-[fit-content]">
                  {record.address}
                </Typography.Text>
              </CustomTooltip>
              <Typography.Text className="!block font-roboto font-normal text-xs text-blue-waikawa-grey leading-[0.875rem] truncate">
                {record.countryName}
              </Typography.Text>
            </>
          );
        },
      },
      {
        title: <TableHeaderTitle title="Depot email" />,
        render: (_value: any, record: Depot, _index: number) => (
          <div className="truncate flex items-center justify-between h-full">
            <CustomMutipleTagsDisplay
              tags={record.emails}
              numberOfShowedTags={2}
            />
          </div>
        ),
      },
      {
        title: <TableHeaderTitle title="Logistic email" />,
        render: (_value: any, record: Depot, _index: number) => (
          <div className="truncate flex items-center justify-between h-full">
            <CustomMutipleTagsDisplay
              tags={record.logisticEmails}
              numberOfShowedTags={2}
            />
          </div>
        ),
      },
      {
        title: "",
        width: 60,
        render: (_value: any, record: Depot, _index: number) => (
          <div className="flex items-center justify-center w-full">
            <Dropdown
              placement="bottomLeft"
              trigger={["click"]}
              menu={{
                items: itemsCustom(record),
                onClick: (menuInfo) => handleEdoAction(menuInfo, record),
                className:
                  "border border-solid border-blue-sapphire !rounded-none !p-0 shadow-none [&>li]:!rounded-none [&>li]:!px-3 [&>li]:!py-2 [&>li]:min-w-[8.25rem] [&>li:hover]:!bg-blue-solitude-light",
              }}
            >
              <Image
                preview={false}
                className="cursor-pointer !w-6 !h-6"
                src={ThreeDotExpandIcon}
                alt="edo-action-icon"
              />
            </Dropdown>
          </div>
        ),
      },
    ],
    [role]
  );

  return columns;
};
