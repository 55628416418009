import { Button, ButtonProps, Typography } from "antd";
import { ButtonColor, ButtonSize, ButtonVariant } from "./types";
import { ReactNode, useMemo } from "react";

type CustomButtonProps = Omit<ButtonProps, "title"> & {
  title?: string | ReactNode;
  variant?: "filled" | "outline" | "text";
  customSize: "large" | "medium" | "small" | "extra-small";
  customColor: "primary" | "secondary" | "danger" | "tonal";
  customClassName?: string;
  customTextClassName?: string;
};

export const CustomButtom = ({
  title,
  variant = ButtonVariant.filled,
  customSize,
  customColor,
  customClassName = "",
  customTextClassName = "",
  ...restProps
}: CustomButtonProps) => {
  const styles = useMemo(() => {
    let buttonClassName =
      "!rounded-none border-solid shadow-none min-w-26 flex items-center justify-center [&_.anticon.anticon-loading.anticon-spin]:text-white";
    let textClassName = "font-roboto font-normal !block";

    // custom button size
    switch (customSize) {
      case ButtonSize.large:
        buttonClassName = `${buttonClassName} h-12 !py-3 !px-6`;
        textClassName = `${textClassName} text-base leading-5`;
        break;
      case ButtonSize.medium:
        buttonClassName = `${buttonClassName} h-11 !py-2.5 !px-5`;
        textClassName = `${textClassName} text-base leading-5`;
        break;
      case ButtonSize.small:
        buttonClassName = `${buttonClassName} h-10 !py-2 !px-5`;
        textClassName = `${textClassName} text-sm leading-4`;
        break;
      case ButtonSize.extraSmall:
        buttonClassName = `${buttonClassName} h-9 !py-2 !px-4`;
        textClassName = `${textClassName} text-sm leading-4`;
        break;
      default:
        buttonClassName = `${buttonClassName} h-10 !py-2 !px-5`;
        textClassName = `${textClassName} text-sm leading-5`;
        break;
    }

    switch (variant) {
      case ButtonVariant.filled:
        buttonClassName = `${buttonClassName} border-2`;
        textClassName = `${textClassName} text-white`;

        switch (customColor) {
          case ButtonColor.primary:
            buttonClassName = `${buttonClassName} border-blue-sapphire bg-blue-sapphire hover:bg-blue-sapphire-bold [&:not(:disabled):hover]:!border-blue-sapphire-bold disabled:bg-blue-rock-blue disabled:border-blue-rock-blue`;
            break;
          case ButtonColor.secondary:
            buttonClassName = `${buttonClassName} border-red-base bg-red-base hover:bg-red-free-speech [&:not(:disabled):hover]:!border-red-free-speech disabled:bg-blue-rock-blue disabled:border-blue-rock-blue`;
            break;
          default:
            buttonClassName = `${buttonClassName} border-blue-sapphire bg-blue-sapphire hover:bg-blue-sapphire-bold [&:not(:disabled):hover]:!border-blue-sapphire-bold disabled:bg-blue-rock-blue disabled:border-blue-rock-blue`;
            break;
        }
        break;
      case ButtonVariant.outline:
        buttonClassName = `${buttonClassName} border-1`;

        switch (customColor) {
          case ButtonColor.primary:
            buttonClassName = `${buttonClassName} group bg-white [&:not(:disabled):hover]:!border-blue-sapphire-bold [&:not(:disabled):hover]:bg-blue-lavender focus:shadow-[0px_0p_0px_2px_theme('colors.blue.columbia')] focus:!border-blue-sapphire-bold disabled:border-blue-rock-blue`;
            textClassName = `${textClassName} [&:not(:disabled)]:text-blue-sapphire group-disabled:text-blue-rock-blue`;
            break;
          case ButtonColor.secondary:
            buttonClassName = `${buttonClassName} group bg-white [&:not(:disabled):hover]:!border-red-base focus:shadow-[0px_0px_0px_2px_theme('colors.red.pale-pink')] focus:!border-red-base disabled:border-blue-rock-blue`;
            textClassName = `${textClassName} [&:not(:disabled)]:text-red-base group-disabled:text-blue-rock-blue`;
            break;

          default:
            break;
        }
        break;
      case ButtonVariant.text:
        buttonClassName = `${buttonClassName} border-transparent`;
        switch (customColor) {
          case ButtonColor.primary:
            buttonClassName = `${buttonClassName}`;
            textClassName = `${textClassName}`;
            break;
          case ButtonColor.secondary:
            buttonClassName = `${buttonClassName} group [&:not(:disabled):hover]:!border-red-base focus:shadow-[0px_0px_0px_2px_theme('colors.red.pale-pink')] disabled:border-transparent disabled:bg-transparent`;
            textClassName = `${textClassName} [&:not(:disabled)]:text-red-base group-disabled:text-blue-rock-blue`;
            break;

          default:
            buttonClassName = `${buttonClassName} border-transparent bg-transparent`;
            break;
        }

        break;
      default:
        break;
    }

    return {
      buttonClassName,
      textClassName,
    };
  }, [customColor, customSize, variant]);

  return (
    <Button
      className={`${styles.buttonClassName} ${customClassName}`}
      type="default"
      {...restProps}
    >
      <Typography.Text
        className={`${styles.textClassName} ${customTextClassName}`}
      >
        {title}
      </Typography.Text>
    </Button>
  );
};
