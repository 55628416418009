import { Col, Row } from "antd";

import { FormSelect } from "components/form/Select";

export const VoyageInformation = () => (
  <Row className="pb-2" gutter={[16, 0]}>
    <Col span={24}>
      <FormSelect
        mode="tags"
        label="Actual Voyage No."
        open={false}
        name="actualVoyageNo"
        disabled
        suffixIcon={null}
        customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
      />
    </Col>
    <Col span={24}>
      <FormSelect
        mode="tags"
        label="LARA Voyage No."
        open={false}
        name="laraVoyageNo"
        disabled
        suffixIcon={null}
        customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
      />
    </Col>
    <Col span={24}>
      <FormSelect
        mode="tags"
        label="Arrival Date"
        open={false}
        name="arrivalDate"
        disabled
        suffixIcon={null}
        customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
      />
    </Col>
  </Row>
);
