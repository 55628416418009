import { Col, Row } from "antd";
import { EdoStatus, UserRole } from "interfaces/common-model";
import { isEmpty, isObject } from "lodash";

import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";
import { DefaultOptionType } from "antd/es/select";
import { FormSelect } from "components/form/Select";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { useGetCustomerList } from "pages/customer-management/queries";
import DepotSelect from "pages/depot-management/components/DepotSelect";
import { EdoDetailFormConfig } from "pages/eod-management/hooks/use-config-status-matrix";
import { AsyncSelect } from "./AsyncSelect";
import { ConsigneeInput } from "./ConsigneeInput";

type BLInfoProps = {
  config: EdoDetailFormConfig;
  blListOptions: DefaultOptionType[];
  isConsigneeTemporary: boolean;
  blNo?: string | null;
  isFieldRequiredForChangeStatus: boolean;
  role: UserRole;
  currentStatus?: EdoStatus;
  nextStatus?: EdoStatus | null;
  callBackAddNewConsignee: () => void;
};

export const BLInfo = ({
  config,
  blNo,
  blListOptions,
  isConsigneeTemporary,
  isFieldRequiredForChangeStatus,
  role,
  currentStatus,
  nextStatus,
  callBackAddNewConsignee,
}: BLInfoProps) => (
  <Row className="pb-6" gutter={[16, 0]}>
    <Col span={12}>
      <FormSelect
        virtual
        disabled={config?.blNo.disabled}
        options={blListOptions}
        name="blNo"
        label="BL No."
        placeholder="Enter BL No."
        rules={[
          {
            validator: (
              _rule: RuleObject,
              values:
                | {
                    label: string;
                    value: string;
                  }
                | string,
              callback: (error?: string) => void,
            ): Promise<void | any> | void => {
              if (
                nextStatus === EdoStatus.Release && //only check when change to release
                [UserRole.Counter].includes(role) && //only check on counter role
                currentStatus &&
                [EdoStatus.ReadyForRelease, EdoStatus.Unrelease].includes(
                  //only check when change to release
                  currentStatus,
                )
              ) {
                if (
                  isObject(values) && !isEmpty(values) //base value as object
                    ? values.label.includes(",")
                    : JSON.parse(values as string).label.includes(",") //selected value stringify as string
                ) {
                  callback("");
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            message: <ErrorInput message="BL No. must be selected" />,
            validateTrigger: "onSubmit",
          },
          {
            required: isFieldRequiredForChangeStatus
              ? config.blNo.required
              : false,
            message: <ErrorInput />,
          },
        ]}
      />
    </Col>
    <Col span={12}>
      <ConsigneeInput
        disabled={config?.consigneeName.disabled}
        blNo={blNo}
        controlName="isTemporary"
        name="consigneeName"
        subName="temporaryConsignee"
        isShopCustomizeConsignee
        controlRules={[
          {
            required: isFieldRequiredForChangeStatus
              ? config.consigneeName.required
              : false,
            message: <ErrorInput />,
          },
        ]}
        subRules={[
          {
            validator: (
              _rule: RuleObject,
              values: StoreValue,
              callback: (error?: string) => void,
            ): Promise<void | any> | void => {
              if (
                nextStatus === EdoStatus.Release &&
                role === UserRole.Counter &&
                currentStatus === EdoStatus.ReadyForRelease
              ) {
                callback("");
              } else {
                callback();
              }
            },
            message: (
              <ErrorInput message="Cannot Release eDO with Temporary Consignee" />
            ),
          },
          {
            required:
              isFieldRequiredForChangeStatus &&
              !(
                role === UserRole.Counter &&
                currentStatus === EdoStatus.ReadyForRelease
              )
                ? config.consigneeName.required
                : false,
            message: <ErrorInput />,
          },
        ]}
        rules={[
          {
            required: isFieldRequiredForChangeStatus
              ? config.consigneeName.required
              : false,
            message: <ErrorInput />,
          },
        ]}
        label="Consignee"
        isConsigneeTemporary={isConsigneeTemporary}
        callBackAddNewConsignee={callBackAddNewConsignee}
      />
    </Col>
    <Col span={12}>
      <AsyncSelect
        virtual
        showSearch
        allowCreateNewOne
        hideAddNewEmpty={[
          UserRole.Counter,
          UserRole.Admin,
          UserRole.ITOfficer,
        ].includes(role)}
        disabled={config?.receivingParty.disabled}
        name="receivingParty"
        label="Receiving Party"
        filterOption={false}
        placeholder="Enter Receiving party"
        optionKey={{ label: "customerName", value: "id" }}
        fetchOptions={useGetCustomerList}
        params={{ Status: true, SearchForNameOnly: true, IsGetAll: true }}
        rules={[
          {
            required: isFieldRequiredForChangeStatus
              ? config.receivingParty.required
              : false,
            message: <ErrorInput />,
          },
        ]}
        isLogHistory
      />
    </Col>
    <Col span={12}>
      <DepotSelect
        rules={[
          {
            required: isFieldRequiredForChangeStatus
              ? config.podName.required
              : false,
            message: <ErrorInput />,
          },
        ]}
        isLogHistory
        disabled={config?.podName.disabled}
        name="podName"
        label="POD"
        placeholder="Select POD"
        filterDepots={(depot) => depot.status}
      />
    </Col>
    <Col span={24}>
      <DepotSelect
        rules={[
          {
            required: isFieldRequiredForChangeStatus
              ? config.fPodName.required
              : false,
            message: <ErrorInput />,
          },
        ]}
        isLogHistory
        disabled={config?.fPodName.disabled}
        name="fPodName"
        label="FPOD"
        placeholder="Select FPOD"
        filterDepots={(depot) => depot.status}
      />
    </Col>
  </Row>
);
