import { QueryClient, QueryClientProvider } from "react-query";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { unSave } from "libs/storage";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "router";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

export type AppProps = {
  instance: PublicClientApplication;
};

export const App = ({ instance }: AppProps) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Prevent the default behavior (page refresh)
      unSave(LOCAL_STORAGE_KEYS.COUNTRY);
      unSave(LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN);
      unSave(LOCAL_STORAGE_KEYS.USER_ROLE);
    };

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <MsalProvider instance={instance}>
        <RouterProvider router={router} />
      </MsalProvider>
    </QueryClientProvider>
  );
};
