import {
  CustomerFilters,
  CustomerFormFilters,
  CustomerStatus,
} from "./interfaces";

import { DEFAULT_PERPAGE } from "constants/constants";

export const INITIAL_FORM_VALUES: CustomerFormFilters = {
  Keyword: undefined,
  Status: "",
  Dates: null,
};

export const INITIAL_FILTERS: CustomerFilters = {
  PageNum: 1,
  PageSize: Number(DEFAULT_PERPAGE),
  countryName: ""
};

export const customerStatusOptions = [
  { label: "All Status", value: "" },
  { label: CustomerStatus.Active, value: CustomerStatus.Active },
  { label: CustomerStatus.Inactive, value: CustomerStatus.Inactive },
];
