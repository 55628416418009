import { Alert, notification } from "antd";

import { ArgsProps } from "antd/es/notification/interface";

export const useNotification = () => {
  const handleOpenSuccessNotification = (argsProps: ArgsProps) => {
    const {
      message,
      type = "success",
      duration = 3, // 3 seconds
      ...restProps
    } = argsProps;

    notification.open({
      message: null,
      description: <Alert message={message} type={type} showIcon />,
      placement: "bottomRight",
      className: "custom-alert !p-0",
      duration,
      style: {
        width: 600,
      },
      ...restProps,
    });
  };
  const handleOpenFailNotification = (argsProps: ArgsProps) => {
    const {
      message,
      type = "error",
      duration = 3, // 3 seconds
      ...restProps
    } = argsProps;

    notification.open({
      message: null,
      description: <Alert message={message} type={type} showIcon />,
      placement: "bottomRight",
      className: "custom-alert !p-0",
      duration,
      style: {
        width: 600,
      },
      ...restProps,
    });
  };

  return {
    handleOpenSuccessNotification,
    handleOpenFailNotification,
  };
};
