import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import { ActionDetail } from "pages/eod-management/components/ModalTransactionHistory/interfaces";
import { FiltersEdo } from "pages/eod-management";
import { TreeDataType } from "pages/eod-management/interfaces";

type EditBlType = "Single" | "Multiple";

export type EdoContextType = {
  doId?: string;
  uploadFileType?: string;
  uploadFileName?: string | string[];
  isOpenModalUpdateStatus?: boolean;
  isOpenModalCreateCustomer?: boolean;
  currentBLChoosing?: TreeDataType;
  editBlWithCounterRole: {
    isOpen: boolean;
    type: EditBlType | null;
    bl: TreeDataType | null;
    canEdit: boolean | null;
    timestamp: string | null;
  };
  isOpenDrawerEdoDetail: boolean;
  editBlWithLogisticRole: {
    isOpen: boolean;
    type: EditBlType | null;
    bl: TreeDataType | null;
    canEdit: boolean | null;
    timestamp: string | null;
  };
  timestamp?: string;
  bl: TreeDataType | null;
  isOpenHistoryTransaction: {
    isOpen: boolean;
    edoRowData: string | null;
  };
  isOpenHistoryTransactionEmailDetail: {
    isOpen: boolean;
    emailDataOfEdoRow?: ActionDetail | null;
  };
  customConsignee: string | null;
  isOpenModalAddCustomConsingee?: boolean;
  edoFilter: FiltersEdo;
  isSelectAll: boolean;
  listExceptEdoId: string[];
  uploadFile: {
    quantity: number;
    isReading: boolean;
  };
  isLoadingBulkUpdate: boolean;
  setEdo: Dispatch<React.SetStateAction<EdoContextType>>;
};

export type EdoContextProviderProps = {
  children: ReactNode;
};

export const INITIAL_EDO_CONTEXT: EdoContextType = {
  bl: null,
  isOpenDrawerEdoDetail: false,
  isOpenModalUpdateStatus: false,
  isOpenModalCreateCustomer: false,
  editBlWithLogisticRole: {
    isOpen: false,
    type: null,
    bl: null,
    canEdit: true,
    timestamp: null,
  },
  editBlWithCounterRole: {
    isOpen: false,
    type: null,
    bl: null,
    canEdit: true,
    timestamp: null,
  },
  isOpenHistoryTransaction: {
    isOpen: false,
    edoRowData: null,
  },
  isOpenHistoryTransactionEmailDetail: {
    isOpen: false,
    emailDataOfEdoRow: null,
  },
  customConsignee: null,
  isOpenModalAddCustomConsingee: false,
  edoFilter: {
    fromBLETADate: null,
    toBLETADate: null,
    fromUpdateDate: null,
    toUpdateDate: null,
    fromPickupDate: null,
    toPickupDate: null,
    fromReturnDate: null,
    toReturnDate: null,
    listStatus: [],
    listContainerSize: [],
    isDG: null,
    listSOCIndicator: [],
    isPL: null,
    countryName: "",
  },
  isSelectAll: false,
  listExceptEdoId: [],
  uploadFile: {
    quantity: 0,
    isReading: true,
  },
  isLoadingBulkUpdate: false,
  setEdo: () => {},
};

// create edo context
export const EdoContext = createContext<EdoContextType>(INITIAL_EDO_CONTEXT);

// edo hook context
export const useEdoContext = () => useContext(EdoContext);

// edo context provider
export const EdoContextProvider = ({ children }: EdoContextProviderProps) => {
  const [edo, setEdo] = useState<EdoContextType>(INITIAL_EDO_CONTEXT);

  const contextValue: EdoContextType = useMemo(
    () => ({ ...edo, setEdo }),
    [edo]
  );

  return (
    <EdoContext.Provider value={contextValue}>{children}</EdoContext.Provider>
  );
};
