import { GetTempEdiFile } from "./queries";
import { IFetchApi } from "libs/fetchAPI";
import { omitEmptyParams } from "libs/helper";

export type GetUpdateListParams = {
  PageNum: number;
  PageSize: number;
  EdoId: string;
  BucketName: string;
  isTrigger?:boolean
};

export type GetReleaseListParams = {
  PageNum?: number;
  PageSize?: number;
};

export const historyServices = {
  getUpdateList: (params?: GetUpdateListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `histories/update?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getReleaseList: (params?: GetReleaseListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `histories/release?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getEdiFile: (params: GetTempEdiFile): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `edos-extraction/download-file-s3?${searchParams}`,
      method: "get",
      isAuth: true,
      isDownload: true,
    };
  },
};
