import { Col, Form, Modal, ModalProps, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useCreateAccount, useUpdateAccount } from "../queries";

import { ModalConfirmation } from "components/ModalConfirmation";
import { CustomButtom } from "components/button/CustomButton";
import { FormCheckbox } from "components/form/Checkbox";
import { FormInput } from "components/form/Input";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { useNotification } from "hooks/use-notification";
import { ccgidRegex, emailValidator } from "libs/helper";
import { get } from "lodash";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { ICustomer } from "pages/customer-management/interfaces";
import { AsyncSelect } from "pages/eod-management/components/DrawerEdoDetail/AsyncSelect";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { useCompanySearch } from "pages/eod-management/queries";
import { Account } from "../interfaces";

type ModalAccountProps = ModalProps & {
  isEdit?: boolean;
  isCustomerManagement?: boolean;
  account?: Account | null;
  onCancel?: () => void;
  onCreateCustomerSuccess?: (customer: ICustomer) => void;
  refreshList: () => void;
};

type AccountForm = {
  ccgid: string;
  companyName: string | null;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
};

const INITIAL_FORM_VALUES: AccountForm = {
  ccgid: "",
  companyName: null,
  email: "",
  firstName: "",
  lastName: "",
  isActive: true,
};

export const ModalAccount = ({
  isEdit,
  account,
  onCancel,
  isCustomerManagement = true,
  onCreateCustomerSuccess,
  refreshList,
  ...restProps
}: ModalAccountProps) => {
  const [form] = Form.useForm<AccountForm>();
  const [isShowModalConfirmDelete, setIsShowModalConfirmEdit] =
    useState<boolean>(false);

  const { mutate: createAccount, isLoading: isCreatingCustomer } =
    useCreateAccount();

  const { mutate: updateAccount, isLoading: isUpdatingCustomer } =
    useUpdateAccount();

  const ccgid = Form.useWatch("ccgid", form);

  const { handleOpenSuccessNotification, handleOpenFailNotification } =
    useNotification();

  const currentCountry = useCountry();
  const ccgidError = form.getFieldError("ccgid");
  const ccgidErrorMessage =
    ccgidError.length < 1 || !ccgid ? undefined : (
      <ErrorInput message={ccgidError[0]} />
    );

  const handleCancelModal = () => {
    onCancel && onCancel();
    if (!isEdit && !isCreatingCustomer) {
      form.resetFields();
    }

    if (isEdit && account && account.ccgid !== "" && !isUpdatingCustomer) {
      form.setFieldsValue(account);
      form.setFieldValue("companyName", account.companyCode);
    }
  };

  const handleApiError = (error: any) => {
    const msgCode = get(error, "response.data.Message");
    if (msgCode === ERROR_MESSAGE_CODE.MSG_03) {
      form.setFields([
        {
          name: "ccgid",
          errors: ["CCGID existed"],
        },
      ]);
    } else if (msgCode === ERROR_MESSAGE_CODE.MSG_09) {
      form.setFields([
        {
          name: "email",
          errors: ["Email existed"],
        },
      ]);
    } else {
      handleOpenFailNotification({
        message: "Something went wrong, Please try again!",
      });
    }
  };

  const handleSubmitForm = (values: AccountForm) => {
    if (!isEdit) {
      createAccount(
        {
          ...values,
          ccgid: values.ccgid.trim(),
          companyName: values.companyName as string,
          countryName: currentCountry,
        },
        {
          onSuccess: () => {
            refreshList();
            form.resetFields();
            handleCancelModal();
            handleOpenSuccessNotification({
              message: "Add new account successfully!",
            });
          },
          onError: (error) => {
            handleApiError(error);
          },
        }
      );
    } else {
      setIsShowModalConfirmEdit(true);
    }
  };

  const handleConfirmEdit = () => {
    const values = form.getFieldsValue();
    setIsShowModalConfirmEdit(false);
    updateAccount(
      {
        ...values,
        ccgid: values.ccgid.trim(),
        companyName: values.companyName as string,
        countryName: currentCountry,
      },
      {
        onSuccess: () => {
          refreshList();
          handleCancelModal();
          handleOpenSuccessNotification({
            message: "Account information has been updated successfully!",
          });
        },
        onError: (error: any) => {
          handleApiError(error);
        },
      }
    );
  };

  useEffect(() => {
    if (isEdit && account && account.ccgid !== "") {
      form.setFieldsValue(account);
      form.setFieldValue("companyName", account.companyCode);
    }
  }, [account, form, isEdit]);


  return (
    <Modal
      closable={false}
      footer={false}
      className="w-[512px] h-[414px]"
      onCancel={handleCancelModal}
      {...restProps}
    >
      <div className="custom-confirmation-modal-customer-management ">
        <Typography.Text className="!block text-blue-sapphire text-xl not-italic font-antonio font-bold tracking-[-1px] m-0">
          {!isEdit ? "Create New Account" : "Edit Account"}
        </Typography.Text>
        <Typography.Text className="!block text-blue-rock-blue font-roboto text-sm not-italic font-normal mt-2">
          Enter Accounts Information
        </Typography.Text>
        <div className="mt-5">
          <Form
            form={form}
            initialValues={INITIAL_FORM_VALUES}
            onFinish={handleSubmitForm}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            disabled={account?.isExecutor}
          >
            <Row className="pb-6" gutter={[16, 0]}>
              <Col span={24}>
                <FormInput
                  label="CCGID"
                  name="ccgid"
                  disabled={isEdit}
                  help={ccgidErrorMessage}
                  placeholder="Enter CCGID"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                    {
                      min: 1,
                      max: 10,
                      message: <ErrorInput message="Invalid input length" />,
                    },
                    {
                      validator: ccgidRegex,
                      message: (
                        <ErrorInput message="Ccgid not allow special character" />
                      ),
                    },
                  ]}
                  onFocus={() =>
                    form.setFields([{ name: "ccgid", errors: [] }])
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  label="First Name"
                  name="firstName"
                  placeholder="Enter First Name"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <FormInput
                  label="Last Name"
                  name="lastName"
                  placeholder="Enter Last Name"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                    {
                      validator: emailValidator,
                      message: <ErrorInput message="Invalid email format" />,
                    },
                  ]}
                  onFocus={() =>
                    form.setFields([{ name: "email", errors: [] }])
                  }
                />
              </Col>
              <Col span={24}>
                <AsyncSelect
                  virtual
                  showSearch
                  name={`companyName`}
                  label="Company"
                  filterOption={false}
                  placeholder="Search For Company"
                  optionKey={{ label: "value", value: "key" }}
                  customFormItemClassName="!mb-1"
                  fetchOptions={useCompanySearch}
                  isTemporary={false}
                  triggerFetchApiManually
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <FormCheckbox
                  name="isActive"
                  disabled={!isCustomerManagement}
                  checkboxLabel="Active"
                  customFormItemClassName="mb-0"
                  valuePropName="checked"
                />
              </Col>
            </Row>

            <div className="grid grid-cols-12 gap-x-4">
              <CustomButtom
                title="Cancel"
                disabled={
                  account?.isExecutor ||
                  isCreatingCustomer ||
                  isUpdatingCustomer
                }
                variant="outline"
                customSize="medium"
                customColor="secondary"
                customClassName="col-span-6"
                onClick={handleCancelModal}
              />
              <CustomButtom
                title="Save"
                disabled={
                  account?.isExecutor ||
                  isCreatingCustomer ||
                  isUpdatingCustomer
                }
                loading={isCreatingCustomer || isUpdatingCustomer}
                customSize="medium"
                customColor="secondary"
                customClassName="col-span-6"
                htmlType="submit"
              />
            </div>
          </Form>
        </div>
      </div>
      <ModalConfirmation
        open={isShowModalConfirmDelete}
        okText="Confirm"
        onCancel={() => setIsShowModalConfirmEdit(false)}
        onOk={handleConfirmEdit}
        cancelText={"Cancel"}
        title="Save changes"
        description="Do you want to save this Account config? This action cannot be undone."
      />
    </Modal>
  );
};
