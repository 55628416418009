import {
  ConfigProvider,
  Modal,
  ModalProps,
  Pagination,
  Table,
  Typography,
} from "antd";
import {
  DEFAULT_PERPAGE_TRANSATION_HISTORY,
  UPDATE_DATE_FORMAT,
} from "constants/constants";
import { useEffect, useMemo, useState } from "react";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import { ColumnsType } from "antd/es/table";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { CustomerDepotHistoryUpdate } from "pages/eod-management/components/ModalTransactionHistory/interfaces";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { dateSorter } from "libs/helper";
import dayjs from "dayjs";
import { get } from "lodash";
import { useDepotHistory } from "../queries";

type ModalCustomerHistory = ModalProps & {
  isOpen: boolean;
  onClose: () => void;
  recordId: string;
};

export const ModalDepotHistory = ({
  isOpen,
  onClose,
  recordId,
}: ModalCustomerHistory) => {
  const defaultTableColumns = (): ColumnsType<CustomerDepotHistoryUpdate> => [
    {
      title: <TableHeaderTitle title="Updated Time" />,
      dataIndex: "modifiedDate",
      ellipsis: true,
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => dateSorter(a.modifiedDate, b.modifiedDate),
      render: (item) => (
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {dayjs(item).format(UPDATE_DATE_FORMAT)}
        </Typography.Text>
      ),
      width: "16%",
    },
    {
      title: <TableHeaderTitle title="Executor" />,
      dataIndex: "executorName",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.executorName?.localeCompare(b.executorName),
      render: (item) => (
        <CustomTooltip copytext={item} isShowCopy>
          <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
            {item}
          </Typography.Text>
        </CustomTooltip>
      ),
      width: "14%",
    },
    {
      title: <TableHeaderTitle title="Action Type" />,
      dataIndex: "actionType",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.actionType?.localeCompare(b.actionType),
      render: (item) => {
        return (
          <CustomTooltip copytext={item} isShowCopy>
            <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
              {item}
            </Typography.Text>
          </CustomTooltip>
        );
      },
    },
    {
      title: <TableHeaderTitle title="Field Change" />,
      dataIndex: "fieldChange",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.fieldChange?.localeCompare(b.fieldChange),
      render: (item) => (
        <CustomTooltip copytext={item} isShowCopy>
          <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
            {item}
          </Typography.Text>
        </CustomTooltip>
      ),
    },
    {
      title: <TableHeaderTitle title="Old Value" />,
      dataIndex: "oldValue",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.oldValue?.localeCompare(b.oldValue),
      render: (
        _value: any,
        record: CustomerDepotHistoryUpdate,
        _index: number
      ) => {
        return (
          <CustomTooltip placement="top" copytext={record.oldValue} isShowCopy>
            <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
              {record.oldValue}
            </Typography.Text>
          </CustomTooltip>
        );
      },
      ellipsis: true,
    },
    {
      title: <TableHeaderTitle title="New Value" />,
      dataIndex: "newValue",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      ellipsis: true,
      sorter: (a, b) => a.newValue?.localeCompare(b.newValue),
      render: (
        _value: any,
        record: CustomerDepotHistoryUpdate,
        _index: number
      ) => {
        return (
          <CustomTooltip placement="top" copytext={record.newValue} isShowCopy>
            <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
              {record.newValue}
            </Typography.Text>
          </CustomTooltip>
        );
      },
    },
  ];
  const [PageNum, setPageNum] = useState(1);

  useEffect(() => {
    setPageNum(1); // reset to page 1
  }, [isOpen]);

  const { data, isFetching: isTableLoading } = useDepotHistory({
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE_TRANSATION_HISTORY),
    isTrigger: isOpen,
    enabled: Boolean(recordId),
    id: recordId,
  });
  const pagination = get(data, "data");
  const dataSource = get(pagination, "data", []);

  const handelCloseModal = () => {
    onClose();
  };
  const tableColumns = useMemo(() => defaultTableColumns(), []);

  return (
    <>
      <Modal
        closable={false}
        className="!w-[1110px]"
        open={isOpen}
        onOk={() => {}}
        onCancel={handelCloseModal}
        footer={false}
        centered
      >
        <div className="mb-6">
          <Typography.Text className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block mb-2">
            Update History
          </Typography.Text>
          <Typography.Text className="text-sm leading-5 font-normal font-Roboto text-blue-rock-blue">
            View Update History
          </Typography.Text>
        </div>
        <div className="custom-table-customer-management [&_.customClass]:!min-h-[170px] [&_.ant-table-wrapper]:!border-solid [&_.ant-table-wrapper]:!border [&_.ant-table-wrapper]:!border-[#D9DCE8]">
          <ConfigProvider renderEmpty={EmptyTable}>
            <Table
              pagination={false}
              loading={isTableLoading}
              columns={tableColumns}
              rowClassName="font-roboto text-sm font-normal"
              dataSource={dataSource || []}
            />
          </ConfigProvider>
        </div>
        {dataSource.length >= 1 && (
          <div className="p-6 flex justify-between items-center border-solid border border-blue-hawkes-blue border-t-0">
            <div>
              <Typography.Text className="font-roboto text-sm not-italic font-normal leading-4 text-blue-waikawa-grey">
                Results:&nbsp;
              </Typography.Text>
              <Typography.Text className="text-blue-midnight-express text-sm not-italic font-normal left-5">
                {pagination?.totalRecords}
              </Typography.Text>
            </div>
            <div className="custom-pagination-customer-management">
              <Pagination
                nextIcon={<img alt="pagination-next" src={AngleRight} />}
                prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
                pageSize={pagination?.pageSize}
                current={PageNum}
                onChange={setPageNum}
                total={pagination?.totalRecords}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
