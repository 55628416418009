import { convertUTCToBaseDate, dateSorter } from "libs/helper";

import { ColumnsType } from "antd/es/table";
import { CustomSortIcon } from "components/CustomSortIcon";
import { Discharge } from "./interfaces";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { Typography } from "antd";
import { useMemo } from "react";

export const useTableColumns = () => {
  const columns: ColumnsType<Discharge> = useMemo(
    () => [
      {
        title: <TableHeaderTitle title="BL No." />,
        ellipsis: true,
        sortIcon: CustomSortIcon,
        sorter: (a, b) =>
          a.billOfLadingNumber?.localeCompare(b.billOfLadingNumber),
        render: (_value: any, record: Discharge) => (
          <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.billOfLadingNumber}
          </Typography.Text>
        ),
      },
      {
        title: <TableHeaderTitle title="Container No." />,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.containerNumber?.localeCompare(b.containerNumber),
        render: (_value: any, record: Discharge) => (
          <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.containerNumber}
          </Typography.Text>
        ),
      },
      {
        title: <TableHeaderTitle title="Container Size/Type" />,
        ellipsis: true,
        sortIcon: CustomSortIcon,
        sorter: (a, b) =>
          a.containerSizeType?.localeCompare(b.containerSizeType),
        render: (_value: any, record: Discharge) => (
          <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.containerSizeType}
          </Typography.Text>
        ),
      },
      {
        title: <TableHeaderTitle title="Depot Name" />,
        ellipsis: true,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.depotName?.localeCompare(b.depotName),
        render: (_value: any, record: Discharge) => (
          <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.depotName}
          </Typography.Text>
        ),
      },
      {
        title: (
          <TableHeaderTitle
            title="Updated Date"
            customClassName="flex justify-center"
          />
        ),
        sorter: (a, b) =>
          dateSorter(
            convertUTCToBaseDate(a.updatedDate, false),
            convertUTCToBaseDate(b.updatedDate, false)
          ),
        render: (_value: any, record: Discharge) => (
          <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-4">
            {convertUTCToBaseDate(record.updatedDate, false)?.format(
              SYSTEM_DATE_FORMAT
            )}
          </Typography.Text>
        ),
      },
      {
        title: <TableHeaderTitle title="Updated By" />,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.updatedBy?.localeCompare(b.updatedBy),
        render: (_value: any, record: Discharge) => (
          <Typography.Text className="truncate !block font-roboto font-normal text-sm text-violet-revolver leading-4">
            {record.updatedBy}
          </Typography.Text>
        ),
      },
    ],
    []
  );

  return columns;
};
