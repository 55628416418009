import { ConfigProvider, Table } from "antd";

import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { useMainTableColumn } from "../hooks/use-main-table-column";

interface ITableSkeleton {
    isTableLoadingOnUI: boolean
  }

export const TableFirstTimeSkeletonUI = ( {isTableLoadingOnUI}: ITableSkeleton) => {
  const mainColumns = useMainTableColumn();
  return (
    <ConfigProvider renderEmpty={EmptyTable}>
      <Table
        pagination={false}
        columns={mainColumns}
        dataSource={[]}
        loading={isTableLoadingOnUI}
      />
    </ConfigProvider>
  );
};
