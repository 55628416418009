import {
  GetDischargeFilterDataOptions,
  SelectALlDischargePayload,
} from "../queries/types";

import { ERROR_ROLLBACK_TRANSACTION } from "constants/constants";
import { GetDischargeListParams } from "./types";
import { IFetchApi } from "libs/fetchAPI";
import { UploadFileTypeDischarge } from "pages/eod-management/interfaces";

export const dischargeServices = {
  getDischargeList: (params?: GetDischargeListParams): IFetchApi => {
    return {
      url: "discharges",
      method: "post",
      isAuth: true,
      payload: params,
    };
  },
  getDischargeDetail: (dischargeId: string): IFetchApi => {
    return {
      url: `discharges/${dischargeId}`,
      method: "get",
      isAuth: true,
    };
  },
  uploadDischargeList: (payload: UploadFileTypeDischarge): IFetchApi => {
    return {
      url: `discharges/import`,
      method: "post",
      isAuth: true,
      payload: payload.file,
      timeout: ERROR_ROLLBACK_TRANSACTION.MAX_TIMEOUT,
      cancelToken: payload.cancelToken,
    };
  },
  exportDischargeTemplate: (): IFetchApi => {
    return {
      url: `templates/excel-discharge`,
      method: "get",
      isAuth: true,
      isDownload: true,
    };
  },
  preReadFileDischargeBeforeAction: (payload: {
    file: FormData;
  }): IFetchApi => {
    return {
      url: `discharges/validate-file-import`,
      method: "post",
      isAuth: true,
      payload: payload.file,
    };
  },
  getDischargeFilterData: (countryName: string): IFetchApi => {
    return {
      url: `discharges/filter?countryName=${countryName}`,
      method: "get",
      isAuth: true,
    };
  },
  postToGetDischargesDetail: (
    options: GetDischargeFilterDataOptions
  ): IFetchApi => {
    return {
      url: "discharges/get-detail-bulk-update",
      method: "post",
      isAuth: true,
      payload: options.payload,
    };
  },
  checkDischargeBulkUpdate: (payload: SelectALlDischargePayload): IFetchApi => {
    return {
      url: "discharges/check-bulk-update",
      method: "post",
      isAuth: true,
      payload,
    };
  },
  dischargeBulkUpdate: (payload: SelectALlDischargePayload): IFetchApi => {
    return {
      url: "discharges/bulk-update",
      method: "put",
      isAuth: true,
      payload,
    };
  },
  deleteDischargeByBulkUpdate: (
    payload: SelectALlDischargePayload
  ): IFetchApi => {
    return {
      url: "discharges/bulk-delete",
      method: "delete",
      isAuth: true,
      payload,
    };
  },
};
