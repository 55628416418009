import { Modal, ModalProps, Typography } from "antd";

import { CustomButtom } from "components/button/CustomButton";
import { ReactNode } from "react";

export type ModalConfirmationProps = ModalProps & {
  title: string;
  description?: string;
  subDescription?: string;
  isLoadingBtn?: boolean;
  isCancelOnly?: boolean;
  customImage?: ReactNode;
  customClassName?: string;
  isDisableButton?: boolean;
};

export const ModalConfirmation = ({
  onOk,
  title,
  okText,
  onCancel,
  cancelText,
  description,
  subDescription,
  customImage,
  isLoadingBtn = false,
  isCancelOnly = false,
  isDisableButton = false,
  customClassName,
  ...restProps
}: ModalConfirmationProps) => {
  return (
    <Modal
      centered
      maskClosable={!isDisableButton || false} //if it don't have disable button, maskClosable is false
      width={400}
      closable={false}
      onCancel={onCancel}
      footer={false}
      className="custom-modal-style"
      {...restProps}
    >
      <div className="flex justify-center items-center flex-col">
        {customImage}
        <Typography.Text className="text-blue-sapphire text-xl font-antonio font-bold tracking-[-0.1px] leading-6 m-0 pt-5">
          {title}
        </Typography.Text>
        {description && (
          <Typography.Text className="mt-2 text-blue-waikawa-grey text-sm font-normal leading-5 flex text-center px-5">
            {description}
          </Typography.Text>
        )}
        {subDescription && (
          <Typography.Text className="text-blue-waikawa-grey text-sm font-normal leading-5 flex text-center px-5">
            {subDescription}
          </Typography.Text>
        )}

        <div className="flex justify-between w-full mt-8">
          {!isCancelOnly && (
            <CustomButtom
              title={cancelText}
              disabled={isDisableButton || isLoadingBtn}
              variant="outline"
              customSize="medium"
              customColor="secondary"
              customClassName={`${customClassName} w-42`}
              onClick={onCancel}
            />
          )}
          <CustomButtom
            disabled={isDisableButton}
            loading={isLoadingBtn}
            title={okText}
            customSize="medium"
            customColor="secondary"
            customClassName={`${customClassName} w-42 flex items-center justify-center [&_.anticon.anticon-loading.anticon-spin]:text-white`}
            onClick={onOk}
          />
        </div>
      </div>
    </Modal>
  );
};
