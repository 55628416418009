import { Image, Menu, Typography } from "antd";
import { SIDE_BAR_KEYS, SIDE_BAR_MENU } from "../constants";
import { useEffect, useMemo, useState } from "react";

import { ExpandIcon } from "./ExpandIcon";
import ModalUpdateAllEdo from "pages/eod-management/components/ModalUpdateAllEdo";
import SidebarCollapse from "assets/icons/SidebarCollapse.svg";
import SidebarCollapseActive from "assets/icons/SidebarCollapseActive.svg";
import { SidebarItem } from "./SidebarItem";
import { SidebarItemType } from "layouts/types";
import { UserRole } from "interfaces/common-model";
import { useLocation } from "react-router-dom";
import {
  RoleListType,
  useUserRole,
} from "pages/eod-management/components/SelectRole";
import { get } from "lodash";
import { customerQueryKeys } from "layouts/queries";
import { queryClient } from "App";
import { useCountry } from "pages/eod-management/components/SelectCountry";

type SelectInfo = {
  key: string;
};

type SidebarProps = {
  isCollapseSidebar: boolean;
  setIsCollapseSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Sidebar = ({
  isCollapseSidebar,
  setIsCollapseSidebar,
}: SidebarProps) => {
  const { pathname } = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>(
    SIDE_BAR_KEYS.ACCOUNTS
  );

  const queryRoleList: RoleListType[] | any = get(
    queryClient.getQueryData(customerQueryKeys.GET_USER_ROLE),
    "data.roles"
  );
  const currentCountry = useCountry();

  const RoleOptions = useMemo(() => {
    const listOps = queryRoleList
      ? queryRoleList
          .filter(
            (item: RoleListType) =>
              ![UserRole.Admin, UserRole.ITOfficer].includes(item.roleEnum) &&
              item.countryName === currentCountry
          )
          .map((role: RoleListType) => ({
            label: role.roleName,
            value: role.roleEnum,
          }))
      : [];

    return listOps;
  }, [queryRoleList, currentCountry]);

  const roleLocal = useUserRole();
  const roleLocalAdmin = useUserRole(true);

  const handleSelectItem = (selectInfo: SelectInfo) => {
    setSelectedKey(selectInfo.key);
  };

  useEffect(() => {
    const currentPath = pathname
      .split("/")
      .filter((i) => i !== "" && i !== SIDE_BAR_KEYS.DASHBOARD);

    // update 'selectedKey' corresponding with path name
    if (currentPath.length && currentPath?.[0] !== selectedKey) {
      setSelectedKey(currentPath[0]);
    }
  }, [pathname, selectedKey]);

  function filterSidebarItemsByRole(
    sidebarItems: SidebarItemType[],
    role: UserRole
  ): SidebarItemType[] {
    return role || role === 0 //value as 0
      ? sidebarItems.filter((item) => item.role.includes(role))
      : [];
  }

  const renderSideBar = useMemo(() => {
    let cloneSideBarMenu = [...SIDE_BAR_MENU];

    return (
      RoleOptions.length === 0
        ? filterSidebarItemsByRole(cloneSideBarMenu, roleLocalAdmin)
        : filterSidebarItemsByRole(cloneSideBarMenu, roleLocal)
    ).map((item) => (
      <SidebarItem {...item} isSelected={selectedKey === item.key} />
    ));
  }, [selectedKey, roleLocal, roleLocalAdmin, RoleOptions]);

  return (
    <>
      <div
        className={`h-full bg-white border-r border-b border-b-blue-solitude border-solid border-r-blue-solitude absolute top-0 left-0 transition-all duration-300  
      ease-out ${isCollapseSidebar ? "w-[4.3rem]" : "w-60"}`}
      >
        <div
          className={`flex items-center justify-between px-6 h-12 pt-4 pb-3 mb-2 ${
            isCollapseSidebar ? "mr-2" : ""
          }`}
        >
          <Typography.Text
            className={`font-roboto font-normal text-sm text-blue-waikawa-grey leading-5 transition-all duration-300  
      ease-out ${!isCollapseSidebar ? "ml-0" : "-ml-[10rem]"}`}
          >
            Menu
          </Typography.Text>
          <Image
            preview={false}
            className="cursor-pointer"
            alt="side-bar-collapse-icon"
            src={isCollapseSidebar ? SidebarCollapseActive : SidebarCollapse}
            onClick={() => setIsCollapseSidebar((prev) => !prev)}
          />
        </div>
        <Menu
          mode="inline"
          defaultOpenKeys={[SIDE_BAR_KEYS.MANAGEMENT, SIDE_BAR_KEYS.ACCOUNTS]}
          onSelect={handleSelectItem}
          className="!border-none"
          expandIcon={(props) => <ExpandIcon {...props} />}
        >
          {renderSideBar}
        </Menu>
      </div>
      <ModalUpdateAllEdo />
    </>
  );
};
