export const ENDPOINTS = {
  AUTH: {
    LOGIN: "/auth/login",
    REFRESH: "/auth/refresh",
    SSO: "/auth/sso",
  },
};

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  EXPIRED_TIME: "expired_time",
  TOKEN_TYPE: "token_type",
  LOG_OUT: "log_out",
  USER_ROLE: "user_role",
  USER_ROLE_TEMP_ADMIN: "temp_user_role",
  VERSION_LOGGER: "version_logger",
  PING_INFO: "ping_info",
  LOG_TYPE: "7cf298af-6a53-4f3c-82ea-9e14f4b378f3",
  COUNTRY: "country",
};
