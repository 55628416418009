import { logout, save } from "libs/storage";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { CustomButtom } from "components/button/CustomButton";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { Typography } from "antd";
import { UserRole } from "interfaces/common-model";
import { useEffect } from "react";
import { useGetUserRole } from "layouts/queries";
import { useNavigate } from "react-router-dom";

export const NoPermission = () => {
  const {
    data: userRole,
    isLoading: isLoadingGetRole,
    isSuccess,
  } = useGetUserRole(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingGetRole && isSuccess) {
      if (userRole?.data?.roles?.length === 0) {
        return;
      }
      const roleEnum = userRole?.data?.roles[0]?.roleEnum; //for now each user have only one role
      if (roleEnum === UserRole.ImportCS) {
        save(LOCAL_STORAGE_KEYS.USER_ROLE, roleEnum);
        navigate("/edo");
      } else if (roleEnum) {
        save(LOCAL_STORAGE_KEYS.USER_ROLE, roleEnum);
        navigate("/edo");
      }
    }
  }, [isLoadingGetRole]);

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleLogOut = () => {
    if (isAuthenticated) {
      //of Azure
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      navigate("/");
      logout();
    }
  };

  return (
    <div className="h-full flex flex-col items-center justify-start pt-16">
      <Typography.Text className="mb-3 font-roboto font-normal text-xl text-blue-waikawa-grey leading-6">
        You don't have permission to view this site
      </Typography.Text>
      <CustomButtom
        title={"Log out"}
        variant="outline"
        customSize="medium"
        customColor="secondary"
        onClick={handleLogOut}
      />
    </div>
  );
};
