import { ConfigProvider, Pagination, Table, Typography } from "antd";
import {
  DEFAULT_PERPAGE_TRANSATION_HISTORY,
  SYSTEM_DATE_FORMAT,
  UPDATE_DATE_FORMAT,
} from "constants/constants";
import { useEffect, useState } from "react";
import { useGetEdiFile, useGetReleaseList } from "../queries";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import { ColumnsType } from "antd/es/table";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { HistoryRelease } from "../interfaces";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { authConfig } from "constants/configs";
import { dateSorter } from "libs/helper";
import dayjs from "dayjs";
import get from "lodash/get";
import { useEdoContext } from "contexts/edo-context";

export const TabItemRelease = () => {
  const [PageNum, setPageNum] = useState(1);
  const { isOpenHistoryTransaction } = useEdoContext();

  useEffect(() => {
    setPageNum(1); // reset to page 1
  }, [isOpenHistoryTransaction.isOpen]);

  const { data, isFetching: isTableLoading } = useGetReleaseList({
    EdoId: isOpenHistoryTransaction.edoRowData as string,
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE_TRANSATION_HISTORY),
    BucketName: authConfig.s3Bucket || "",
    isTrigger: isOpenHistoryTransaction.isOpen,
    enabled: Boolean(isOpenHistoryTransaction.edoRowData),
  });

  const tableColumns = (): ColumnsType<HistoryRelease> => [
    {
      title: <TableHeaderTitle title="Updated Time" />,
      dataIndex: "modifiedDate",
      ellipsis: true,
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => dateSorter(a.modifiedDate, b.modifiedDate),
      render: (item) => (
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {dayjs(item).format(UPDATE_DATE_FORMAT)}
        </Typography.Text>
      ),
      width: "17%",
    },
    {
      title: <TableHeaderTitle title="Executor" />,
      dataIndex: "executorName",
      ellipsis: true,
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.executorName?.localeCompare(b.executorName),
      render: (item) => (
        <CustomTooltip copytext={item} isShowCopy>
          <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
            {item}
          </Typography.Text>
        </CustomTooltip>
      ),
      width: "14%",
    },
    {
      title: <TableHeaderTitle title="Transaction Type" />,
      dataIndex: "transactionType",
      showSorterTooltip: false,
      ellipsis: true,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.transactionType?.localeCompare(b.transactionType),
      render: (item) => (
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {item}
        </Typography.Text>
      ),
      width: "17%",
    },
    {
      title: <TableHeaderTitle title="Pick up date" />,
      dataIndex: "pickUpDate",
      showSorterTooltip: false,
      ellipsis: true,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => dateSorter(a.pickUpDate, b.pickUpDate),
      render: (item) => (
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {item && dayjs(item).format(SYSTEM_DATE_FORMAT)}
        </Typography.Text>
      ),
      width: "14%",
    },
    {
      title: <TableHeaderTitle title="Return Date" />,
      dataIndex: "returnDate",
      showSorterTooltip: false,
      ellipsis: true,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => dateSorter(a.returnDate, b.returnDate),
      render: (item) => (
        //backend parse date to string to match the date view as string
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {item} 
        </Typography.Text>
      ),
      width: "14%",
    },
    {
      title: <TableHeaderTitle title={"ETA"} />,
      dataIndex: "eta",
      showSorterTooltip: false,
      ellipsis: true,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => dateSorter(a.eta, b.eta),
      render: (item) => (
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {item && dayjs(item).format(SYSTEM_DATE_FORMAT)}
        </Typography.Text>
      ),
      width: "12%",
    },
    {
      title: <TableHeaderTitle title="Data" />,
      dataIndex: "data",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      width: "20%",
      ellipsis: true,
      sorter: (a, b) => a.data?.localeCompare(b.data),
      render: (_value: any, record: HistoryRelease, _index: number) => {
        if (record.isFileReady) {
          return (
            <CustomTooltip copytext={_value} isShowCopy={false}>
              <Typography.Text
                onClick={() => handleOpenEdiUrl(record)}
                className="!block truncate cursor-pointer underLineHover font-roboto text-sm text-blue-royal-blue leading-4 font-normal"
              >
                {_value}
              </Typography.Text>
            </CustomTooltip>
          );
        } else
          return (
            <Typography.Text className="cursor-pointer font-roboto text-sm text-red-free-speech leading-4 font-normal">
              Not available to view yet
            </Typography.Text>
          );
      },
    },
  ];

  const { mutate: getEdiFile } = useGetEdiFile();

  const handleOpenEdiUrl = (record: HistoryRelease) => {
    const payload = {
      fileName: record.data,
      bucketName: authConfig.s3Bucket || "",
    };
    getEdiFile(payload, {
      onSuccess: (data: any) => {
        const blob = new Blob([data], { type: "text/plain" });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);

        // Specify the filename for the downloaded file
        downloadLink.download = `${record.data}`;

        // Append the link to the body
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Remove the link from the body
        document.body.removeChild(downloadLink);
      },
    });
  };

  const pagination = get(data, "data.paginatedResponseModel", {
    pageNum: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  });
  const dataSource = get(data, "data.paginatedResponseModel.data", []);

  return (
    <>
      <div className="custom-table-customer-management [&_.customClass]:!min-h-[170px]">
        <ConfigProvider renderEmpty={EmptyTable}>
          <Table
            pagination={false}
            loading={isTableLoading}
            columns={tableColumns()}
            rowClassName="font-roboto text-sm font-normal"
            dataSource={dataSource as HistoryRelease[]}
          />
        </ConfigProvider>
      </div>
      {dataSource.length >= 1 && (
        <div className="p-6 flex justify-between items-center border border-blue-hawkes-blue">
          <div>
            <Typography.Text className="font-roboto text-sm not-italic font-normal leading-4 text-blue-waikawa-grey">
              Results:&nbsp;
            </Typography.Text>
            <Typography.Text className="text-blue-midnight-express text-sm not-italic font-normal left-5">
              {pagination?.totalRecords}
            </Typography.Text>
          </div>
          <div className="custom-pagination-customer-management">
            <Pagination
              nextIcon={<img alt="pagination-next" src={AngleRight} />}
              prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
              pageSize={pagination?.pageSize}
              current={PageNum}
              onChange={setPageNum}
              total={pagination?.totalRecords}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </>
  );
};
