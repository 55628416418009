import { Discharge, DischargeFilterData } from "../interfaces";
import {
  FilterDataType,
  GetDischargeDetail,
  GetDischargeFilterDataOptions,
  GetDischargeListOptions,
  SelectALlDischargePayload,
  ValidationPreRead,
} from "./types";
import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";
import { useMutation, useQuery } from "react-query";

import { UploadFileTypeDischarge } from "pages/eod-management/interfaces";
import { dischargeServices } from "../services";
import { fetchApi } from "libs/fetchAPI";
import { useCountry } from "pages/eod-management/components/SelectCountry";

export const dischargeQueryKeys = {
  GET_DISCHARGE_LIST: "GET_DISCHARGE_LIST",
  GET_DISCHARGE_LIST_FILTER_DATA: "GET_DISCHARGE_LIST_FILTER_DATA",
  GET_DISCHARGE_DISCHARGES_DETAIL: "GET_DISCHARGE_DISCHARGES_DETAIL",
};

const depotQueryKeys = {
  GET_DISCHARGE_LIST_BY_SEARCH: "GET_DISCHARGE_LIST_BY_SEARCH",
};

export const useGetDischargeList = (options?: GetDischargeListOptions) => {
  return useQuery({
    queryKey: [dischargeQueryKeys.GET_DISCHARGE_LIST, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<Discharge>>>(
        dischargeServices.getDischargeList(options)
      ),
    ...options,
  });
};

export const useGetDischargeDetail = (options: GetDischargeDetail) =>
  useQuery({
    queryKey: [depotQueryKeys.GET_DISCHARGE_LIST_BY_SEARCH, options],
    queryFn: () =>
      fetchApi<IBaseResponse<Discharge>>(
        dischargeServices.getDischargeDetail(options.dischargeId)
      ),
    ...options,
  });

export const useImportDischargeList = () =>
  useMutation((payload: UploadFileTypeDischarge) =>
    fetchApi(dischargeServices.uploadDischargeList(payload))
  );

export const usePreReadDischargeFile = () =>
  useMutation((payload: { file: FormData }) =>
    fetchApi<IBaseResponse<ValidationPreRead>>(
      dischargeServices.preReadFileDischargeBeforeAction(payload)
    )
  );

export const useExportExcelDischarge = () =>
  useMutation(() => fetchApi(dischargeServices.exportDischargeTemplate()));

export const useGetFilterData = () => {
  const countryName = useCountry();
  return useQuery({
    queryKey: [dischargeQueryKeys.GET_DISCHARGE_LIST_FILTER_DATA],
    queryFn: () =>
      fetchApi<IBaseResponse<DischargeFilterData>>(
        dischargeServices.getDischargeFilterData(countryName)
      ),
  });
};

export const useGetDischargesDetail = (
  options: GetDischargeFilterDataOptions
) =>
  useQuery({
    queryKey: [
      dischargeQueryKeys.GET_DISCHARGE_DISCHARGES_DETAIL,
      options.timestamp,
    ],
    queryFn: () =>
      fetchApi<IBaseResponse<FilterDataType[]>>(
        dischargeServices.postToGetDischargesDetail(options)
      ),
    ...options,
  });

export const useDischargeBulkUpdate = () =>
  useMutation((payload: SelectALlDischargePayload) =>
    fetchApi(dischargeServices.dischargeBulkUpdate(payload))
  );

export const useCheckDischargeBulkUpdate = () =>
  useMutation((payload: SelectALlDischargePayload) =>
    fetchApi(dischargeServices.checkDischargeBulkUpdate(payload))
  );

export const useDeleteUpdateBLWithLogistic = () =>
  useMutation((payload: SelectALlDischargePayload) =>
    fetchApi(dischargeServices.deleteDischargeByBulkUpdate(payload))
  );
