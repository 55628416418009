import { Radio, RadioChangeEvent } from "antd";

import CreateEdoActive from "assets/icons/CreateEdoActive.svg";
import CreateEdoInActive from "assets/icons/CreateEdoInActive.svg";
import UpdateEdoActive from "assets/icons/UpdateEdoActive.svg";
import UpdateEdoInActive from "assets/icons/UpdateEdoInActive.svg";
import { EodAction } from "pages/eod-management/constants";

interface IProps {
  action?: EodAction;
  setAction: (action: EodAction) => void;
  isCreationEdo: boolean;
}

export const ACTIONS = [
  {
    iconInActive: UpdateEdoInActive,
    iconActive: UpdateEdoActive,

    title: "Upload Cuscar File",
    description: "Upload Cuscar file to update eDOs to the system",
    action: EodAction.uploadFile,
  },
  {
    iconInActive: CreateEdoInActive,
    iconActive: CreateEdoActive,
    title: "Enter BL No.",
    description: "Enter your preferred BL No. to get latest update from LARA",
    action: EodAction.enterBlNumb,
  },
];

export const ChooseAction = ({ action, setAction, isCreationEdo }: IProps) => {
  const onChange = (e: RadioChangeEvent) => {
    setAction(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={action} className="w-full">
      <div className="grid grid-cols-2 gap-4">
        {ACTIONS.map((item, index) => {
          const active = action === item.action;
          return (
            <label
              key={index}
              className={`flex-1 cursor-pointer border border-solid bg-blue-solitude-light rounded-lg p-4 ${
                active ? "border-blue-sapphire" : "border-transparent"
              }`}
            >
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <img
                    src={active ? item.iconActive : item.iconInActive}
                    alt=""
                  />
                  <p className="font-roboto text-sm text-blue-sapphire mb-0">
                    {item.title}
                  </p>
                </div>
                <Radio
                  value={item.action}
                  disabled={
                    item.action === EodAction.enterBlNumb && isCreationEdo
                  }
                />
              </div>
              <p className="font-roboto text-xs text-blue-waikawa-grey font-light mt-2 mb-0">
                {item.description}
              </p>
            </label>
          );
        })}
      </div>
    </Radio.Group>
  );
};
