import { Collapse, Typography } from "antd";

import ArrowDownIcon from "assets/icons/ArrowDownIcon.svg";
import ArrowUpIcon from "assets/icons/ArrowUpIcon.svg";
import CustomMutipleTagsDisplay from "components/CustomMutipleTagsDisplay";
import DangerIcon from "assets/icons/DangerIcon.svg";
import { FileType } from "./CreateUpdateEdo/components/UploadFileEod";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import SuccessIcon from "assets/icons/SuccessIcon.svg";
import UploadFileEdiIcon from "assets/icons/UploadFileEdiIcon.svg";
import UploadFileTxtIcon from "assets/icons/UploadFileTxtIcon.svg";
import XCloseIcon from "assets/icons/XCloseIcon.svg";
import { useEdoContext } from "contexts/edo-context";

export interface IModalUploadIMportEdo {
  callBackClose: () => void;
  callBackCancel: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isDuplicated: boolean;
  isLaraFail: boolean;
  isImport: boolean;
  showButtonX: boolean;
}

const ModalUploadImportEdo = ({
  callBackClose,
  callBackCancel,
  isLoading,
  isSuccess,
  isDuplicated,
  isLaraFail,
  isImport,
  showButtonX,
}: IModalUploadIMportEdo) => {
  const { uploadFileType, uploadFileName } = useEdoContext();
  return (
    <div className="absolute bottom-0 right-10 w-[307px]">
      <Collapse
        defaultActiveKey={["1"]}
        className="bg-white rounded-none rounded-t-lg shadow-popUpShadow [&_.ant-collapse-content]:border-t-0 [&_.ant-collapse-content-box]:!p-0"
        items={[
          {
            key: "1",
            label: (
              <div>
                <Typography.Text className="font-antonio font-bold text-base text-blue-sapphire leading-5 tracking-[-0.04px]">
                  {isImport ? "Importing Files" : "Updating eDOs"}
                </Typography.Text>
              </div>
            ),
            children: (
              <div className="flex flex-col">
                {isLoading && (
                  <div className="flex justify-between bg-blue-solitude-light items-center py-2 px-4">
                    <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-4">
                      {isImport
                        ? "Starting Upload Files...."
                        : "Starting Updating...."}
                    </Typography.Text>
                    <Typography.Text
                      onClick={callBackCancel}
                      className="cursor-pointer font-roboto font-normal text-sm text-blue-royal-blue leading-6"
                    >
                      Cancel
                    </Typography.Text>
                  </div>
                )}
                <div className="flex p-4 justify-between items-center">
                  <div className="flex items-center w-4/5">
                    {uploadFileType === "lara" ? (
                      <CustomMutipleTagsDisplay
                        tags={uploadFileName as string[]}
                        numberOfShowedTags={1}
                      />
                    ) : (
                      <>
                        <img
                          src={
                            uploadFileType === FileType.txt
                              ? UploadFileTxtIcon
                              : UploadFileEdiIcon
                          }
                          alt="fileType"
                        />
                        <Typography.Text className="truncate font-roboto font-normal text-base text-blue-waikawa-grey leading-[18px]">
                          {uploadFileName}
                        </Typography.Text>
                      </>
                    )}
                  </div>
                  {isLoading ? (
                    <Loading3QuartersOutlined
                      className="text-blue-sapphire h-4"
                      spin
                    />
                  ) : isSuccess ? (
                    <img src={SuccessIcon} alt="successIcon" />
                  ) : (
                    <div className="flex">
                      <Typography.Text className="mr-2 font-roboto font-normal text-xs text-blue-rock-blue leading-[14px]">
                        Failed
                      </Typography.Text>
                      <img src={DangerIcon} alt="" />
                    </div>
                  )}
                </div>
                {isDuplicated && (
                  <Typography.Text className="p-4 pt-0 mt-[-4px] font-roboto font-normal text-xs text-red-torch leading-4">
                    There are 1 or more duplicate containers in the uploaded
                    file. Please check again.
                  </Typography.Text>
                )}
                {isLaraFail && (
                  <Typography.Text className="p-4 pt-0 mt-[-4px] font-roboto font-normal text-xs text-red-torch leading-4">
                    Lara connection failed
                  </Typography.Text>
                )}
              </div>
            ),
          },
        ]}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) =>
          isActive ? (
            <div>
              <img src={ArrowDownIcon} alt="open" />
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : null}
            </div>
          ) : (
            <div>
              <img src={ArrowUpIcon} alt="open" />
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : null}
            </div>
          )
        }
      />
    </div>
  );
};

export default ModalUploadImportEdo;
