
import { ERROR_ROLLBACK_TRANSACTION } from "constants/constants";
import { IFetchApi } from "libs/fetchAPI";
import { UploadFileTypeDischarge } from "pages/eod-management/interfaces";

export const BKAVServices = {
  exportBKAVTemplate: (): IFetchApi => {
    return {
      url: `templates/excel-bkav-migration`,
      method: "get",
      isAuth: true,
      isDownload: true,
    };
  },
  preReadFileBKAVBeforeAction: (payload: {
    file: FormData;
  }): IFetchApi => {
    return {
      url: `edos-extraction/check-bkav-data`,
      method: "post",
      isAuth: true,
      payload: payload.file,
    };
  },
  uploadBKAV: (payload: UploadFileTypeDischarge): IFetchApi => { //can reuse
    return {
      url: `edos-extraction/import-bkav-data`,
      method: "post",
      isAuth: true,
      payload: payload.file,
      timeout: ERROR_ROLLBACK_TRANSACTION.MAX_TIMEOUT,
      cancelToken: payload.cancelToken,
      isDownload: true,
    };
  },
};
