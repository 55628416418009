import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import { load, logout, save } from "./storage";

import { IRefreshTokenRes } from "interfaces/authent-model";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { MutableRefObject } from "react";
import axios from "axios";
import { isPingSSO } from "hooks/use-is-ping-sso";
import { loginApiRequest } from "./auth-config";
import { msalInstance } from "index";
import { Alert, notification } from "antd";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { getTimezoneOffset } from "./helper";

export interface IFetchApi {
  url: string;
  method: "get" | "delete" | "post" | "put" | "patch";
  isAuth?: boolean;
  payload?: any;
  isDownload?: boolean;
  params?: URLSearchParams;
  isUsingBaseUrl?: {
    token: string;
    baseUrl: string;
  };
  timeout?: number;
  cancelToken?: MutableRefObject<CancelTokenSource | undefined>;
}

const apiBase: string = process.env.REACT_APP_DOMAIN as string;
let refreshTokenPromise: any | null; // this holds any in-progress token refresh requests

export async function fetchApi<IData>(options: IFetchApi): Promise<IData> {
  const account = msalInstance.getAllAccounts()[0];
  const msalResponse = await msalInstance
    .acquireTokenSilent({
      ...loginApiRequest,
      account: account,
    })
    .then((accessTokenResponse) => {
      // Acquire token silent success
      return accessTokenResponse.accessToken;
    })
    .catch((err) => {
      //Acquire token silent failure, and send an interactive request
      if (err instanceof InteractionRequiredAuthError) {
        logout();
      }
    });
  if (options.cancelToken) {
    options.cancelToken.current = axios.CancelToken.source();
  }
  let timeoutId =
    options.timeout && options.cancelToken
      ? options.timeout &&
        setTimeout(() => {
          timeoutId = 0;
          // @ts-ignore
          options.cancelToken?.current?.cancel(
            `The file cannot be processed due to taking too long to respond. Please try again.`
          );
        }, options.timeout)
      : 0;

  try {
    const config: AxiosRequestConfig = {
      headers: {
        ...(options.isAuth && {
          Authorization: `Bearer ${
            options.isUsingBaseUrl
              ? options.isUsingBaseUrl.token
              : isPingSSO()
              ? load(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
              : msalResponse
          }`,
        }),
        currentTimezone: getTimezoneOffset(),
      },

      baseURL: Boolean(options.isUsingBaseUrl)
        ? `${options.isUsingBaseUrl?.baseUrl}`
        : `${apiBase}/api/v1`,
      data: { ...options.payload },
    };
    if (options.cancelToken) {
      // @ts-ignore
      config["cancelToken"] = options.cancelToken.current.token;
    }

    if (options.isDownload) {
      config["responseType"] = "blob";
    }

    if (options.params) {
      config["params"] = options.params;
    }

    const response: AxiosResponse<IData, any> = await axios[options.method](
      `${Boolean(options.isUsingBaseUrl) ? "" : "/"}`.concat(options.url),
      (options.method !== "delete" && options.payload) || config, // the method get & delete wont have data and receive only 2 params -> payload empty -> get config
      config // third param always be config
    );

    return response.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (!isPingSSO() && error.response && error.response.status === 401) {
      //this behaviour expected never happend which Azure already handle this
      logout();
    } else {
      const originalRequest = error.config;
      const refreshToken = load(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
      const accessToken = load(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      if ((!accessToken || !refreshToken) && isPingSSO()) {
        //deleted local storage
        logout();
      }
      if (
        isPingSSO() &&
        [400, 404].includes(error.response.status) &&
        [
          "Token is invalid",
          "Can not create token!",
          "invalid_token",
          "token_hasnt_expired_yet",
          "refresh_token_has_expired",
        ].includes(error.response.data.Message)
      ) {
        //wrong access token -> hardcode change local storage
        logout();
      }
      if (
        accessToken &&
        refreshToken &&
        error.response.status === 401 &&
        !originalRequest.retry
      ) {
        if (!refreshTokenPromise) {
          // check for an existing in-progress request
          // if nothing is in-progress, start a new refresh token request
          originalRequest.retry = true;
          refreshTokenPromise = getRefreshToken(
            refreshToken as string,
            accessToken as string
          ).then(() => {
            refreshTokenPromise = null; // clear state
          });
        }
        return refreshTokenPromise.then((token: any) => {
          // eslint-disable-next-line no-param-reassign
          error.config.headers["X-CSRF-TOKEN"] = token;
          return axios.request(error.config);
        });
      }

      if (error.response && error.response.status === 401) {
        //refresh token expired
        // EXPIRED ALL
        logout();
      }
    }
    if (
      error?.response &&
      Object.keys(ERROR_MESSAGE_CODE).includes(error?.response?.data?.Message)
    ) {
      return Promise.reject(error);
    }
    notification.open({
      message: null,
      description: (
        <Alert
          message={"Something went wrong, please try again later."}
          type={"error"}
          showIcon
        />
      ),
      placement: "topLeft",
      className: "custom-alert !p-0",
      key: "exist-one-notification",
      style: {
        width: 600,
      },
    });
    return Promise.reject(error);
  } finally {
    timeoutId && clearTimeout(timeoutId);
  }
}

export const getRefreshToken = async (refreshToken: string, token: string) => {
  await axios
    .post(`${apiBase}/api/auth/refresh-token`, {
      token,
      refreshToken,
    })
    .then((res: { data: { data: IRefreshTokenRes } }) => {
      if (res?.data) {
        save(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, res?.data?.data?.token);
        save(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, res?.data?.data?.refreshToken);
      }
    })
    .catch(() => {
      logout();
    });
};
