import { EmailDetail, GetEmailListParams, emailServices } from "./services";
import {
  IBasePaginationResponse,
  IBaseResponse,
} from "interfaces/common-model";
import { useMutation, useQuery } from "react-query";

import { fetchApi } from "libs/fetchAPI";

export const emailQueryKeys = {
  GET_EMAIL_LIST: "GET_EMAIL_LIST",
  GET_EMAIL_DETAIL: "GET_EMAIL_DETAIL",
};

export type EmailItem = {
  id: string;
  blDetail: {
    sentDate: string;
    billOfLadingNumber: string;
  };
  containerNumbers: string[];
  emails: string[];
  additionEmails: string[];
  subject: string;
  body: string;
  status: string;
};

export type ResendEmail = {
  emails: string[];
  additionalEmail: string[];
  bucketName: string;
  isMultipleResend: boolean;
  countryName: string;
  currentTimezone: string;
};

export const useGetEmailList = (options?: GetEmailListParams) =>
  useQuery({
    queryKey: [emailQueryKeys.GET_EMAIL_LIST, options],
    queryFn: () =>
      fetchApi<IBaseResponse<IBasePaginationResponse<EmailItem>>>(
        emailServices.getEmailList(options)
      ),
    ...options,
  });

export const useGetEmailDetail = (options: EmailDetail, isOpen: boolean) =>
  useQuery({
    queryKey: [emailQueryKeys.GET_EMAIL_DETAIL],
    queryFn: () =>
      fetchApi<IBaseResponse<EmailItem>>(emailServices.getEmailDetail(options)),
    enabled: isOpen,
  });

export const useResendEmail = () =>
  useMutation((payload: ResendEmail) =>
    fetchApi(emailServices.resendEmail(payload))
  );
