import { Form, FormItemProps, Input } from "antd";

import { TextAreaProps } from "antd/es/input";
import { formStyles } from "./styles";

const { TextArea } = Input;

type FormInputProps = Omit<TextAreaProps, "size"> & FormItemProps;

export const FormTextArea = ({
  label,
  disabled,
  placeholder,
  rules,
  autoSize,
  rows = 5,
  ...restProps
}: FormInputProps) => (
  <Form.Item
    className={formStyles.formItem.base}
    rules={rules}
    label={label}
    {...restProps}
  >
    <TextArea
      rows={rows}
      autoSize={autoSize}
      disabled={disabled}
      placeholder={placeholder}
      className={formStyles.input.textArea}
      {...restProps}
    />
  </Form.Item>
);
