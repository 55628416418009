import { Col, Form, Modal, ModalProps, Row, Typography } from "antd";

import { CustomButtom } from "components/button/CustomButton";
import { ErrorInput } from "./ErrorInput";
import { FormTextArea } from "components/form/Textarea";
import { useEdoContext } from "contexts/edo-context";
import { useEffect } from "react";

type ModalAddNewConsignee = ModalProps & {
  customConsignee: string | null;
};

type CustomerForm = {
  consigneeName: string;
};

const INITIAL_FORM_VALUES: CustomerForm = {
  consigneeName: "",
};

export const ModalAddNewConsignee = ({
  customConsignee,
  ...restProps
}: ModalAddNewConsignee) => {
  const { setEdo } = useEdoContext();
  const [form] = Form.useForm<CustomerForm>();

  useEffect(() => {
    customConsignee && form.setFieldValue("consigneeName", customConsignee);
  }, [customConsignee]);

  const handleCancelModal = () => {
    form.resetFields();
    setEdo((prev) => ({
      ...prev,
      isOpenModalAddCustomConsingee: false,
    }));
  };

  const handleSubmitForm = (values: CustomerForm) => {
    setEdo((prev) => ({
      ...prev,
      customConsignee: values.consigneeName,
      isOpenModalAddCustomConsingee: false,
    }));
  };

  return (
    <Modal
      closable={false}
      footer={false}
      className="!w-[688px] !h-[253px] [&_.ant-modal-content]:p-0"
      onCancel={handleCancelModal}
      {...restProps}
    >
      <>
        <div className="custom-confirmation-modal-customer-management">
          <Typography.Text className="p-6 pb-0 !block text-blue-sapphire text-xl not-italic font-antonio font-bold tracking-[-1px] m-0">
            Custom Consignee
          </Typography.Text>
          <Typography.Text className="p-6 py-0 !block text-blue-rock-blue font-roboto text-sm not-italic font-normal mt-2">
            Add A Temporary Consignee
          </Typography.Text>
          <div className="mt-5">
            <Form
              form={form}
              initialValues={INITIAL_FORM_VALUES}
              onFinish={handleSubmitForm}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
            >
              <Row className="p-6 py-0" gutter={[16, 0]}>
                <Col span={24}>
                  <FormTextArea
                    
                    name="consigneeName"
                    label="Consignee"
                    placeholder="Enter Consignee Name"
                    rules={[
                      {
                        required: true,
                        message: <ErrorInput />,
                      },
                    ]}
                  />
                </Col>
              </Row>
              <div className="flex justify-end border-blue-solitude border-t border-solid p-6 pt-4">
                <CustomButtom
                  title="Cancel"
                  variant="outline"
                  customSize="medium"
                  customColor="secondary"
                  customClassName="w-[143px]"
                  onClick={handleCancelModal}
                />
                <CustomButtom
                  title="Save"
                  customSize="medium"
                  customColor="secondary"
                  customClassName="ml-4 w-[143px]"
                  htmlType="submit"
                />
              </div>
            </Form>
          </div>
        </div>
      </>
    </Modal>
  );
};
