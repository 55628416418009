import { FiltersEdo, ReturnDateType } from "./interfaces";
import {
  GetContainerSizeTypeOptions,
  GetGetBillOfLadingListOptions,
  SelectALlPayload,
} from "./queries";

import { CancelTokenSource } from "axios";
import { ERROR_ROLLBACK_TRANSACTION } from "../../constants/constants";
import { ExcelColumnSortType } from ".";
import { IFetchApi } from "libs/fetchAPI";
import { MutableRefObject } from "react";
import { UserRole } from "interfaces/common-model";
import { omitEmptyParams } from "libs/helper";

export type GetEdoListParams = FiltersEdo & {
  keyword?: string;
  pageNum?: number;
  pageSize?: number;
  roleEnum: UserRole;
  countryName: string;
  currentTimezone: string;
};

export type SearchingListParams = {
  Keyword?: string;
  ContainerNum?: string;
  Blno?: string;
  countryName: string;
};

export type GetPartyAndConsigneeByBlId = {
  blid: string;
};

export type UpdatePayload = {
  currentTimezone: string;
  listDOId: string[];
  onListScreen: boolean;
  voyageInfo: {
    actualVoyageNo?: string;
    laraVoyageNo?: string;
    arrivalDate?: string | null;
    vesselName?: string;
    carrier?: string;
    isApplyAllBL?: boolean;
  } | null;
  blInfo: {
    blCode: string | null;
    consigneeCode: string | null;
    fPodCode: string | null;
    isTemporary?: boolean;
    podCode: string | null;
    receivingPartyCode: string | null;
  } | null;
  doInfo: {
    securityCode: string | null;
    releaseNo: string | null;
    pickupDate?: string | null;
    returnDate?: number;
    returnFullDate?: string | null;
    returnNumber?: boolean;
    emptyReturnDepotCode?: string | null;
    isOffHire?: boolean | null;
    updateReason: string | null;
  } | null;
  bucketName: string;
  queryTracking?: boolean;
} & SelectALlPayload;

export type UpdateSingleEdo = {
  edoId: string;
  actualVoyageNo: string;
  vesselName: string;
  carrier: string;
  arrivalDate: string | null;
  isApplyAll: boolean;
  blNo: string | null;
  consignee: string | null;
  receivingParty: string | null;
  pod: string | null;
  fpod: string | null;
  pickUpdate: string | null;
  returnDate: string | null;
  returnDays: number;
  isOffHire: boolean;
  emptyReturnDepot: string | null;
  updateReason: string | null;
  userId: string;
  isTempConsignee: boolean;
  roleEnum: UserRole;
  bucketName: string;
  currentTimezone: string;
};

export type UpdateBLDataWithLogistic = {
  containerSizeType: string;
  emptyReturnDepotCode: string | null;
  isOffHire: boolean | null;
  queryTracking?: boolean;
};

export type UpdateBLWithLogisticPayload = {
  listEdoId: string[];
  updateData: UpdateBLDataWithLogistic[];
  bucketName: string;
  queryTracking?: boolean;
  currentTimezone: string;
} & SelectALlPayload;

export type UpdateBLDataWithCounter = {
  receivingPartyCode: string | null;
  consigneeCode?: string | null;
  pickupDate: string | null;
  returnDate: number | null;
  returnFullDate: string | null;
  returnNumber: boolean | null;
  updateReason: string | null;
  isTemporary?: boolean;
  billNumber?: string;
  queryTracking?: boolean;
};

export type UpdateBLWithCounterPayload = {
  listEdoId: string[];
  updateData: UpdateBLDataWithCounter;
  bucketName: string;
  saveOnly: boolean;
  queryTracking?: boolean;
} & SelectALlPayload;

export type ContainerSizeTypeListParams = {
  id: string[];
};

export type BillOfLadingListParams = {
  id: string[];
};

export type ExportPDFParam = {
  BillId: string;
  EmailId: string;
};

export type ExportPDFByBillEmailPDF = {
  emailDetail: string;
  bucketName: string;
  currentTimezone: string;
};

export const edoServices = {
  getEdoList: (params: GetEdoListParams): IFetchApi => {
    return {
      url: `edos`,
      method: "post",
      isAuth: true,
      payload: params,
    };
  },
  getContainerSize: (countryName: string): IFetchApi => {
    return {
      url: `edos/container-size?countryName=${countryName}`,
      method: "get",
      isAuth: true,
    };
  },
  getEdoDetail: (doId: string): IFetchApi => {
    return {
      url: `edos/${doId}`,
      method: "get",
      isAuth: true,
    };
  },
  getConsigneeList: (params?: SearchingListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });

    return {
      url: `edos-extraction/consignee-searching?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getPODList: (params?: SearchingListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });

    return {
      url: `edos-extraction/pod-searching?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getFPODList: (params?: SearchingListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });

    return {
      url: `edos-extraction/fpod-searching?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  updateStatus: (payload: UpdatePayload): IFetchApi => {
    return {
      url: `edos/bulk-update-status`,
      method: "put",
      isAuth: true,
      payload,
    };
  },
  getEmptyDepotList: (params?: SearchingListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `edos-extraction/emptyDepot-searching?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getCompanySearch: (params?: SearchingListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `accounts/company-searching?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  createOrUpdateEdo: (payload: {
    append: FormData;
    type?: string;
    cancelToken: MutableRefObject<CancelTokenSource | undefined>;
    countryName: string;
  }): IFetchApi => {
    return {
      url: `edos-extraction/create`,
      method: "post",
      isAuth: true,
      payload: payload.append,
      timeout: ERROR_ROLLBACK_TRANSACTION.MAX_TIMEOUT,
      cancelToken: payload.cancelToken,
    };
  },
  preReadFileBeforeAction: (payload: {
    append: FormData;
    type: string;
  }): IFetchApi => {
    return {
      url: `edos-extraction/read-file`,
      method: "post",
      isAuth: true,
      payload: payload.append,
    };
  },
  createOrUpdateEdoByLaraApi: (payload: {
    listBill: string[];
    isAdd: boolean;
    cancelToken: MutableRefObject<CancelTokenSource | undefined>;
    countryName: string;
  }): IFetchApi => {
    return {
      url: `edos-extraction/create/lara`,
      method: "post",
      isAuth: true,
      payload,
      timeout: ERROR_ROLLBACK_TRANSACTION.MAX_TIMEOUT,
      cancelToken: payload.cancelToken,
    };
  },
  editSingleEdo: (payload: UpdateSingleEdo): IFetchApi => {
    return {
      url: `edos-extraction/edit-single`,
      method: "post",
      isAuth: true,
      payload,
    };
  },
  exportExcelEdo: (
    params: FiltersEdo &
      ExcelColumnSortType & { roleEnum: UserRole; countryName: string }
  ): IFetchApi => {
    return {
      url: `edos/export-excel`,
      method: "post",
      isAuth: true,
      payload: params,
      isDownload: true,
    };
  },
  exportPDFByBill: (payload: ExportPDFParam): IFetchApi => {
    return {
      url: `bill-of-ladings/export-pdf`,
      method: "post",
      isAuth: true,
      isDownload: true,
      payload,
    };
  },
  exportPDFByBillEmailPDF: (payload: ExportPDFByBillEmailPDF): IFetchApi => {
    return {
      url: `email/email-pdf-attachment`,
      method: "post",
      isAuth: true,
      isDownload: true,
      payload,
    };
  },
  exportPDF: (payload: { id: string; timezone: string }): IFetchApi => {
    return {
      url: `edos/export-pdf/${payload.id}?timezone=${payload.timezone}`,
      method: "get",
      isAuth: true,
      isDownload: true,
    };
  },
  getPickupdateRerurnDateByArrivalDate: (
    params?: ReturnDateType
  ): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `edos/return-date?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  checkAbleToSendEmailToLogistic: (payload: UpdatePayload): IFetchApi => {
    return {
      url: "edos/check-logistic-emails",
      method: "post",
      isAuth: true,
      payload,
    };
  },
};

export const blService = {
  posToGetContainerSizeTypeList: (
    options: GetContainerSizeTypeOptions
  ): IFetchApi => {
    const searchParams = new URLSearchParams();

    for (let id of options.id) {
      searchParams.append("id", id);
    }

    return {
      url: "bill-of-ladings/list-container-size-type",
      method: "post",
      isAuth: true,
      params: searchParams,
      payload: options.payload,
    };
  },
  checkUpdateBLWithLogistic: (
    payload: UpdateBLWithLogisticPayload
  ): IFetchApi => {
    return {
      url: "bill-of-ladings/check-bulk-update-logistic",
      method: "post",
      isAuth: true,
      payload,
    };
  },
  updateBLWithLogistic: (payload: UpdateBLWithLogisticPayload): IFetchApi => {
    return {
      url: "bill-of-ladings/bulk-update-logistic",
      method: "put",
      isAuth: true,
      payload,
    };
  },

  // Edit BL for Logistic role
  posToGetBillOfLadingList: (
    params: GetGetBillOfLadingListOptions
  ): IFetchApi => {
    const searchParams = new URLSearchParams();

    for (let id of params.id) {
      searchParams.append("id", id);
    }

    return {
      url: "bill-of-ladings/list-bill-detail",
      method: "post",
      isAuth: true,
      params: searchParams,
      payload: params.payload,
    };
  },
  checkUpdateBLWithCounter: (
    payload: UpdateBLWithCounterPayload
  ): IFetchApi => {
    return {
      url: "bill-of-ladings/check-bulk-update-counter",
      method: "post",
      isAuth: true,
      payload,
    };
  },
  updateBLWithCounter: (payload: UpdateBLWithCounterPayload): IFetchApi => {
    return {
      url: "bill-of-ladings/bulk-update-counter",
      method: "put",
      isAuth: true,
      payload,
    };
  },
  getReceivingPartyAndConsigneeByBLId: (blId: string): IFetchApi => {
    return {
      url: `bill-of-ladings/selected/${blId}`,
      method: "get",
      isAuth: true,
    };
  },
};
