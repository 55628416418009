import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout, save } from "libs/storage";
import { useEffect, useMemo, useState } from "react";

import { AppLoading } from "./AppLoading";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import { Layout } from "layouts";
import { SIDE_BAR_MENU } from "layouts/constants";
import { UserRole } from "interfaces/common-model";
import { authConfig } from "constants/configs";
import { isPingSSO } from "hooks/use-is-ping-sso";
import useDidMountEffect from "hooks/use-did-mount-effect";
import { useGetUserRole } from "layouts/queries";
import { useUserRole } from "pages/eod-management/components/SelectRole";

export const PrivateRoute = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const role = useUserRole();
  const location = useLocation();
  const currentPathname = location.pathname;
  const [appLoading, setAppLoading] = useState(false);

  useEffect(() => {
    save(LOCAL_STORAGE_KEYS.VERSION_LOGGER, authConfig.edoVersion);
  }, []);

  const checkRoleToAccessPath = useMemo(
    () =>
      SIDE_BAR_MENU.find(
        (item) => item.key === currentPathname.substring(1)
      )?.role.includes(role),
    [currentPathname, role]
  );

  const {
    data: userRole,
    isLoading: isLoadingGetRole,
    isSuccess,
    isError,
  } = useGetUserRole(isAuthenticated || Boolean(isPingSSO()));
  const navigate = useNavigate();

  useDidMountEffect(() => {
    if (isError) {
      logout();
      return;
    }
    if (!isLoadingGetRole && isSuccess) {
      if (userRole?.data?.roles?.length === 0) {
        navigate("/no-permission");
        return;
      }
      const roleEnum = userRole?.data?.roles[0]?.roleEnum;
      if (roleEnum === UserRole.ImportCS) {
        save(LOCAL_STORAGE_KEYS.USER_ROLE, roleEnum);
        setAppLoading(
          inProgress === "logout" || inProgress === "handleRedirect"
        );
      } else if (roleEnum) {
        save(LOCAL_STORAGE_KEYS.USER_ROLE, roleEnum);
        setAppLoading(
          inProgress === "logout" || inProgress === "handleRedirect"
        );
      } else {
        navigate("/no-permission");
      }
    }
  }, [inProgress, isLoadingGetRole, isSuccess]);

  if (appLoading) {
    return <AppLoading />;
  }

  if (!isAuthenticated && !isPingSSO()) {
    return <Navigate to="/" />;
  }

  if (!checkRoleToAccessPath) {
    // temporary disable check path by role
    // return <Navigate to="edo" />;
  }

  return isPingSSO() ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <AuthenticatedTemplate>
      <Layout>
        <Outlet />
      </Layout>
    </AuthenticatedTemplate>
  );
};
