import { Alert, Divider, Form, Modal } from "antd";
import { getFileTypeByName, onDisablePastDate } from "libs/helper";
import { useEffect, useState } from "react";

import { ButtonVariant } from "components/button/types";
import { ChooseAction } from "./components/ChooseAction";
import { CustomButtom } from "components/button/CustomButton";
import { EodAction } from "pages/eod-management/constants";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { FormDatePicker } from "components/form/DatePicker";
import { FormInput } from "components/form/Input";
import { FormSelect } from "components/form/Select";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import { UploadFileEod } from "./components/UploadFileEod";
import dayjs from "dayjs";
import { useCountry } from "../SelectCountry";
import useDidMountEffect from "hooks/use-did-mount-effect";
import { useEdoContext } from "contexts/edo-context";
import { usePreReadFileBeforeAction } from "pages/eod-management/queries";

export type FromType = {
  ActualVoyage?: string;
  isCreationEdo?: boolean;
  Blno?: string | string[];
  Eta?: string;
  File?: {
    originFileObj: File;
  }[];
  isAdd: boolean;
};
export interface IProps {
  modalTitle?: string;
  subTitle?: string;
  callBackSuccess: (
    formValue: FormData | string[],
    type: "normal" | "lara"
  ) => void;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  isCreationEdo: boolean;
  isSuccessConfirmation: boolean;
  isLoadingSubmit: boolean;
}

export const INIT_UPLOAD_DATA = {
  File: null,
};

export default function ModalCreateUpdateEdo({
  modalTitle,
  callBackSuccess,
  isOpen,
  setOpen,
  subTitle,
  isCreationEdo,
  isSuccessConfirmation,
  isLoadingSubmit,
}: IProps) {
  const [form] = Form.useForm();
  const [action, setAction] = useState<EodAction>();
  const [triggerResetUploadStyle, setTriggerResetUploadStyle] =
    useState<boolean>(false);
  const { setEdo, uploadFile } = useEdoContext();

  useEffect(() => {
    //TODO: open this when issue related to update edo is fixed
    // setAction(isCreationEdo ? EodAction.uploadFile : EodAction.enterBlNumb);
    setAction(EodAction.uploadFile)
  }, [isCreationEdo]);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setTriggerResetUploadStyle(isCreationEdo);
    setOpen(false);
  };
  const handleReset = () => {
    form.setFieldsValue({ File: INIT_UPLOAD_DATA.File });
  };

  useDidMountEffect(() => {
    if (isSuccessConfirmation) {
      form.resetFields();
      setTriggerResetUploadStyle(isCreationEdo);
    }
  }, [isSuccessConfirmation]);

  useDidMountEffect(() => {
    form.setFieldsValue({ File: INIT_UPLOAD_DATA.File });
    setTriggerResetUploadStyle(isCreationEdo);
  }, [action]);

  useDidMountEffect(() => {
    form.resetFields();
    setTriggerResetUploadStyle(isCreationEdo);
  }, [isCreationEdo]);

  const currentCountry = useCountry();

  const onFinish = (values: FromType) => {
    if (action === EodAction.uploadFile) {
      var formData = new FormData();
      values.ActualVoyage &&
        formData.append("ActualVoyage", values.ActualVoyage);
      values.Blno && formData.append("Blno", values.Blno as string);
      formData.append("IsAdd", `${isCreationEdo}`);
      values.Eta &&
        formData.append("Eta", dayjs(values.Eta).format(SYSTEM_DATE_FORMAT));
      if (values.File) {
        formData.append("file", values.File[0].originFileObj);
        setEdo((prev) => ({
          ...prev,
          uploadFileType: values.File
            ? getFileTypeByName(values.File[0].originFileObj.name)
            : "",
          uploadFileName: `${
            values.File ? values.File[0].originFileObj.name : ""
          }`,
        }));
        currentCountry && formData.append("CountryName", currentCountry);
      }
      callBackSuccess(formData, "normal");
    } else {
      values.Blno && callBackSuccess(values.Blno as string[], "lara");
      if (values?.Blno && values?.Blno?.length > 0) {
        setEdo((prev) => ({
          ...prev,
          uploadFileType: "lara",
          uploadFileName: values.Blno,
        }));
      }
    }
  };

  const file = Form.useWatch("File", form);

  const { mutate: preReadFile, isLoading: isLoadingPreReadFile } =
    usePreReadFileBeforeAction();

  useEffect(() => {
    if (file && file.length > 0) {
      setEdo((prev) => ({
        ...prev,
        uploadFile: {
          quantity: 0,
          isReading: true,
        },
      }));

      var formData = new FormData();
      formData.append("file", file[0].originFileObj);
      const payload = {
        append: formData as FormData,
        type: `${isCreationEdo ? "create" : "update"}`,
      };

      preReadFile(payload, {
        onSuccess: (response) => {
          setEdo((prev) => ({
            ...prev,
            uploadFile: {
              quantity: response.data.containerNumber,
              isReading: false,
            },
          }));
        },
      });
    }
  }, [file]);

  const handleSelectChange = (value: any) => {
    // Set the maximum number of items
    const maxItems = 10;

    // If the number of selected items exceeds the limit, trim the selection as UI
    if (value.length > maxItems) {
      value = value.slice(0, maxItems);
    }

    // Manually trigger the form validation and set the selected items as value
    form.setFieldsValue({ Blno: value });
  };

  return (
    <Modal
      closable={false}
      className="!w-[512px]"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      centered
    >
      <div className="custom-confirmation-modal-customer-management ">
        <div>
          <div>
            <h1 className="text-blue-sapphire text-[20px] not-italic font-antonio font-bold m-[0px] tracking-[-0.1px]">
              {modalTitle}
            </h1>
            <p className="text-blue-rock-blue p-[0px] font-roboto text-[14px] not-italic font-normal mt-[8px]">
              {subTitle}
            </p>
          </div>
          <div className="mt-[20px]">
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              initialValues={INIT_UPLOAD_DATA}
            >
              <div className="custom-customer-modal-customer-management">
                {/* only show "actual voyage no" & "eta" inputs when user create eDO */}
                {isCreationEdo && (
                  <>
                    <div className="flex justify-between [&_.ant-form-item]:w-[224px]">
                      <FormInput
                        name="ActualVoyage"
                        label="Actual Voyage No."
                        placeholder="Enter Actual Voyage No."
                        disabled={action === EodAction.enterBlNumb}
                        rules={[
                          {
                            required:
                              isCreationEdo && action === EodAction.uploadFile,
                            message: <ErrorInput />,
                          },
                        ]}
                      />
                      <FormDatePicker
                        name="Eta"
                        label="ETA"
                        placeholder="DD/MM/YYYY"
                        disabledDate={onDisablePastDate}
                        customDatePickerClassName="w-[224px]"
                        disabled={action === EodAction.enterBlNumb}
                        rules={[
                          {
                            required:
                              isCreationEdo && action === EodAction.uploadFile,
                            message: <ErrorInput />,
                          },
                        ]}
                      />
                    </div>
                    <Divider className="bg-blue-solitude" />
                  </>
                )}
                <ChooseAction setAction={setAction} action={action} isCreationEdo={isCreationEdo}/>
                {action === EodAction.uploadFile ? (
                  <UploadFileEod
                    callBackResetFile={handleReset}
                    triggerReset={triggerResetUploadStyle}
                    isCreationEdo={isCreationEdo}
                    action={action}
                  />
                ) : (
                  <>
                    <FormSelect
                      mode="tags"
                      label="BL No."
                      open={false}
                      name="Blno"
                      suffixIcon={null}
                      placeholder="Enter BL No."
                      customSelectClassName="w-full [&_.ant-select-selector]:!min-h-[40px] [&_.ant-select-selection-overflow]:gap-1 [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5 !h-auto"
                      customFormItemClassName="w-full mt-3"
                      onChange={handleSelectChange}
                      disabled={isCreationEdo || (action === EodAction.enterBlNumb && !isCreationEdo)}
                      rules={[
                        {
                          required: true,
                          message: <ErrorInput />,
                        },
                      ]}
                    />
                    <Alert
                      className="mt-[-12px] px-3 py-1 rounded border-none bg-blue-alice-blue text-blue-sapphire text-xs font-normal font-style"
                      message="Insert BL No. (max. 10) and press Submit to continue"
                      type="info"
                      showIcon
                      closable
                    />
                  </>
                )}
                <div className="grid grid-cols-12 gap-x-4 mt-8">
                  <CustomButtom
                    title="Cancel"
                    variant={ButtonVariant.outline}
                    customSize={"medium"}
                    customColor={"secondary"}
                    customClassName="col-span-6"
                    onClick={handleCancel}
                  />
                  <CustomButtom
                    disabled={
                      (!uploadFile.isReading && uploadFile.quantity === 0) ||
                      isLoadingPreReadFile || (isCreationEdo && action === EodAction.enterBlNumb)
                    } //if file upload contain 0 edos, disable upload
                    title="Submit"
                    customSize={"medium"}
                    customColor={"secondary"}
                    customClassName="col-span-6"
                    htmlType="submit"
                    loading={isLoadingSubmit}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
