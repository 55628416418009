import SortArrowDown from "assets/icons/SortArrowDown.svg";
import SortArrowUp from "assets/icons/SortArrowUp.svg";
import SortArrows from "assets/icons/SortArrows.svg";
import { SortOrder } from "antd/es/table/interface";

export type CustomSortIconProps = {
  sortOrder: SortOrder;
};

export const CustomSortIcon = ({ sortOrder }: CustomSortIconProps) => {
  if (sortOrder === "ascend") {
    return <img alt="table-sort-icon-arrow-up" src={SortArrowUp} />;
  }

  if (sortOrder === "descend") {
    return <img alt="table-sort-icon-arrow-down" src={SortArrowDown} />;
  }

  return <img alt="table-sort-icon-arrows" src={SortArrows} />;
};
