import { EdoStatus } from "interfaces/common-model";

export const AUTH_SESSION_KEY = "session";
export const DEFAULT_PERPAGE = "15";
export const DEFAULT_PERPAGE_DISCHARGE = "50";
export const DEFAULT_PERPAGE_TRANSATION_HISTORY = "5";
export const DEFAULT_PERPAGE_EDOS = "10";
export const REACT_QUERY_RETRY_TIMES = 5; //only apply for edo list

export const PROFILE_KEY = "PROFILE_KEY";

export const SYSTEM_DATE_FORMAT = "DD/MM/YYYY";
export const UPDATE_DATE_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const EMAIL_DATE_FORMAT = "DD/MM/YYYY HH:mm";
export const EXCEL_NAME_FILE_FORMAT = "DDMMYYYY_HHmm";

export const EDO_STATUS = {
  New: {
    // action as restore
    value: EdoStatus.New,
    label: "New",
    actionLabel: "Restore",
    //TODO: might consider to also push color value in it
  },
  WaitForLog: {
    //action send to logis
    value: EdoStatus.WaitForLog,
    label: "Wait For Log",
    actionLabel: "Send To Logistic",
  },
  ReadyForRelease: {
    value: EdoStatus.ReadyForRelease,
    label: "Ready For Release",
  },
  Unrelease: {
    value: EdoStatus.Unrelease,
    label: "Unreleased",
    actionLabel: "Unrelease",
  },
  Shortship: {
    value: EdoStatus.Shortship,
    label: "Shortship",
  },
  Delete: {
    value: EdoStatus.Delete,
    label: "Deleted",
    actionLabel: "Delete",
  },
  Release: {
    value: EdoStatus.Release,
    label: "Released",
    actionLabel: "Release",
  },
  ReleaseUpdate: {
    value: EdoStatus.ReleaseUpdate,
    label: "Released - Updated",
  },
};

export const ERROR_MESSAGE_CODE = {
  MSG_00: "MSG_00",
  MSG_01: "MSG_01",
  MSG_02: "MSG_02",
  MSG_03: "MSG_03",
  MSG_04: "MSG_04",
  MSG_05: "MSG_05",
  MSG_06: "MSG_06",
  MSG_07: "MSG_07",
  MSG_08: "MSG_08",
  MSG_09: "MSG_09",
  ERROR_00: "ERROR_00",
  ERROR_01: "ERROR_01",
  ERROR_02: "ERROR_02",
  ERROR_03: "ERROR_03",
  ERROR_04: "ERROR_04",
  ERROR_05: "ERROR_05",
  ERROR_07: "ERROR_07",
};

export const ERROR_ROLLBACK_TRANSACTION = {
  ERR_CANCELED: "ERR_CANCELED",
  MAX_TIMEOUT: 1800000,
};

export enum EActionType {
  SEND_TO_LOGISTICS = 1,
  READY_FOR_RELEASE = 2,
  DELETED = 3,
  SHORTSHIP = 4,
  SEND_EMAIL_TO_LOGISTIC = 5,
  RELEASE = 6,
  SINGLE_UPDATE_BL = 7,
  SING_UPDATE_EDO = 8,
  MASS_UPDATE_BL = 9,
  MASS_UPDATE_EDO = 10,
}

// Email regex
// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}$/;

export const NUMBER_AND_CHARS_ONLY = /^[a-zA-Z0-9]+$/;

export enum MAX_TRUNCATE {
  TOTAL_30_CHARS = 30,
  TOTAL_23_CHARS = 23,
}

export enum ConsigneeFlags {
  CONSIGNEE = "consignee",
  NOTIFY1S = "notify1s",
  NOTIFY2S = "notify2s",
}

export const CMG_CGM_DEPOT = "CMA CGM"; //only this depot get disable on depot list with role !== admin/IT Officer

export enum LoginType {
  PingSSO = "PingSSO",
  Azure = "Azure",
}

export const SK = "cd4ab7d3-7208-4aae-8559-e7dbde1d9da8";

export enum FieldChange {
  CHANGE_ALL = -1,
  NO_CHANGE = 0,
  ETA = 1,
  VOYAGE_NO = 2,
}
