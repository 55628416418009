import { ButtonColor, ButtonSize } from "./types";
import { DropDownProps, Dropdown, MenuProps, Typography } from "antd";
import { ReactNode, useMemo } from "react";

import DownCheveronIcon from "assets/icons/DownCheveronIcon.svg";
import { ItemType } from "antd/es/menu/hooks/useItems";

type CustomButtonProps = Omit<DropDownProps, "title"> & {
  loading?: boolean;
  customSize?: "large" | "medium" | "small" | "extra-small";
  customColor?: "primary" | "secondary" | "danger" | "tonal";
  title?: string | ReactNode;
  customClassName?: string;
  customTextClassName?: string;
  customMenuClassName?: string;
  items: MenuProps["items"];
  callBackDataClickDropDown: (value: ItemType) => void;
  callBackClickButton: () => void;
};

export const CustomDropdownButton = ({
  title,
  items,
  customSize = ButtonSize.small,
  customColor = ButtonColor.secondary,
  customClassName = "",
  customTextClassName = "",
  customMenuClassName = "",
  callBackDataClickDropDown,
  callBackClickButton,
  ...restProps
}: CustomButtonProps) => {
  const styles = useMemo(() => {
    let buttonClassName =
      "w-fit [&_.ant-btn-compact-item]:rounded-none [&_.ant-btn-compact-first-item]:border-y-0 [&_.ant-btn-compact-first-item]:border-l-0 [&_.ant-btn-compact-first-item]:!border-r-white [&_.ant-btn-compact-first-item]:!border-r-[0.03125rem] [&_:not(.ant-btn-compact-last-item)]:!me-0 [&_.ant-btn-compact-last-item]:!border-none [&_.ant-btn-compact-last-item]:flex [&_.ant-btn-compact-last-item]:items-center [&_.ant-btn-compact-last-item]:justify-center [&_.ant-btn-icon.ant-btn-loading-icon]:text-white";
    let textClassName = "font-roboto font-normal px-2";

    switch (customSize) {
      case ButtonSize.large:
        buttonClassName = `${buttonClassName} [&_.ant-btn-compact-item]:h-12`;
        textClassName = `${textClassName} text-base leading-6`;
        break;
      case ButtonSize.medium:
        buttonClassName = `${buttonClassName} [&_.ant-btn-compact-item]:h-11 [&_.ant-btn-compact-last-item]:w-11 [&_.ant-btn-compact-last-item]:p-3`;
        textClassName = `${textClassName} text-base leading-6`;
        break;
      case ButtonSize.small:
        buttonClassName = `${buttonClassName} [&_.ant-btn-compact-item]:h-10 [&_.ant-btn-compact-last-item]:w-10 [&_.ant-btn-compact-last-item]:p-2.5`;
        textClassName = `${textClassName} text-sm leading-5`;
        break;
      case ButtonSize.extraSmall:
        buttonClassName = `${buttonClassName} [&_.ant-btn-compact-item]:h-9`;
        textClassName = `${textClassName} text-sm leading-5`;
        break;

      default:
        buttonClassName = "[&_.ant-btn-compact-item]:h-10";
        textClassName = "";
        break;
    }

    switch (customColor) {
      case ButtonColor.secondary:
        buttonClassName = `${buttonClassName} [&_.ant-btn-compact-item]:bg-red-base`;
        textClassName = `${textClassName} text-white`;
        break;

      default:
        buttonClassName = "";
        textClassName = "";
        break;
    }

    return {
      buttonClassName,
      textClassName,
    };
  }, [customColor, customSize]);

  const onMenuClick: MenuProps["onClick"] = (e) => {
    callBackDataClickDropDown(e);
  };

  const onClickButton = () => {
    callBackClickButton();
  };

  return (
    <Dropdown.Button
      className={`${styles.buttonClassName} ${customClassName}`}
      type="default"
      onClick={onClickButton}
      icon={<img src={DownCheveronIcon} alt="downIcon" />}
      menu={{
        items,
        onClick: onMenuClick,
        rootClassName: `custom-account-select-dropdown w-[11.5rem] !rounded-none !p-0 [&>li]:h-10 [&>li]:!rounded-none [&>li>span>div]:font-normal [&>li>span>div]:font-roboto [&>li>span>div]:text-blue-sapphire [&>li>span>div]:text-base [&_.ant-dropdown-menu-title-content]:font-roboto [&_.ant-dropdown-menu-title-content]:text-blue-sapphire [&_.ant-dropdown-menu-title-content]:text-base [&_.ant-dropdown-menu-title-content]:leading-6 [&_.ant-dropdown-menu-title-content]:font-normal ${customMenuClassName}`,
      }}
      {...restProps}
    >
      <Typography.Text
        className={`${styles.textClassName} ${customTextClassName}`}
      >
        {title}
      </Typography.Text>
    </Dropdown.Button>
  );
};
