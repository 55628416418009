export enum DepotType {
  Terminal = "Terminal",
  Depot = "Depot",
}

export interface Depot {
  address: string;
  depotCode: string;
  depotId: string;
  depotName: string;
  emails: string[];
  logisticEmails: string[];
  isDeployed: boolean;
  status: boolean;
  type: DepotType;
  phoneNumber: string;
  countryName: string;
}

export interface DepotFilters {
  countries: string[];
  isDeployeds: boolean[];
  status: boolean[];
  types: DepotType[];
}

export enum DepotFormStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum DepotFormDeployed {
  Yes = "Yes",
  No = "No",
}

export interface DepotFormValues {
  Status: DepotFormStatus | string;
  CountryName: string;
  IsDeployed: DepotFormDeployed | string;
  Type: DepotType | string;
  KeyWord: string;
  PageNum: number;
  PageSize: number;
}

export interface DepotDetailFormValues {
  address: string | null;
  countryName: string | null;
  depotCode: string | null;
  depotId: string | null;
  depotName: string | null;
  emails?: string[];
  logisticEmails?: string[];
  isDeployed: boolean;
  status: boolean;
  type: DepotType;
  phoneNumber: string | null;
}
