import {
  Collapse,
  CollapseProps,
  Divider,
  Drawer,
  Form,
  Image,
  Typography,
} from "antd";
import {
  useCheckUpdateBLWithLogistic,
  useGetContainerSizeTypeList,
  useUpdateBLWithLogistic,
} from "pages/eod-management/queries";
import { useEffect, useMemo, useState } from "react";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import ChangeToLogisticIcon from "assets/icons/changeToLogisticIcon.svg";
import { ContainerDetail } from "./ContainerDetail";
import { CustomButtom } from "components/button/CustomButton";
import DrawerCloseIcon from "assets/icons/XCloseIcon.svg";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { FormSelect } from "components/form/Select";
import { ModalConfirmation } from "components/ModalConfirmation";
import { UpdateBLWithLogisticPayload } from "pages/eod-management/services";
import { ViewLoading } from "components/ViewLoading";
import { authConfig } from "constants/configs";
import get from "lodash/get";
import { getTimezoneOffset, getValueFromAsyncSelect } from "libs/helper";
import { useCountry } from "../SelectCountry";
import { useEdoContext } from "contexts/edo-context";
import { useNotification } from "hooks/use-notification";
import { useUserRole } from "../SelectRole";
import { map, uniq } from "lodash";

type UpdateBLModalProps = {
  refetchEdoList: () => void;
  selectedBLs: string[];
};

export const DrawerEditBLLogistic = ({
  selectedBLs = [],
  refetchEdoList,
}: UpdateBLModalProps) => {
  const {
    editBlWithLogisticRole,
    edoFilter,
    isSelectAll,
    listExceptEdoId,
    setEdo,
    isLoadingBulkUpdate,
  } = useEdoContext();
  const [form] = Form.useForm();
  const { handleOpenSuccessNotification } = useNotification();
  const role = useUserRole();
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);
  const [modalConfirmationConfig, setModalConfirmationConfig] = useState({
    title: "",
    description: "",
    subDescription: "",
  });
  const countryName = useCountry();

  const { data, isLoading: isFetchingContainerSizeType } =
    useGetContainerSizeTypeList({
      payload: {
        listExceptEdoId,
        isSelectAll,
        filterRequestModel: { ...edoFilter, roleEnum: role, countryName },
      },
      id: selectedBLs,
      timestamp: editBlWithLogisticRole.timestamp,
      enabled:
        editBlWithLogisticRole.isOpen &&
        selectedBLs.length > 0 &&
        selectedBLs.every((item) => !!item),
    });
  const ableToSubmit: boolean = get(data, "data.isAllValid", true);

  const {
    mutateAsync: checkUpdateBLWithLogistic,
    isLoading: isCheckingBLData,
  } = useCheckUpdateBLWithLogistic();

  const { mutateAsync: updateBLWithLogistic, isLoading: isUpdatingBLData } =
    useUpdateBLWithLogistic();

  useEffect(() => {
    setEdo((prve) => ({ ...prve, isLoadingBulkUpdate: isUpdatingBLData }));
  }, [isUpdatingBLData]);

  const containerSizeType = get(data, "data.containerData");
  const listBLAndVoyageInfo = get(data, "data.listBLAndVoyageInfo");

  const tagOptions = useMemo(() => {
    if (!listBLAndVoyageInfo || !listBLAndVoyageInfo.length) {
      return {
        blTagOptions: [],
        voyageTagOption: [],
      };
    }

    let blTagOptions: {
      label: string;
      value: string;
    }[] = [];

    let voyageTagOption: {
      label: string;
      value: string;
    }[] = [];

    for (let item of listBLAndVoyageInfo) {
      if (blTagOptions.findIndex((bl) => bl.value === item.blName)) {
        blTagOptions.push({ label: item.blName, value: item.blName });
      }
      if (voyageTagOption.findIndex((bl) => bl.value === item.voyageNumber)) {
        voyageTagOption.push({
          label: item.voyageNumber,
          value: item.voyageNumber,
        });
      }
    }
    return {
      blTagOptions,
      voyageTagOption,
    };
  }, [listBLAndVoyageInfo]);

  // Extract unique blName values
  const blDefaultOptions = uniq(map(listBLAndVoyageInfo, "blName"));

  // Extract unique voyageNumber values
  const voyageDefaultOptions = uniq(map(listBLAndVoyageInfo, "voyageNumber"));

  const collapseContainerItems: CollapseProps["items"] = useMemo(() => {
    if (!containerSizeType || !containerSizeType.length) {
      return [];
    }

    return containerSizeType.map((item) => ({
      label: `${item.containerSizeType} Container`,
      key: item.containerSizeType,
      children: <ContainerDetail {...item} />,
    }));
  }, [containerSizeType]);

  const collapseActiveKeys =
    collapseContainerItems.length > 0
      ? collapseContainerItems.map((item) => item.key as number)
      : [];

  const handleCloseModalConfirmation = () => {
    setIsOpenModalConfirmation(false);
    setModalConfirmationConfig({
      title: "",
      description: "",
      subDescription: "",
    });
  };

  const handleUpdateBLs = (payload?: UpdateBLWithLogisticPayload) => {
    let updateBLsData = payload;

    if (!updateBLsData) {
      const formValues = form.getFieldsValue();
      let updateData = [];

      for (const container of containerSizeType!) {
        const isOffHide = formValues[`isOfHide-${container.containerSizeType}`];
        const emptyReturnDepotCode =
          formValues[`emptyReturnDepotName-${container.containerSizeType}`];

        const updatedContainer = {
          containerSizeType: container.containerSizeType,
          emptyReturnDepotCode: getValueFromAsyncSelect(emptyReturnDepotCode),
          isOffHire: Boolean(isOffHide),
        };

        updateData.push(updatedContainer);
      }

      updateBLsData = {
        listEdoId: selectedBLs,
        updateData,
        bucketName: authConfig.s3Bucket || "",
        listExceptEdoId,
        isSelectAll,
        filterRequestModel: { ...edoFilter, roleEnum: role, countryName },
        queryTracking: true,
        currentTimezone: getTimezoneOffset(),
      };
    }

    if (isSelectAll) {
      setIsOpenModalConfirmation(false);
      updateBLsData!.queryTracking = true;
    }

    // check update data first
    updateBLWithLogistic(updateBLsData)
      .then(() => {
        handleOpenSuccessNotification({
          message: "BLs List has been updated!",
        });
        setIsOpenModalConfirmation(false);
        handleCloseDrawerEditBLLogistic();
        refetchEdoList(); // refetch edos list
      })
      .catch(() => {
        handleOpenSuccessNotification({
          type: "error",
          message: "Something went wrong! Please check that again.",
        });
      });
  };

  const handleSubmitForm = (values: Record<string, string>) => {
    // all fields are valid, then check BL data

    let updateData = [];
    for (const container of containerSizeType!) {
      const isOffHide = values[`isOfHide-${container.containerSizeType}`];
      const emptyReturnDepotCode =
        values[`emptyReturnDepotName-${container.containerSizeType}`];

      const updatedContainer = {
        containerSizeType: container.containerSizeType,

        emptyReturnDepotCode: getValueFromAsyncSelect(emptyReturnDepotCode),
        isOffHire: Boolean(isOffHide),
      };

      updateData.push(updatedContainer);
    }

    const payload = {
      listEdoId: selectedBLs,
      updateData,
      bucketName: authConfig.s3Bucket || "",
      listExceptEdoId,
      isSelectAll,
      filterRequestModel: { ...edoFilter, roleEnum: role, countryName },
      currentTimezone: getTimezoneOffset(),
    };

    checkUpdateBLWithLogistic(payload)
      .then(() => {
        handleUpdateBLs(payload);
      })
      .catch((error) => {
        const msgCode = get(error, "response.data.Message");

        if (msgCode === ERROR_MESSAGE_CODE.MSG_03) {
          setModalConfirmationConfig({
            title: "Cannot perform action",
            description: "1 or more eDOs are not valid to mass update.",
            subDescription: "Please check again",
          });

          setIsOpenModalConfirmation(true);
        }

        if (msgCode === ERROR_MESSAGE_CODE.MSG_04) {
          setModalConfirmationConfig({
            title: "Empty return depot selected",
            description:
              "There is at least 1 eDO which has been assigned Empty return depot",
            subDescription: "Do you want to continue?",
          });
          setIsOpenModalConfirmation(true);
        }
      });
  };

  const handleCloseDrawerEditBLLogistic = () => {
    form.resetFields();

    setEdo((prev) => ({
      ...prev,
      editBlWithLogisticRole: {
        isOpen: false,
        type: null,
        bl: null,
        canEdit: null,
        timestamp: null,
      },
      bl: null,
    }));
  };

  useEffect(() => {
    if (containerSizeType && collapseContainerItems.length) {
      let initialFormValues = {};

      containerSizeType.forEach((container) => {
        const emptyReturnDepotName = container.emptyReturnDepotCode
          ? {
              label: container.emptyReturnDepotName,
              value: container.emptyReturnDepotCode,
            }
          : null;

        initialFormValues = {
          ...initialFormValues,
          [`numberOfContainer-${container.containerSizeType}`]: container.count,
          [`emptyReturnDepotName-${container.containerSizeType}`]:
            emptyReturnDepotName,
          [`isOfHide-${container.containerSizeType}`]: Boolean(
            container.isOffHire
          ),
        };
      });

      form.setFieldsValue(initialFormValues);
    }
  }, [collapseContainerItems.length, containerSizeType, form]);

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      contentWrapperStyle={{ width: 664 }}
      open={editBlWithLogisticRole.isOpen}
      placement="right"
      className="rounded-tl-lg rounded-bl-lg"
      onClose={handleCloseDrawerEditBLLogistic}
      bodyStyle={{ padding: 0 }}
    >
      {isFetchingContainerSizeType ? (
        <ViewLoading />
      ) : (
        <div className="p-6">
          <div className="flex items-center justify-between">
            <Typography.Text className="!block font-antonio font-bold text-[1.625rem] text-blue-sapphire leading-8 -tracking-[0.01625rem]">
              Update Empty Return Depot
            </Typography.Text>
            <Image
              preview={false}
              className="cursor-pointer"
              alt="custom-drawer-close-icon"
              src={DrawerCloseIcon}
              onClick={handleCloseDrawerEditBLLogistic}
            />
          </div>
          <Divider className="mt-[1.875rem] bg-blue-solitude" />
          <Form layout="vertical">
            <FormSelect
              label="BL No."
              mode="tags"
              disabled
              suffixIcon={null}
              defaultValue={blDefaultOptions}
              options={tagOptions.blTagOptions}
              open={false}
              name="blNo"
              placeholder="Enter Email"
              customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
            />

            <FormSelect
              label="Actual Voyage No."
              mode="tags"
              disabled
              suffixIcon={null}
              defaultValue={voyageDefaultOptions}
              options={tagOptions.voyageTagOption}
              open={false}
              name="blNo"
              placeholder="Enter Email"
              customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
            />
          </Form>
          <Form
            className="pb-16"
            form={form}
            layout="vertical"
            onFinish={handleSubmitForm}
          >
            <Collapse
              items={collapseContainerItems}
              bordered={false}
              expandIconPosition="end"
              defaultActiveKey={collapseActiveKeys}
              className="mt-6 [&_.ant-collapse-item]:border-none [&_.ant-collapse-header]:border-solid [&_.ant-collapse-header]:!border-blue-solitude [&_.ant-collapse-header]:!p-4 [&_.ant-collapse-header]:bg-blue-solitude-light [&_.ant-collapse-content]:!bg-white [&_.ant-collapse-content]:pt-4 [&_.ant-collapse-content-box]:!p-0 [&_.ant-collapse-header-text]:text-base [&_.ant-collapse-header-text]:font-antonio [&_.ant-collapse-header-text]:font-bold [&_.ant-collapse-header-text]:leading-5 [&_.ant-collapse-header-text]:tracking-[-0.04px] [&_.ant-collapse-header-text]:!text-red-base [&_.ant-collapse-header-text]:select-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowUp}
                  />
                ) : (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowDown}
                  />
                )
              }
            />
            <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
              <div className="flex items-center justify-between">
                <CustomButtom
                  customClassName="min-w-[9.75rem]"
                  customColor="secondary"
                  customSize="medium"
                  htmlType="submit"
                  title="Save and Change status"
                  disabled={!ableToSubmit || isLoadingBulkUpdate}
                />
                <CustomButtom
                  title="Cancel"
                  variant="text"
                  customSize="medium"
                  customColor="secondary"
                  customClassName="w-32"
                  onClick={handleCloseDrawerEditBLLogistic}
                />
              </div>
            </div>
          </Form>
          <ModalConfirmation
            isDisableButton={isCheckingBLData || isUpdatingBLData}
            open={isOpenModalConfirmation}
            onOk={() => handleUpdateBLs()}
            onCancel={handleCloseModalConfirmation}
            customImage={
              <Image
                preview={false}
                alt="modal-confirm-change-edo-status-icon"
                src={ChangeToLogisticIcon}
              />
            }
            okText="Confirm"
            cancelText="Cancel"
            title={modalConfirmationConfig.title}
            description={modalConfirmationConfig.description}
            subDescription={modalConfirmationConfig.subDescription}
          />
        </div>
      )}
    </Drawer>
  );
};
