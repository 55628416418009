import { Col, Row } from "antd";

import { Dayjs } from "dayjs";
import { DropdownDatePicker } from "../DrawerEdoDetail/DropdownDatePicker";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { FormDatePicker } from "components/form/DatePicker";
import { FormInput } from "components/form/Input";
import { FormTextArea } from "components/form/Textarea";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import { useDateValidator } from "pages/eod-management/hooks/use-date-validator";
import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";

type IDeliveryInformation = {
  isMultiple: boolean;
  arrivalDate?: string | Dayjs;
  isReturnByDays: boolean;
  pickupDate: Dayjs | null;
  setFieldValue: (name: string, value: string | boolean) => void;
  isRequireUpdateReason: boolean;
  saveOnly: boolean;
};

export const DeliveryInformation = ({
  setFieldValue,
  isMultiple,
  pickupDate,
  arrivalDate,
  isReturnByDays,
  isRequireUpdateReason,
  saveOnly,
}: IDeliveryInformation) => {
  const {
    pickupDateValidator,
    returnDateValidator,
    onDisablePastPickupDate,
    onDisablePastReturnDate,
    numberReturnDateValidator,
  } = useDateValidator({ arrivalDate, pickupDate });

  return (
    <Row className="pb-2" gutter={[16, 0]}>
      {!isMultiple && (
        <>
          <Col span={12}>
            <FormInput name="securityCode" disabled label="Security Code" />
          </Col>
          <Col span={12}>
            <FormInput disabled name="releaseNo" label="Release No." />
          </Col>
        </>
      )}
      <Col span={12}>
        <FormDatePicker
          allowClear={false}
          name="pickupDate"
          label="Pick Up Date"
          placeholder="Select date"
          disabledDate={onDisablePastPickupDate}
          customDatePickerClassName="w-full"
          rules={[
            {
              required: true,
              message: <ErrorInput />,
            },
            {
              validator: pickupDateValidator,
              message: <ErrorInput message="Invalid value" />,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <DropdownDatePicker
          allowClear={false}
          isByDays={isReturnByDays}
          label="Return Date"
          placeholder="Select date"
          controlName="isReturnByDays"
          format={SYSTEM_DATE_FORMAT}
          setFieldValue={setFieldValue}
          subName="returnDate"
          name="returnFullDate"
          disabledDate={onDisablePastReturnDate}
          rules={[
            {
              required: true,
              message: <ErrorInput />,
            },
            {
              validator: returnDateValidator,
              message: <ErrorInput message="Invalid value" />,
            },
          ]}
          subRules={[
            {
              required: !saveOnly,
              message: <ErrorInput />,
            },
            {
              validator: (
                rule: RuleObject,
                value: StoreValue,
                callback: (error?: string) => void
              ) => numberReturnDateValidator(rule, value, callback, !saveOnly),

              message: (
                <ErrorInput message="Invalid input value. Must be at least 1 day" />
              ),
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <FormTextArea
          name="updateReason"
          label="Update Reason"
          placeholder="Enter Update Reason"
          autoSize={{ minRows: 5, maxRows: 5 }}
          disabled={!isRequireUpdateReason}
          rules={[
            {
              required: isRequireUpdateReason,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};
