import { Form, Modal, ModalProps, Skeleton, Typography } from "antd";
import { cloneDeep, compact } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Account, AccountRolesAndCountries } from "../interfaces";
import { useAssignRole, useGetAccountsRolesAndCountries } from "../queries";

import { DefaultOptionType } from "antd/es/select";
import DeleteIcon from "assets/icons/DeleteIcon.svg";
import PlusRedIcon from "assets/icons/PlusRedIcon.svg";
import { ModalConfirmation } from "components/ModalConfirmation";
import { CustomButtom } from "components/button/CustomButton";
import { ButtonVariant } from "components/button/types";
import { useNotification } from "hooks/use-notification";
import { cleanRoles } from "libs/helper";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { CustomSelect } from "./CustomSelect";

type ModalAssignRole = ModalProps & {
  roleOptions: DefaultOptionType[];
  countryOptions: DefaultOptionType[];
  onclose: () => void;
  account: Account;
  refreshList: () => void;
  timeStamp: string;
};

type AssignRoleForm = {
  items: AccountRolesAndCountries[];
};

const INITIAL_FORM_VALUES = {
  items: [{}],
};

export const ModalAssignRole = ({
  roleOptions,
  countryOptions,
  onclose,
  account,
  refreshList,
  timeStamp,
  ...restProps
}: ModalAssignRole) => {
  const [form] = Form.useForm<AssignRoleForm>();
  const items = Form.useWatch("items", form);

  const { data, isLoading } = useGetAccountsRolesAndCountries({
    ccgid: account.ccgid,
    timeStamp,
  });

  const { mutate: assignRole, isLoading: assigningRole } = useAssignRole();
  const { handleOpenSuccessNotification, handleOpenFailNotification } =
    useNotification();

  useEffect(() => {
    form.resetFields();
  }, [restProps.open]);

  const handleCancelModal = () => {
    form.resetFields();
    onclose();
  };

  const [isShowModalConfirmDelete, setIsShowModalConfirmDelete] =
    useState<boolean>(false);

  const handleConfirmDelete = () => {
    const values = form.getFieldsValue();
    setIsShowModalConfirmDelete(false);
    assignRole(
      {
        data: values.items,
        ccgid: account.ccgid,
      },
      {
        onSuccess: () => {
          refreshList();
          handleCancelModal();
          handleOpenSuccessNotification({
            message: "Roles have been assigned successfully!",
          });
        },
        onError: () => {
          handleOpenFailNotification({
            message: "Something went wrong, Please try again!",
          });
        },
      }
    );
  };

  const handleSubmitForm = () => {
    setIsShowModalConfirmDelete(true);
  };

  function addDisabledProperty(inputArray: any, referenceArray: any) {
    const inputCountries = inputArray.map((item: any) => item?.country);
    referenceArray.forEach((item: any) => {
      if (inputCountries.includes(item.label)) {
        item.disabled = true; //disable options
      }
    });
    return referenceArray;
  }

  const countrySelection = useMemo(() => {
    const countryClone = cloneDeep(
      compact(countryOptions.filter((item) => item.value !== ""))
    );
    return items && items[0].country
      ? addDisabledProperty(items, countryClone)
      : countryClone;
  }, [items]);

  useEffect(() => {
    if (!isLoading && data) {
      form.setFieldValue("items", cleanRoles(data.data));
    }
  }, [isLoading, data]);

  return (
    <Modal
      closable={false}
      footer={false}
      className="!w-[512px] !h-[253px] [&_.ant-modal-content]:p-0"
      onCancel={handleCancelModal}
      {...restProps}
    >
      <>
        <div className="custom-confirmation-modal-customer-management">
          <Typography.Text className="p-6 pb-0 !block text-blue-sapphire text-xl not-italic font-antonio font-bold tracking-[-0.5%] m-0">
            Assign Role
          </Typography.Text>
          <Typography.Text className="p-6 py-0 !block text-blue-rock-blue font-roboto text-sm not-italic font-normal mt-2">
            Choose Country and Roles for Account
          </Typography.Text>
          <div className="mt-3 pb-6">
            <Form
              className="max-h-[600px] overflow-y-auto"
              form={form}
              initialValues={INITIAL_FORM_VALUES}
              onFinish={handleSubmitForm}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              disabled={account.isExecutor}
            >
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      rowGap: 16,
                      flexDirection: "column",
                    }}
                  >
                    {fields.map((field) => (
                      <div className="bg-blue-solitude-light flex flex-col p-5 pb-0 mx-6 mb-0">
                        <div className="flex justify-between pb-5">
                          <Typography.Text className="text-blue-sapphire font-antonio font-bold leading-5 text-base tracking-[-0.25%]">
                            {`Country ${field.name + 1}`}
                          </Typography.Text>
                          {fields.length > 1 && (
                            <img
                              className="cursor-pointer"
                              onClick={() => remove(field.name)}
                              src={DeleteIcon}
                              alt=""
                            />
                          )}
                        </div>
                        {isLoading ? (
                          <Skeleton.Input
                            className="!w-full mb-2"
                            size="large"
                            active
                          />
                        ) : (
                          <>
                            <CustomSelect
                              label="Country"
                              name={[field.name, "country"]}
                              placeholder="Select Country"
                              options={countrySelection}
                              listHeight={200}
                              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
                              rules={[
                                {
                                  required: true,
                                  message: <ErrorInput />,
                                },
                              ]}
                            />
                            <CustomSelect
                              label="Role"
                              mode="multiple"
                              name={[field.name, "roles"]}
                              placeholder="Select Role"
                              options={roleOptions.filter(
                                (item) => item.value !== ""
                              )}
                              listHeight={200}
                              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
                              customClassName="[&_.ant-select-selection-item]:!my-1 !h-auto [&_.ant-select-selector]:!py-0 [&_.ant-select-selector]:!px-2.5 [&_.ant-select-selection-search]:!ms-[0.1875rem]"
                            />
                          </>
                        )}
                      </div>
                    ))}
                    {items?.length !== countrySelection?.length && (
                      <div
                        className="cursor-pointer flex mx-6"
                        onClick={() => add()}
                      >
                        <img
                          className="mr-2"
                          src={PlusRedIcon}
                          alt="add new account"
                        />
                        <Typography.Text className="text-red-base font-roboto font-normal text-base leading-6">
                          Add New Country
                        </Typography.Text>
                      </div>
                    )}
                  </div>
                )}
              </Form.List>
              <div className="flex gap-2 mx-6 mt-6">
                <CustomButtom
                  title="Cancel"
                  variant={ButtonVariant.outline}
                  customSize={"large"}
                  customColor={"secondary"}
                  onClick={handleCancelModal}
                  customClassName={"w-full"}
                  loading={assigningRole}
                />
                <CustomButtom
                  htmlType="submit"
                  title="Save"
                  variant={ButtonVariant.filled}
                  customSize={"large"}
                  customColor={"secondary"}
                  customClassName={"w-full"}
                  loading={assigningRole}
                />
              </div>
            </Form>
          </div>
          <ModalConfirmation
            open={isShowModalConfirmDelete}
            okText="Confirm"
            onCancel={() => setIsShowModalConfirmDelete(false)}
            onOk={handleConfirmDelete}
            cancelText={"Cancel"}
            title="Save changes"
            description="Do you want to save this role config? This action cannot be undone."
          />
        </div>
      </>
    </Modal>
  );
};
