import { Alert, notification } from "antd";
import { logout, save } from "libs/storage";
import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  useCheckAccountUpdate,
  useGetAccountInfo,
  useGetUserRole,
} from "./queries";
/* eslint-disable react-hooks/exhaustive-deps */
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { AppLoading } from "components/AppLoading";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import useDidMountEffect from "hooks/use-did-mount-effect";
import { getPingSSOInfo } from "hooks/use-get-ping-sso-user-info";
import { isPingSSO } from "hooks/use-is-ping-sso";
import { MasterDataService, useMasterData } from "hooks/use-master-data";
import { UserRole } from "interfaces/common-model";
import { loginRequest } from "libs/auth-config";
import { useNavigate } from "react-router-dom";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Profile } from "./types";

type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isCollapseSidebar, setIsCollapseSidebar] = useState(false);
  const [profile, setProfile] = useState<Profile>({
    displayName: "",
    mail: "",
  });
  const { data: userRole, isLoading: isLoadingGetRole } = useGetUserRole(
    isAuthenticated || Boolean(isPingSSO()),
  );

  const { mutate: checkAccountUpdate } = useCheckAccountUpdate();

  const { mutate: getUserAccountInfo, isLoading: isLoadingGetUserAccountInfo } =
    useGetAccountInfo();
  const pingProfile = getPingSSOInfo();
  const navigate = useNavigate();

  const { isPending: isMasterDataPending } = useMasterData([
    MasterDataService.DEPOTS,
  ]);

  const isLoading = isLoadingGetRole || isMasterDataPending;

  const requestProfileData = useCallback(() => {
    if (isPingSSO() && pingProfile?.email) {
      setProfile({
        displayName: pingProfile.email.split("@")[0],
        mail: pingProfile.email,
      });
    }
    // Silently acquires an access token which is then attached to a request for MS Graph data
    else {
      instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: accounts[0],
        })
        .then((response) => {
          getUserAccountInfo(response.accessToken, {
            onSuccess: (res) => {
              setProfile({
                displayName: res.displayName,
                mail: res.mail,
              });
            },
            onError: () => {
              logout();
            },
          });
        })
        .catch(() => {
          //Acquire token silent failure, and send an interactive request
          logout();
        });
    }
  }, [accounts, instance]);

  useEffect(() => {
    // TODO: using 'setTimeout' to ensure the app has authentication
    // Need to improve this functional
    setTimeout(() => {
      requestProfileData();
    }, 1000);
  }, [isAuthenticated]);

  useDidMountEffect(() => {
    if (userRole?.data?.roles?.length === 0) {
      navigate("/no-permission");
    }
    if (!isLoadingGetRole && userRole && userRole?.data?.roles[0]?.roleEnum) {
      if (
        [UserRole.ITOfficer, UserRole.Admin].includes(
          userRole.data?.roles[0].roleEnum,
        )
      ) {
        save(LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN, UserRole.ImportCS);
      }
      save(LOCAL_STORAGE_KEYS.USER_ROLE, userRole.data?.roles[0].roleEnum); //for now each user can have as many roles as he/she like because CMA like that
    }
  }, [isLoadingGetRole]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        checkAccountUpdate(undefined, {
          onSuccess: (data) => {
            if (data.data.updatedFieldNames.length > 0) {
              notification.open({
                message: null,
                description: (
                  <Alert
                    message={
                      <div>
                        {data.data.message} - Will log out after 10 seconds
                      </div>
                    }
                    type={"info"}
                    showIcon
                  />
                ),
                placement: "top",
                className: "custom-alert !p-0",
                duration: 10,
                style: {
                  width: 600,
                },
              });
              setTimeout(() => {
                logout();
              }, 10000);
            }
          },
        });
      },
      5 * 60 * 1000,
    ); // 5 minutes in milliseconds
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isLoading ? (
        <AppLoading />
      ) : (
        <div className="w-full h-screen flex flex-col">
          <Header
            isFetchingProfile={isLoadingGetUserAccountInfo}
            profile={profile}
          />
          <div className="flex border-t border-solid border-t-blue-solitude flex-1 bg-blue-solitude-light relative">
            <Sidebar
              isCollapseSidebar={isCollapseSidebar}
              setIsCollapseSidebar={setIsCollapseSidebar}
            />
            <div
              className={`p-6 flex-1 flex flex-col overflow-y-scroll h-[calc(100vh-80px)] transition-all duration-300 ease-out ${isCollapseSidebar ? "ml-[4.5rem]" : "ml-60"}`}
            >
              <div className="flex-1 max-h-fit">{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
