/* eslint-disable react-hooks/exhaustive-deps */
import { ActionDetail, HistoryUpdate } from "../interfaces";
import { ConfigProvider, Pagination, Table, Typography } from "antd";
import {
  DEFAULT_PERPAGE_TRANSATION_HISTORY,
  EActionType,
  UPDATE_DATE_FORMAT,
} from "constants/constants";
import { useEffect, useMemo, useState } from "react";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import { ColumnsType } from "antd/es/table";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { authConfig } from "constants/configs";
import { dateSorter } from "libs/helper";
import dayjs from "dayjs";
import get from "lodash/get";
import { useEdoContext } from "contexts/edo-context";
import { useGetUpdateList } from "../queries";

type TabItemProps = {
  callBackOutSide: (actualVoyageNo: string, containerNo: string) => void;
};

export const TabItemUpdate = ({ callBackOutSide }: TabItemProps) => {
  const [PageNum, setPageNum] = useState(1);
  const { isOpenHistoryTransaction, setEdo } = useEdoContext();

  const defaultTableColumns = (): ColumnsType<HistoryUpdate> => [
    {
      title: <TableHeaderTitle title="Updated Time" />,
      dataIndex: "modifiedDate",
      ellipsis: true,
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => dateSorter(a.modifiedDate, b.modifiedDate),
      render: (item) => (
        <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
          {dayjs(item).format(UPDATE_DATE_FORMAT)}
        </Typography.Text>
      ),
      width: "16%",
    },
    {
      title: <TableHeaderTitle title="Executor" />,
      dataIndex: "executorName",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.executorName?.localeCompare(b.executorName),
      render: (item) => (
        <CustomTooltip copytext={item} isShowCopy>
          <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
            {item}
          </Typography.Text>
        </CustomTooltip>
      ),
      width: "14%",
    },
    {
      title: <TableHeaderTitle title="Action Type" />,
      dataIndex: "actionDetail",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) =>
        a.actionDetail.actionType?.localeCompare(b.actionDetail.actionType),
      render: (item) => {
        const ableToOpenMail =
          [EActionType.SEND_EMAIL_TO_LOGISTIC, EActionType.RELEASE].includes(
            item.actionTypeId
          ) && item?.emailDetailId;
        return (
          <Typography.Text
            onClick={() => (ableToOpenMail ? openEmailDetail(item) : {})}
            className={`${
              ableToOpenMail ? "cursor-pointer underLineHover" : ""
            } font-roboto text-sm text-blue-midnight-express leading-4 font-normal`}
          >
            {item.actionType}
          </Typography.Text>
        );
      },
    },
    {
      title: <TableHeaderTitle title="Field Change" />,
      dataIndex: "fieldChange",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.fieldChange?.localeCompare(b.fieldChange),
      render: (item) => (
        <CustomTooltip copytext={item} isShowCopy>
          <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
            {item}
          </Typography.Text>
        </CustomTooltip>
      ),
    },
    {
      title: <TableHeaderTitle title="Old Value" />,
      dataIndex: "oldValue",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      sorter: (a, b) => a.oldValue?.localeCompare(b.oldValue),
      render: (_value: any, record: HistoryUpdate, _index: number) => {
        return (
          <CustomTooltip placement="top" copytext={record.oldValue} isShowCopy>
            <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
              {record.oldValue}
            </Typography.Text>
          </CustomTooltip>
        );
      },
      ellipsis: true,
    },
    {
      title: <TableHeaderTitle title="New Value" />,
      dataIndex: "newValue",
      showSorterTooltip: false,
      sortIcon: CustomSortIcon,
      ellipsis: true,
      sorter: (a, b) => a.newValue?.localeCompare(b.newValue),
      render: (_value: any, record: HistoryUpdate, _index: number) => {
        return (
          <CustomTooltip placement="top" copytext={record.newValue} isShowCopy>
            <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
              {record.newValue}
            </Typography.Text>
          </CustomTooltip>
        );
      },
    },
  ];

  const openEmailDetail = (rowData: ActionDetail) => {
    const currentId = isOpenHistoryTransaction.edoRowData;
    setEdo((prev) => ({
      ...prev,
      isOpenHistoryTransaction: {
        edoRowData: currentId,
        isOpen: false,
      },
      isOpenHistoryTransactionEmailDetail: {
        isOpen: true,
        emailDataOfEdoRow: rowData,
      },
    }));
  };

  useEffect(() => {
    setPageNum(1); // reset to page 1
  }, [isOpenHistoryTransaction.isOpen]);

  const { data, isFetching: isTableLoading } = useGetUpdateList({
    EdoId: isOpenHistoryTransaction.edoRowData as string,
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE_TRANSATION_HISTORY),
    BucketName: authConfig.s3Bucket || "",
    isTrigger: isOpenHistoryTransaction.isOpen,
    enabled: Boolean(isOpenHistoryTransaction.edoRowData),
  });

  const pagination = get(data, "data.paginatedResponseModel", {
    pageNum: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  });
  const dataSource = get(data, "data.paginatedResponseModel.data", []);
  const actualVoyageNo = get(data, "data.actualVoyageNo");
  const containerNo = get(data, "data.containerNo");

  useEffect(() => {
    actualVoyageNo &&
      containerNo &&
      callBackOutSide(actualVoyageNo, containerNo);
  }, [isTableLoading]);

  const tableColumns = useMemo(() => defaultTableColumns(), []);

  return (
    <>
      <div className="custom-table-customer-management [&_.customClass]:!min-h-[170px]">
        <ConfigProvider renderEmpty={EmptyTable}>
          <Table
            pagination={false}
            loading={isTableLoading}
            columns={tableColumns}
            rowClassName="font-roboto text-sm font-normal"
            dataSource={dataSource as HistoryUpdate[]}
          />
        </ConfigProvider>
      </div>
      {dataSource.length >= 1 && (
        <div className="p-6 flex justify-between items-center border border-blue-hawkes-blue">
          <div>
            <Typography.Text className="font-roboto text-sm not-italic font-normal leading-4 text-blue-waikawa-grey">
              Results:&nbsp;
            </Typography.Text>
            <Typography.Text className="text-blue-midnight-express text-sm not-italic font-normal left-5">
              {pagination?.totalRecords}
            </Typography.Text>
          </div>
          <div className="custom-pagination-customer-management">
            <Pagination
              nextIcon={<img alt="pagination-next" src={AngleRight} />}
              prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
              pageSize={pagination?.pageSize}
              current={PageNum}
              onChange={setPageNum}
              total={pagination?.totalRecords}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </>
  );
};
