import {
  DatePicker,
  DatePickerProps,
  Form,
  FormItemProps,
  Skeleton,
} from "antd";

import CalendarIcon from "assets/icons/CalendarIcon.svg";
import { Dayjs } from "dayjs";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import { formStyles } from "./styles";
import { useEffect } from "react";

// custom date picker header (week)
const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

const CustomRenderExtraFooter = () => (
  <div className="flex items-center justify-between gap-[1.625rem] pr-2.5">
    {daysOfWeek.map((day, index) => (
      <div key={index} className="flex-1 text-center">
        {day}
      </div>
    ))}
  </div>
);

type FormDatePickerProps = FormItemProps &
  DatePickerProps & {
    showToday?: boolean;
    customDatePickerClassName?: string;
    callBackOnChange?: (date: Dayjs | null) => void;
    isLoadingRecalculateArrivalDate?: boolean;
  };

export const FormDatePicker = ({
  name,
  disabled,
  showToday,
  allowClear,
  disabledDate,
  customDatePickerClassName,
  format = SYSTEM_DATE_FORMAT,
  placeholder = SYSTEM_DATE_FORMAT,
  callBackOnChange,
  isLoadingRecalculateArrivalDate = false,
  ...restProps
}: FormDatePickerProps) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--date-picker-dual-single-style-position-left",
      `20px`
    );
  }, []);

  return (
    <Form.Item className={formStyles.formItem.base} name={name} {...restProps}>
      {isLoadingRecalculateArrivalDate ? (
        <Skeleton.Input className="!w-full mb-2" size="large" active />
      ) : (
        <DatePicker
          allowClear={allowClear}
          format={format}
          disabled={disabled}
          showToday={showToday}
          placeholder={placeholder}
          disabledDate={disabledDate}
          renderExtraFooter={CustomRenderExtraFooter}
          suffixIcon={<img src={CalendarIcon} alt="calendarIcon" />}
          className={`${formStyles.datePicker.base} ${customDatePickerClassName}`}
          onChange={(value) =>
            callBackOnChange ? callBackOnChange(value) : {}
          }
        />
      )}
    </Form.Item>
  );
};
