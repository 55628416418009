import { DatePicker, TimeRangePickerProps, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import CalendarIcon from "assets/icons/CalendarIcon.svg";
import { PanelMode } from "interfaces/common-model";
import { RangePickerProps } from "antd/es/date-picker";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export const rangePresets: TimeRangePickerProps["presets"] = [
  {
    label: (
      <Typography.Text className="font-roboto font-bold text-sm text-blue-midnight-express leading-4 !block px-2.5 py-3">
        Today
      </Typography.Text>
    ),
    value: [dayjs(), dayjs()],
  },
  {
    label: (
      <Typography.Text className="font-roboto font-bold text-sm text-blue-midnight-express leading-4 !block  px-2.5 py-3">
        Last 7 Days
      </Typography.Text>
    ),
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: (
      <Typography.Text className="font-roboto font-bold text-sm text-blue-midnight-express leading-4 !block px-2.5 py-3">
        Last 14 Days
      </Typography.Text>
    ),
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: (
      <Typography.Text className="font-roboto font-bold text-sm text-blue-midnight-express leading-4 !block px-2.5 py-3">
        Last 30 Days
      </Typography.Text>
    ),
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: (
      <Typography.Text className="font-roboto font-bold text-sm text-blue-midnight-express leading-4 !block px-2.5 py-3">
        Last 60 Days
      </Typography.Text>
    ),
    value: [dayjs().add(-60, "d"), dayjs()],
  },
  {
    label: (
      <Typography.Text className="font-roboto font-bold text-sm text-blue-midnight-express leading-4 !block px-2.5 py-3">
        Last 90 Days
      </Typography.Text>
    ),
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

type CustomDateRangePicker = RangePickerProps & {
  customClassName?: string;
  placeHolder?: string;
  secondPlaceHolder?: string
};

export const DateRangePicker = ({
  customClassName = "",
  placeHolder = "From - To",
  secondPlaceHolder = "",
  ...restProps
}: CustomDateRangePicker) => {
  const [trigeerDaysOfWeeksStyle, setTrigeerDaysOfWeeksStyle] =
    useState<boolean>(false);
  const daysOfWeek = useMemo(() => {
    return ["S", "M", "T", "W", "T", "F", "S"];
  }, []);
  const customHeader = useMemo(() => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "27px",
          }}
        >
          {daysOfWeek.map((day, index) => (
            <div key={index} style={{ flex: 1, textAlign: "center" }}>
              {day}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "27px",
            paddingRight: "10px",
          }}
        >
          {daysOfWeek.map((day, index) => (
            <div key={index} style={{ flex: 1, textAlign: "center" }}>
              {day}
            </div>
          ))}
        </div>
      </>
    );
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--date-picker-dual-single-style-position-left",
      `152px`
    );
  }, [trigeerDaysOfWeeksStyle]);

  const handlePanelChange = useCallback(
    (e: any, mode: [PanelMode, PanelMode]) => {
      document.documentElement.style.setProperty(
        "--date-picker-visibility-custom",
        `${(mode[0] === "date" && mode[1]) === "date" ? "visible" : "hidden"}`
      );
    },
    []
  );
  const handleOnClick = () => {
    setTrigeerDaysOfWeeksStyle((pve) => !pve);
  };
  return (
    <div className="custom-rangePicker-customer-management">
      <RangePicker
        format={SYSTEM_DATE_FORMAT}
        className={`border border-blue-waikawa-grey hover:!border-blue-waikawa-grey rounded-none ${customClassName}`}
        placeholder={[placeHolder, secondPlaceHolder]}
        presets={rangePresets}
        suffixIcon={<img src={CalendarIcon} alt="calendarIcon" />}
        renderExtraFooter={() => customHeader}
        onPanelChange={handlePanelChange}
        {...restProps}
        onClick={handleOnClick}
      />
    </div>
  );
};
