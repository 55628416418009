import { FieldChange, SYSTEM_DATE_FORMAT } from "constants/constants";
import { useEffect, useMemo } from "react";

import { ColumnsType } from "antd/es/table";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { EditBLButton } from "../components/EditBLButton";
import { TreeDataType } from "../interfaces";
import { Typography } from "antd";
import dayjs from "dayjs";
import { useUserRole } from "../components/SelectRole";

export const useMainTableColumn = (): ColumnsType<TreeDataType> => {
  const role = useUserRole();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--visible-border-edit-bl",
      `${
        false // [UserRole.Counter, UserRole.Logistic].includes(role) disabled by CR: Update BLs to remove edit single button
          ? "1px dashed #D9DCE8" //theme("colors.blue.solitude") can't use theme set thought string
          : "none"
      }`
    );
  }, [role]);

  const columns = useMemo<ColumnsType<TreeDataType>>(
    () => [
      {
        title: "BL No.",
        dataIndex: "blNo",
        key: "blno",
        sortIcon: CustomSortIcon,
        render: (item) => (
          <div className="flex flex-col w-60">
            <CustomTooltip copytext={item} isShowCopy>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight leading-4 font-normal max-w-[fit-content]">
                {item}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
      },
      {
        title: "Vessel Name/ Carrier ",
        dataIndex: "voyageNameDetail",
        key: "voyageNameDetail",
        sortIcon: CustomSortIcon,
        sorter: (a, b) =>
          a.voyageNameDetail.vesselName?.localeCompare(
            b.voyageNameDetail.vesselName
          ),
        render: (item) => (
          <div className="flex flex-col w-full">
            <Typography.Text className="truncate font-roboto text-sm text-blue-midnight-express leading-5 font-normal">
              {item.vesselName}
            </Typography.Text>
            <CustomTooltip copytext={item.carrierName} isShowCopy>
              <Typography.Text className="!block truncate font-roboto text-xs text-blue-waikawa-grey leading-5 font-normal max-w-[fit-content]">
                {item.carrierName}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: "Actual Voyage No.",
        dataIndex: "actualVoyageNo",
        key: "actualVoyageNo",
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.actualVoyageNo?.localeCompare(b.actualVoyageNo),
        render: (value, item) => (
          <div className="flex flex-col">
            <CustomTooltip
              copytext={
                [FieldChange.VOYAGE_NO, FieldChange.CHANGE_ALL].includes(
                  item.dataChangedStatus
                )
                  ? "Changed after release"
                  : value
              }
              isShowCopy={false}
            >
              <Typography.Text
                className={`font-roboto text-sm text-blue-midnight-express leading-5 font-normal ${
                  [FieldChange.VOYAGE_NO, FieldChange.CHANGE_ALL].includes(
                    item.dataChangedStatus
                  ) && `!text-red-base`
                }`}
              >
                {value}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: "LARA Voyage No. ",
        dataIndex: "laraVoyageNo",
        key: "laraVoyageNo",
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.actualVoyageNo?.localeCompare(b.actualVoyageNo),
        render: (item) => (
          <div className="flex flex-col">
            <Typography.Text className="font-roboto text-sm text-blue-midnight-express leading-5 font-normal">
              {item}
            </Typography.Text>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        render: (value, item) => (
          <div className="flex flex-col">
            <CustomTooltip
              copytext={
                [FieldChange.ETA, FieldChange.CHANGE_ALL].includes(
                  item.dataChangedStatus
                )
                  ? "Changed after release"
                  : value && dayjs(value).format(SYSTEM_DATE_FORMAT)
              }
              isShowCopy={false}
            >
              <Typography.Text
                className={`font-roboto text-sm text-blue-midnight-express leading-5 font-normal ${
                  [FieldChange.ETA, FieldChange.CHANGE_ALL].includes(
                    item.dataChangedStatus
                  ) && `!text-red-base`
                }`}
              >
                {value && dayjs(value).format(SYSTEM_DATE_FORMAT)}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: "",
        dataIndex: "edit",
        key: "edit",
        render: (_text: string, record: TreeDataType, _index: number) => (
          <EditBLButton record={record} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [role]
  );

  return columns;
};
