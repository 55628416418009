import { ReactNode } from "react";
import { Typography } from "antd";

type TableHeaderTitleProps = {
  title: string | ReactNode;
  customClassName?: string;
};

export const TableHeaderTitle = ({
  title,
  customClassName,
}: TableHeaderTitleProps) => (
  <Typography.Text
    className={`font-roboto text-sm text-blue-sapphire leading-4 font-normal ${customClassName}`}
  >
    {title}
  </Typography.Text>
);
