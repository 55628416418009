import { Tooltip, TooltipProps, Typography } from "antd";

import CopyIcon from "assets/icons/CopyIcon.svg";
import { TooltipsCopy } from "./TooltipsCopy";

type ITooltip = TooltipProps & {
  copytext: string | React.ReactNode;
  isShowCopy: boolean;
  children: React.ReactNode;
  callBackOnMouseEnter?: (type: string) => void;
  callBackonMouseLeave?: (type: string) => void;
  customToolTip?: string;
  customInnerClass?: string;
};

export const CustomTooltip = ({
  copytext,
  isShowCopy,
  children,
  callBackOnMouseEnter,
  callBackonMouseLeave,
  customToolTip='',
  customInnerClass='',
  ...props
}: ITooltip) => {
  return (
    <Tooltip
      {...props}
      className="cursor-default"
      rootClassName={`[&_.ant-tooltip-inner]:p-3 ${customToolTip}`}
      title={
        <div
          onMouseEnter={() =>
            callBackOnMouseEnter ? callBackOnMouseEnter("enter") : {}
          }
          onMouseLeave={() =>
            callBackonMouseLeave ? callBackonMouseLeave("leave") : {}
          }
          className={`flex items-center ${customInnerClass}`}
        >
          <Typography.Text className="font-roboto font-normal text-sm !text-white leading-5">
            {copytext}
          </Typography.Text>
          {isShowCopy && (
            <TooltipsCopy 
            trigger={["click"]} copytext={`${copytext}`} placement="right"
            >
            <img
              className="ml-3 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(copytext as string);
              }}
              src={CopyIcon}
              alt="copyIcon"
            />
            </TooltipsCopy>
          )}
        </div>
      }
      arrow={false}
    >
      {children}
    </Tooltip>
  );
};
