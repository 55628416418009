import type { Dayjs } from "dayjs";
import { EDO_STATUS } from "constants/constants";
import { ExpandedChildDataType } from "./interfaces";
import dayjs from "dayjs";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/duration"));

export const filterCanEditedoByRole = (listData: ExpandedChildDataType[]) => {
  return listData.filter((item) => item.canEdit).map((item) => item.id);
};

export const convertDayjsDataToUtc = (stringDate: Dayjs[], index: number) => {
  return stringDate
    ? index === 0
      ? dayjs.utc(stringDate[index]).startOf("day").format()
      : dayjs.utc(stringDate[index]).endOf("day").format()
    : null;
};

type ReversedEdoStatus = { [key: number]: { label: string } };

export const reversedEDO_STATUS: ReversedEdoStatus = Object.entries(
  EDO_STATUS
).reduce((acc: any, [key, value]) => {
  acc[value.value] = { label: value.label };
  return acc;
}, {});
