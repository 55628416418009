import dayjs, { Dayjs } from "dayjs";

import { RuleObject } from "antd/es/form";
import { UserRole } from "interfaces/common-model";
import { useUserRole } from "../components/SelectRole";

type UseDateValidatorProps = {
  arrivalDate?: string | Dayjs | null;
  pickupDate: string | Dayjs | null;
};

export const useDateValidator = ({
  arrivalDate,
  pickupDate,
}: UseDateValidatorProps) => {
  const role = useUserRole();
  const isCounter = role === UserRole.Counter;

  const pickupDateValidator = (
    _rule: RuleObject,
    value: Dayjs,
    callback: (error?: string) => void
  ): Promise<void | any> | void => {
    if (value && arrivalDate) {
      const minDate = dayjs(arrivalDate).endOf("day");
      if (value.isBefore(minDate.subtract(1, "day"))) {
        callback("Invalid date");
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const returnDateValidator = (
    _rule: RuleObject,
    value: Dayjs,
    callback: (error?: string) => void
  ): Promise<void | any> | void => {
    if (value && pickupDate) {
      const minDate = dayjs(pickupDate).endOf("day");
      if (value.isBefore(minDate.subtract(1, "day"))) {
        callback("Invalid date");
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const numberReturnDateValidator = (
    _rule: RuleObject,
    value: number,
    callback: (error?: string) => void,
    accept?: boolean
  ): Promise<void | any> | void => {
    //0 counted as one valid input, and also return empty so first rule check will run
    if (!accept) return callback();
    if (!value && value !== 0) return callback();
    if (value <= 0) {
      callback("Invalid input value. Must be at least 1 day");
    } else {
      callback();
    }
  };

  const onDisablePastPickupDate = (current: Dayjs) => {
    const today = dayjs().endOf("day");
    const minDate = isCounter ? dayjs(arrivalDate).endOf("day") : today;

    return (
      current.isBefore(minDate.subtract(1, "day")) || current.isSame(minDate)
    );
  };

  const onDisablePastReturnDate = (current: Dayjs) => {
    const today = dayjs().endOf("day");

    const minDate = pickupDate
      ? dayjs(pickupDate).endOf("day")
      : isCounter
      ? dayjs(arrivalDate).endOf("day")
      : today;

    return (
      current.isBefore(minDate.subtract(1, "day")) || current.isSame(minDate)
    );
  };

  return {
    pickupDateValidator,
    returnDateValidator,
    onDisablePastPickupDate,
    onDisablePastReturnDate,
    numberReturnDateValidator,
  };
};
