import { Avatar, Image, Typography } from "antd";

import Boxes from "assets/icons/Boxes.svg";
import FixedAmount from "assets/icons/FixedAmount.svg";
import Truck from "assets/icons/Truck.svg";
import { useMemo } from "react";

export enum FieldType {
  "number" = "number",
  "size" = "size",
  "seal" = "seal",
}

type ContainerFieldProps = {
  type?: FieldType;
  value?: string | number;
};

export const ContainerField = ({
  type = FieldType.number,
  value = "",
}: ContainerFieldProps) => {
  const field = useMemo(() => {
    let icon;
    let title;

    switch (type) {
      case FieldType.number:
        icon = Truck;
        title = "Container Number";
        break;
      case FieldType.size:
        icon = Boxes;
        title = "Container Size";
        break;
      case FieldType.seal:
        icon = FixedAmount;
        title = "Seal Number";
        break;
      default:
        icon = Truck;
        title = "";
        break;
    }

    return {
      icon,
      title,
    };
  }, [type]);

  return (
    <div className="flex items-center justify-start h-full">
      <Avatar
        rootClassName="!block"
        className="bg-blue-lavender flex items-center justify-center min-w-[2rem]"
        size={32}
        icon={<Image preview={false} src={field.icon} />}
      />
      <div className="flex flex-col items-start pl-3">
        <Typography.Text className="!block font-roboto font-normal text-sm leading-4 text-blue-waikawa-grey">
          {field.title}
        </Typography.Text>
        <Typography.Text className="!block font-roboto font-normal text-sm leading-4 text-blue-sapphire mt-2">
          {value}
        </Typography.Text>
      </div>
    </div>
  );
};
