import {
  Button,
  DatePicker,
  DatePickerProps,
  Dropdown,
  DropdownProps,
  Form,
  FormItemProps,
  Image,
  InputNumber,
  InputNumberProps,
  MenuProps,
  Skeleton,
  Typography,
} from "antd";
import { useEffect, useState } from "react";

import ArrowDown from "assets/icons/ArrowDown.svg";
import CalendarIcon from "assets/icons/CalendarIcon.svg";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import { formStyles } from "components/form/styles";

// TODO: Need to refactor
type DropdownDatePickerProps = FormItemProps &
  DropdownProps &
  DatePickerProps &
  InputNumberProps & {
    showToday?: boolean;
    isByDays: boolean;
    subName?: string;
    subRules?: FormItemProps["rules"];
    controlName?: string;
    isLoadingRecalculateArrivalDate?: boolean;
    setFieldValue: (name: string, value: string | boolean) => void;
  };

enum DateType {
  ByDate = "byDate",
  ByDays = "byDays",
}

const dateTypeOptions = [
  { label: "By Date", key: DateType.ByDate },
  { label: "By Days", key: DateType.ByDays },
];

// custom date picker header (week)
const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

const CustomRenderExtraFooter = () => (
  <div className="flex items-center justify-between gap-[1.625rem] pr-2.5">
    {daysOfWeek.map((day, index) => (
      <div key={index} className="flex-1 text-center">
        {day}
      </div>
    ))}
  </div>
);

export const DropdownDatePicker = ({
  name,
  isByDays,
  disabled,
  subRules,
  subName,
  rules,
  showToday,
  allowClear,
  format = SYSTEM_DATE_FORMAT,
  controlName,
  placeholder,
  disabledDate,
  setFieldValue,
  label,
  isLoadingRecalculateArrivalDate = false,
  ...restProps
}: DropdownDatePickerProps) => {
  const [dateType, setDateType] = useState<DateType>(DateType.ByDate);

  const hanleClickDateType: MenuProps["onClick"] = ({ key }) => {
    setDateType(key as DateType);
    setFieldValue(controlName as string, Boolean(key === DateType.ByDays));
  };

  useEffect(() => {
    setDateType(isByDays ? DateType.ByDays : DateType.ByDate);
  }, [isByDays]);

  return (
    <Form.Item
      className={`${formStyles.formItem.base} mb-0`}
      label={label}
      {...restProps}
    >
      {isLoadingRecalculateArrivalDate ? (
        <Skeleton.Input className="!w-full mb-2" size="large" active />
      ) : (
        <>
          <Form.Item
            className="absolute top-1 left-[0.125rem] z-20 mb-0"
            name={controlName}
          >
            <Dropdown
              trigger={["click"]}
              disabled={disabled}
              menu={{
                items: dateTypeOptions,
                onClick: hanleClickDateType,
                rootClassName:
                  "!rounded-none !p-0 border border-solid border-blue-sapphire [&>li]:h-10 [&>li]:!rounded-none [&>li>span]:font-normal [&>li>span]:font-roboto [&>li>span]:text-blue-sapphire [&>li>span]:text-sm !mt-1",
              }}
            >
              <Button className="flex items-center p-3 rounded-none h-8 border-none [&:not(:disabled):hover]:!border-none disabled:bg-blue-solitude-second disabled:border-grey-slate-grey">
                <div className="flex items-center border-r border-solid border-r-blue-rock-blue pr-2 h-4">
                  <Typography.Text
                    className={`font-roboto font-normal text-sm leading-4 text-blue-waikawa-grey ${
                      disabled ? "!text-grey-slate-grey" : ""
                    } !block`}
                  >
                    {dateType === "byDate"
                      ? dateTypeOptions[0].label
                      : dateTypeOptions[1].label}
                  </Typography.Text>
                  <Image
                    className="ml-2 !w-4 !h-4 !-mt-[0.25rem]"
                    preview={false}
                    alt="dropdown-date-picker-arrow-down"
                    src={ArrowDown}
                  />
                </div>
              </Button>
            </Dropdown>
          </Form.Item>
          {dateType === DateType.ByDate ? (
            <Form.Item name={name} className="mb-0" rules={rules}>
              <DatePicker
                allowClear={allowClear}
                format={format}
                className={formStyles.datePicker.withDropdown}
                suffixIcon={<img src={CalendarIcon} alt="calendarIcon" />}
                name={name}
                disabled={disabled}
                showToday={showToday}
                placeholder={placeholder}
                disabledDate={disabledDate}
                renderExtraFooter={CustomRenderExtraFooter}
              />
            </Form.Item>
          ) : (
            <Form.Item name={subName} className="mb-0" rules={subRules}>
              <InputNumber
                type="number"
                className={formStyles.input.number}
                placeholder="Enter number of days"
                controls={false}
                disabled={disabled}
                suffix={
                  <Typography.Text className="font-roboto font-normal text-xs leading-[0.875rem] !text-blue-waikawa-grey">
                    Days
                  </Typography.Text>
                }
                {...restProps}
              />
            </Form.Item>
          )}
        </>
      )}
    </Form.Item>
  );
};
