import { Col, Row } from "antd";

import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";
import { FormDatePicker } from "components/form/DatePicker";
import { FormInput } from "components/form/Input";
import { FormTextArea } from "components/form/Textarea";
import { Toggle } from "components/Toggle";
import { SYSTEM_DATE_FORMAT } from "constants/constants";
import { Dayjs } from "dayjs";
import { EdoStatus } from "interfaces/common-model";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import DepotSelect from "pages/depot-management/components/DepotSelect";
import { EdoDetailFormConfig } from "pages/eod-management/hooks/use-config-status-matrix";
import { useDateValidator } from "pages/eod-management/hooks/use-date-validator";
import { DropdownDatePicker } from "./DropdownDatePicker";

type DeliveryInfoProps = {
  isByDays: boolean;
  config: EdoDetailFormConfig;
  arrivalDate?: Dayjs | null;
  pickupDate: Dayjs | null;
  isFieldRequiredForChangeStatus: boolean;
  setFieldValue: (name: string, value: string | boolean) => void;
  isEnabledUpdateReason: boolean;
  nextStatus: EdoStatus | null | undefined;
  isLoadingRecalculateArrivalDate?: boolean;
};

export const DeliveryInfo = ({
  isByDays,
  config,
  pickupDate,
  arrivalDate,
  setFieldValue,
  isFieldRequiredForChangeStatus,
  isEnabledUpdateReason,
  nextStatus,
  isLoadingRecalculateArrivalDate,
}: DeliveryInfoProps) => {
  const {
    pickupDateValidator,
    returnDateValidator,
    onDisablePastPickupDate,
    onDisablePastReturnDate,
    numberReturnDateValidator,
  } = useDateValidator({ arrivalDate, pickupDate });

  return (
    <Row gutter={[16, 0]}>
      <Col span={12}>
        <FormInput
          disabled={config?.securityCode.disabled}
          name="securityCode"
          label="Security Code"
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.securityCode.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <FormInput
          disabled={config?.releaseNo.disabled}
          name="releaseNo"
          label="Release No."
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.releaseNo.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <FormDatePicker
          isLoadingRecalculateArrivalDate={isLoadingRecalculateArrivalDate}
          allowClear={false}
          name="pickupDate"
          label="Pick Up Date"
          placeholder="Select date"
          disabled={config?.pickupDate.disabled}
          disabledDate={onDisablePastPickupDate}
          showToday={false}
          customDatePickerClassName="w-full"
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.pickupDate.required
                : false,
              message: <ErrorInput />,
            },
            {
              validator: pickupDateValidator,
              message: <ErrorInput message="Invalid value" />,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <DropdownDatePicker
          allowClear={false}
          isByDays={isByDays}
          showToday={false}
          disabled={
            config?.returnDate.disabled || config.returnFullDate.disabled
          }
          isLoadingRecalculateArrivalDate={isLoadingRecalculateArrivalDate}
          disabledDate={onDisablePastReturnDate}
          label="Return Date"
          placeholder="Select date"
          controlName="isReturnByDays"
          format={SYSTEM_DATE_FORMAT}
          setFieldValue={setFieldValue}
          subName="returnDate"
          name="returnFullDate"
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.returnFullDate.required
                : false,
              message: <ErrorInput />,
            },
            {
              validator: returnDateValidator,
              message: <ErrorInput message="Invalid value" />,
            },
          ]}
          subRules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.returnDate.required
                : false,
              message: <ErrorInput />,
            },
            {
              validator: (
                rule: RuleObject,
                value: StoreValue,
                callback: (error?: string) => void,
              ) =>
                numberReturnDateValidator(
                  rule,
                  value,
                  callback,
                  isFieldRequiredForChangeStatus,
                ),
              message: (
                <ErrorInput message="Invalid input value. Must be at least 1 day" />
              ),
            },
          ]}
        />
      </Col>
      <Col className="relative" span={24}>
        <DepotSelect
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? config.emptyReturnDepotName.required
                : false,
              message: <ErrorInput />,
            },
          ]}
          isLogHistory
          name="emptyReturnDepotName"
          label="Empty Return Depot"
          placeholder="Choose Empty Return Depot"
          disabled={config?.emptyReturnDepotName.disabled}
          filterDepots={(depot) => depot.status}
        />
        <div className="absolute -top-2 right-0">
          <Toggle
            name="isOffHire"
            label="Off-Hire"
            disabled={config?.isOffHire.disabled}
            customFormItemClassName="[&_.ant-form-item-row]:!flex-row [&_.ant-form-item-row]:!justify-end [&_.ant-form-item-row]:!flex-row [&_.ant-form-item-label]:!p-0 [&_.ant-form-item-label>label]:pt-[0.125rem] [&_.ant-form-item-label>label]:text-sm [&_.ant-form-item-label>label]:text-blue-sapphire [&_.ant-form-item-label>label]:font-roboto [&_.ant-form-item-label>label]:font-normal [&_.ant-form-item-label>label]:leading-8 [&_.ant-form-item-control]:!w-auto [&_.ant-form-item-control]:!grow-0 [&_.ant-form-item-control]:!mr-2"
            rules={[
              {
                required: isFieldRequiredForChangeStatus
                  ? config.isOffHire.required
                  : false,
                message: <ErrorInput />,
              },
            ]}
          />
        </div>
      </Col>
      <Col span={24}>
        <FormTextArea
          name="updateReason"
          label="Update Reason"
          placeholder="Enter Update Reason"
          disabled={!isEnabledUpdateReason || config.updateReason.disabled}
          autoSize={{ minRows: 5, maxRows: 5 }}
          rules={[
            {
              required: isFieldRequiredForChangeStatus
                ? nextStatus === EdoStatus.Unrelease
                  ? false
                  : config.updateReason.required
                : false,
              message: <ErrorInput />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};
