import EditIcon from "assets/icons/PencilEditIcon.svg";
import { Image } from "antd";
import { TreeDataType } from "../interfaces";
import { UserRole } from "interfaces/common-model";
import dayjs from "dayjs";
import find from "lodash/find";
import { useEdoContext } from "contexts/edo-context";
import { useUserRole } from "./SelectRole";

type EditBLButtonProps = {
  record: TreeDataType;
};

// Because only this button subscribes the "edo-context"
// Therefore, it is necessary to separate it into a separate component
// to avoid causing re-rendering effects on other components.
export const EditBLButton = ({ record }: EditBLButtonProps) => {
  const role = useUserRole();
  const { setEdo } = useEdoContext();

  const handleEditBL = (record: TreeDataType) => {
    const canEdit = Boolean(find(record.deliveryOrders, { canEdit: true }));
    role === UserRole.Logistic
      ? setEdo((prev) => ({
          ...prev,
          bl: record,
          editBlWithLogisticRole: {
            isOpen: true,
            type: "Single",
            bl: record,
            canEdit,
            timestamp: dayjs().toISOString(),
          },
        }))
      : setEdo((prev) => ({
          ...prev,
          editBlWithCounterRole: {
            isOpen: true,
            type: "Single",
            bl: record,
            canEdit,
            timestamp: dayjs().toISOString(),
          },
        }));
  };

  return (
    <div
      className={`cursor-pointer flex justify-center w-full item-center ${
        false //[UserRole.Counter, UserRole.Logistic].includes(role) disabled by CR: Update BLs to remove edit single button
          ? "visible"
          : "invisible"
      }`}
      onClick={() => handleEditBL(record)}
    >
      <Image preview={false} src={EditIcon} alt="edit-bl-pencil-icon" />
    </div>
  );
};
