import { Dispatch, useEffect, useMemo } from "react";
import { EdoStatus, UserRole } from "interfaces/common-model";

import { CustomButtom } from "components/button/CustomButton";
import { CustomDropdownButton } from "components/button/CustomDropdownButton";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { MenuProps } from "antd";
import { NextStatusData } from "pages/eod-management/hooks/use-config-status-matrix";

type FooterDrawerProps = {
  isDisable: boolean;
  userRole: UserRole;
  isSOC?: boolean;
  loading?: boolean;
  loadingUpdate?: boolean;
  edoStatus: EdoStatus;
  nextStatusData: NextStatusData;
  setHasFormFooter: Dispatch<React.SetStateAction<boolean>>;
  onTriggerValidateForm: (
    value: boolean,
    nextStatus: EdoStatus | undefined | null
  ) => void;
  onCloseDrawerEdoDetail: () => void;
  canRestore: boolean;
  isDisableAllWhenBulkUpdate: boolean;
};

export const FormFooter = ({
  isDisable = false,
  isSOC,
  userRole,
  edoStatus,
  loading,
  loadingUpdate,
  nextStatusData,
  setHasFormFooter,
  onTriggerValidateForm,
  onCloseDrawerEdoDetail,
  canRestore = true,
  isDisableAllWhenBulkUpdate,
}: FooterDrawerProps) => {
  const buttonMenuDropdown = nextStatusData.dropdown;

  const isTriggerWithLogistic =
    userRole === UserRole.Logistic &&
    [EdoStatus.Release, EdoStatus.ReleaseUpdate, EdoStatus.WaitForLog].includes(
      edoStatus
    );

  const isImportCSandDONewStatus =
    userRole === UserRole.ImportCS && edoStatus === EdoStatus.New;

  // combine with save button
  // importCS + (wait_for_log / ready_for_release)
  // counter + (release / release_update)
  const isCombineSaveButton =
    (userRole === UserRole.ImportCS &&
      [
        EdoStatus.WaitForLog,
        EdoStatus.ReadyForRelease,
        EdoStatus.Unrelease,
      ].includes(edoStatus)) ||
    (userRole === UserRole.Counter &&
      (edoStatus === EdoStatus.Release ||
        edoStatus === EdoStatus.ReleaseUpdate));

  const footerData = useMemo(() => {
    let changeStatusButtonTitle = "";
    let nextActions: MenuProps["items"] = [];
    let changeStatusButtonKey = 4;
    let actions = [...buttonMenuDropdown];

    if (isCombineSaveButton) {
      actions = [
        {
          key: null,
          label: "Save",
        },
        ...actions,
      ];
    }

    if (buttonMenuDropdown.length < 1) {
      return {
        nextActions,
        changeStatusButtonTitle,
        changeStatusButtonKey,
      };
    }

    if (isImportCSandDONewStatus) {
      // If the "isSOC" is true, then the suggestion button is "Ready for release"
      // However, still send the value of "Wait for log" status to the backend
      actions = isSOC
        ? actions.slice(1)
        : [...actions.slice(0, 1), ...actions.slice(2, 4)];
    }

    // suggestion button is first element in dropdown array
    changeStatusButtonTitle = actions[0].label || "";
    changeStatusButtonKey = actions[0].key as number;

    // special case for role = "Import CS" and & EDO status = "new"
    if (actions.length > 1) {
      nextActions = actions.slice(1) as ItemType[];
    } else {
      nextActions = actions as ItemType[];
    }

    return {
      nextActions,
      changeStatusButtonTitle,
      changeStatusButtonKey,
    };
  }, [
    buttonMenuDropdown,
    isCombineSaveButton,
    isImportCSandDONewStatus,
    isSOC,
  ]);

  // check user can change status & edit info
  const isChangeStatusAndEditInfo =
    nextStatusData.canChangeStatus &&
    nextStatusData.canEditInfo &&
    buttonMenuDropdown.length > 0;

  // check only change status
  const isOnlyChangeStatus =
    nextStatusData.canChangeStatus && !nextStatusData.canEditInfo;

  // check can edit info or not
  const isOnlyEditInfo =
    (!nextStatusData.canChangeStatus && nextStatusData.canEditInfo) ||
    (nextStatusData.canChangeStatus &&
      nextStatusData.canEditInfo &&
      buttonMenuDropdown.length === 0);

  const handleCloseDrawerEdoDetail = () => {
    onCloseDrawerEdoDetail();
  };

  // trigger when user select item from dropdown button
  const onSelectDropdownItem = (item: ItemType) => {
    const isNoNeedValidateFields = [
      EdoStatus.Delete,
      EdoStatus.Shortship,
      EdoStatus.New,
    ].includes(edoStatus);
    onTriggerValidateForm(!isNoNeedValidateFields, Number(item?.key));
  };

  // when user click to suggest button
  const onSuggestButtonClick = () => {
    const nextStatusKey =
      isImportCSandDONewStatus && isSOC
        ? EdoStatus.WaitForLog
        : footerData.changeStatusButtonKey;

    let isValidate = true;

    if (
      nextStatusKey === null && //if action is not "change status action" (as "Save" button) -> no validation
      !(
        (
          userRole === UserRole.Counter && //except action update field "Update reason" of release/release of role counter
          [EdoStatus.Release, EdoStatus.ReleaseUpdate].includes(edoStatus)
        ) // the "Save" button tread as "change status action"
      )
    ) {
      isValidate = false;
    }

    onTriggerValidateForm(isValidate, nextStatusKey);
  };

  // when user save edo data only
  const onEditInfo = () => {
    onTriggerValidateForm(isTriggerWithLogistic, null);
  };

  useEffect(() => {
    setHasFormFooter(
      isChangeStatusAndEditInfo || isOnlyChangeStatus || isOnlyEditInfo
    );
  }, [
    isChangeStatusAndEditInfo,
    isOnlyChangeStatus,
    isOnlyEditInfo,
    setHasFormFooter,
  ]);

  if (isChangeStatusAndEditInfo) {
    if (isCombineSaveButton) {
      return (
        <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
          <div className="flex items-center justify-between">
            <CustomDropdownButton
              customSize="medium"
              customColor="secondary"
              loading={loading || loadingUpdate}
              customClassName={`[&_.ant-btn-compact-first-item]:w-auto [&_.ant-btn-compact-first-item]:min-w-[10rem] ${
                isDisableAllWhenBulkUpdate &&
                `[&_.ant-btn-compact-item]:bg-[#EFEFF1]`
              }`}
              customMenuClassName="w-[12.75rem]"
              callBackDataClickDropDown={onSelectDropdownItem}
              callBackClickButton={onSuggestButtonClick}
              items={footerData.nextActions}
              title={footerData.changeStatusButtonTitle}
              disabled={isDisableAllWhenBulkUpdate || isDisable}
            />
            <CustomButtom
              title="Cancel"
              variant="text"
              disabled={loading || loadingUpdate || isDisable}
              customSize="medium"
              customColor="secondary"
              customClassName="w-32"
              onClick={handleCloseDrawerEdoDetail}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {buttonMenuDropdown.length === 1 ? (
                <CustomButtom
                  customClassName="min-w-[9.75rem]"
                  customColor="secondary"
                  loading={loading}
                  customSize="medium"
                  title={footerData.changeStatusButtonTitle}
                  onClick={onSuggestButtonClick}
                  disabled={isDisableAllWhenBulkUpdate || isDisable}
                />
              ) : (
                <CustomDropdownButton
                  customSize="medium"
                  loading={loading}
                  customColor="secondary"
                  customClassName={`[&_.ant-btn-compact-first-item]:w-auto [&_.ant-btn-compact-first-item]:min-w-[10rem] ${
                    isDisableAllWhenBulkUpdate &&
                    `[&_.ant-btn-compact-item]:bg-[#EFEFF1]`
                  }`}
                  customMenuClassName="w-[12.75rem]"
                  callBackDataClickDropDown={onSelectDropdownItem}
                  callBackClickButton={onSuggestButtonClick}
                  items={footerData.nextActions}
                  title={footerData.changeStatusButtonTitle}
                  disabled={isDisableAllWhenBulkUpdate || isDisable}
                />
              )}

              <CustomButtom
                customClassName="w-32 ml-4 [&_.anticon]:!text-red-base"
                loading={loadingUpdate}
                customColor="secondary"
                customSize="medium"
                variant="outline"
                title="Save"
                disabled={
                  loadingUpdate || isDisableAllWhenBulkUpdate || isDisable
                }
                onClick={onEditInfo}
              />
            </div>
            <CustomButtom
              title="Cancel"
              variant="text"
              disabled={loading || isDisable}
              customSize="medium"
              customColor="secondary"
              customClassName="w-32"
              onClick={handleCloseDrawerEdoDetail}
            />
          </div>
        </div>
      );
    }
  }

  if (isOnlyChangeStatus) {
    return (
      <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
        <div className="flex items-center justify-between">
          {buttonMenuDropdown.length === 1 ? (
            <CustomButtom
              customClassName="min-w-[9.75rem] [&_.anticon.anticon-loading.anticon-spin]:text-white"
              customColor="secondary"
              loading={loading}
              customSize="medium"
              onClick={onSuggestButtonClick}
              title={footerData.changeStatusButtonTitle}
              disabled={
                isDisable ||
                ([EdoStatus.Shortship, EdoStatus.Delete].includes(edoStatus) &&
                  !canRestore)
              }
            />
          ) : (
            <CustomDropdownButton
              customSize="medium"
              loading={loading}
              customColor="secondary"
              customClassName="[&_.ant-btn-compact-first-item]:w-40"
              customMenuClassName="w-[12.75rem]"
              callBackDataClickDropDown={onSelectDropdownItem}
              callBackClickButton={onSuggestButtonClick}
              items={footerData.nextActions}
              title={footerData.changeStatusButtonTitle}
              disabled={isDisable}
            />
          )}
          <CustomButtom
            title="Cancel"
            disabled={loading || isDisable}
            variant="text"
            customSize="medium"
            customColor="secondary"
            customClassName="w-32"
            onClick={handleCloseDrawerEdoDetail}
          />
        </div>
      </div>
    );
  }

  if (isOnlyEditInfo) {
    return (
      <div className="absolute bottom-0 left-0 z-50 w-full px-6 pt-4 pb-6 bg-white border-t border-solid border-t-blue-solitude rounded-bl-lg">
        <div className="flex items-center justify-between">
          <CustomButtom
            customClassName="w-32 [&_.anticon]:!text-red-base"
            customColor="secondary"
            loading={loadingUpdate}
            customSize="medium"
            title="Save"
            disabled={loadingUpdate || isDisable}
            onClick={onEditInfo}
          />
          <CustomButtom
            title="Cancel"
            variant="text"
            disabled={loading || isDisable}
            customSize="medium"
            customColor="secondary"
            customClassName="w-32"
            onClick={handleCloseDrawerEdoDetail}
          />
        </div>
      </div>
    );
  }

  return null;
};
