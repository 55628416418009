/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Drawer,
  Form,
  Image,
  Row,
  Typography,
} from "antd";
import {
  ConsigneeFlags,
  EDO_STATUS,
  ERROR_MESSAGE_CODE,
  SYSTEM_DATE_FORMAT,
  UPDATE_DATE_FORMAT,
} from "constants/constants";
import { ContainerField, FieldType } from "./ContainerField";
import { UpdatePayload, UpdateSingleEdo } from "pages/eod-management/services";
import {
  convertTextareaToString,
  getTimezoneOffset,
  getValueFromAsyncSelect,
  parseJsonGetValueOrLabel,
  removeRedundantConsigneeFlag,
} from "libs/helper";
import { isEmpty, isObject } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useCheckUpdateStatusToLogistic,
  useGetEdoDetail,
  useGetPartyAndConsigneeByBlId,
  useGetPickupdateRerurnDateByArrivalDate,
  useUpdateSingleEdo,
  useUpdateStatus,
} from "pages/eod-management/queries";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import { BLInfo } from "./BLInfo";
import ChangeToLogisticIcon from "assets/icons/changeToLogisticIcon.svg";
import { Dayjs } from "dayjs";
import { DeliveryInfo } from "./DeliveryInfo";
import DrawerCloseIcon from "assets/icons/XCloseIcon.svg";
import { EdoDetailForm } from "pages/eod-management/interfaces";
import { EdoStatus } from "interfaces/common-model";
import { FormFooter } from "./FormFooter";
import { ICustomer } from "pages/customer-management/interfaces";
import { ModalAddNewConsignee } from "pages/customer-management/components/ModalAddNewConsignee";
import { ModalConfirmation } from "components/ModalConfirmation";
import { ModalCustomer } from "pages/customer-management/components/ModalCustomer";
import { StatusLabel } from "components/StatusLabel";
import { ViewLoading } from "components/ViewLoading";
import { VoyageInfo } from "./VoyageInfo";
import { authConfig } from "constants/configs";
import dayjs from "dayjs";
import get from "lodash/get";
import { getEdoDetailModalConfig } from "pages/eod-management/updateStatusItemSetup";
import { useConfigStatusMatrix } from "pages/eod-management/hooks/use-config-status-matrix";
import { useEdoContext } from "contexts/edo-context";
import { useNotification } from "hooks/use-notification";
import { useUserRole } from "../SelectRole";

enum EdoDetailCollapseKeys {
  Voyage = "VOYAGE_INFO",
  BL = "BL_INFO",
  Delivery = "DELIVERY_INFO",
}

const INITIAL_EDO_DETAIL_FORM: EdoDetailForm = {
  // voyage info
  actualVoyageNo: "",
  laraVoyageNo: "",
  arrivalDate: dayjs(),
  vesselName: "",
  carrier: "",
  isApplyAllBL: true,

  // bl info
  blNo: { label: "", value: "" },
  consigneeName: { label: "", value: "" },
  receivingParty: { label: "", value: "" },
  podName: { label: "", value: "" },
  fPodName: { label: "", value: "" },
  isTemporary: true, //prevent call api when first visit with temp = false
  temporaryConsignee: "",

  // delivery info
  securityCode: "",
  releaseNo: "",
  pickupDate: dayjs(),
  isReturnByDays: false,
  returnDate: null,
  returnFullDate: dayjs(),
  emptyReturnDepotName: { label: "", value: "" },
  isOffHire: false,
  updateReason: "",
};

type DrawerEdoDetailProps = {
  refetchEdoList: () => void;
};

export const DrawerEdoDetail = ({ refetchEdoList }: DrawerEdoDetailProps) => {
  const edoDetailRef = useRef<HTMLDivElement>(null);

  const {
    doId = "",
    isOpenModalUpdateStatus,
    isOpenDrawerEdoDetail,
    isOpenModalCreateCustomer,
    customConsignee,
    timestamp,
    isOpenModalAddCustomConsingee,
    setEdo,
    edoFilter,
    isSelectAll,
    listExceptEdoId,
    isLoadingBulkUpdate,
  } = useEdoContext();
  const [form] = Form.useForm<EdoDetailForm>();
  const { handleOpenSuccessNotification } = useNotification();

  const edoDetailParams = {
    doId,
    timestamp,
    enabled: !!doId,
  };

  const {
    data,
    isLoading: isFetchingEdoDetail,
    refetch,
  } = useGetEdoDetail(edoDetailParams);

  const {
    mutateAsync: updateEdoStatusAndData,
    isLoading: isChangingStatusAndUpdateData,
  } = useUpdateStatus();

  const { mutateAsync: updateSingleEdo, isLoading: isUpdatingEdo } =
    useUpdateSingleEdo();

  const [hasFormFooter, setHasFormFooter] = useState(false);
  const [nextStatus, setNextStatus] = useState<EdoStatus | undefined | null>(
    undefined
  );
  const [isFieldRequiredForChangeStatus, setIsFieldRequiredForChangeStatus] =
    useState(false);

  const role = useUserRole();
  const edoDetail = get(data, "data");
  const canChangeStatusToRestore = get(edoDetail, "canRestore", true);
  const [consigneeMappingBl, setConsigneeMappingBl] = useState<
    {
      blId: string | null;
      tempSelectedConsignee: string | null;
    }[]
  >([]);

  const blNo = Form.useWatch("blNo", form);
  const consigneeName = Form.useWatch("consigneeName", form);
  const { mutateAsync: getPartyAndConsigneeByBlId } =
    useGetPartyAndConsigneeByBlId();

  const updateBlMappingConsignee = (keyToUpdate: string, newValue: string) => {
    const updatedData = consigneeMappingBl.map((item) => {
      if (item.blId === keyToUpdate) {
        return { ...item, tempSelectedConsignee: newValue };
      }
      return item;
    });
    const elementToUpdateIndex = updatedData.findIndex(
      (item) => item.blId === keyToUpdate
    );

    if (elementToUpdateIndex === -1) {
      updatedData.push({ blId: keyToUpdate, tempSelectedConsignee: newValue });
    }

    setConsigneeMappingBl(updatedData);
  };

  useEffect(() => {
    if (!isObject(blNo) && !isEmpty(blNo)) {
      const convertedBl = parseJsonGetValueOrLabel(blNo, true);
      getPartyAndConsigneeByBlId(`${convertedBl}`).then((data) => {
        const elementToUpdateIndex = consigneeMappingBl.findIndex(
          (item) => item.blId === convertedBl
        );
        const formValues: EdoDetailForm = {
          ...form.getFieldsValue(),
          consigneeName:
            elementToUpdateIndex !== -1 &&
            consigneeMappingBl[elementToUpdateIndex].tempSelectedConsignee
              ? consigneeMappingBl[elementToUpdateIndex].tempSelectedConsignee
              : data.data.selectedConsignee,
          receivingParty: data.data.receivingPartyInfo
            ? {
                label: data.data.receivingPartyInfo.name,
                value: data.data.receivingPartyInfo.code,
              }
            : null,
        };

        if (elementToUpdateIndex === -1) {
          convertedBl &&
            updateBlMappingConsignee(convertedBl, consigneeName as string);
        }
        form.setFieldsValue(formValues);
      });
    }
  }, [blNo]);

  useEffect(() => {
    if (!isObject(blNo) && !isEmpty(blNo) && blNo && consigneeName) {
      const convertedBl = parseJsonGetValueOrLabel(blNo, true);
      updateBlMappingConsignee(convertedBl, consigneeName as string);
    }
  }, [consigneeName]);

  const blInfo = get(edoDetail, "blInfo", {
    blCode: "",
    blName: "",
    listBill: [],
  });

  const blListOfCurrentContainerOptions = useMemo(() => {
    return blInfo.listBill.map((item) => ({
      label: item.name,
      value: JSON.stringify({ label: item.name, value: item.code }),
    }));
  }, [blInfo]);

  const pickupDate = Form.useWatch("pickupDate", form);
  const arrivalDate = Form.useWatch("arrivalDate", form);

  const isConsigneeTemporary = Form.useWatch("isTemporary", form);

  const config = useConfigStatusMatrix({
    role,
    status: (edoDetail?.status as EdoStatus) || EdoStatus.New,
  });

  const {
    mutate: getPickupdateRerurnDateByArrivalDate,
    isLoading: loadingRecalculateDate,
  } = useGetPickupdateRerurnDateByArrivalDate();

  const modalChangeEdoStatusConfig = useMemo(
    () => getEdoDetailModalConfig(nextStatus as EdoStatus, edoDetail?.isSOC),
    [nextStatus, edoDetail]
  );

  const handleAddnewConsignee = () => {
    setEdo((prev) => ({
      ...prev,
      isOpenModalAddCustomConsingee: true,
    }));
  };

  const onChangArivalDate = (date: Dayjs | null) => {
    // The reason why I don't use useEffect with arrivalDate as dependency is because
    // antd model not really have mounting phase, it never unmount once you enter the page
    // in that case all the state will never reset when you close the modal,
    // so useEffect will cause lots of side effects which take time to handle
    if (edoDetail && arrivalDate) {
      const payload = {
        edoId: edoDetail.id,
        arrivalDate: dayjs(date).format(SYSTEM_DATE_FORMAT),
      };
      getPickupdateRerurnDateByArrivalDate(payload, {
        onSuccess: (res) => {
          form.setFieldsValue({
            pickupDate: res.data.pickUpDate ? dayjs(res.data.pickUpDate) : null,
            returnDate: res.data.returnDays,
            returnFullDate: res.data.returnDate
              ? dayjs(res.data.returnDate)
              : null,
          });
        },
      });
    }
  };

  const collapseItems: CollapseProps["items"] = useMemo(
    () => [
      {
        key: EdoDetailCollapseKeys.Voyage,
        label: "Voyage Information",
        children: (
          <VoyageInfo
            config={config}
            isFieldRequiredForChangeStatus={isFieldRequiredForChangeStatus}
            onChangArivalDate={onChangArivalDate}
            loadingRecalculateDate={loadingRecalculateDate}
          />
        ),
      },
      {
        key: EdoDetailCollapseKeys.BL,
        label: "BL Information",
        children: (
          <BLInfo
            config={config}
            blNo={
              !isObject(blNo) && !isEmpty(blNo)
                ? parseJsonGetValueOrLabel(blNo, true)
                : blInfo && !isEmpty(blInfo)
                ? blInfo.blCode
                : null
            }
            isConsigneeTemporary={isConsigneeTemporary}
            blListOptions={blListOfCurrentContainerOptions as any}
            isFieldRequiredForChangeStatus={isFieldRequiredForChangeStatus}
            role={role}
            currentStatus={edoDetail?.status}
            nextStatus={nextStatus}
            callBackAddNewConsignee={handleAddnewConsignee}
          />
        ),
      },
      {
        key: EdoDetailCollapseKeys.Delivery,
        label: "Delivery Information",
        children: (
          <DeliveryInfo
            config={config}
            pickupDate={pickupDate}
            arrivalDate={arrivalDate}
            setFieldValue={form.setFieldValue}
            isByDays={Boolean(edoDetail?.doInfo?.returnNumber)}
            isFieldRequiredForChangeStatus={isFieldRequiredForChangeStatus}
            isEnabledUpdateReason={Boolean(
              edoDetail?.status &&
                [EdoStatus.Release, EdoStatus.ReleaseUpdate].includes(
                  edoDetail?.status
                )
            )}
            nextStatus={nextStatus}
            isLoadingRecalculateArrivalDate={loadingRecalculateDate}
          />
        ),
      },
    ],
    [
      blListOfCurrentContainerOptions,
      config,
      edoDetail?.blInfo.blCode,
      edoDetail?.doInfo?.returnNumber,
      edoDetail?.voyageInfo.arrivalDate,
      form.setFieldValue,
      isConsigneeTemporary,
      isFieldRequiredForChangeStatus,
      pickupDate,
      loadingRecalculateDate,
    ]
  );

  const handleApiError = (error: any) => {
    const msgCode = get(error, "response.data.Message");

    if (msgCode === ERROR_MESSAGE_CODE.MSG_02) {
      handleOpenSuccessNotification({
        type: "error",
        message: "Oops! Something went wrong!",
      });
      handleDisplayModalUpdateStatus(false);
    }
  };

  const isValidJson = (data: Object | string) => {
    return isObject(data) && !isEmpty(data);
  };

  const [checkAbleToSendEmailToLogistic, setOpenModalChecker] =
    useState<boolean>(false);

  const handleTriggerValidateForm = (
    value: boolean,
    nextStatus: EdoStatus | undefined | null
  ) => {
    setNextStatus(nextStatus);
    setIsFieldRequiredForChangeStatus(value);
    form.submit();
  };

  const finalFormPayload: UpdatePayload = useMemo(() => {
    const formValues = form.getFieldsValue();
    return {
      currentTimezone: getTimezoneOffset(),
      listDOId: [edoDetail?.id!],
      status: nextStatus,
      onListScreen: false,
      voyageInfo: {
        actualVoyageNo: formValues.actualVoyageNo,
        arrivalDate: formValues.arrivalDate
          ? dayjs(formValues.arrivalDate).toISOString()
          : null,
        carrier: formValues.carrier,
        isApplyAllBL: Boolean(formValues.isApplyAllBL),
        laraVoyageNo: formValues.laraVoyageNo,
        vesselName: formValues.vesselName,
      },
      blInfo: {
        blCode: isValidJson(formValues.blNo as Object | string)
          ? getValueFromAsyncSelect(formValues.blNo)
          : parseJsonGetValueOrLabel(
              formValues.blNo as string,
              true //get value
            ),
        blName: isValidJson(formValues.blNo as Object | string)
          ? getValueFromAsyncSelect(formValues.blNo, true)
          : parseJsonGetValueOrLabel(
              formValues.blNo as string,
              false //get value
            ), //get label value
        consigneeCode: formValues.isTemporary
          ? formValues.temporaryConsignee
          : removeRedundantConsigneeFlag(
              getValueFromAsyncSelect(formValues.consigneeName, true), //check consignee
              [
                ConsigneeFlags.CONSIGNEE,
                ConsigneeFlags.NOTIFY1S,
                ConsigneeFlags.NOTIFY2S,
              ]
            ),
        consigneeName: formValues.isTemporary
          ? formValues.temporaryConsignee
          : removeRedundantConsigneeFlag(
              getValueFromAsyncSelect(formValues.consigneeName, true), //check consignee
              [
                ConsigneeFlags.CONSIGNEE,
                ConsigneeFlags.NOTIFY1S,
                ConsigneeFlags.NOTIFY2S,
              ]
            ),
        fPodCode: isValidJson(formValues.fPodName as Object | string)
          ? getValueFromAsyncSelect(formValues.fPodName)
          : parseJsonGetValueOrLabel(formValues.fPodName as string, true),
        fPodName: isValidJson(formValues.fPodName as Object | string)
          ? getValueFromAsyncSelect(formValues.fPodName, true)
          : parseJsonGetValueOrLabel(formValues.fPodName as string, false),
        isTemporary: formValues.isTemporary,
        podCode: isValidJson(formValues.podName as Object | string)
          ? getValueFromAsyncSelect(formValues.podName)
          : parseJsonGetValueOrLabel(formValues.podName as string, true),
        podName: isValidJson(formValues.podName as Object | string)
          ? getValueFromAsyncSelect(formValues.podName, true)
          : parseJsonGetValueOrLabel(formValues.podName as string, false),
        receivingPartyCode: isValidJson(
          formValues.receivingParty as Object | string
        )
          ? getValueFromAsyncSelect(formValues.receivingParty)
          : parseJsonGetValueOrLabel(formValues.receivingParty as string, true),
        receivingPartyName: isValidJson(
          formValues.receivingParty as Object | string
        )
          ? getValueFromAsyncSelect(formValues.receivingParty, true)
          : parseJsonGetValueOrLabel(
              formValues.receivingParty as string,
              false
            ),
      },
      doInfo: {
        emptyReturnDepotCode: isValidJson(
          formValues.emptyReturnDepotName as Object | string
        )
          ? getValueFromAsyncSelect(formValues.emptyReturnDepotName) || null
          : parseJsonGetValueOrLabel(
              formValues.emptyReturnDepotName as string,
              true
            ),
        emptyReturnDepotName: isValidJson(
          formValues.emptyReturnDepotName as Object | string
        )
          ? getValueFromAsyncSelect(formValues.emptyReturnDepotName, true) ||
            null
          : parseJsonGetValueOrLabel(
              formValues.emptyReturnDepotName as string,
              false
            ),
        isOffHire: formValues.isOffHire,
        pickupDate: formValues.pickupDate
          ? dayjs(formValues.pickupDate).toISOString()
          : null,
        releaseNo: formValues.releaseNo,
        returnDate: formValues.returnDate ? Number(formValues.returnDate) : 0,
        returnFullDate: formValues.returnFullDate
          ? dayjs(formValues.returnFullDate).toISOString()
          : null,
        returnNumber: Boolean(formValues.isReturnByDays),
        securityCode: formValues.securityCode,
        updateReason: formValues.updateReason,
      },
      bucketName: authConfig.s3Bucket || "",
      filterRequestModel: { ...edoFilter, roleEnum: role },
      isSelectAll,
      listExceptEdoId,
    };
  }, [
    form,
    edoDetail,
    authConfig,
    role,
    nextStatus,
    handleTriggerValidateForm,
  ]);

  const handleUpdateStatus = (payload?: UpdatePayload) => {
    updateEdoStatusAndData(payload || finalFormPayload)
      .then(() => {
        handleOpenSuccessNotification({
          message: "eDO has been updated successfully!",
        });
        refetch(); // refetch edo detail
        refetchEdoList(); // refetch edo list
        handleDisplayModalUpdateStatus(false);
        setOpenModalChecker(false);
        edoDetailRef.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      })
      .catch(handleApiError);
  };

  const {
    mutate: checkUpdateStatus,
    isLoading: isLoadingCheckUpdateToLogistic,
  } = useCheckUpdateStatusToLogistic();

  const handleChangeStatus = () => {
    handleUpdateStatus();
  };

  const handleCheckUpdateStatus = () => {
    const payload = { ...finalFormPayload };
    checkUpdateStatus(payload, {
      onSuccess: () => {
        handleUpdateStatus(payload);
      },
      onError: (error: any) => {
        if (error.response.data.Message === ERROR_MESSAGE_CODE.MSG_01) {
          setOpenModalChecker(true);
        }
      },
    });
  };

  const handleChangeStatusAndUpdateEdo = () => {
    if (nextStatus === EDO_STATUS.WaitForLog.value) {
      handleCheckUpdateStatus();
    } else {
      handleUpdateStatus();
    }
  };

  const handleCancelLogisticChecker = () => {
    setOpenModalChecker(false);
    handleDisplayModalUpdateStatus(false);
  };

  const handleUpdateEdo = () => {
    const formValues = form.getFieldsValue();

    const payload: UpdateSingleEdo = {
      currentTimezone: getTimezoneOffset(),
      edoId: edoDetail?.id!,
      roleEnum: role,
      actualVoyageNo: formValues.actualVoyageNo,
      vesselName: formValues.vesselName,
      carrier: formValues.carrier,
      arrivalDate: formValues.arrivalDate
        ? dayjs(formValues.arrivalDate).format(SYSTEM_DATE_FORMAT)
        : null,
      isApplyAll: formValues.isApplyAllBL,
      blNo: isValidJson(formValues.blNo as Object | string)
        ? getValueFromAsyncSelect(formValues.blNo)
        : parseJsonGetValueOrLabel(
            formValues.blNo as string,
            true //get value
          ),
      consignee: formValues.isTemporary
        ? formValues.temporaryConsignee
        : convertTextareaToString(
            removeRedundantConsigneeFlag(
              getValueFromAsyncSelect(formValues.consigneeName, true),
              [
                ConsigneeFlags.CONSIGNEE,
                ConsigneeFlags.NOTIFY1S,
                ConsigneeFlags.NOTIFY2S,
              ]
            )
          ),
      receivingParty: isValidJson(formValues.receivingParty as Object | string)
        ? getValueFromAsyncSelect(formValues.receivingParty)
        : parseJsonGetValueOrLabel(formValues.receivingParty as string, true),
      pod: isValidJson(formValues.podName as Object | string)
        ? getValueFromAsyncSelect(formValues.podName)
        : parseJsonGetValueOrLabel(formValues.podName as string, true),
      fpod: isValidJson(formValues.fPodName as Object | string)
        ? getValueFromAsyncSelect(formValues.fPodName)
        : parseJsonGetValueOrLabel(formValues.fPodName as string, true),
      pickUpdate: formValues.pickupDate
        ? dayjs(formValues.pickupDate).format(SYSTEM_DATE_FORMAT)
        : null,
      returnDate: formValues.returnFullDate
        ? dayjs(formValues.returnFullDate).format(SYSTEM_DATE_FORMAT)
        : null,
      returnDays: formValues.returnDate ? Number(formValues.returnDate) : 0,
      isOffHire: formValues.isOffHire,
      emptyReturnDepot: isValidJson(
        formValues.emptyReturnDepotName as Object | string
      )
        ? getValueFromAsyncSelect(formValues.emptyReturnDepotName)
        : parseJsonGetValueOrLabel(
            formValues.emptyReturnDepotName as string,
            true
          ),
      updateReason: formValues.updateReason,
      userId: "", // TODO: need to update real user id
      isTempConsignee: formValues.isTemporary,
      bucketName: authConfig.s3Bucket || "",
    };
    updateSingleEdo(payload)
      .then(() => {
        handleOpenSuccessNotification({
          message: "eDO has been updated successfully!",
        });
        refetch(); // refetch edo detail
        refetchEdoList(); // refetch edo list
        handleDisplayModalUpdateStatus(false);
        edoDetailRef.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      })
      .catch((error) => {
        // TODO: handle error
      });
  };

  const handleCloseDrawerEdoDetail = () => {
    form.resetFields();
    setNextStatus(undefined);
    setIsFieldRequiredForChangeStatus(false);
    setEdo((prev) => ({
      ...prev,
      isOpenModalCreateCustomer: false,
      isOpenModalUpdateStatus: false,
      isOpenDrawerEdoDetail: false,
      customConsignee: null,
    }));
  };

  const handleCloseModalCustomer = useCallback(() => {
    setEdo((prev) => ({
      ...prev,
      isOpenModalCreateCustomer: false,
    }));
  }, [setEdo]);

  const handleDisplayModalUpdateStatus = useCallback(
    (isOpen: boolean) => {
      // hidden/display modal confirm to change status and update eDO data
      setEdo((prev) => ({
        ...prev,
        isOpenModalUpdateStatus: isOpen,
      }));
    },
    [setEdo]
  );

  const handleUpdateReceivingPartyField = (customer: ICustomer) => {
    form.setFieldValue("receivingParty", {
      label: `${customer.vatId} - ${customer.customerName}`,
      value: customer.id,
    });
  };

  const handleSubmitForm = () => {
    // all fields are valid, if the "nextStatus" is null (only update edo data)
    // Do not show the modal confirmation
    if (nextStatus === null) {
      handleUpdateEdo();
      return;
    }
    handleDisplayModalUpdateStatus(true);
  };

  useEffect(() => {
    if (edoDetail) {
      const { voyageInfo, blInfo, doInfo } = edoDetail;
      if (blInfo) {
        const formValues: EdoDetailForm = {
          // voyage info
          actualVoyageNo: voyageInfo.actualVoyageNo,
          laraVoyageNo: voyageInfo.laraVoyageNo,
          arrivalDate: voyageInfo.arrivalDate
            ? dayjs(voyageInfo.arrivalDate)
            : null,
          vesselName: voyageInfo.vesselName,
          carrier: voyageInfo.carrier,
          isTemporary: Boolean(blInfo.isTemporary),
          temporaryConsignee: blInfo.consigneeCode,
          isApplyAllBL: Boolean(voyageInfo.isApplyAllBL),
          // bl info
          blNo: blInfo.blCode
            ? {
                label: blInfo.blName,
                value: blInfo.blCode,
              }
            : null,
          consigneeName: blInfo.consigneeCode
            ? {
                label: blInfo.consigneeName,
                value: blInfo.consigneeCode,
              }
            : null,
          receivingParty: blInfo.receivingPartyCode
            ? {
                label: `${blInfo.receivingPartyVatId} - ${blInfo.receivingPartyName}`,
                value: blInfo.receivingPartyCode,
              }
            : null,
          podName: blInfo.podCode
            ? {
                label: blInfo.podName,
                value: blInfo.podCode,
              }
            : null,
          fPodName: blInfo.fPodCode
            ? {
                label: blInfo.fPodName,
                value: blInfo.fPodCode,
              }
            : null,
          // delivery info
          securityCode: doInfo.securityCode,
          releaseNo: doInfo.releaseNo,
          pickupDate: doInfo.pickupDate ? dayjs(doInfo.pickupDate) : null,
          isReturnByDays:
            doInfo.returnNumber !== null ? Boolean(doInfo.returnNumber) : null,
          returnDate: edoDetail.doInfo.returnDate || null,
          returnFullDate: doInfo.returnFullDate
            ? dayjs(doInfo.returnFullDate)
            : null,
          emptyReturnDepotName: doInfo.emptyReturnDepotCode
            ? {
                label: doInfo.emptyReturnDepotName,
                value: doInfo.emptyReturnDepotCode,
              }
            : null,
          isOffHire: Boolean(doInfo.isOffHire),
          updateReason: doInfo.updateReason,
        };
        form.setFieldsValue(formValues);
      }
    }
  }, [edoDetail, form]);

  useEffect(() => {
    if (customConsignee) {
      form.setFieldsValue({
        consigneeName: customConsignee,
      });
      setEdo((prev) => ({
        ...prev,
        isOpenModalAddCustomConsingee: false,
      }));
    }
  }, [customConsignee]);

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      contentWrapperStyle={{ width: 712 }}
      open={isOpenDrawerEdoDetail}
      placement="right"
      className="rounded-tl-lg rounded-bl-lg"
      onClose={handleCloseDrawerEdoDetail}
      bodyStyle={{ padding: 0 }}
    >
      {isFetchingEdoDetail ? (
        <ViewLoading />
      ) : (
        <div className="p-6" ref={edoDetailRef}>
          <div className="flex items-center justify-between">
            <Typography.Text className="font-antonio font-bold text-[1.625rem] text-blue-sapphire leading-8 tracking-[-0.26px] !block">
              {edoDetail?.blInfo.blName || "---"}
            </Typography.Text>
            <Image
              preview={false}
              className="cursor-pointer"
              alt="custom-drawer-close-icon"
              src={DrawerCloseIcon}
              onClick={handleCloseDrawerEdoDetail}
            />
          </div>
          <div className="flex items-center justify-start mt-3">
            <StatusLabel
              isResponsive={false}
              labelStatus={edoDetail?.status ?? EdoStatus.New}
            />
            <Typography.Text className="!block font-roboto font-normal text-xs leading-[0.875rem] text-blue-waikawa-grey">
              Update at:&nbsp;
              {edoDetail?.createdDate
                ? dayjs(edoDetail?.modifiedDate).format(UPDATE_DATE_FORMAT)
                : "---"}
            </Typography.Text>
          </div>
          <Row className="mt-8" gutter={[16, 0]}>
            <Col span="8">
              <ContainerField value={edoDetail?.containerNumber || "---"} />
            </Col>
            <Col span="8">
              <ContainerField
                type={FieldType.size}
                value={edoDetail?.containerSize || "---"}
              />
            </Col>
            <Col span="8">
              <ContainerField
                type={FieldType.seal}
                value={edoDetail?.containerSealNumber || "---"}
              />
            </Col>
          </Row>
          <Divider className="mb-4" />
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            initialValues={INITIAL_EDO_DETAIL_FORM}
            className={hasFormFooter ? "pb-16" : "p-0"}
            onFinish={handleSubmitForm}
            requiredMark={false}
          >
            <Collapse
              items={collapseItems}
              bordered={false}
              expandIconPosition="end"
              defaultActiveKey={[
                EdoDetailCollapseKeys.BL,
                EdoDetailCollapseKeys.Delivery,
                EdoDetailCollapseKeys.Voyage,
              ]}
              className="[&_.ant-collapse-item]:border-none [&_.ant-collapse-header]:border-b [&_.ant-collapse-header]:border-solid [&_.ant-collapse-header]:!border-blue-solitude [&_.ant-collapse-header]:!p-4 [&_.ant-collapse-header]:bg-blue-solitude-light [&_.ant-collapse-content]:!bg-white [&_.ant-collapse-content]:pt-4 [&_.ant-collapse-content-box]:!p-0 [&_.ant-collapse-header-text]:text-base [&_.ant-collapse-header-text]:font-antonio [&_.ant-collapse-header-text]:font-bold [&_.ant-collapse-header-text]:leading-5 [&_.ant-collapse-header-text]:tracking-[-0.04px] [&_.ant-collapse-header-text]:!text-red-base [&_.ant-collapse-header-text]:select-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowUp}
                  />
                ) : (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowDown}
                  />
                )
              }
            />
            <FormFooter
              isDisable={loadingRecalculateDate}
              userRole={role}
              loading={
                isChangingStatusAndUpdateData ||
                isLoadingCheckUpdateToLogistic ||
                loadingRecalculateDate
              }
              loadingUpdate={isUpdatingEdo}
              isSOC={edoDetail?.isSOC}
              edoStatus={edoDetail?.status ?? EdoStatus.New}
              nextStatusData={config.nextStatus}
              setHasFormFooter={setHasFormFooter}
              onTriggerValidateForm={handleTriggerValidateForm}
              onCloseDrawerEdoDetail={handleCloseDrawerEdoDetail}
              canRestore={canChangeStatusToRestore}
              isDisableAllWhenBulkUpdate={isLoadingBulkUpdate}
            />
          </Form>
        </div>
      )}
      <ModalCustomer
        isCustomerManagement={false}
        open={isOpenModalCreateCustomer}
        onCancel={handleCloseModalCustomer}
        onCreateCustomerSuccess={handleUpdateReceivingPartyField}
      />
      {isOpenModalAddCustomConsingee && (
        <ModalAddNewConsignee
          customConsignee={customConsignee}
          open={isOpenModalAddCustomConsingee}
        />
      )}
      <ModalConfirmation
        isLoadingBtn={
          isChangingStatusAndUpdateData ||
          isUpdatingEdo ||
          isLoadingCheckUpdateToLogistic
        }
        isDisableButton={
          isChangingStatusAndUpdateData ||
          isUpdatingEdo ||
          isLoadingCheckUpdateToLogistic
        }
        open={isOpenModalUpdateStatus}
        onOk={handleChangeStatusAndUpdateEdo}
        onCancel={() => handleDisplayModalUpdateStatus(false)}
        customImage={
          <Image
            preview={false}
            alt="modal-confirm-change-edo-status-icon"
            src={modalChangeEdoStatusConfig?.icon}
          />
        }
        okText={modalChangeEdoStatusConfig.okText}
        cancelText={modalChangeEdoStatusConfig.cancelText}
        title={modalChangeEdoStatusConfig?.title!}
        description={modalChangeEdoStatusConfig?.description}
        subDescription={modalChangeEdoStatusConfig?.subDescription}
      />
      <ModalConfirmation
        isDisableButton={isChangingStatusAndUpdateData}
        open={checkAbleToSendEmailToLogistic}
        onOk={handleChangeStatus}
        onCancel={handleCancelLogisticChecker}
        customImage={
          <Image
            preview={false}
            alt="modal-confirm-change-edo-status-icon"
            src={ChangeToLogisticIcon}
          />
        }
        okText="Confirm"
        cancelText="Cancel"
        title={"No Logistic email found"}
        description={
          "We cannot found matching Logistic email(s) associate with selected Depot(s). Do you still want to continue proceed and change status without sending email to Logistic team?"
        }
      />
    </Drawer>
  );
};
