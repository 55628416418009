import { Col, Row } from "antd";

import ArrowDown from "assets/icons/ArrowDown.svg";
import { AsyncSelect } from "../DrawerEdoDetail/AsyncSelect";
import { ConsigneeInput } from "../DrawerEdoDetail/ConsigneeInput";
import { DefaultOptionType } from "antd/es/select";
import { ErrorInput } from "pages/customer-management/components/ErrorInput";
import { FormSelect } from "components/form/Select";
import { useGetCustomerList } from "pages/customer-management/queries";

type BLInformationProps = {
  isMultiple: boolean;
  blNo?: string | null;
  isConsigneeTemporary: boolean;
  blListOptions?: DefaultOptionType[];
  isHasAnyReleaseStatus?: boolean;
  callBackAddNewConsignee: () => void;
};

export const BLInformation = ({
  blNo,
  isMultiple,
  isConsigneeTemporary,
  blListOptions,
  isHasAnyReleaseStatus = false,
  callBackAddNewConsignee,
}: BLInformationProps) => (
  <Row className="pb-2" gutter={[16, 0]}>
    <Col span={24}>
      {!isMultiple ? (
        <FormSelect
          options={blListOptions}
          disabled={isHasAnyReleaseStatus}
          label="BL No."
          name="blNo"
          rules={[
            {
              required: true,
              message: <ErrorInput />,
            },
          ]}
          suffixIcon={
            isMultiple ? null : (
              <img alt="select-arrow-down-icon" src={ArrowDown} />
            )
          }
          placeholder="Enter Email"
          customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
        />
      ) : (
        <FormSelect
          mode="tags"
          options={blListOptions}
          label="BL No."
          open={false}
          name="blNo"
          disabled={isMultiple || isHasAnyReleaseStatus}
          rules={[
            {
              required: true,
              message: <ErrorInput />,
            },
          ]}
          suffixIcon={
            isMultiple ? null : (
              <img alt="select-arrow-down-icon" src={ArrowDown} />
            )
          }
          placeholder="Enter Email"
          customSelectClassName="[&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-scroll [&_.ant-select-selection-overflow]:no-scrollbar [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
        />
      )}
    </Col>

    {!isMultiple && (
      <Col span={12}>
        <ConsigneeInput
          disabled={isMultiple || isHasAnyReleaseStatus}
          controlName="isConsigneeTemporary"
          name="consigneeName"
          subName="consigneeTemporary"
          blNo={blNo}
          subRules={[
            {
              required: true,
              message: <ErrorInput />,
            },
          ]}
          rules={[
            {
              required: true,
              message: <ErrorInput />,
            },
          ]}
          label="Consignee"
          isConsigneeTemporary={isConsigneeTemporary}
          isShopCustomizeConsignee
          callBackAddNewConsignee={callBackAddNewConsignee}
        />
      </Col>
    )}
    <Col span={isMultiple ? 24 : 12}>
      <AsyncSelect
        disabled={isHasAnyReleaseStatus}
        virtual
        showSearch
        allowCreateNewOne
        name="receivingParty"
        label="Receiving Party"
        filterOption={false}
        placeholder="Enter Receiving party"
        optionKey={{ label: "customerName", value: "id" }}
        fetchOptions={useGetCustomerList}
        params={{ Status: true, SearchForNameOnly: true }}
        rules={[
          {
            required: true,
            message: <ErrorInput />,
          },
        ]}
      />
    </Col>
  </Row>
);
