import "./index.css";

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "libs/auth-config";
import { fetchApi } from "libs/fetchAPI";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import React from "react";
import reportWebVitals from "./reportWebVitals";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(async (message) => {
  // Update UI or interact with EventMessage here
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    fetchApi({
      //audit login for Azure
      url: `accounts/login`,
      method: "post",
      isAuth: true,
    });
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
