import {
  Alert,
  Col,
  Form,
  Image,
  Modal,
  ModalProps,
  Row,
  Typography,
} from "antd";
import { useCreateCustomer, useUpdateCustomer } from "../queries";
import { useEffect, useState } from "react";

import CrossIcon from "assets/icons/CrossIcon.svg";
import { CustomButtom } from "components/button/CustomButton";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import { ErrorInput } from "./ErrorInput";
import { FormCheckbox } from "components/form/Checkbox";
import { FormInput } from "components/form/Input";
import { FormSelect } from "components/form/Select";
import { ICustomer } from "../interfaces";
import { UpdateCustomerPayload } from "../services";
import { emailValidator } from "libs/helper";
import get from "lodash/get";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { useNotification } from "hooks/use-notification";

type ModalCustomerProps = ModalProps & {
  isCustomerManagement?: boolean;
  customer?: ICustomer | null;
  refetchOption?: () => void;
  onCancel?: () => void;
  onCreateCustomerSuccess?: (customer: ICustomer) => void;
};

type CustomerForm = {
  listEmail: string[];
  customerName: string;
  vatId: string;
  address: string;
  phoneNumber: string;
  status: boolean;
};

const INITIAL_FORM_VALUES: CustomerForm = {
  listEmail: [],
  customerName: "",
  vatId: "",
  address: "",
  phoneNumber: "",
  status: true,
};

export const ModalCustomer = ({
  customer,
  onCancel,
  refetchOption,
  isCustomerManagement = true,
  onCreateCustomerSuccess,
  ...restProps
}: ModalCustomerProps) => {
  const [form] = Form.useForm<CustomerForm>();
  const { handleOpenSuccessNotification } = useNotification();

  const { mutateAsync: createCustomer, isLoading: isCreatingCustomer } =
    useCreateCustomer();

  const { mutateAsync: updateCustomer, isLoading: isUpdatingCustomer } =
    useUpdateCustomer();

  const [isCreate, setIsCreate] = useState(true);
  const vatId = Form.useWatch("vatId", form);

  const vatIdError = form.getFieldError("vatId");
  const vatIdErrorMessage =
    vatIdError.length < 1 || !vatId ? undefined : (
      <ErrorInput message={vatIdError[0]} />
    );

  const handleCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const handleApiError = (error: any) => {
    const msgCode = get(error, "response.data.Message");

    if (msgCode === ERROR_MESSAGE_CODE.MSG_03) {
      form.setFields([
        {
          name: "vatId",
          errors: ["VAT ID existed"],
        },
      ]);
    }

    if (msgCode === ERROR_MESSAGE_CODE.MSG_04) {
      form.setFields([
        {
          name: "vatId",
          errors: ["Invalid input value"],
        },
      ]);
    }
  };
  const currentCountry = useCountry();

  const handleSubmitForm = (values: CustomerForm) => {
    if (isCreate) {
      // create new customer
      createCustomer({ ...values, countryName: currentCountry })
        .then((response) => {
          const createdCustomer = response.data;

          // show notification
          handleOpenSuccessNotification({
            message: "Add new customer successfully!",
          });
          // trigger the action when create a new customer successfully
          onCreateCustomerSuccess && onCreateCustomerSuccess(createdCustomer);
          // refetch customer list
          refetchOption && refetchOption();
          // close modal and reset form
          handleCancelModal();
        })
        .catch(handleApiError);
    } else {
      // update customer
      const payload: UpdateCustomerPayload = {
        ...values,
        id: customer!.id,
        countryName: currentCountry,
      };

      updateCustomer(payload)
        .then(() => {
          handleOpenSuccessNotification({
            message: "Customer information has been updated successfully!",
          });
          refetchOption && refetchOption();
          handleCancelModal();
        })
        .catch(handleApiError);
    }
  };

  useEffect(() => {
    setIsCreate(!customer);
  }, [customer]);

  useEffect(() => {
    if (customer) {
      form.setFieldsValue(customer);
    }
  }, [customer, form]);

  return (
    <Modal
      closable={false}
      footer={false}
      className="w-[512px] h-[414px]"
      onCancel={handleCancelModal}
      {...restProps}
    >
      <div className="custom-confirmation-modal-customer-management ">
        <Typography.Text className="!block text-blue-sapphire text-xl not-italic font-antonio font-bold tracking-[-1px] m-0">
          {isCreate ? "Create New Customer" : "Edit Customer"}
        </Typography.Text>
        <Typography.Text className="!block text-blue-rock-blue font-roboto text-sm not-italic font-normal mt-2">
          Enter Customer’s Information
        </Typography.Text>
        <div className="mt-5">
          <Form
            form={form}
            initialValues={INITIAL_FORM_VALUES}
            onFinish={handleSubmitForm}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
          >
            <Row className="pb-6" gutter={[16, 0]}>
              <Col span={12}>
                <FormInput
                  label="VAT ID"
                  name="vatId"
                  help={vatIdErrorMessage}
                  placeholder="Enter VAT ID"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                    {
                      min: 10,
                      max: 15,
                      message: <ErrorInput message="Invalid VAT ID" />,
                    },
                  ]}
                  onFocus={() =>
                    form.setFields([{ name: "vatId", errors: [] }])
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  label="Customer Name"
                  name="customerName"
                  placeholder="Enter Customer name"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label="Phone Number"
                  name="phoneNumber"
                  placeholder="Enter Phone number"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label="Address"
                  name="address"
                  placeholder="Enter Address"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <FormSelect
                  showSearch
                  mode="tags"
                  label="Emails"
                  open={false}
                  name="listEmail"
                  rules={[
                    {
                      required: true,
                      message: <ErrorInput />,
                    },
                    {
                      validator: emailValidator,
                      message: (
                        <ErrorInput message="Incorrect email format(s)" />
                      ),
                    },
                  ]}
                  suffixIcon={null}
                  placeholder="Enter Email(s)"
                  customSelectClassName="[&_.ant-select-selection-item]:!my-1 !h-auto [&_.ant-select-selector]:!py-1.5 [&_.ant-select-selector]:!px-2.5 [&_.ant-select-selection-search]:!ms-[0.1875rem]"
                  onFocus={() =>
                    form.setFields([{ name: "listEmail", errors: [] }])
                  }
                />
              </Col>
              <Col span={24}>
                <Alert
                  showIcon
                  closable
                  closeIcon={
                    <Image
                      preview={false}
                      src={CrossIcon}
                      alt="alert-cross-icon"
                    />
                  }
                  message="Insert emails and press Save to continue"
                  type="info"
                  className="w-full rounded py-1.5 px-3 border-none bg-blue-alice-blue [&_.ant-alert-message]:text-blue-sapphire [&_.ant-alert-message]:font-roboto [&_.ant-alert-message]:font-normal [&_.ant-alert-message]:text-xs [&_.ant-alert-message]:leading-[0.875rem] mb-4"
                />
              </Col>
              <Col span={24}>
                <FormCheckbox
                  name="status"
                  disabled={!isCustomerManagement}
                  checkboxLabel="Customer is Active?"
                  customFormItemClassName="mb-0"
                  valuePropName="checked"
                />
              </Col>
            </Row>

            <div className="grid grid-cols-12 gap-x-4">
              <CustomButtom
                title="Cancel"
                disabled={isCreatingCustomer || isUpdatingCustomer}
                variant="outline"
                customSize="medium"
                customColor="secondary"
                customClassName="col-span-6"
                onClick={handleCancelModal}
              />
              <CustomButtom
                title="Save"
                disabled={isCreatingCustomer || isUpdatingCustomer}
                loading={isCreatingCustomer || isUpdatingCustomer}
                customSize="medium"
                customColor="secondary"
                customClassName="col-span-6"
                htmlType="submit"
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
